import * as React from 'react';
import {useContext} from 'react';
import {FormattedMessage} from 'react-intl';

import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {GoTo} from '../model';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';

import styles from './GoToComponent.pcss';
import {SelectGoToBlock} from './SelectGoTo';
import {StepElementProps} from './StepElementProps';

type GoToOption = 'false' | 'repeated' | 'outOfRange' | 'invalidLength';

type OptionsProps = { availableOptions: GoToOption[] };
type TypeProps = { goTo?: GoTo };

export const GoToComponent = ({question, onChange, availableOptions}: StepElementProps<TypeProps> & OptionsProps) => {
    const {blocks} = useContext<SurveyContextType>(SurveyContext);
    const changeGoTo = (key: string) => (blockId: string) => {
        onChange({
            ...question,
            goTo: {
                ...question.goTo,
                [key]: blockId
            }
        });
    };

    const goTo = question.goTo || {};
    return (
        <div>
            {availableOptions.map(goToKey => (
                <div key={goToKey} data-key={goToKey} className={styles.itemContainer}>
                    <div className={styles.itemLabel} data-test="go-to-label">
                        <FormattedMessage id={`survey-creator.goTo.${goToKey}Label`}/>
                    </div>
                    <div className={styles.itemDescription} data-test="go-to-description">
                        <FormattedMessage id={`survey-creator.goTo.${goToKey}Description`}/>
                    </div>
                    <ElementWithLabel labelId={'survey-creator.goTo.titleLabel'} className={styles.selectContainer}
                                      data-test="go-to-select" noPadding>
                        <SelectGoToBlock
                          currentBlockId={goTo[goToKey]}
                          onChange={changeGoTo(goToKey)}
                          blocks={blocks.available()}
                          includeContinue
                          includeRepeat={goToKey === 'outOfRange' || goToKey === 'invalidLength'}
                        />
                    </ElementWithLabel>
                </div>
            ))}
        </div>
    );
};
