import _ from 'lodash';
import * as React from 'react';
import { useContext } from 'react';

import { ElementContainer } from '../../components/ElementContainer/ElementContainer';
import { ElementWithLabel } from '../../components/ElementWithLabel/ElementWithLabel';
import { Input } from '../../components/Input';
import { Label } from '../../components/Label';
import { TextAreaWithVariables } from '../../components/TextArea/TextAreaWithVariables';
import { DeleteIcon } from '../../icons';
import { RedirectHeader } from '../model';
import { SurveyContext } from '../SurveyCreatorContext';
import { AddButton } from './AddButton';
import { StepElementProps } from './StepElementProps';

import styles from './RedirectHeadersComponent.pcss';

const RedirectHeaderInput = (props: {
  header: RedirectHeader;
  onChange: (header: RedirectHeader) => void;
}) => {
  const { variables } = useContext(SurveyContext);
  const availableVariables = variables.getVariablesForCreator();

  return (
    <div data-test="redirect-header" className={styles.headerElement}>
      <ElementWithLabel
        labelId={'survey-creator.redirectStep.redirectHeaderName'}
        noPadding
        className={styles.headerElementInput}
      >
        <Input
          dataTest="name"
          value={_.isNil(props.header.name) ? '' : props.header.name}
          onChange={(name) => props.onChange({ ...props.header, name })}
          width="100%"
        />
      </ElementWithLabel>
      <ElementWithLabel
        labelId={'survey-creator.redirectStep.redirectHeaderValue'}
        noPadding
        className={styles.headerElementInput}
      >
        <TextAreaWithVariables
          dataTest="value"
          value={_.isNil(props.header.value) ? '' : props.header.value}
          onChange={(value) => {
            if (Array.isArray(value)) {
              props.onChange({ ...props.header, value: value.join('') });
            } else {
              props.onChange({ ...props.header, value });
            }
          }}
          asInput
          availableVariables={availableVariables}
        />
      </ElementWithLabel>
    </div>
  );
};

type RedirectHeadersProps = { redirectHeaders?: RedirectHeader[] };

export function RedirectHeaders({
  question,
  onChange,
}: StepElementProps<RedirectHeadersProps>) {
  const headers = question.redirectHeaders ?? [];

  const addHeader = () =>
    onChange({
      ...question,
      redirectHeaders: [...headers, { name: '', value: '' }],
    });

  const removeHeader = (index: number) =>
    onChange({
      ...question,
      redirectHeaders: headers.filter((_value, id) => id !== index),
    });

  const onHeaderChange = (changedHeader: RedirectHeader, index: number) => {
    onChange({
      ...question,
      redirectHeaders: headers.map((header, id) =>
        id === index ? changedHeader : header
      ),
    });
  };

  return (
    <ElementContainer>
      <Label labelId={'survey-creator.redirectStep.redirectHeadersLabel'} />
      <div>
        {headers.map((header, index) => (
          <div
            key={index}
            data-test={`redirect-header-container-${index}`}
            className={styles.headerContainer}
          >
            <RedirectHeaderInput
              header={header}
              onChange={(changedHeader) => onHeaderChange(changedHeader, index)}
            />
            <div
              data-test="remove-redirect-header-button"
              onClick={() => removeHeader(index)}
              className={styles.remove}
            >
              <DeleteIcon />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.addButton}>
        <AddButton
          onClick={addHeader}
          dataTest={'add-redirect-header-button'}
          labelId={'survey-creator.redirectStep.addRedirectHeaderButton'}
        />
      </div>
    </ElementContainer>
  );
}
