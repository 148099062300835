export enum StepTabType {
    Question = 'question',
    General = 'general',
    Answer = 'answer',
    Intents = 'intents',
    SpecialCharacters = 'specialCharacters'
}

export enum BlockDescriptionTabType {
    Preview = 'preview',
}