import React, { useCallback, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useSelector } from 'react-redux';

import styles from './LiveStatus.pcss';
import { currentUser } from '../selectors';
import { useCurrentBot } from '../utils/CurrentBot';
import { Link, useLocation } from 'react-router-dom';
import { TooltipComponent } from '../components/Tooltip/Tooltip';
import classNames from 'classnames';
import { CurrentUser } from '../utils/CurrentUser';

type StatusEventData = {
  email: string;
  botId: string;
  location: string;
  blockId?: string;
  isActive: boolean;
};

type StatusEventRequest = {
  event: 'status';
  data: StatusEventData;
};

type StatusEventResponse = {
  event: 'status';
  data: StatusEventData[];
};

type LiveStatusContextType = Record<string, any>;

export const LiveStatusContext = React.createContext<LiveStatusContextType>(
  {} as LiveStatusContextType
);

export const LiveStatus = (props: React.PropsWithChildren<{ isAdmin: boolean}>) => {
  const { pathname } = useLocation();
  const user = useSelector(currentUser);
  const bot = useCurrentBot();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isTabVisible, setIsTabVisible] = useState(true);
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    process.env.REACT_APP_SOCKET_URL,
    {
      shouldReconnect: () => true,
      retryOnError: true,
      reconnectAttempts: 1000,
      queryParams: {
        token: accessToken,
      },
    },
    !!process.env.REACT_APP_SOCKET_URL && !!accessToken,
  );
  const [contextValue, setContextValue] = useState({});
  const [status, setStatus] = useState<StatusEventData[]>([]);

  const handleVisibilityChange = useCallback(() => {
    setIsTabVisible(document.visibilityState === 'visible');
  }, []);

  const fetchAccessToken = async () => {
    const token = await CurrentUser.token();
    setAccessToken(token);
  };

  useEffect(() => {
    fetchAccessToken();
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const handleWakeUp = () => {
      if (getWebSocket()?.readyState !== ReadyState.OPEN) {
        getWebSocket()?.close();
      }
    };

    window.addEventListener('focus', handleWakeUp);

    return () => {
      window.removeEventListener('focus', handleWakeUp);
    };
  }, []);

  const sendStatusUpdate = () => {
    if (!user || !bot) {
      return;
    }

    const message: StatusEventRequest = {
      event: 'status',
      data: {
        email: user?.email,
        botId: bot?.id,
        location: pathname,
        isActive: isTabVisible,
      },
    };

    sendMessage(JSON.stringify(message));
  };

  useEffect(() => {
    if (!lastMessage?.data || !props.isAdmin) {
      setStatus([]);
      setContextValue({});
      return;
    }

    const data = JSON.parse(lastMessage.data)?.data as StatusEventResponse['data'];

    if (!data) {
      setStatus([]);
      setContextValue({});
      return;
    }

    const scenarioPathRegex = /.*scenario\/([^\/]*)\/?([^\/]*)?\/?([^\/]*)?/i;

    const enhancedData = data.map((item) => {
      const matchResult = item.location.match(scenarioPathRegex);
      if (matchResult) {
        const [_, moduleType, moduleId, stepId] = matchResult;
        return {
          ...item,
          moduleType,
          moduleId,
          stepId,
        };
      } else {
        return {
          ...item,
          moduleType: null,
          moduleId: null,
          stepId: null,
        };
      }
    });

    setContextValue(enhancedData.reduce((acc, item) => {
      if (item.moduleType && item.moduleType !== 'modules') {
        acc.visitors[item.moduleType] = [
          ...(acc.visitors[item.moduleType] || []),
          item,
        ];
      }

      if (item.moduleId) {
        acc.visitors[item.moduleId] = [
          ...(acc.visitors[item.moduleId] || []),
          item,
        ];
      }

      if (item.stepId) {
        acc.visitors[item.stepId] = [
          ...(acc.visitors[item.stepId] || []),
          item,
        ];
      }

      return acc;
    }, { visitors: {} }));

    setStatus(enhancedData || []);
  }, [lastMessage]);

  useEffect(() => {
      sendStatusUpdate();
  }, [sendMessage, pathname, isTabVisible, readyState]);

  if (!props.isAdmin || !pathname?.includes('survey-creator')) {
    return (
      <LiveStatusContext.Provider value={contextValue}>
        {props.children}
      </LiveStatusContext.Provider>
    );
  }

  return (
    <LiveStatusContext.Provider value={contextValue}>
      {props.children}

      <div
        className={styles.container}
      >
        <TooltipComponent
          tooltipText={readyState === ReadyState.OPEN ? 'Connected' : 'Disconnected'}
          trigger="hover"
        >
          <div
            className={classNames(styles.connectionIndicator, readyState === ReadyState.OPEN && styles.connected)}
          />
        </TooltipComponent>

        <div className={styles.visitorsContainer}>
          {status
            .filter(s => s.location.includes('scenario'))
            .map(
              (data, index) => (
                <TooltipComponent
                  key={index}
                  tooltipText={data.email}
                  trigger="hover"
                >
                  <Link to={data.location} className={styles.visitorLink}>
                    <div className={classNames(styles.visitor, !data.isActive && styles.inactive)}>
                      {data.email.charAt(0).toUpperCase()}
                    </div>
                  </Link>
                </TooltipComponent>
              )
            )}
        </div>
      </div>
    </LiveStatusContext.Provider>
  );
};
