import React from 'react';
import { useIntl } from 'react-intl';

import { useCurrentBot } from '../../utils/CurrentBot';
import { EventData } from '../models/Events';

import { Event } from './Event';
import styles from './Events.pcss';

interface Props {
    readonly eventsAvailableSpace: number;
    readonly events: ReadonlyArray<EventData>;
}

export function Events({ events }: Props) {
    const intl = useIntl();
    const bot = useCurrentBot();

    const getLabelFor = (event: EventData) => {
        return bot.type === 'survey'
            ? intl.messages[`conversations.filter.${event.key}`] as string || event.label
            : event.label;
    };

    return (
        <div data-test="events-cell" className={styles.events}>
            <div className={styles.eventGroup}>
                {
                    events.map(event => (
                        <Event
                            key={event.key}
                            name={event.label}
                            type={event.type}
                        >
                            {
                                Boolean(event.icon) && event.icon
                            }
                            {getLabelFor(event)}
                        </Event>
                    ))
                }
            </div>
        </div>
    );
}
