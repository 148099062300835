import { useContext, useMemo } from 'react';
import _ from 'lodash';
import { Loadable } from '../../../../reducers/types';
import { BlockQuestion, EntityAnswer, isWordStep, SurveyIntent, WordAnswerType } from '../../../model';
import { variableResolver } from './variableResolver';
import { SurveyContext, SurveyContextType } from '../../../SurveyCreatorContext';
import { useAllEntitiesValues } from '../../EntityDetails/hooks/useAllEntitiesValues';
import { useParams } from 'react-router';
import { BotCreatorRouteMatch, getScopeForVariables } from '../../../useCreatorRouter';

export function extractEntitiesFromBotDefinition(
  steps: BlockQuestion[],
  intents: SurveyIntent[],
) {
  const primaryAnswerEntities = steps
    .filter(isWordStep)
    .map((step) => step.dictionary
      .filter((item) => item.type === WordAnswerType.ENTITY)
      .map((entityItem) => entityItem.id)
    ).flat();
  const asociatedAnswerEntities = steps
    .filter((step) => step.associatedAnswers)
    .map((step) => step.associatedAnswers.map(answer => answer.entity))
    .flat();
  const intentEntities = intents
    .flatMap(intent => intent.entities)
    .map(associatedEntity => associatedEntity.entity);

  return _.uniq([...primaryAnswerEntities, ...asociatedAnswerEntities, ...intentEntities]);
}

const parseEntitiesDetails = (entitiesDetails: Pick<{ [p: string]: Loadable<EntityAnswer[]> }, string>, processEntities: boolean) => {
    return Object.entries(entitiesDetails).map(([entityName, payload]) => ({
        name: entityName,
        values: processEntities ? payload?.data || [] : [],
    }));
}

export const useVariableResolver = ({ shouldProcessEntities = true }: { shouldProcessEntities?: boolean } = {}) => {
    const params = useParams<BotCreatorRouteMatch>();  
    const { currentBotId, variables, steps, intents, executableFunctions } = useContext<SurveyContextType>(SurveyContext);

    const entitiesUsedInAnswers = useMemo(() => extractEntitiesFromBotDefinition(steps.get(), intents.get()), [])
    const entitiesDetails = useAllEntitiesValues(entitiesUsedInAnswers, currentBotId)

    const resolvedVariablesTypes = useMemo(() => {
      return variableResolver({
        steps: steps.get(),
        intents: intents.get(),
        variables: variables.getForScope((getScopeForVariables(params))),
        entities: parseEntitiesDetails(entitiesDetails, shouldProcessEntities),
        executableFunctions: executableFunctions.get(),
      })
    }, 
    // optimisation to recalculate variable types when variables change as variables.getForScope creates new variable array on each call
    [steps.get(), intents.get(), variables.get(), entitiesDetails, executableFunctions.get()]
  );

    return { resolvedVariablesTypes };
};
