import * as React from 'react';
import { useContext, useLayoutEffect, useRef } from 'react';
import { animateScroll, scroller } from 'react-scroll';

import { Scrollbar } from '../../components/Scrollbar/Scrollbar';
import { SurveyContext, SurveyContextType } from '../SurveyCreatorContext';

import { AddStepButton } from './AddStepButton';
import styles from './BlockDetails.pcss';
import { BlockPreview } from './BlockPreview';
import { VerticalSeparator } from './VerticalSeparator';
import { BlockQuestion } from '../model';
import { addOrdinalNumberToBlockQuestionName } from './utils';
import { useLocation, useParams } from 'react-router';
import {
  blockUrl,
  BotCreatorRouteMatch,
  getScopeForCreatorParams,
} from '../useCreatorRouter';
import {useHistory} from 'react-router-dom';

const SCROLL_CONTAINER_ID = 'block-preview-scroll-container';

export const BlockDetails = React.memo(() => {
  const { blocks, questions } = useContext<SurveyContextType>(SurveyContext);
  const newQuestionRef = useRef<string>();
  const params = useParams<BotCreatorRouteMatch>();
  const { pathname } = useLocation();
  const history = useHistory();
  const scope = getScopeForCreatorParams(params, pathname);
  const currentBlock = blocks.getCurrentStepsOrBlockForScope(scope);
  const currentQuestion = questions.getForScope(scope);

  if (currentBlock && !params.blockId) {
    history.replace(blockUrl(params.orgId, params.botId, currentBlock.id));
  }

  useLayoutEffect(() => {
    if (newQuestionRef.current) {
      scroller.scrollTo(newQuestionRef.current, {
        duration: 1000,
        containerId: SCROLL_CONTAINER_ID,
        smooth: true,
      });
      newQuestionRef.current = undefined;
    }
  }, [currentQuestion]);

  useLayoutEffect(() => {
    animateScroll.scrollToTop({
      duration: 0,
      containerId: SCROLL_CONTAINER_ID,
      smooth: true,
    });
  }, [currentBlock?.id]);

  if (!currentBlock) {
    return null;
  }

  const handleAdd = (questionToAdd: BlockQuestion) => {
    newQuestionRef.current = questionToAdd.id;

    questions.add(
      addOrdinalNumberToBlockQuestionName(
        questionToAdd,
        currentBlock.questions.length + 1
      ),
      scope
    );
  };

  return (
    <div data-test="survey-block-details" className={styles.container}>
      <VerticalSeparator />
      <Scrollbar
        renderView={(props: any) => <div {...props} id={SCROLL_CONTAINER_ID} />}
      >
        <div className={styles.details}>
          <BlockPreview
            blockId={currentBlock.id}
            currentlySelectedBlock={currentBlock}
            onBlockUpdated={blocks.update}
          />
        </div>
      </Scrollbar>
      <div className={styles.buttonContainer}>
        <AddStepButton onAdd={handleAdd} disabled={!!questions.new} />
      </div>
      <VerticalSeparator />
    </div>
  );
});
