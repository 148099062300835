import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';

import {Input} from '../components/Input';
import {SelectComponent} from '../components/Select/Select';
import {interpretationApiLocale} from '../language/botLanguage';
import {Bot} from '../surveyCreator/model';
import {getIntentDisplayName, Intent} from '../surveyCreator/SurveyCreatorContext';
import {withCurrentBot} from '../utils/CurrentBot';
import {HttpClient} from '../utils/HttpClient';

import styles from './learning.pcss';

type Props = {
    currentBot: Bot;
    maxRasaStatusCheckTime?: number;
};

export type IntentEvaluationPrediction = {
    text: string;
    intent: string;
    predicted: string;
    confidence: number;
};

interface State {
    intent: string;
    currentIntent: IntentParseResult;
    currentIntentProd: IntentParseResult;
    allIntents: Intent[];
    globalIntents: Intent[];
    intentSuggestions: Intent[];
    currentGlobalIntent: {
        sentence: string;
        intent: string;
    };
    evaluationAccuracy?: number;
    wrongEvaluatedSentences: IntentEvaluationPrediction[];
}

interface IntentParseResult {
    intent?: string;
    confidence?: number;
    error?: boolean;
}

class IntentLearning extends React.Component<Props & WrappedComponentProps, State> {
    constructor(props: Props & WrappedComponentProps) {
        super(props);
        this.state = {
            intent: '',
            currentIntent: {},
            currentIntentProd: {},
            allIntents: [],
            globalIntents: [],
            intentSuggestions: [],
            wrongEvaluatedSentences: [],
            currentGlobalIntent: {
                sentence: '',
                intent: ''
            }
        };
    }

    componentDidMount() {
        this.fetchIntents();
        this.fetchGlobalIntents();
    }

    componentDidUpdate(prevProps: Props): void {
        if (_.get(prevProps, 'currentBot.id') !== this.props.currentBot.id) {
            this.fetchIntents();
            this.fetchGlobalIntents();
        }
    }

    render() {
        return (
            <div className={classNames(styles.learning)}>
                <div data-test="global-intents" className={styles.globalIntents}>
                    <FormattedMessage id={'learning.globalIntents'}/>
                    <div className={styles.globalIntentsContent}>
                        <Input
                            dataTest={'global-intent-data-sentence'}
                            value={this.state.currentGlobalIntent.sentence}
                            width={500}
                            onChange={this.onChangeGlobalIntentSentence}
                        />
                        <div className={styles.globalIntentsSelect}>
                            <SelectComponent
                                value={{
                                    id: this.state.currentGlobalIntent.intent,
                                    name: this.state.currentGlobalIntent.intent
                                }}
                                options={this.state.globalIntents.map(el => ({
                                    id: el.name,
                                    name: getIntentDisplayName(el)
                                }))}
                                onChange={this.onChangeGlobalIntent}
                            />
                        </div>
                        <button
                            data-test="add-global-intent-data"
                            className={`${styles.button} ${styles.globalIntentButton}`}
                            onClick={this.addGlobalIntentData}
                        >
                            <FormattedMessage id={'learning.addIntent'}/>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private onChangeGlobalIntent = (value: any) => {
        this.setState(prevState => ({currentGlobalIntent: {...prevState.currentGlobalIntent, intent: value.id}}));
    };

    private onChangeGlobalIntentSentence = (value: string) => {
        this.setState(prevState => ({currentGlobalIntent: {...prevState.currentGlobalIntent, sentence: value}}));
    };

    private addGlobalIntentData = async () => {
        const {sentence, intent} = this.state.currentGlobalIntent;
        if (!sentence || !intent) {
            return;
        }
        await HttpClient.post({
            path: `/admin/global-train-data`,
            body: {trainData: [{text: sentence, intent, language: interpretationApiLocale[this.props.currentBot.languageCode]}]}
        });
        this.setState(() => ({currentGlobalIntent: {sentence: '', intent: ''}}));
    };

    private fetchIntents() {
        return HttpClient.get({path: `/bots/${this.props.currentBot.id}/intents`})
            .then(response => response.data)
            .then(intents => this.setState({allIntents: intents, intentSuggestions: intents}));
    }

    private fetchGlobalIntents() {
        return HttpClient.get({path: `/admin/global-intents`, params: {language: this.props.currentBot.languageCode}})
            .then(response => response.data)
            .then(intents => this.setState({globalIntents: intents}));
    }
}

export default withCurrentBot<{ maxRasaStatusCheckTime?: number }>(injectIntl(IntentLearning));
