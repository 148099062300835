import * as React from 'react';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import classNames from 'classnames';

import { originToBadgeMap } from '../../surveyCreator/components/variables/Variables';
import { VariableName } from '../../surveyCreator/model';
import { Button } from '../Button/Button';
import { Badge } from '../Badge/Badge';

import styles from './TextAreaWithVariables.pcss';
import textAreaStyles from './TextArea.pcss';

type Props = {
  dataTest?: string;
  value?: string | Array<string>;
  onChange?: (value: string | Array<string>) => void;
  disabled?: boolean;
  availableVariables: VariableName[];
  asInput?: boolean;
  placeholder?: string;
  className?: string;
  errorMessage?: string;
  autoFocus?: boolean;
}

export const TextAreaWithVariables = ({
  ...props
}: Props) => {
  const ref = useRef(null);
  const buttonMouseDownRef = useRef(false);
  const scrollParentRef = useRef(null);
  const newCaretPosition = useRef(null);
  const [focused, setFocused] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    scrollParentRef.current = ref.current.closest('div[style*="overflow: scroll"]');
  }, [ref.current]);

  useEffect(() => {
    if (newCaretPosition.current) {
      ref.current.focus();
      ref.current.selectionStart = newCaretPosition.current;
      ref.current.selectionEnd = newCaretPosition.current;
      newCaretPosition.current = undefined;
    }
  }, [props.value]);
  const fromDBFormatToName = props.availableVariables.reduce((acc, curr) => ({...acc, ['${' + curr.id + '}']: curr.name}), {});
  const allVariablesInDBFormatRegExp = new RegExp(`\\\${(${props.availableVariables.map(v => v.id).join('|')})}`, 'g');
  const getValueInReactMentionFormat = () => {
    if (Array.isArray(props.value)) {
      return;
    } else {
      return props.value?.toString().replace(allVariablesInDBFormatRegExp, (matched) => `@<${fromDBFormatToName[matched]}>`)
    }
  }
  const valueInReactMentionFormat = getValueInReactMentionFormat();
  const allVariablesInReactMentionFormat = props.availableVariables.map(v => `@\\<${v.name.replace(/[.*+?^${}()]/g, '\\\\$&')}\\>`).join('|');
  const allVariablesInReactMentionFormatRegExp = new RegExp(`(${allVariablesInReactMentionFormat})`, 'g');

  const toExternalFormat = (value) => value.replace(/@\<(.*?)?>/g, (_, matched) => {
    const key = Object.keys(fromDBFormatToName).find(k => fromDBFormatToName[k] === matched);
    return key ? key : `${matched}`;
  })

  const handleChange = (event) => {
    const value = event.target.value;
    props?.onChange(toExternalFormat(value));
  };

  const addVariable = () => {
    buttonMouseDownRef.current = false;
    const position = ref.current.selectionStart;
    newCaretPosition.current = position + 2;
    const variablesInValue = [...Array.from(valueInReactMentionFormat.matchAll(allVariablesInReactMentionFormatRegExp))];
    const positionsToAddInValue = variablesInValue.reduce((acc, current) => current.index - acc < position ? acc + 1 : acc, 0);
    const positionInValue = position + positionsToAddInValue;
    const newValue = typeof valueInReactMentionFormat === 'string' && valueInReactMentionFormat.substring(0, positionInValue) + '${' + valueInReactMentionFormat.substring(positionInValue);
    props?.onChange(toExternalFormat(newValue));
  }
  const handleMouseDown = (event: MouseEvent) => buttonMouseDownRef.current = event.button === 0
  const handleFocus = () => setFocused(true)
  const handleBlur = (event, clickedSuggestion) => {
    if (!buttonMouseDownRef.current && !clickedSuggestion) {
      setFocused(false)
    }
  }

  return (
    <div data-test={props.dataTest} className={classNames(styles.wrapper, {
      [styles.error]: Boolean(props.errorMessage)
    }, props.className)}>
      {Boolean(props.errorMessage) && <div className={textAreaStyles.input__tooltip} role="tooltip">{props.errorMessage}</div>}
      <MentionsInput
        value={valueInReactMentionFormat}
        inputRef={ref}
        classNames={styles}
        allowSpaceInQuery
        placeholder={props.placeholder}
        allowSuggestionsAboveCursor
        singleLine={props.asInput}
        onChange={handleChange}
        disabled={props.disabled}
        suggestionsPortalHost={scrollParentRef.current}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoFocus={props.autoFocus}
      >
        <Mention
          markup={'@<__display__>'}
          trigger={/(\$\{(.*))$/}
          data={props.availableVariables.map(variable => ({id: variable.id, display: variable.name, badge: originToBadgeMap[variable.origin]}))}
          className={styles.mention}
          displayTransform={(id, display) => {
            const badge = originToBadgeMap[props.availableVariables.find(v => v.name === id)?.origin];
            return `${badge ? ` ${badge} -` : ''} ${display} `;
          }}
          renderSuggestion={(suggestion, search, highlightedDisplay) => (
            <div className={styles.suggestion}>
              {(suggestion as SuggestionDataItem & { badge?: string }).badge && <Badge size="small">{(suggestion as SuggestionDataItem & { badge?: string }).badge}</Badge>}
              <span data-test="suggestion-text">{highlightedDisplay}</span>
            </div>
          )}
        />
      </MentionsInput>
      {
        focused && (
          <Button className={styles.addButton} onMouseDown={handleMouseDown} onClick={addVariable} dataTest={'add-variable'}>
            {intl.messages['variables-text-area.add-button']}
          </Button>
        )
      }
    </div>
  );
};
