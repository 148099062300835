import * as React from 'react';
import { ElementWithLabel } from '../../components/ElementWithLabel/ElementWithLabel';
import styles from './SetVariable.pcss';
import { StepElementProps } from './StepElementProps';
import { Variables } from './variables/Variables';
import { useVariableCreation } from './variables/useVariableCreation';
import { VariableValue } from './variables/VariableValue';
import { WordQuestionResult } from './variables/variableResolver/variableResolver';

type Props = {
    variable: string,
    value: string | WordQuestionResult
};


export const SetVariable = (props: StepElementProps<Props>) => {
    const handleVariableChange = (value: string) => props.onChange({
        ...props.question,
        variable: value
    });
    const handleValueChange = (value: string | WordQuestionResult) => props.onChange({
        ...props.question,
        value
    });
    const { selectedOption, options, handleSelect, handleRename, handleCreate } = useVariableCreation(handleVariableChange, props.question.variable)

    return (
        <div className={styles.container}>
            <ElementWithLabel
                labelId={`survey-creator.setVariableStep.variable`}
                borderEnabled={false}
                className={styles.input}
                dataTest="set-variable-variable"
                inner
            >
                <Variables
                    selectedOption={selectedOption}
                    options={options}
                    handleSelect={handleSelect}
                    handleRename={handleRename}
                    handleCreate={handleCreate}
                />
            </ElementWithLabel>
            <ElementWithLabel inner className={styles.input} labelId="survey-creator.setVariableStep.value" dataTest="set-variable-value-container">
                <VariableValue selectedOption={selectedOption} value={props.question.value} onChange={handleValueChange} />
            </ElementWithLabel>
        </div>
    );
};
