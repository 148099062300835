import * as React from 'react';
import { useIntl } from 'react-intl';

import { Fallback } from '../../model';
import { PillComponent } from '../pill/Pill';

import styles from './FallbackBlock.pcss';

export type FallbackProps = {
  fallback: Fallback & { id: string };
  selected: boolean;
  navigateToFallback: () => void
  dataTest?: string;
  itemName?: string;
}


export const FallbackBlock = ({ fallback, selected, navigateToFallback, dataTest, itemName = 'survey-creator.fallback' }: FallbackProps) => {
  const intl = useIntl();

  return (
    <div className={styles.fallbackPill} data-test={'fallback-block'}>
        <PillComponent
            onSelect={navigateToFallback}
            item={fallback}
            itemName={intl.formatMessage({id: itemName})}
            selected={selected}
            dataTest={dataTest ?? 'survey-creator-fallback-block'}
        />
    </div>
  )
};
