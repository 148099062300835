import _ from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
  withRouter,
} from 'react-router-dom';

import {
  fetchBots as fetchBotsAction,
  fetchCurrentUser as fetchCurrentUserAction,
  fetchNamesDictionary as fetchNamesDictionaryAction,
} from './actions/actions';
import { AppContent } from './AppContent';
import './colors.pcss';
import { Loader } from './components/Loader';
import { Dashboard } from './dashboard/Dashboard';
import './global.pcss';
import { Header } from './layout/Header';
import { MainPage } from './layout/MainPage';
import { RootState } from './reducers/reducers';
import { Bot } from './surveyCreator/model';
import { getBotUrlId, useCurrentBot } from './utils/CurrentBot';
import { FeatureFlag, featureFlags } from './utils/featureFlags';
import { UserRole } from './utils/CurrentUser';
import { useInitPostHog, postHog } from './utils/postHog';
import './transitions.pcss';
import './variables.pcss';
import './vendors.css';
import { SMSSettingsTab } from './organizations/sms/SMSSettingsTab';
import { LiveStatus } from './liveStatus/LiveStatus';

type StateProps = {
  namesDictionaryFetched: boolean;
  currentUserFetched: boolean;
  botsFetched: boolean;
  isAdmin: boolean;
  hasBots: boolean;
  hasCreatorRole: boolean;
  hasPlannerRole: boolean;
  hasSettingsRole: boolean;
  hasInspectorRole: boolean;
};

type DispatchProps = {
  fetchCurrentUser: () => void;
  fetchBots: () => void;
  fetchNamesDictionary: (bot: Bot) => void;
};

type Props = StateProps & DispatchProps & RouteComponentProps<any>;

const CURRENT_BOT_KEY = 'currentBot';

function App({
  fetchCurrentUser,
  fetchBots,
  fetchNamesDictionary,
  namesDictionaryFetched,
  currentUserFetched,
  botsFetched,
  isAdmin,
  hasCreatorRole,
  hasPlannerRole,
  hasSettingsRole,
  hasInspectorRole,
}: Props) {
  const location = useLocation();
  useInitPostHog();
  useEffect(() => {
    fetchCurrentUser();
    fetchBots();
    featureFlags.set(FeatureFlag.LEMMATIZATION, true);
  }, []);
  const currentBot = useCurrentBot();
  useEffect(() => {
    if (currentBot) {
      fetchNamesDictionary(currentBot);
      localStorage.setItem(CURRENT_BOT_KEY, getBotUrlId(currentBot));
    }
  }, [currentBot]);

  useEffect(() => {
    postHog.capture('$pageview');
  }, [location.pathname]);

  if (!currentUserFetched || !botsFetched) {
    return <Loader />;
  }

  return (
    <LiveStatus isAdmin={isAdmin}>
      <Switch>
        <Route path="/:orgId/sms" component={SMSSettingsTab} />
        <Route
          exact
          path="/:orgId"
          render={({ location: currentLocation }) => (
            <Redirect to={`${currentLocation.pathname}/sms`} />
          )}
        />
        <Route
          path="/:orgId/:botId"
          render={() => {
            if (botsFetched && !Boolean(currentBot)) {
              return <Redirect to="/" />;
            }
            return (
              <AppContent
                admin={isAdmin}
                hasCreatorRole={hasCreatorRole}
                hasPlannerRole={hasPlannerRole}
                hasSettingsRole={hasSettingsRole}
                hasInspectorRole={hasInspectorRole}
                bot={currentBot}
                onBotsUpdate={fetchBots}
                data-test="app-content"
              />
            );
          }}
        />
        <Route
          path="/"
          render={() => (
            <MainPage>
              {!currentBot ? (
                <Dashboard onBotsUpdate={fetchBots} />
              ) : (
                <>
                  <Header />
                  {!namesDictionaryFetched && <Loader delay={200} />}
                </>
              )}
            </MainPage>
          )}
        />
      </Switch>
    </LiveStatus>
  );
}

const mapStateToProps = (state: RootState): StateProps => ({
    namesDictionaryFetched: state.namesDictionary.fetched,
    currentUserFetched: state.currentUser.fetched,
    botsFetched: state.bots.fetched,
    hasBots: _.size(state.bots.bots) > 0,
    isAdmin: _.get(state, 'currentUser.user["cognito:groups"]', []).includes(UserRole.ADMIN),
    hasCreatorRole: _.get(state, 'currentUser.user["cognito:groups"]', []).includes(UserRole.CREATOR),
    hasPlannerRole: _.get(state, 'currentUser.user["cognito:groups"]', []).includes(UserRole.PLANNER),
    hasSettingsRole: _.get(state, 'currentUser.user["cognito:groups"]', []).includes(UserRole.SETTINGS),
    hasInspectorRole: _.get(state, 'currentUser.user["cognito:groups"]', []).includes(UserRole.INSPECTOR),
});

const mapDispatchToProps: DispatchProps = {
    fetchCurrentUser: fetchCurrentUserAction.request,
    fetchBots: fetchBotsAction.request,
    fetchNamesDictionary: fetchNamesDictionaryAction.request
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
