import {orderBy} from 'lodash';
import * as React from 'react';
import {useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {callbackTypes} from '../model';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';

import {CallbackOptionsComponent} from './CallbackOptions';
import styles from './Callbacks.pcss';
import {PillComponent} from './pill/Pill';
import {TooltipComponent} from '../../components/Tooltip/Tooltip';
import {InformationIcon} from '../../icons';
import { CallbackTypeId } from '../../utils/CurrentBot';
import { BotCreatorRouteMatch, useCreatorRouter } from '../useCreatorRouter';
import { useParams } from 'react-router';


export const Callbacks = () => {
  const intl = useIntl();
  const {callbacks} = useContext<SurveyContextType>(SurveyContext);
  const params = useParams<BotCreatorRouteMatch>();

  const { navigateToCallback } = useCreatorRouter()
  const currentCallback = orderBy(
    callbackTypes
      .filter(callbackId => !!callbacks.all[callbackId])
      .map(callbackId => ({callbackId, name: intl.messages[`survey-creator.callbacks.${callbackId}`] as string})),
    'name',
    'asc'
  );
  return (
    <div className={styles.callbacks}>
        <div className={styles.titleWithIcon}>
          <FormattedMessage id={'survey-creator.callbacksLabel'}>
            {(value) => <span className={styles.title}>{value}</span>}
          </FormattedMessage>


        <TooltipComponent tooltipText={intl.messages['survey-creator.callbacksTooltip'] as string}>
            <div style={{padding: '5px'}}>
                <InformationIcon/>
            </div>
        </TooltipComponent>
    </div>

      <div className={styles.list} data-test={'callbacks-list'}>
        {
          currentCallback
            .map(({callbackId, name}) =>
              (
                <PillComponent 
                  onSelect={() => navigateToCallback(callbackId as CallbackTypeId)} 
                  item={callbacks.all[callbackId]}
                              key={callbackId}
                              itemName={name}
                              selected={params.callbackId === callbackId}
                              dataTest={`survey-creator-callback.${callbackId}`}
                              renderOptions={(renderOptionsProps) =>
                                (<CallbackOptionsComponent
                                  {...renderOptionsProps}
                                  onDelete={async () => {
                                      await callbacks.remove(callbackId);

                                  }}

                                />)
                              }
                />
              ))
        }
      </div>
    </div>
  );
};
