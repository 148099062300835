import cx from 'classnames';
import get from 'lodash/get';
import React, {useCallback, useContext, useState} from 'react';
import { useIntl } from 'react-intl';
import {useHistory, useRouteMatch} from 'react-router';

import {AddButton} from '../../components/AddButton/AddButton';
import {HorizontalTabsLayout} from '../../components/layouts/HorizontalTabsLayout/HorizontalTabsLayout';
import {EntityButtonIcon} from '../../icons/EntityButtonIcon';
import {ScriptIcon} from '../../icons/ScriptIcon';
import {getBotUrlId, useCurrentBot} from '../../utils/CurrentBot';

import {AddBlockButton} from './AddBlockButton';
import {Blocks} from './Blocks';
import styles from './BlocksAndIntents.pcss';
import {BotTester} from './BotTester/BotTester';
import {Callbacks} from './Callbacks';
import {EntitiesMenu} from './EntitiesMenu';
import {usePendingEntity} from './EntityDetails/hooks/usePendingEntity';
import {Intents} from './Intents';
import {TryIt} from './TryIt';
import { SurveyContext, SurveyContextType } from '../SurveyCreatorContext';
import { Loader } from '../../components/Loader';

export enum BlocksTabs {
  Scenario = 'scenario',
  Entities = 'entities'
}

const TabToIconMapper = (tab: BlocksTabs) => ({
  [BlocksTabs.Entities]: EntityButtonIcon,
  [BlocksTabs.Scenario]: ScriptIcon
})[tab];

const TabToComponentMapper = (tab: BlocksTabs, onEditToggle: (value: boolean) => void) => ({
  [BlocksTabs.Entities]: <EntitiesMenu/>,
  [BlocksTabs.Scenario]: <>
    <Blocks onEditToggle={onEditToggle}/>
    <Intents onEditToggle={onEditToggle}/>
    <Callbacks/>
  </>
})[tab];

const horizontalTabLayoutStyles = {
  root: styles.horizontalTabsLayout,
  currentTabContent: styles.currentTabContent,
  tabsHeader: styles.tabsHeader,
}

export const BlocksAndIntents = React.memo(() => {
  const intl = useIntl();
  const history = useHistory();
  const match = useRouteMatch();
  const currentBot = useCurrentBot();
  const {createPendingEntity} = usePendingEntity();
  const tabs = React.useMemo(() => Object
    .keys(BlocksTabs)
    .map((key: BlocksTabs) => ({
      id: BlocksTabs[key],
      label: String(intl.messages[`survey-creator.tabs.${BlocksTabs[key]}`]),
      dataTest: `${BlocksTabs[key]}-tab`,
      Icon: TabToIconMapper(BlocksTabs[key])
    })), [intl]);
  const onTabClick = useCallback((path: string) => history.push(`/${getBotUrlId(currentBot)}/survey-creator/${path}`), [history]);
  const currentTab = get(match.params, 'tabName');
  const { isLoading } = useContext<SurveyContextType>(SurveyContext);

  const [isRenaming, setRenaming] = useState(false);

  const getComponentForTab = useCallback((tab: BlocksTabs) => TabToComponentMapper(tab, setRenaming), [setRenaming]);

  return (
    <div className={styles.outer}>
      <div className={cx(
        styles.container,
        {[styles.noBottomPadding]: currentTab === 'entities'}
      )}>
        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <HorizontalTabsLayout
            currentTab={currentTab}
            tabs={tabs}
            onTabClick={onTabClick}
            getComponentForTab={getComponentForTab}
            fadePadding
            classes={horizontalTabLayoutStyles}
            disabled={isRenaming}
          />
        )}
      </div>

      <div className={styles.buttonContainer}>
        <TryIt/>
        {
          currentTab === 'scenario' &&
          <AddBlockButton/>
        }
        {
          currentTab === 'entities' &&
          <AddButton
              dataTest="create-entity"
              textId="survey-creator.addEntityButtonLabel"
              onClick={createPendingEntity}
          />
        }

        <BotTester/>
      </div>

    </div>
  );
});
