import { DataCollectionStepIcon, DateStepIcon, DateTimeStepIcon, DictStepIcon, DictationStepIcon, EmailStepIcon, EndStepIcon, GoToStepIcon, HelpStepIcon, HttpStepIcon, NumberStepIcon, OpenStepIcon, RedirectStepIcon, SetVariableStepIcon, SmsStepIcon, StatementStepIcon } from '../../icons';
import { FunctionStepIcon } from '../../icons/FunctionStepIcon';
import { QuestionType } from '../../surveyCreator/model';
import { useSurveyCreator } from '../../surveyCreator/useSurveyCreator';

export const linkElementAltAction = (href: string) => { window.open(href, '_blank') };
export const fromDBFormatToName = (variables: ReturnType<typeof useSurveyCreator>['definition']['variables']) => variables.getForScope('creator').reduce((acc, curr) => ({ ...acc, [`$\{${curr.id}}`]: curr.name }), {});

export const mapStepTypeToIcon = (type: QuestionType) => {
  switch (type) {
    case QuestionType.WORD: return DictStepIcon;
    case QuestionType.STATEMENT: return StatementStepIcon;
    case QuestionType.OPEN: return OpenStepIcon;
    case QuestionType.DATE: return DateStepIcon;
    case QuestionType.DATETIME: return DateTimeStepIcon;
    case QuestionType.NUMERICAL: return NumberStepIcon;
    case QuestionType.HELP_PROPOSE: return HelpStepIcon;
    case QuestionType.SPELLING: return DictationStepIcon;
    case QuestionType.SET_VARIABLE: return SetVariableStepIcon;
    case QuestionType.DATA_COLLECTION: return DataCollectionStepIcon;
    case QuestionType.HTTP: return HttpStepIcon;
    case QuestionType.SMS: return SmsStepIcon;
    case QuestionType.EMAIL: return EmailStepIcon;
    case QuestionType.REDIRECT: return RedirectStepIcon;
    case QuestionType.FUNCTION: return FunctionStepIcon;
    case QuestionType.END: return EndStepIcon;
    case QuestionType.GO_TO_BLOCK: return GoToStepIcon;
    default: return null;
  }
}