import * as React from 'react';
import {useContext} from 'react';
import {
    DragDropContext,
    Droppable,
} from 'react-beautiful-dnd';
import {FormattedMessage, useIntl} from 'react-intl';

import {Block} from '../model';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';

import {BlockComponent} from './Block';
import styles from './Blocks.pcss';
import {FallbackBlock} from './FallbackBlock/FallbackBlock';
import {InformationIcon} from '../../icons';
import {TooltipComponent} from '../../components/Tooltip/Tooltip';
import {HorizontalSeparator} from '../../components/HorizontalSeparator/HorizontalSeparator';
import {useCreatorRouter} from '../useCreatorRouter';
import {useBlockDrag} from './useBlockDrag';
import {GroupedBlocksComponent} from './GroupedBlocksContent';

export type BlocksProps = {
    onEditToggle?: (value: boolean) => void;
}

export const topLevelTypeDropId = 'topLevel';

export const Blocks: React.FC<BlocksProps> = React.memo(({ onEditToggle }) => {
    const intl = useIntl();
    const { blocks, groups, fallback, silenceFallback, blocksRef } = useContext<SurveyContextType>(SurveyContext);
    const updateBlockName = React.useCallback((name: string, block: Block) => blocks.rename(block.id, name), [blocks.rename]);
    const { current, navigateToFallback, navigateToSilenceFallback } = useCreatorRouter()

    const { onDragEnd } = useBlockDrag();

    return (
        <div ref={blocksRef} className={styles.blocks}>
            <div className={styles.titleWithIcon}>
                <span className={styles.title}>
                    <FormattedMessage id={'survey-creator.blocksLabel'} />
                </span>
                <TooltipComponent tooltipText={intl.messages['survey-creator.blocksTooltip'] as string}>
                    <div style={{ padding: '5px' }}>
                        <InformationIcon />
                    </div>
                </TooltipComponent>
            </div>

            <div className={styles.list} data-test="blocks-list">
                <DragDropContext data-test={'blocks-drag-drop-container'} onDragEnd={onDragEnd}>
                    <Droppable droppableId="blocksDroppable" type={topLevelTypeDropId}>
                        {(droppable) => (
                            <div {...droppable.droppableProps} ref={droppable.innerRef}>
                                {
                                    groups.groupedBlocks.map((group, index) => {
                                        if (group.type === 'module') {
                                            return (
                                                <BlockComponent
                                                    key={group.blocks[0].id}
                                                    index={index}
                                                    block={group.blocks[0]}
                                                    onEditToggle={onEditToggle}
                                                    onBlockNameUpdate={updateBlockName}
                                                    isSelected={current.blockId === group.blocks[0].id}
                                                />
                                            );
                                        }
                                        return <GroupedBlocksComponent
                                            key={group.id}
                                            group={group}
                                            index={index}
                                            onEditToggle={onEditToggle}
                                            updateBlockName={updateBlockName}
                                        />
                                    })
                                }
                                {droppable.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <HorizontalSeparator />
                <FallbackBlock
                    navigateToFallback={navigateToFallback}
                    fallback={{ ...fallback.get(), id: 'fallback' }}
                    selected={current.fallback}
                />
                <FallbackBlock
                    navigateToFallback={navigateToSilenceFallback}
                    dataTest="survey-creator-silence-fallback-block"
                    itemName="survey-creator.silenceFallback"
                    fallback={{ ...silenceFallback.get(), id: 'silence-fallback' }}
                    selected={current.silenceFallback}
                />
            </div>
        </div>
    );
});
