import posthog from 'posthog-js';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { currentUser } from '../selectors';

const postHogOptions = {
  api_host: process.env.REACT_APP_POST_HOG_API_HOST,
  opt_out_capturing_by_default: true,
  mask_all_text: true,
  session_recording: {
    maskTextSelector: '[data-sensitive="true"]',
  },
};

if (process.env.REACT_APP_POST_HOG_API_KEY) {
    posthog.init(
      process.env.REACT_APP_POST_HOG_API_KEY,
      postHogOptions,
    );
}


export const postHog = posthog;

export const useInitPostHog = () => {
  const postHogHook = usePostHog();
  const user = useSelector(currentUser);

  useEffect(() => {
    if (user?.hasAcceptedRecentTerms) {
      postHogHook.opt_in_capturing();
      postHogHook.identify(user.id, {
        email: user.email,
        organizations: user.organizations,
        groups: user.groups,
      });
    }
  }, [user]);
}
