import * as React from 'react';

import styles from './BlockOptions.pcss';
import {DeleteOption} from './optionsMenu/DeleteOption';
import {DuplicateOption} from './optionsMenu/DuplicateOption';
import {EditOption} from './optionsMenu/EditOption';
import {OptionsComponent} from './optionsMenu/Options';
import {StartOption} from './optionsMenu/StartOption';
import {RenderOptionsParams} from './pill/Pill';
import _ from 'lodash';
import {GroupOption} from './optionsMenu/GroupOption';
import {useCallback, useContext, useMemo} from 'react';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';
import {RemoveFromGroupOption} from './optionsMenu/RemoveFromGroupOption';
import {Block} from '../model';

type Props = {
  block: Block;
} & RenderOptionsParams

export const BlockOptionsComponent = (props: Props) => {
    const {groups, blocks} = useContext<SurveyContextType>(SurveyContext);

    const groupItems = groups.get().map(group => {
        return {id: group.id, name: group.name}
    })

    const onAssignGroup = useCallback((groupName: string) => blocks.groupBlock(props.block.id, groupName), [blocks, props.block.id]);
    const onSelectGroup = useCallback((groupId: string) => blocks.addToGroup(props.block.id, groupId), [blocks, props.block.id]);
    const onRemove = useCallback(() => blocks.remove(props.block), [blocks, props.block]);
    const onSetAsFirst = useCallback(() => blocks.setAsFirst(props.block), [blocks, props.block]);
    const onClone = useCallback(() => blocks.clone(props.block), [blocks, props.block]);
    const onRemoveFromGroup = useCallback(() => blocks.removeFromGroup(props.block.id), [blocks, props.block.id]);

    const isBlockInGroup = useMemo(() => groups.isInGroup(props.block.id), [groups, props.block.id]);
    const options =
    [
      <DeleteOption key="delete-option" onDelete={onRemove} />,
      !blocks.isFirst(props.block.id) && !isBlockInGroup && <StartOption key="start-option" onClick={onSetAsFirst} />,
      <DuplicateOption key={'clone-option'} onClick={onClone} />,
      <EditOption key={'edit-option'} onClick={props.onEditStart} />,
        isBlockInGroup ?
          <RemoveFromGroupOption key="remove-from-group-option" onClick={onRemoveFromGroup} /> :
          <GroupOption key="group-option" items={groupItems} onSelectGroup={onSelectGroup} onCreateGroup={onAssignGroup}/>
    ]


    return (
    <OptionsComponent
      iconContainerClass={styles.options}
      options={_.compact(options)} />
  );
};

