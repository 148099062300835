
import React, { useCallback, useMemo, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { FormattedMessage } from 'react-intl';
import ReactTable, { Column } from 'react-table';
import styles from './Table.pcss';

import { Scrollbar } from '../Scrollbar/Scrollbar';
import { Button } from '../Button/Button';
import { Loader } from '../Loader';

type TableProps = {
  data: Record<string, any>[];
  columns: Column<Record<string, any>>[];
  isLoading?: boolean;
  hasMore?: boolean;
  loadMore?: (...args: any[]) => any
};

/** @deprecated prefer src/components/Table/Table.ts */
export const  Table = ({
  data,
  columns,
  isLoading,
  hasMore,
  loadMore,
}: TableProps) => {
  const scrollTopPositionRef = useRef(0);
  const horizontalScrollbar = useRef<Scrollbars>();
  const verticalScrollbar = useRef<Scrollbars>();
  const verticalScrollbarContainer = useRef<HTMLDivElement>();
  const loadMoreContainer = useRef<HTMLDivElement>();

  const style = useMemo(() => ({ overflowY: 'hidden' }) as any, []);

  const onScrollVertical = (scrollTop) => {
    if (scrollTop !== scrollTopPositionRef.current) {
      scrollTopPositionRef.current = scrollTop;
    }
  };

  const onScroll = useCallback((scrollTop, scrollLeft) => {
    const verticalTrack = getVerticalTrack(verticalScrollbar.current);
    if (verticalTrack) {
      verticalTrack.style.left = `${scrollLeft + horizontalScrollbar.current.getClientWidth() - 15}px`;
    }
    if (verticalScrollbarContainer.current && horizontalScrollbar.current) {
      verticalScrollbarContainer.current.style.height = getInnerWindowHeight(horizontalScrollbar.current);
    }
    if (loadMoreContainer.current) {
      loadMoreContainer.current.style.marginLeft = `${scrollLeft}px`;
      loadMoreContainer.current.style.width = `${horizontalScrollbar.current.getClientWidth()}px`;
    }
  }, [verticalScrollbarContainer.current, loadMoreContainer.current, verticalScrollbar.current]);

  const verticalScrollRef = (ref) => {
    verticalScrollbar.current = ref;
    if (verticalScrollbar.current) {
      verticalScrollbar.current.scrollTop(scrollTopPositionRef.current);
    }
  };

  return (
    <div data-test="table" className={styles.container}>
      <Scrollbar ref={horizontalScrollbar} style={style} onScroll={onScroll}>
        <ReactTable
          sortable={false}
          data={data}
          columns={columns}
          minRows={0}
          pageSize={100}
          manual={true}
          showPagination={false}
          noDataText=""
          TbodyComponent={state => (
            <div
              ref={verticalScrollbarContainer}
              style={{ height: getInnerWindowHeight(horizontalScrollbar.current), width: '100%', ...state.style }}
            >
              <Scrollbar ref={verticalScrollRef} verticalTrackClassName={styles.verticalTrack} hideHorizontal={true}
                         onScroll={onScrollVertical}>
                {state.children}
                {(hasMore || isLoading) && (
                  <div ref={loadMoreContainer} className={styles.loadMoreContainer}>
                    {!isLoading && (
                      <Button onClick={loadMore} dataTest="load-more-button">
                        <FormattedMessage id="callsList.loadMore" />
                      </Button>
                    )}
                    {isLoading && <Loader dataTest="list-loader"/>}
                  </div>
                )}
              </Scrollbar>
            </div>
          )}
        />
      </Scrollbar>
    </div>
  );
}
const getInnerWindowHeight = (horizontalScrollbar?: Scrollbars) => `${horizontalScrollbar ? horizontalScrollbar.getClientHeight() - 50 : 0}px`;

const getVerticalTrack = (scrollbar: any): HTMLDivElement => scrollbar && scrollbar.trackVertical ? scrollbar.trackVertical : undefined;
