import * as React from 'react';
import {useContext} from 'react';

import {BorderedElementWithLabel} from '../../components/ElementWithLabel/BorderedElementWithLabel';
import {GoToBlockQuestion} from '../model';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';

import {SelectGoToBlock} from './SelectGoTo';

export const GoToBlockSelect = (props: {
  question: GoToBlockQuestion;
  onChange: (question: GoToBlockQuestion) => void;
}) => {
  const {blocks} = useContext<SurveyContextType>(SurveyContext);
  const changeNextBlock = (newValue: string) =>
    props.onChange({
      ...props.question,
      nextBlock: newValue
    });
  return (
    <div data-test={'next-block-select'}>
      <BorderedElementWithLabel labelId={'survey-creator.goToBlock.selectLabel'}>
        <SelectGoToBlock onChange={changeNextBlock} blocks={blocks.get()} currentBlockId={props.question.nextBlock} />
      </BorderedElementWithLabel>
    </div>
  );
};
