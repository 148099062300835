import React from 'react';
import { useIntl } from 'react-intl';
import { Modal } from '../../components/Modal/Modal';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { TextLabel } from '../../components/Label';
import { Highlighter } from '../../components/Highlighter';
import styles from './StepLineDetails.pcss';

export type DetailsLineBody = Record<string, any> | any[];
type Props = {
  onClose: () => void;
  details:  DetailsLineBody;
  title: string;
};

export function InterpretationStepLineDetails({ title, details, onClose }: Props) {
  const intl = useIntl();

  return (
    <Modal
      modalSize="medium"
      dataTest="entities-details"
      noPadding
      noFixedHeight
    >
      <ModalContent
        headerContent={<span data-test="interpretation-line-details-title">{title}</span>}
        onClose={onClose}
        closeButtonDataTest="entities-details-close"
        displayBottomSeparator={false}
      >
        <div className={styles.content}>
          {details && (
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.interpretation.modal.interpretationResult',
                })}
              />
              <Highlighter
                dataTest="interpretation-line-details-response-body"
                dataSensitive="true"
              >
                {JSON.stringify(details, null, 2)}
              </Highlighter>
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}
