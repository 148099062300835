import * as React from 'react';
import {useContext} from 'react';
import {useIntl} from 'react-intl';
import {noop} from 'lodash';
import classNames from 'classnames';

import {Input} from '../../components/Input';
import {StepElementProps} from './StepElementProps';
import {TextAreaWithVariables} from '../../components/TextArea/TextAreaWithVariables';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';
import {CollectedDataItem, DataCollectionStep} from '../model';
import {ErrorPopup} from '../../components/ErrorPopup/ErrorPopup';
import { Box } from '../../components/Box/Box';
import { Label } from '../../components/Label';
import { DeleteIcon } from '../../icons';
import { AddButton } from './AddButton';
import { VerticalSeparator } from './VerticalSeparator';

import styles from './DataCollectionStepComponent.pcss';
import { useParams } from 'react-router';
import { BotCreatorRouteMatch, getScopeForVariables } from '../useCreatorRouter';

const DATA_COLLECTION_STEP_DEFAULT_COLUMNS: CollectedDataItem[] = [
    { name: 'phone_number', value: '${phoneNumber}' },
    { name: 'conversation_details', value: '${interactionDate}' },
]

export const DataCollectionStepComponent = ({ question, onChange }: StepElementProps<DataCollectionStep>) => {
    const intl = useIntl();

    const { questions: { withError } } = useContext<SurveyContextType>(SurveyContext);
    const errors = withError(question.id, 'collectedData');

    const {variables} = React.useContext<SurveyContextType>(SurveyContext);
    const collectedData: CollectedDataItem[] = question.collectedData || [];

    const params = useParams<BotCreatorRouteMatch>();

    const handleVariableChange = (i: number, value: string) => {
        const newCollectedData = collectedData.map((cd, idx) => {
            if (i === idx) {
                return {
                    ...cd,
                    value
                }
            }

            return cd;
        });
        onChange({
            ...question,
            collectedData: newCollectedData
        })
    };
    const handleValueChange = (i: number, value: string) => onChange({
        ...question,
        collectedData: collectedData.map((cd, idx) => {
            if (i === idx) {
                return {
                    ...cd,
                    name: value
                }
            }

            return cd;
        })
    });

    const onAdd = () => {
        onChange({
            ...question,
            collectedData: [...collectedData, { name: '', value: '' }]
        })
    }

    const onDelete = (index: number) => {
        const newCollectedData = [...collectedData];
        newCollectedData.splice(index, 1);

        onChange({
            ...question,
            collectedData: newCollectedData
        });
    };

    const renderPredefinedRow = (cd: CollectedDataItem) => {
        return (
            <div key={cd.name} className={styles.groupContainer}>
                <div className={styles.inputsGroup}>
                    <div className={styles.inputContainer}>
                        <Label
                            className={styles.label}
                            labelId="survey-creator.data_collection.column.name"
                        />
                        <Input
                            className={styles.input}
                            disabled
                            value={`${intl.messages[`survey-creator.data_collection.${cd.name}`]}`}
                            onChange={noop}
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <Label
                            className={styles.label}
                            labelId="survey-creator.data_collection.column.value"
                        />
                        <TextAreaWithVariables
                            className={styles.input}
                            value={cd.value}
                            disabled
                            asInput
                            availableVariables={variables.getForScope(getScopeForVariables(params))}
                            onChange={noop}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const renderRow = (_, i: number) => {
        const cd = collectedData[i];
        const nameError = errors.find(e => e.field === `collectedData[${i}].name`)?.error
        const variableError = errors.find(e => e.field === `collectedData[${i}].value`)?.error

        return (
            <div key={i} className={styles.groupContainer}>
                <div className={styles.inputsWithActionsGroup}>
                    <div className={classNames(styles.inputsGroup, styles.inputsWithActions)}>
                        <div className={classNames(styles.inputContainer)}>
                            <Label
                                className={styles.label}
                                labelId="survey-creator.data_collection.column.name"
                            />
                            {
                                Boolean(nameError) && 
                                <ErrorPopup
                                    dataTest="input-error"
                                    messageId={`survey-creator.data_collection.error.${nameError}`}
                                />
                            }
                            <Input
                                className={styles.input}
                                dataTest="column-name"
                                value={cd.name}
                                onChange={value => handleValueChange(i, value)}
                                error={nameError}
                            />
                        </div>
                        <div className={classNames(styles.inputContainer)}>
                            <Label
                                className={styles.label}
                                labelId="survey-creator.data_collection.column.value"
                            />
                            <TextAreaWithVariables
                                className={styles.input}
                                dataTest="column-value"
                                value={cd.value}
                                asInput
                                availableVariables={variables.getForScope(getScopeForVariables(params))}
                                onChange={value => handleVariableChange(i, `${value}`)}
                                errorMessage={variableError ? `${intl.messages[`survey-creator.data_collection.error.${variableError}`]}` : null}
                            />
                        </div>
                    </div>
                    <VerticalSeparator noMargin />
                    <button 
                        data-test="column-delete"
                        className={styles.deleteButton}
                        onClick={() => onDelete(i)}
                    >
                        <DeleteIcon />
                    </button>
                </div>
            </div>
        )
    }

    return (
      <Box border className={styles.container}>
        {DATA_COLLECTION_STEP_DEFAULT_COLUMNS.map(renderPredefinedRow)}
        {collectedData.map(renderRow)}
        <AddButton
            buttonType="link"
            labelId="survey-creator.data_collection.addColumnButton"
            dataTest="column-add"
            onClick={onAdd}
        />
      </Box>
    );
};
