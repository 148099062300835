export type Messages = {
    'timestamp.format': string;
    'hour.format': string;
    'date.format': string;
    'month.format': string;
    'datetime.format': string;
    'date.from': string;
    'date.to': string;
    'date.placeholder': string;
    'generalError': string;
    'close': string;

    'header.help': string;
    'header.user': string;

    'cancelLabel': string,
    'downloadButton.errorToast': string,

    'callsList.timestamp': string;
    'callsList.duration': string;
    'callsList.phoneNumber': string;
    'callsList.events': string;
    'callsList.events.hideMore': string;
    'callsList.recording': string;
    'callsList.name': string;
    'callsList.done': string;
    'callsList.done-button': string;
    'callsList.smsTranscriptHeader': string;
    'callsList.size': string;
    'callsList.count': string;
    'callsList.loading': string;
    'callsList.noData': string;
    'callsList.loadMore': string;

    'callDetails.emptyState': string;
    'callDetails.noTranscript': string;
    'callDetails.noAudio': string;
    'callDetails.textChat': string;
    'callDetails.emptyDetails': string;
    'callDetails.loading': string;
    'callDetails.statementNotRecognized': string;
    'callDetails.smsTranscript': string;
    'callDetails.notFound': string;
    'callDetails.showSSMLTags': string;
    'callDetails.recognitionSource.dtmf': string,
    'conversationMarkCaller.description': string;
    'conversationMarkCaller.human': string;
    'conversationMarkCaller.voiceMail': string;
    'conversationMarkCaller.error': string;
    'conversationMarkCaller.marked.MARKED_AS_HUMAN': string,
    'conversationMarkCaller.marked.MARKED_AS_VOICEMAIL': string,

    'contacts.table.navigate': string;
    'contacts.table.phoneNumber': string;
    'contacts.table.status': string;
    'contacts.table.lastInteraction': string;
    'contacts.table.currentInteraction': string;
    'contacts.table.followUpInteraction': string;
    'contacts.table.interactionsPerformed': string;
    'contacts.table.importDate': string;
    'contacts.table.actions': string;
    'contacts.table.status.ANSWERED': string;
    'contacts.table.status.NOT_ANSWERED': string;
    'contacts.table.status.VOICEMAIL_DETECTED': string;
    'contacts.filters.clear': string;
    'contacts.filters.apply': string;
    'contacts.filters.phone.label': string;
    'contacts.filters.status.label': string;
    'contacts.statusFilter.status': string;
    'contacts.statusFilter.phoneNumber': string;
    'contacts.viewsSelect.contactsList': string;
    'contacts.viewsSelect.scheduler': string;
    'contacts.report.buttonLabel': string;
    'contacts.report.fileName': string;
    'contacts.report.noContacts': string;
    'contacts.add.buttonLabel': string;
    'contacts.add.buttonLabel.disabled.noSchedule': string;
    'contacts.add.uploadLabel': string;
    'contacts.add.error.validation': string;
    'contacts.add.error.schedulerDisabled': string;
    'contacts.add.error.SHOULD_HAVE_AT_LEAST_ONE_CONTACT': string;
    'contacts.add.error.SHOULD_HAVE_A_CORRECT_NUMBER_OF_DIGITS': string;
    'contacts.add.error.PHONE_NUMBER_FORMAT_IS_INVALID': string;
    'contacts.add.error.PHONE_NUMBER_COLUMN_REQUIRED': string;
    'contacts.add.error.PHONE_NUMBER_REQUIRED': string;
    'contacts.add.error.PHONE_NUMBER_COUNTRY_CALLING_CODE_MISMATCH': string;
    'contacts.add.error.UNSUPPORTED_FILE_EXTENSION': string;
    'contacts.add.error.UNKNOWN_ERROR': string;
    'contacts.add.title': string;
    'contacts.add.guidelines': string;
    'contacts.add.footer.text': string;
    'contacts.add.body': string;
    'contacts.add.confirm': string;
    'contacts.add.reload': string;
    'contacts.add.finish': string;
    'contacts.add.successBody': string;
    'contacts.add.partiallyFailedBody': string;
    'contacts.add.manualOption.name': string;
    'contacts.add.manualOption.description': string;
    'contacts.add.integrationOption.name': string;
    'contacts.add.integrationOption.description': string;
    'contacts.add.integrationModal.cancel': string;
    'contacts.add.integrationModal.back': string;
    'contacts.add.integrationModal.next': string;
    'contacts.add.integrationModal.nextDisabledTooltip': string;
    'contacts.add.integrationModal.confirm': string;
    'contacts.add.integrationModal.activePlanner.cancel': string;
    'contacts.add.integrationModal.activePlanner.import': string;
    'contacts.add.integrationModal.inactivePlanner.import': string;
    'contacts.add.integrationModal.inactivePlanner.importAndRun': string;
    'contacts.add.integrationModal.import.successToast.disabledPlanner': string;
    'contacts.add.integrationModal.import.successToast.disabledPlannerLink': string;
    'contacts.add.integrationModal.import.successToast.enabledPlanner': string;
    'contacts.add.integrationModal.import.successToast.enabledPlannerLink': string;
    'contacts.add.integrationModal.import.warningToast.disabledPlanner': string;
    'contacts.add.integrationModal.import.warningToast.resumePlannerLink': string;
    'contacts.add.integrationModal.import.warningToast.enabledPlanner': string;
    'contacts.add.integrationModal.import.warningToast.plannerLink': string;
    'contacts.add.integrationModal.import.warningToast.notImportedVisitsLink': string;
    'contacts.add.integrationModal.import.warningToast.notImportedVisitsReportName': string;
    'contacts.add.integrationModal.import.errorToast': string;
    'contacts.add.integrationModal.step1.title': string;
    'contacts.add.integrationModal.step1.instruction': string;
    'contacts.add.integrationModal.step1.date.label': string;
    'contacts.add.integrationModal.step1.date.placeholder': string;
    'contacts.add.integrationModal.step1.time.label': string;
    'contacts.add.integrationModal.step1.time.labelFrom': string;
    'contacts.add.integrationModal.step1.time.labelTo': string;
    'contacts.add.integrationModal.step1.condition.label': string;
    'contacts.add.integrationModal.step1.condition.placeholder': string;
    'contacts.add.integrationModal.step1.conditionValue.label': string;
    'contacts.add.integrationModal.step1.conditionOption.DOCTORS': string;
    'contacts.add.integrationModal.step1.conditionOption.CLINICS': string;
    'contacts.add.integrationModal.step1.conditionOption.SERVICES': string;
    'contacts.add.integrationModal.step1.conditionOption.PRACTICES': string;
    'contacts.add.integrationModal.step1.addCondition': string;
    'contacts.add.integrationModal.step1.preview': string;
    'contacts.add.integrationModal.step1.preview.phoneNumber': string;
    'contacts.add.integrationModal.step1.preview.patient': string;
    'contacts.add.integrationModal.step1.preview.visit': string;
    'contacts.add.integrationModal.step1.preview.doctor': string;
    'contacts.add.integrationModal.step1.preview.service': string;
    'contacts.add.integrationModal.step1.preview.localization': string;
    'contacts.add.integrationModal.step1.preview.practice': string;
    'contacts.add.integrationModal.step1.preview.emptyState': string;
    'contacts.add.integrationModal.step2.title': string;
    'contacts.add.integrationModal.step2.description': string;
    'contacts.add.integrationModal.step2.query': string;
    'contacts.add.integrationModal.step2.query.doctors': string;
    'contacts.add.integrationModal.step2.query.clinics': string;
    'contacts.add.integrationModal.step2.query.services': string;
    'contacts.add.integrationModal.step2.excludeContact': string;
    'contacts.add.integrationModal.step2.excludedContacts': string;
    'contacts.add.integrationModal.step3.title': string;
    'contacts.add.integrationModal.step3.description': string;
    'contacts.add.integrationModal.step3.query': string;
    'contacts.add.integrationModal.step3.interactionStep.sms': string;
    'contacts.add.integrationModal.step3.interactionStep.call': string;
    'contacts.add.integrationModal.step3.interactionStep.http': string;
    'contacts.add.integrationModal.step3.interactionStep.madeRightAway': string;
    'contacts.add.integrationModal.step3.interactionStep.madeAfterHoursAndMinutes': string;
    'contacts.add.integrationModal.step3.interactionStep.madeAfterHours': string;
    'contacts.add.integrationModal.step3.interactionStep.madeAfterMinutes': string;
    'contacts.add.integrationModal.step3.interactionStep.madeTodayAt': string;
    'contacts.add.integrationModal.step3.interactionStep.madeTomorrowAt': string;
    'contacts.add.integrationModal.step3.interactionStep.madeOnDayAt': string;
    'contacts.cancel.allContactsButtonLabel': string;
    'contacts.cancel.allContactsButtonLabelWithCount': string;
    'contacts.cancel.selectedContactsButtonLabel': string;
    'contacts.cancel.selectedContactsButtonLabelWithCount': string;
    'contacts.cancel.noContactsTooltip': string;
    'contacts.cancel.multipleContactsModal.header.allContacts': string;
    'contacts.cancel.multipleContactsModal.header.selectedContacts': string;
    'contacts.cancel.multipleContactsModal.message.contacts': string;
    'contacts.cancel.multipleContactsModal.message.createdContacts': string;
    'contacts.cancel.multipleContactsModal.message.inProgressContacts': string;
    'contacts.cancel.multipleContactsModal.info': string;
    'contacts.cancel.multipleContactsModal.cancelButton': string;
    'contacts.cancel.multipleContactsModal.confirmButton': string;
    'contacts.cancel.multipleContacts.toast.success': string;
    'contacts.cancel.multipleContacts.toast.warning': string;
    'contacts.cancel.multipleContacts.toast.error': string;
    'contacts.phoneNumberFilterLabel': string;
    'contacts.status.created': string;
    'contacts.status.in_progress': string;
    'contacts.status.survey_complete': string;
    'contacts.status.survey_incomplete': string;
    'contacts.status.cancelled': string;

    'contacts.interaction.result.delivered': string,
    'contacts.interaction.result.undelivered': string,
    'contacts.interaction.result.received': string,

    'contacts.interaction.result.failed': string,
    'contacts.interaction.result.succeeded': string,

    'contacts.interaction.result.not_answered': string,
    'contacts.interaction.result.all_required_questions_answered': string,
    'contacts.interaction.result.voicemail': string,
    'contacts.interaction.result.missing_required_question': string,
    'contacts.interaction.result.postpone_request': string,
    'contacts.interaction.result.stop_calling': string,
    'contacts.interaction.status.processed': string,

    'contacts.interaction.status.pending': string,
    'contacts.interaction.status.active': string,
    'contacts.interaction.status.failed': string,
    'contacts.interaction.status.cancelled': string,
    'contacts.interaction.type.call-outbound': string,
    'contacts.interaction.type.call-inbound': string,

    'contacts.interaction.type.sms-inbound': string,
    'contacts.interaction.type.sms-outbound': string,

    'contacts.interaction.type.http-inbound': string,
    'contacts.interaction.type.http-outbound': string,

    'contacts.interaction.dateLabel': string,
    'contacts.interaction.statusLabel': string,
    'contacts.interaction.typeLabel': string,
    'contacts.interaction.resultLabel': string,
    'contacts.interaction.messageLabel': string,

    'contacts.interaction.empty': string,

    'contacts.details.cancelContactButtonLabel': string,
    'contacts.details.cancelContactDescription': string,
    'contacts.cancel.modal.header': string,
    'contacts.cancel.modal.body': string,
    'contacts.cancel.modal.confirm': string,
    'contacts.cancel.modal.cancel': string,
    'contacts.cancel.successToast': string,
    'contacts.cancel.errorToast': string,

    'contacts.noContacts.importFileExtensions': string,
    'contacts.noContacts.addContactFile': string,
    'contacts.noContacts.addContacts': string,
    'contacts.noContacts.readMoreInKnowledgeBase': string,
    'contacts.noContacts.readMore': string,
    'contacts.noContacts.readMoreUrl': string,
    'contacts.noContacts.addContactsViaAPI': string,
    'contacts.noContacts.referToDocs': string,
    'contacts.noContactsForFilters.contactsNotFound': string,
    'contacts.noContactsForFilters.changeValue': string,
    'contacts.noContactsNoSchedule.activateScheduler': string,
    'contacts.noContactsNoSchedule.scheduler': string,
    'contacts.noContactsNoSchedule.nextSteps': string,

    'dashboard.header.search.placeholder': string,
    'dashboard.botsView.addBot.button': string,
    'dashboard.botsView.manageOrganization.button': string,
    'dashboard.botsView.filterByTags.button': string,
    'dashboard.botsView.customerInvisibleBots.show': string,
    'dashboard.botsView.customerInvisibleBots.hide': string,
    'dashboard.clonePopup.title': string,
    'dashboard.clonePopup.baseBotName': string,
    'dashboard.clonePopup.baseBotLanguage': string,
    'dashboard.clonePopup.newBotName': string,
    'dashboard.clonePopup.client': string,
    'dashboard.clonePopup.client.placeholder': string,
    'dashboard.clonePopup.aiModel': string,
    'dashboard.clonePopup.visibleForClient': string,
    'dashboard.clonePopup.save': string,
    'dashboard.clonePopup.cancel': string,
    'dashboard.clonePopup.addNewClient': string,
    'dashboard.clonePopup.aiModelOptions.shareModel': string,
    'dashboard.clonePopup.aiModelOptions.copyModel': string,
    'dashboard.clonePopup.aiModelOptions.createNew': string,
    'dashboard.clonePopup.aiModelOptions.placeholder': string,
    'dashboard.addBotPopup.title': string,
    'dashboard.addBotPopup.newBotName': string,
    'dashboard.addBotPopup.newBotLanguage': string,
    'dashboard.addBotPopup.client': string,
    'dashboard.addBotPopup.visibleForClient': string,
    'dashboard.addBotPopup.anonymized': string,
    'dashboard.addBotPopup.save': string,
    'dashboard.addBotPopup.cancel': string,
    'dashboard.addBotPopup.languages.pl-PL': string,
    'dashboard.addBotPopup.languages.en-GB': string,
    'dashboard.addBotPopup.languages.en-US': string,
    'dashboard.addBotPopup.languages.en-AU': string,
    'dashboard.addBotPopup.languages.hu-HU': string,
    'dashboard.addBotPopup.languages.ru-RU': string,
    'dashboard.addBotPopup.languages.fr-FR': string,
    'dashboard.addBotPopup.language.placeholder': string,
    'dashboard.addBotPopup.intentSet': string,
    'dashboard.addBotPopup.intentSet.default': string,
    'dashboard.addBotPopup.intentSet.default-medical': string,
    'dashboard.addBotPopup.client.placeholder': string,
    'dashboard.addBotPopup.invalidTags': string,
    'dashboard.editBotPopup.title': string,
    'dashboard.editBotPopup.botName': string,
    'dashboard.editBotPopup.botLanguage': string,
    'dashboard.editBotPopup.client': string,
    'dashboard.editBotPopup.botTags': string,
    'dashboard.editBotPopup.botTagsTooltip': string,
    'dashboard.editBotPopup.tooManyTags': string,
    'dashboard.editBotPopup.visibleForClient': string,
    'dashboard.editBotPopup.update': string,
    'dashboard.editBotPopup.cancel': string,

    'organization.header.organization': string,
    'organization.tabs.sms': string,
    'organization.sms.smsSettings.heading': string,
    'organization.sms.smsSettings.disclaimer': string,
    'organization.sms.additionalBotNumber.heading': string,
    'organization.sms.additionalBotNumber.disclaimer': string,
    'organization.sms.providerName': string,
    'organization.sms.phoneNumber': string,
    'organization.sms.botName': string,
    'organization.sms.supervoip.login': string,
    'organization.sms.supervoip.password': string,
    'organization.sms.supervoip.apiKey': string,
    'organization.sms.vonage.apiKey': string,
    'organization.sms.vonage.apiSecret': string,
    'organization.sms.twilio.accountSid': string,
    'organization.sms.twilio.authToken': string,
    'organization.sms.parlance.applicationGuid': string,
    'organization.sms.parlance.apiKey': string,
    'organization.sms.parlance.baseUrl': string,
    'organization.sms.saveChanges': string,
    'organization.sms.cancel': string,
    'organization.sms.addOverride': string,
    'organization.sms.update.successToast': string,
    'organization.sms.update.errorToast': string,
    'organization.sms.testSms': string,
    'organization.sms.testSms.tooltip.disabled': string,
    'organization.sms.testSms.title': string,
    'organization.sms.testSms.phoneNumber': string,
    'organization.sms.testSms.cancel': string,
    'organization.sms.testSms.send': string,
    'organization.sms.testSms.success': string,
    'organization.sms.testSms.error': string,

    'scheduler.dateOptions.placeholder': string;
    'scheduler.holidayOptions.text': string;
    'scheduler.holidayOptions.country.selectText': string;
    'scheduler.holidayOptions.country.Poland': string;
    'scheduler.holidayOptions.country.USA': string;
    'scheduler.callingDays.text': string;
    'scheduler.callingDays.Monday': string;
    'scheduler.callingDays.Tuesday': string;
    'scheduler.callingDays.Wednesday': string;
    'scheduler.callingDays.Thursday': string;
    'scheduler.callingDays.Friday': string;
    'scheduler.callingDays.Saturday': string;
    'scheduler.callingDays.Sunday': string;
    'scheduler.timeSettings.timezone': string;
    'scheduler.timeSettings.callingHours': string;
    'scheduler.maxConcurrentCallsSettings.enabled': string;
    'scheduler.acceptIncomingCallsSettings.enabled': string;
    'scheduler.timeZoneLabel': string;
    'scheduler.guideBox.stepOne.header': string;
    'scheduler.guideBox.stepOne.content': string;
    'scheduler.guideBox.stepTwo.header': string;
    'scheduler.guideBox.stepTwo.content': string;
    'scheduler.guideBox.stepThree.header': string;
    'scheduler.guideBox.stepThree.content': string;
    'scheduler.steps.callStep.type.JustInTime': string;
    'scheduler.steps.callStep.type.FollowUpInGivenHours': string;
    'scheduler.steps.callStep.type.FollowUpInGivenDaysAt': string;
    'scheduler.steps.callStep.FollowUpInGivenDaysAt.before': string;
    'scheduler.steps.callStep.FollowUpInGivenDaysAt.currentDay': string;
    'scheduler.steps.callStep.FollowUpInGivenDaysAt.nextDay': string;
    'scheduler.steps.callStep.FollowUpInGivenDaysAt.multipleDays': string;
    'scheduler.steps.titleLabel': string;
    'scheduler.steps.callHeader': string;
    'scheduler.steps.callDescription': string;
    'scheduler.steps.smsHeader': string;
    'scheduler.steps.smsCollapsed': string;
    'scheduler.steps.smsDescription': string;
    'scheduler.steps.httpHeader': string;
    'scheduler.steps.httpCollapsed': string;
    'scheduler.steps.httpDescription': string;
    'scheduler.steps.addStep': string;
    'scheduler.steps.addStepDescription': string;
    'scheduler.steps.addCall': string;
    'scheduler.steps.addSms': string;
    'scheduler.steps.addHttp': string;
    'scheduler.step.callSelect': string;
    'scheduler.step.callSelectFirst': string;
    'scheduler.step.numberOfMinutesLabel': string;
    'scheduler.step.numberOfHoursLabel': string;
    'scheduler.step.numberOfDaysLabel': string;
    'scheduler.step.callTimeLabel': string;
    'scheduler.step.smsTemplateLabel': string;
    'scheduler.step.smsSenderNameLabel': string;

    'scheduler.step.httpStep.url': string;
    'scheduler.step.httpStep.method': string;
    'scheduler.step.httpStep.headers': string;
    'scheduler.step.httpStep.body': string;
    'scheduler.step.httpStep.timeout': string;
    'scheduler.step.httpStep.resetTimeout': string;

    'scheduler.botStatus.readyToCall': string;
    'scheduler.botStatus.calling': string;
    'scheduler.botStatus.paused': string;
    'scheduler.botStatus.noSchedule': string;
    'scheduler.botStatus.noScheduleAndPhoneNumber': string;
    'scheduler.botStatus.noPhoneNumber': string;
    'scheduler.botStatus.label': string;

    'scheduler.contactStatuses.inQueue': string;
    'scheduler.contactStatuses.inProgress': string;
    'scheduler.contactStatuses.called': string;

    'scheduler.status.tooltip.inQueue': string;
    'scheduler.status.tooltip.inProgress': string;
    'scheduler.status.tooltip.called': string;

    'scheduler.button.tooltip.noScheduleAndPhoneNumber': string;
    'scheduler.button.tooltip.noPhoneNumber': string;
    'scheduler.button.tooltip.noSchedule': string;

    'scheduler.button.pause': string,
    'scheduler.button.resume': string,

    'scheduler.save': string;
    'scheduler.cancel': string;

    'table.next': string;
    'table.previous': string;
    'table.page': string;
    'table.of': string;
    'table.rows': string;

    'conversations.filters.phone.label': string;
    'conversations.filters.name.label': string;
    'conversations.filters.phrase.label': string;
    'conversations.filters.filter': string;
    'conversations.filters.cancel': string;
    'conversations.filters.filter.clear': string;
    'conversations.filters.report': string;
    'conversations.filters.showAll': string;
    'conversations.filters.events.clear': string;
    'conversations.filters.duration.label': string;

    'conversations.options.button': string;
    'conversations.options.modal.title': string;
    'conversations.options.modal.save': string;
    'conversations.options.modal.description': string;
    'conversations.options.modal.deselectAll': string;
    'conversations.options.modal.selectAll': string;
    'conversations.options.modal.ssmlTags': string;
    'conversations.options.modal.module': string;
    'conversations.options.modal.step_name': string;
    'conversations.options.modal.http': string;
    'conversations.options.modal.set_variable': string;
    'conversations.options.modal.data_collection': string;
    'conversations.options.modal.function': string;
    'conversations.options.modal.send_sms': string;
    'conversations.options.modal.redirect': string;
    'conversations.options.modal.interpretation': string;
    'conversations.options.modal.dtmf': string;


    'conversations.details.modal.stepLinkExpired': string;

    'conversations.variables.modal.stepLink': string;
    'conversations.variables.modal.name': string;
    'conversations.variables.modal.value': string;

    'conversations.http.modal.stepLink': string;
    'conversations.http.modal.url': string;
    'conversations.http.modal.method': string;
    'conversations.http.modal.headers': string;
    'conversations.http.modal.requestBody': string;
    'conversations.http.modal.responseBody': string;

    'conversations.sms.modal.stepLink': string;
    'conversations.sms.modal.from': string;
    'conversations.sms.modal.to': string;
    'conversations.sms.modal.text': string;
    'conversations.sms.modal.provider': string;
    'conversations.sms.modal.status': string;
    'conversations.sms.modal.errorMessage': string;

    'conversations.redirect.modal.stepLink': string;
    'conversations.redirect.modal.to': string;
    'conversations.redirect.modal.from': string;
    'conversations.redirect.modal.trunk': string;
    'conversations.redirect.modal.type': string;
    'conversations.redirect.modal.destination': string;
    'conversations.redirect.modal.recordingAction': string;
    'conversations.redirect.modal.headers': string;

    'conversations.interpretation.intent': string;
    'conversations.interpretation.entity': string;
    'conversations.interpretation.translation': string,
    'conversations.interpretation.modal.interpretationResult': string;

    'conversations.dataCollection.report': string,
    'conversations.dataCollection.modal.collectedData': string;

    'conversations.function.name': string;
    'conversations.function.inputs': string;
    'conversations.function.outputs': string;
    'conversations.function.enabled': string,
    'conversations.function.disabled': string,

    'filters.date.from': string;
    'filters.date.to': string;
    'filters.date.search': string;

    'statistics.period.day': string;
    'statistics.period.week': string;
    'statistics.period.month': string;
    'statistics.period.year': string;
    'statistics.period.custom': string;
    'statistics.period.placeholder': string;
    'statistics.button.report.download': string;
    'statistics.report.fileName': string;
    'statistics.chart.hourly': string;
    'statistics.chart.daily': string;
    'statistics.chart.monthly': string;
    'statistics.summary': string;
    'statistics.results': string;
    'statistics.information': string;
    'statistics.surveyQuestions': string;
    'statistics.recognizedIntents': string;
    'statistics.filters.containingText': string;
    'statistics.addons.minutesUsed': string;
    'statistics.addons.tooltip.minutesUsed': string;

    'statistics.dashboard.call_answered': string;
    'statistics.dashboard.call_not_answered': string;
    'statistics.dashboard.voice_mail_detected': string;
    'statistics.dashboard.incoming_call': string;
    'statistics.dashboard.allCalls': string;
    'statistics.dashboard.call_redirected': string;
    'statistics.dashboard.average_call_duration': string;
    'statistics.dashboard.unique_callers_count': string;
    'statistics.dashboard.tooltip.call_answered': string;
    'statistics.dashboard.tooltip.call_not_answered': string;
    'statistics.dashboard.tooltip.voice_mail_detected': string;
    'statistics.dashboard.tooltip.incoming_call': string;
    'statistics.dashboard.tooltip.allCalls': string;
    'statistics.dashboard.tooltip.call_redirected': string;
    'statistics.dashboard.tooltip.average_call_duration': string;
    'statistics.dashboard.tooltip.unique_callers_count': string;

    'statistics.scenarioStepsDashboard.savingChangesToastError': string;
    'statistics.scenarioStepsDashboard.pinnedSteps': string;
    'statistics.scenarioStepsDashboard.remainingSteps': string;
    'statistics.scenarioStepsDashboard.allSteps': string;
    'statistics.scenarioStepsDashboard.pinAllSteps': string;
    'statistics.scenarioStepsDashboard.unpinAllSteps': string;

    'statistics.scenarioStepsDashboard.userHelp.someStepsPinned.description': string;
    'statistics.scenarioStepsDashboard.userHelp.someStepsPinned.callToAction': string;

    'statistics.scenarioStepsDashboard.userHelp.allStepsPinned.description': string;
    'statistics.scenarioStepsDashboard.userHelp.allStepsPinned.callToAction': string;

    'statistics.scenarioStepsDashboard.tooltip.pin': string;
    'statistics.scenarioStepsDashboard.tooltip.unpin': string;

    'statistics.scenarioStepsDashboard.emptyState.header': string;
    'statistics.scenarioStepsDashboard.emptyState.description': string;
    'statistics.scenarioStepsDashboard.emptyState.callToAction': string;
    'statistics.scenarioStepsDashboard.emptyState.editView': string;

    'statistics.scenarioStepsDashboard.navbar.editView': string;
    'statistics.scenarioStepsDashboard.navbar.cancel': string;
    'statistics.scenarioStepsDashboard.navbar.saveChanges': string;
    'statistics.scenarioStepsDashboard.navbar.pinDescription': string;

    'header.conversations': string;
    'header.done-conversations': string;
    'header.live-conversations': string;
    'header.reports': string;
    'header.statistics': string;
    'header.summary': string;
    'header.learning': string;
    'header.settings': string;
    'header.logout': string;
    'header.termsOfService': string,
    'header.changePassword': string;
    'header.inspector': string;
    'header.survey-creator': string;
    'header.contacts': string;
    'header.candidates': string;

    'header.changePasswordModal.formHeader': string;
    'header.changePasswordModal.confirmationHeader': string;
    'header.changePasswordModal.submit': string;
    'header.changePasswordModal.cancel': string;
    'header.changePasswordModal.oldPasswordLabel': string;
    'header.changePasswordModal.newPasswordLabel': string;
    'header.changePasswordModal.repeatNewPasswordLabel': string;
    'header.changePasswordModal.confirmationDescription': string;

    'survey.externalVariables.dictionary': string;
    'survey.externalVariables.save': string;
    'survey.triggerCalls': string;
    'survey.triggerSkippedCalls': string;
    'survey.config.tabs.general': string;
    'survey.config.tabs.phone': string;
    'survey.config.tabs.admin': string;
    'survey.config.tabs.api': string;
    'survey.config.tabs.api.docsButtonLabel': string;
    'survey.config.tabs.api.show': string,
    'survey.config.tabs.api.copy': string,
    'survey.config.tabs.api.copied': string,
    'survey.config.returnCallToggleLabel': string;
    'survey.config.returnCallMaxCallsLabel': string;
    'survey.config.returnCallIntervalLabel': string;
    'survey.config.phoneConfigs': string;
    'survey.config.addPhone': string;
    'survey.config.countryCallingCode': string;
    'survey.config.countryCallingCodeNote': string;
    'survey.config.addNumber': string;
    'survey.config.phoneNumber': string;
    'survey.config.serverAddress': string;

    'survey.config.anonymized': string;
    'survey.config.timeZone': string;
    'survey.config.admin.addVariable': string;
    'survey.config.admin.addVariable.title.contact': string;
    'survey.config.admin.addVariable.title.external': string;
    'survey.config.voiceConfig.label': string;
    'survey.config.voiceConfig.language': string;
    'survey.config.voiceConfig.defaultContent': string;
    'survey.config.voiceConfig.voiceName': string;
    'survey.config.voiceConfig.googleLink': string;
    'survey.config.voiceConfig.googleLinkText': string;
    'survey.config.voiceConfig.speed': string;
    'survey.config.voiceConfig.pitch': string;
    'survey.config.voiceConfig.voiceTestContent': string;
    'survey.config.voiceConfig.voiceTest': string;
    'survey.config.api.username': string;
    'survey.config.api.password': string;
    'survey.config.api.botId': string;
    'survey.config.save': string;

    'inspector.filters.phrase': string;
    'inspector.filters.withoutAnswers': string;
    'inspector.filters.withoutIntents': string;
    'inspector.filters.question': string;
    'inspector.filters.intent': string;
    'inspector.filters.question.tooltip': string;
    'inspector.filters.intent.tooltip': string;
    'inspector.text': string;
    'inspector.recognizedIntent': string;
    'inspector.recognizedAnswer': string;
    'inspector.occurrences': string;
    'inspector.stepName': string;
    'inspector.wordQuestionValue': string;
    'inspector.actions.goToConversation': string;
    'inspector.actions.remove': string;
    'inspector.train': string,
    'inspector.train.sentencesLabel': string,
    'inspector.train.addToLabel': string,
    'inspector.train.addToStep': string,
    'inspector.train.addToIntent': string,
    'inspector.train.answerInLabel': string,
    'inspector.train.intentLabel': string,
    'inspector.train.scopeLabel': string,
    'inspector.train.scopeLocalOption': string,
    'inspector.train.scopeGlobalOption': string,
    'inspector.train.header': string,

    'learning.entityName': string;
    'learning.value': string;
    'learning.learn': string;
    'learning.addIntent': string;
    'learning.learn.dev': string;
    'learning.learn.prod': string;
    'learning.trainingStatus.dev': string;
    'learning.trainingStatus.prod': string;
    'learning.trainingStatus.IDLE': string;
    'learning.trainingStatus.TRAINING': string;
    'learning.trainingStatus.DISABLED': string;
    'learning.trainingStatus.ERROR': string;
    'learning.interpretation': string;
    'learning.evaluate.dev': string;
    'learning.evaluate.prod': string;
    'learning.evaluate.accuracy': string;
    'learning.globalIntents': string;
    'learning.multipleSentences': string;
    'learning.cancel': string;
    'learning.intentTraining.intent': string;
    'learning.intentTraining.error': string;
    'learning.intentTraining.statusLabel': string;
    'learning.intentTraining.trainingBotButton': string;
    'learning.intentTraining.intentSelect.placeholder': string;
    'learning.intentTraining.addUtterance': string;
    'learning.intentTraining.addMultiple': string;
    'learning.intentTraining.recognizedIntentLabel': string;
    'learning.intentTraining.confidenceLabel': string;

    'learning.intentTraining.intentInput.placeholder': string;
    'wrong-sentences.table.text': string;
    'wrong-sentences.table.intent': string;
    'wrong-sentences.table.predicted': string;
    'wrong-sentences.table.confidence': string;
    'train-data-duplicate.popup.text': string;
    'train-data-duplicate.table.text': string;
    'train-data-duplicate.table.intent': string;

    'learning.view.intents': string;

    'learning.view.intent.trainData.shouldRemove': string;
    'learning.view.intent.trainData.shouldRemove.yes': string;
    'learning.view.intent.trainData.shouldRemove.no': string;

    'learning.view.globalSynonymsWarning': string,
    'learning.view.addGlobalSynonym': string,
    'learning.view.globalSynonyms': string,

    'learning.globalSynonyms.newSynonymConfirmationText': string,
    'learning.globalSynonyms.selectPlaceholder': string,
    'learning.globalSynonyms.synonymPlaceholder': string,

    'learning.globalWord.keyHeader': string,
    'learning.globalWord.synonymsHeader': string,
    'learning.globalWord.removeSynonymText': string,
    'learning.globalWord.removeEntityText': string,

    'entity.table.key': string;
    'entity.table.values': string;

    'names-dictionary.table.key': string;
    'names-dictionary.table.values': string;
    'names-dictionary.input.key': string;
    'names-dictionary.input.label': string;
    'names-dictionary.input.category': string;
    'names-dictionary.add.button': string;

    'auth.goBack': string;
    'auth.signIn.header': string;
    'auth.signIn.username': string;
    'auth.signIn.email': string;
    'auth.signIn.password': string;
    'auth.signIn.forgot': string;
    'auth.signIn.signIn': string;
    'auth.signIn.userNotExist': string;
    'auth.signIn.loginDetailsAreIncorrect': string;
    'auth.signIn.resetPasswordDescription': string;
    'auth.signIn.resetPasswordButtonLabel': string;
    'auth.signIn.resetPasswordConfirmationDescription': string;
    'auth.signIn.backToLoginButtonLabel': string;
    'auth.signIn.resetPasswordConfirmationHeader': string;
    'auth.signIn.setNewPasswordConfirmation': string;
    'auth.goBack.signIn.setNewPasswordHelp': string;
    'auth.signIn.setNewPasswordHeader': string;
    'auth.signIn.activationLinkAlreadyUsed': string;
    'auth.signIn.activationLinkExpired': string;
    'auth.signIn.somethingWentWrong': string;
    'auth.signIn.supportEmail': string;
    'auth.signIn.termsOfServiceLoginReminder': string;
    'auth.signIn.termsOfServiceLoginReminder.termsOfService': string,
    'auth.signIn.registrationTitle': string;
    'auth.signIn.registration': string;
    'auth.signIn.register': string;
    'auth.signIn.registerTooltip': string;
    'auth.signIn.termsOfServiceCheckbox': string;
    'auth.signIn.termsOfService': string;
    'auth.signIn.termsOfServiceDateFormat': string;
    'auth.sendCode.header': string;
    'auth.sendCode.username': string;
    'auth.sendCode.sendCode': string;
    'auth.sendCode.userNotExist': string;
    'auth.resetPassword.header': string;
    'auth.resetPassword.code': string;
    'auth.resetPassword.newPassword': string;
    'auth.resetPassword.repeatPassword': string;
    'auth.resetPassword.repeatNewPassword': string;
    'auth.resetPassword.codeErrorMessage': string;
    'auth.resetPassword.newPasswordErrorMessage': string;
    'auth.resetPassword.button': string;
    'auth.newPassword.header': string;
    'auth.newPassword.password': string;
    'auth.newPassword.changeButton': string;
    'auth.newPassword.error': string;
    'auth.newPassword.changePasswordLinkExpired': string;
    'auth.mfaCode.header': string;
    'auth.mfaCode.description': string;
    'auth.mfaCode.code': string;
    'auth.mfaCode.codeLength': string;
    'auth.mfaCode.verifyButton': string;
    'auth.mfaCode.verificationCodeInvalid': string;
    'auth.mfaCode.resendCodeDescription': string;
    'auth.mfaCode.resendCodeLink': string;
    'auth.mfaCode.resendCodeLinkSuccessToast': string;
    'auth.mfaCode.resendCodeLinkFailureToast': string;

    'auth.errors.complexity': string,
    'auth.errors.repeat': string,

    'errors.VALUE_REQUIRED': string,

    'select.search': string;
    'select.noResult': string;

    'no-bots.message_1': string;
    'no-bots.message_2': string;

    'select.placeholder': string;
    'select.noOptions': string;

    'survey-creator.command-palette.categories.modules': string;
    'survey-creator.command-palette.categories.steps': string;
    'survey-creator.command-palette.categories.intents': string;
    'survey-creator.command-palette.categories.texts': string;
    'survey-creator.command-palette.categories.urls': string;
    'survey-creator.command-palette.categories.paths': string;
    'survey-creator.command-palette.categories.actions': string;

    'survey-creator.command-palette.actions.copy-bot-id': string;
    'survey-creator.command-palette.actions.copy-bot-name': string;
    'survey-creator.command-palette.actions.call-me': string;
    'survey-creator.command-palette.actions.creator-list-view': string;
    'survey-creator.command-palette.actions.creator-visual-view': string;
    'survey-creator.command-palette.actions.creator-rollback': string;

    'survey-creator.command-palette.footer': string;

    'survey-creator.history.modal.title': string;
    'survey-creator.history.modal.cancel': string;
    'survey-creator.history.modal.rollback': string;
    'survey-creator.history.modal.annotate': string;
    'survey-creator.history.modal.changeAnnotation': string;
    'survey-creator.history.modal.save': string;
    'survey-creator.history.modal.snapshotTitle': string;
    'survey-creator.history.modal.emptyState': string;

    'survey-creator.intentDetails.training': string,
    'survey-creator.intentDetails.reaction': string,
    'survey-creator.intentDetails.entities': string,
    'survey-creator.intentDetails.reactionType': string,

    'survey-creator.intentDetails.entities.addEntity': string,
    'survey-creator.intentDetails.entities.addEntityDescription': string,

    'survey-creator.intentDetails.entities.number': string,
    'survey-creator.intentDetails.entities.datetime': string,
    'survey-creator.intentDetails.entities.district': string,
    'survey-creator.intentDetails.entities.country': string,
    'survey-creator.intentDetails.entities.person': string,
    'survey-creator.intentDetails.entities.date': string,
    'survey-creator.intentDetails.entities.building_number': string,
    'survey-creator.intentDetails.entities.street': string,
    'survey-creator.intentDetails.entities.time': string,
    'survey-creator.intentDetails.entities.town': string,
    'survey-creator.intentDetails.entities.company': string,
    'survey-creator.intentDetails.entities.POI': string,
    'survey-creator.intentDetails.entities.organization': string,
    'survey-creator.intentDetails.entities.location': string,
    'survey-creator.intentDetails.entities.gpe': string,

    'survey-creator.defaultNamePrefix': string;
    'survey-creator.defaultNamePrefix.redirect': string
    'survey-creator.defaultNamePrefix.numerical': string
    'survey-creator.defaultNamePrefix.word': string
    'survey-creator.defaultNamePrefix.conditional': string
    'survey-creator.defaultNamePrefix.datetime': string
    'survey-creator.defaultNamePrefix.date': string
    'survey-creator.defaultNamePrefix.end': string
    'survey-creator.defaultNamePrefix.go_to_block': string
    'survey-creator.defaultNamePrefix.open': string
    'survey-creator.defaultNamePrefix.spelling': string
    'survey-creator.defaultNamePrefix.send_sms': string
    'survey-creator.defaultNamePrefix.send_email': string
    'survey-creator.defaultNamePrefix.http': string
    'survey-creator.defaultNamePrefix.set_variable': string
    'survey-creator.defaultNamePrefix.statement': string
    'survey-creator.defaultNamePrefix.help_propose': string
    'survey-creator.defaultNamePrefix.generic_order': string
    'survey-creator.defaultNamePrefix.city_or_community': string
    'survey-creator.defaultNamePrefix.confirmation': string
    'survey-creator.defaultNamePrefix.data_collection': string
    'survey-creator.defaultNamePrefix.function': string
    'survey-creator.emptyNamePlaceholder': string;
    'survey-creator.cloneLabel': string;
    'survey-creator.defaultBlockNamePrefix': string;
    'survey-creator.cancel': string;
    'survey-creator.save': string;
    'survey-creator.blocksLabel': string;
    'survey-creator.blocksTooltip': string
    'survey-creator.intentsTooltip': string
    'survey-creator.callbacksTooltip': string
    'survey-creator.addBlockButtonLabel': string;
    'survey-creator.addEntityButtonLabel': string;
    'survey-creator.addBlockItemBlockLabel': string;
    'survey-creator.addBlockItemIntentLabel': string;
    'survey-creator.addBlockItemCallbackLabel': string;
    'survey-creator.addBlockItemDescriptionBlock': string;
    'survey-creator.addBlockItemDescriptionIntent': string;
    'survey-creator.addBlockItemDescriptionCallback': string;
    'survey-creator.addBlockItemDescriptionCallbackDisabled': string;
    'survey-creator.addQuestionButton': string;
    'survey-creator.addCallbackStepButton': string;
    'survey-creator.intent.addNewIntentLabel': string;
    'survey-creator.intent.placeholder': string;
    'survey-creator.intentsLabel': string;
    'survey-creator.callbacksLabel': string;
    'survey-creator.callbacks.callFinished': string;
    'survey-creator.callbacks.voiceMailDetected': string;
    'survey-creator.callbacks.callNotAnswered': string;
    'survey-creator.callback.callFinished.emptyState': string;
    'survey-creator.callback.voiceMailDetected.emptyState': string;
    'survey-creator.callback.callNotAnswered.emptyState': string;
    'survey-creator.callbacks.addPlaceholder': string;
    'survey-creator.textLabel': string;
    'survey-creator.audioLabel': string;
    'survey-creator.messageLabel': string;
    'survey-creator.repeatMessageLabel': string;
    'survey-creator.repeatMessageLabelAlternative': string;
    'survey-creator.followupMessageLabel': string;
    'survey-creator.helpNeededMessageLabel': string;
    'survey-creator.fromIntentMessageLabel': string;
    'survey-creator.statementLabel': string;
    'survey-creator.addRepeatMessageButton': string,
    'survey-creator.messageAudio.upload': string;
    'survey-creator.messageAudio.remove': string;
    'survey-creator.goTo.titleLabel': string;
    'survey-creator.goTo.falseLabel': string;
    'survey-creator.goTo.repeatedLabel': string;
    'survey-creator.goTo.outOfRangeLabel': string;
    'survey-creator.goTo.repeatLabel': string;
    'survey-creator.goTo.invalidLabel': string;
    'survey-creator.goTo.invalidLengthLabel': string;
    'survey-creator.goTo.falseDescription': string;
    'survey-creator.goTo.repeatedDescription': string;
    'survey-creator.goTo.outOfRangeDescription': string;
    'survey-creator.goTo.invalidLengthDescription': string;
    'survey-creator.goTo.continueLabel': string;
    'survey-creator.possibleQuestionsLabel': string;
    'survey-creator.possibleQuestionsDescription': string;
    'survey-creator.possibleQuestionsIntent': string;
    'survey-creator.possibleQuestionsMessageText': string;
    'survey-creator.possibleQuestionsMessageAudio': string;
    'addLabel': string;

    'survey-creator.numericalQuestions.rangesLabel': string;

    'survey-creator.confirmationStepLabel': string;
    'survey-creator.confirmationStepTooltip': string;
    'survey-creator.wordStepLabel': string;
    'survey-creator.wordStepTooltip': string;
    'survey-creator.numericalStepLabel': string;
    'survey-creator.numericalStepTooltip': string;
    'survey-creator.datetimeStepLabel': string;
    'survey-creator.datetimeStepTooltip': string;
    'survey-creator.dateStepLabel': string;
    'survey-creator.dateStepTooltip': string;
    'survey-creator.endStepLabel': string;
    'survey-creator.endStepTooltip': string;
    'survey-creator.conditionalStepLabel': string;
    'survey-creator.conditionalStepTooltip': string;
    'survey-creator.openStepLabel': string;
    'survey-creator.openStepTooltip': string;
    'survey-creator.spellingStepLabel': string;
    'survey-creator.spellingStepTooltip': string;
    'survey-creator.send_smsStepLabel': string;
    'survey-creator.send_smsStepTooltip': string;
    'survey-creator.send_emailStepLabel': string;
    'survey-creator.send_emailStepTooltip': string;
    'survey-creator.httpStepLabel': string;
    'survey-creator.httpStepTooltip': string;
    'survey-creator.set_variableStepLabel': string;
    'survey-creator.set_variableStepTooltip': string;
    'survey-creator.functionStepLabel': string;
    'survey-creator.functionStepTooltip': string;
    'survey-creator.functionStepAddInputArrayButton': string;
    'survey-creator.statementStepLabel': string;
    'survey-creator.statementStepTooltip': string;
    'survey-creator.redirectStepLabel': string;
    'survey-creator.redirectStepTooltip': string;
    'survey-creator.help_proposeStepLabel': string;
    'survey-creator.help_proposeStepTooltip': string;
    'survey-creator.go_to_blockStepLabel': string;
    'survey-creator.go_to_blockStepTooltip': string;
    'survey-creator.data_collectionStepLabel': string;
    'survey-creator.data_collectionStepTooltip': string;
    'survey-creator.data_collection.column.name': string;
    'survey-creator.data_collection.column.value': string;
    'survey-creator.data_collection.addColumnButton': string;
    'survey-creator.data_collection.phone_number': string;
    'survey-creator.data_collection.conversation_details': string;
    'survey-creator.data_collection.error.VALUE_REQUIRED': string;
    'survey-creator.data_collection.error.ITEM_DUPLICATED': string;
    'survey-creator.system_variable.phoneNumber': string;
    'survey-creator.system_variable.botPhoneNumber': string;
    'survey-creator.system_variable.hangUpCause': string;
    'survey-creator.system_variable.hangUpCause.clientHungUp': string,
    'survey-creator.system_variable.hangUpCause.botHungUp': string,
    'survey-creator.system_variable.callDuration': string;
    'survey-creator.system_variable.conversationId': string;
    'survey-creator.system_variable.interactionDate': string;
    'survey-creator.system_variable.followUpInteraction': string,
    'survey-creator.system_variable.lastUserMessage': string,
    'survey-creator.system_variable.transcript': string;
    'survey-creator.system_variable.callDirection': string;
    'survey-creator.system_variable.currentHour': string;
    'survey-creator.system_variable.currentDay': string,
    'survey-creator.system_variable.currentDay.Monday': string,
    'survey-creator.system_variable.currentDay.Tuesday': string,
    'survey-creator.system_variable.currentDay.Wednesday': string,
    'survey-creator.system_variable.currentDay.Thursday': string,
    'survey-creator.system_variable.currentDay.Friday': string,
    'survey-creator.system_variable.currentDay.Saturday': string,
    'survey-creator.system_variable.currentDay.Sunday': string,
    'survey-creator.system_variable.currentDate': string,
    'survey-creator.system_variable.callDirection.inbound': string,
    'survey-creator.system_variable.callDirection.outbound': string,
    'survey-creator.system_variable.preferredLanguage': string;
    'survey-creator.system_variable.patientId': string;
    'survey-creator.system_variable.visitId': string;

    'survey-creator.goToBlock.selectLabel': string,
    'survey-creator.goToBlock.blockPreviewPlaceholder': string,
    'survey-creator.goToBlock.notExistingBlockPreviewPlaceholder': string,

    'survey-creator.dictionary.label': string;
    'survey-creator.dictionary.chipsLabel': string;
    'survey-creator.dictionary.chipsGlobalLabel': string;
    'survey-creator.dictionary.chipsGlobalLabel.hide': string;

    'survey-creator.word.answerIdLabel': string;
    'survey-creator.word.readingAnswersToggle': string;
    'survey-creator.word.answerMessageLabel': string;
    'survey-creator.word.readAnswerType.PERMUTATE': string;
    'survey-creator.word.readAnswerType.SEQUENCE': string;

    'survey-creator.word.addAnswerLabel': string,
    'survey-creator.word.addAnswerButton': string,
    'survey-creator.word.addAnswerDescription': string,

    'survey-creator.word.notPreciseLinkDescription': string,
    'survey-creator.word.notPreciseLink': string,

    'survey-creator.word.notPreciseAnswers.answersTitle': string,
    'survey-creator.word.notPreciseAnswer.description': string,
    'survey-creator.word.notPreciseAnswer.messageLabel': string,
    'survey-creator.word.notPreciseAnswer.repeatMessageLabel': string,
    'survey-creator.word.notPreciseAnswer.messageAlternativeLabel': string,

    'survey-creator.numerical.addRangeButton': string,
    'survey-creator.numerical.addRangeDescription': string,
    'survey-creator.numerical.range.label': string,
    'survey-creator.numerical.range.from': string,
    'survey-creator.numerical.range.to': string,

    'survey-creator.numerical.validation.length': string,
    'survey-creator.numerical.validation.min': string,
    'survey-creator.numerical.validation.max': string,

    'survey-creator.datetime.dateLabel': string;
    'survey-creator.datetime.timeLabel': string;

    'survey-creator.date.forbidDatesInTheFuture': string,
    'survey-creator.date.forbidDatesInThePast': string,

    'survey-creator.intentDetails.goToModule': string,
    'survey-creator.reactionTypeSelect.blockOptionLabel': string;
    'survey-creator.reactionTypeSelect.confirmationOptionLabel': string;
    'survey-creator.reactionTypeSelect.wordOptionLabel': string;
    'survey-creator.reactionTypeSelect.statementOptionLabel': string;
    'survey-creator.reactionTypeSelect.endOptionLabel': string;

    'survey-creator.intentDetails.localSentences': string,
    'survey-creator.intentDetails.talkieSentences': string,
    'survey-creator.intentDetails.enqueuedSentences': string,
    'survey-creator.intentDetails.trainingDataHint': string,
    'survey-creator.intentDetails.addSentences': string,
    'survey-creator.intentDetails.startTraining': string,
    'survey-creator.intentDetails.duplicateSentencesHeader': string,
    'survey-creator.intentDetails.duplicateSentencesList': string,
    'survey-creator.intentDetails.confirmDuplicates': string,
    'survey-creator.intentDetails.removeGlobalSentenceText': string,

    'survey-creator.dictionaryPlaceholder': string;
    'survey-creator.dictionary.toggleToLemma': string;
    'survey-creator.dictionary.toggleToVerbatim': string;
    'survey-creator.dictionary.deleteSynonym': string;

    'survey-creator.tryId': string;

    'survey-creator.condition.eq': string;
    'survey-creator.condition.neq': string;
    'survey-creator.condition.in': string;
    'survey-creator.condition.lt': string;
    'survey-creator.condition.gt': string;
    'survey-creator.condition.truthy': string;
    'survey-creator.condition.falsy': string;
    'survey-creator.condition.exists': string;
    'survey-creator.condition.exists.true': string,
    'survey-creator.condition.exists.false': string,
    'survey-creator.condition.includes': string;
    'survey-creator.condition.includes.chipsPlaceholder': string;
    'survey-creator.condition.invalid': string;

    'survey-creator.conditionalQuestion.true': string;
    'survey-creator.conditionalQuestion.false': string;
    'survey-creator.conditionalQuestion.default': string;

    'survey-creator.conditional.toggleLabel': string;
    'survey-creator.conditional.addButtonLabel': string;
    'survey-creator.conditional.conditionLabel': string;
    'survey-creator.conditional.addGroupButtonLabel': string;
    'survey-creator.conditional.rootConnectiveLabel': string;
    'survey-creator.conditional.groupConnectiveLabel': string;
    'survey-creator.conditional.connectiveOR': string;
    'survey-creator.conditional.connectiveAND': string;
    'survey-creator.conditional.addDescription': string;
    'survey-creator.conditional.multiSelectPlaceholder': string;
    'survey-creator.conditional.selectConditionOperatorPlaceholder': string;
    'survey-creator.conditional.selectVariablePlaceholder': string;
    'survey-creator.conditional.selectOperandPlaceholder': string;
    'survey-creator.conditional.messageVariableSelectLabel': string;
    'survey-creator.conditional.separatorLabel': string;
    'survey-creator.saveAs.label': string;
    'survey-creator.saveAs.inputLabel': string;
    'survey-creator.saveAs.inputDescription': string;
    'survey-creator.saveAs.changeVariableOptionTooltip': string;
    'survey-creator.saveAs.renameVariableOptionTooltip': string;
    'survey-creator.saveAs.renameVariableOptionError': string;
    'survey-creator.dtmf.label': string,
    'survey-creator.dtmf.inputDescription': string,
    'survey-creator.dtmf.minDigits': string,
    'survey-creator.dtmf.maxDigits': string,
    'survey-creator.dtmf.digitsTimeout': string,
    'survey-creator.dtmf.maxTimeout': string,
    'survey-creator.createVariableButton': string;
    'survey-creator.createVariableLabel': string;
    'survey-creator.repeatQuestionLimitLabel': string;
    'survey-creator.numberOfRepeatQuestionLimitLabel': string;
    'survey-creator.conditionalMessageLabel': string;
    'survey-creator.conditionalMessageButtonLabel': string,
    'survey-creator.dictionaryMessageToggle': string;
    'survey-creator.readOption': string;
    'survey-creator.readOptionFIRST': string;
    'survey-creator.readOptionLAST': string;
    'survey-creator.readOptionSEQUENCE': string;
    'survey-creator.readOptionPERMUTATE': string;
    'survey-creator.readOptionDONT_READ': string;
    'survey-creator.permuteAnswers': string;
    'survey-creator.wordQuestionAnswersCount': string;
    'survey-creator.answersCount.min': string;
    'survey-creator.answersCount.max': string;
    'survey-creator.ignorableLabel': string;
    'survey-creator.duplexLabel': string;
    'survey-creator.duplexStopSpeechLabel': string;
    'survey-creator.anonymizeAnswerLabel': string;
    'survey-creator.waitForRedirectionLabel': string;
    'survey-creator.disableInterpretation': string;
    'survey-creator.isPostponeRequest': string;
    'survey-creator.anyIfMultipleAnswersLabel': string;
    'survey-creator.acceptAnyAnswerLabel': string;
    'survey-creator.useDynamicPhonemizationLabel': string;
    'survey-creator.requiredAnswer': string;
    'survey-creator.joinNumbers': string;
    'survey-creator.requiredAnswerDescription': string;
    'survey-creator.customStatus': string;
    'survey-creator.changeRecognizer': string;
    'survey-creator.recognizer.default': string;
    'survey-creator.recognizer.google_default': string;
    'survey-creator.recognizer.google_latest_long': string;
    'survey-creator.recognizer.google_latest_short': string;
    'survey-creator.recognizer.google_phone_call': string;
    'survey-creator.recognizer.google_medical_conversation': string,
    'survey-creator.recognizer.google_telephony': string,
    'survey-creator.recognizer.google_telephony_short': string,
    'survey-creator.recognizer.techmo_default': string;
    'survey-creator.recognizer.techmo_medical': string;
    'survey-creator.recognizer.deepgram_nova-2-phonecall': string,
    'survey-creator.recognizer.deepgram_nova-2-general': string,
    'survey-creator.recognizer.deepgram_nova-2-medical': string,
    'survey-creator.changePreSpeechTimeout': string;
    'survey-creator.resetPreSpeechTimeout': string;
    'survey-creator.changePostSpeechTimeout': string;
    'survey-creator.resetPostSpeechTimeout': string;

    'survey-creator.customStatus.voicemail': string;
    'survey-creator.customStatus.stop_calling': string;
    'survey-creator.customStatus.all_required_questions_answered': string;

    'survey-creator.audio.addRecording': string;
    'survey-creator.audio.replaceRecording': string;
    'survey-creator.audio.downloadRecording': string;
    'survey-creator.audio.deleteRecording': string;

    'survey-creator.confirmationDictionaryYES': string;
    'survey-creator.confirmationDictionaryNO': string;

    'survey-creator.endLabel': string;
    'survey-creator.nextBlockLabel': string;

    'survey-creator.wordAnswerGoToLabel': string;
    'survey-creator.ignorableIntentsLabel': string;
    'survey-creator.ignorableIntentsDescription': string;

    'survey-creator.smsStep.message': string;
    'survey-creator.smsStep.senderNamePlaceholder': string;
    'survey-creator.smsStep.fromNumber': string;
    'survey-creator.smsStep.toNumber': string,

    'survey-creator.emailStep.to': string,
    'survey-creator.emailStep.subject': string,
    'survey-creator.emailStep.message': string,

    'survey-creator.httpStep.url': string;
    'survey-creator.httpStep.urlPlaceholder': string;
    'survey-creator.httpStep.method': string;
    'survey-creator.httpStep.body': string;
    'survey-creator.httpStep.headersLabel': string;
    'survey-creator.httpStep.headerName': string;
    'survey-creator.httpStep.headerValue': string;
    'survey-creator.httpStep.addHeaderButton': string;

    'survey-creator.mapping.mappingsLabel': string;
    'survey-creator.mapping.mappingVariable': string;
    'survey-creator.mapping.mappingPath': string;
    'survey-creator.mapping.mappingPossibleValues': string;
    'survey-creator.mapping.addMappingButton': string;
    'survey-creator.mapping.mappingType': string;

    'survey-creator.setVariableStep.variable': string;
    'survey-creator.setVariableStep.value': string;
    'survey-creator.createVariableDictionaryValue': string;
    'survey-creator.clearVariable': string;
    'survey-creator.setVariable.assignValue.dictionary': string;
    'survey-creator.setVariable.assignValue.variable': string;
    'survey-creator.setVariable.assignValue.plainText': string;

    'survey-creator.genericOrder.initialMessage': string,
    'survey-creator.genericOrder.productAddedMessage': string,
    'survey-creator.genericOrder.resignationMessage': string,
    'survey-creator.genericOrder.nameLabel': string,
    'survey-creator.genericOrder.typeLabel': string,
    'survey-creator.genericOrder.numericPropertyValue': string,
    'survey-creator.genericOrder.propertyValueMessageText': string,
    'survey-creator.genericOrder.synonyms': string,
    'survey-creator.genericOrder.propertyMessage': string,
    'survey-creator.genericOrder.orderLabel': string,
    'survey-creator.genericOrder.positionLabel': string,
    'survey-creator.genericOrder.textFormattingMessageSingularLabel': string,
    'survey-creator.genericOrder.textFormattingMessagePluralLabel': string,
    'survey-creator.genericOrder.textFormattingMessagePluralGenitiveLabel': string,
    'survey-creator.genericOrder.textFormattingMessageLabel': string,
    'survey-creator.genericOrder.propertyLabel': string,
    'survey-creator.genericOrder.propertyValuesLabel': string,
    'survey-creator.genericOrder.optionalProperty': string,
    'survey-creator.genericOrder.customMessageProperty': string,
    'survey-creator.genericOrder.propertiesLabel': string,
    'survey-creator.genericOrder.propertiesValuesLabel': string,
    'survey-creator.genericOrder.textFormattingLabel': string,
    'survey-creator.genericOrder.productsLabel': string,
    'survey-creator.genericOrder.metadataLabel': string,

    'survey-creator.tryIt.label': string,
    'survey-creator.tryIt.placeholder': string,

    'survey-creator.addNewGroup': string,
    'survey-creator.assignToGroupPlaceholder': string,
    'survey-creator.ungroup': string,
    'survey-creator.ungroup.confirm': string,
    'survey-creator.ungroup.cancel': string,
    'survey-creator.removeGroup': string,
    'survey-creator.removeGroup.confirm': string,
    'survey-creator.removeGroup.cancel': string,

    'survey-creator.block.description.preview': string,
    'survey-creator.block.description.edit': string,
    'survey-creator.addBlockDescription': string,
    'survey-creator.addBlockDescriptionButton': string,

    'survey-creator.primary-answers': string,
    'survey-creator.associated-answers': string,
    'survey-creator.entities': string,
    'survey-creator.addEntitiesDescription': string,
    'survey-creator.addNewEntityLabel': string,
    'survey-creator.addNewEntityPlaceholderLabel': string,
    'survey-creator.associatedAnswerEntityLabel': string,
    'survey-creator.associatedAnswerVariable': string,
    'survey-creator.entities-tabs.answers': string,
    'survey-creator.entityCreateError': string,
    'survey-creator.entityAnswerId': string,
    'survey-creator.entitySynonyms': string,
    'survey-creator.entityGlobalSynonyms': string,
    'survey-creator.associatedAnswerGoToEntity': string,
    'survey-creator.associatedAnswerSystemEntity': string,
    'survey-creator.associatedAnswerReaction': string,
    'survey-creator.associatedAnswerReaction.noReaction': string,
    'survey-creator.associatedAnswerReaction.continue': string,
    'survey-creator.fallback': string,
    'survey-creator.fallback.emptyState': string,
    'survey-creator.silenceFallback': string,
    'survey-creator.silenceFallback.emptyState': string,

    'survey-creator.tabs.scenario': string,
    'survey-creator.tabs.entities': string,

    'survey-creator.entities-tabs.answers.addAnswerButton': string,
    'survey-creator.entities-tabs.answers.addAnswerDescription': string,

    'survey-creator.specialCharacters.addNewSpecialCharacter.buttonLabel': string,
    'survey-creator.specialCharacters.addNewSpecialCharacter.description': string,
    'survey-creator.specialCharacters.addNewSpecialCharacter.placeholder': string,
    'survey-creator.specialCharacters.specialCharacterDefinition': string,
    'survey-creator.specialCharacters.characters.space': string,

    'survey-creator.executableFunctions.functionNames.ConcatArrays': string,
    'survey-creator.executableFunctions.functionNames.Assign': string,
    'survey-creator.executableFunctions.functionNames.ArrayFilter': string,
    'survey-creator.executableFunctions.functionNames.ArrayFind': string,
    'survey-creator.executableFunctions.functionNames.StringReplace': string,
    'survey-creator.executableFunctions.functionNames.ArrayNth': string,
    'survey-creator.executableFunctions.functionNames.FuzzyMatcher': string,
    'survey-creator.executableFunctions.functionNames.ArrayTransform': string,

    'step.tabs.error': string,
    'step.tabs.conditions.error': string,
    'step.tabs.conditions.variableId.error': string,
    'step.tabs.conditions.operator.error': string,
    'step.tabs.conditions.operand.error': string,
    'step.tabs.question': string,
    'step.tabs.question.statement': string,
    'step.tabs.question.redirect': string,
    'step.tabs.question.sms': string,
    'step.tabs.question.email': string,
    'step.tabs.question.end': string,
    'step.tabs.answer': string,
    'step.tabs.general': string,
    'step.tabs.intents': string,
    'step.tabs.specialCharacters': string,
    'step.name': string;
    'step.name.error.VALUE_REQUIRED': string;

    'survey-creator.redirectStep.redirectTo': string,
    'survey-creator.redirectStep.redirectFrom': string,
    'survey-creator.redirectStep.redirectFromButton': string,
    'survey-creator.redirectStep.stopRecording': string,
    'survey-creator.redirectStep.redirectTrunk': string,
    'survey-creator.redirectStep.redirectType': string,
    'survey-creator.redirectStep.redirectTypeOptions.dial': string,
    'survey-creator.redirectStep.redirectTypeOptions.transfer': string,
    'survey-creator.redirectStep.redirectTypeOptions.local': string,
    'survey-creator.redirectStep.redirectHeadersLabel': string,
    'survey-creator.redirectStep.addRedirectHeaderButton': string,
    'survey-creator.redirectStep.redirectHeaderName': string,
    'survey-creator.redirectStep.redirectHeaderValue': string,
    'survey-creator.redirectStep.destination': string,

    'survey-creator.functionStep.functions.ConcatArraysDescription': string,
    'survey-creator.functionStep.functions.ConcatArraysInputsArrayA': string,
    'survey-creator.functionStep.functions.ConcatArraysInputsArrayADescription': string,
    'survey-creator.functionStep.functions.ConcatArraysInputsArrayB': string,
    'survey-creator.functionStep.functions.ConcatArraysInputsArrayBDescription': string,
    'survey-creator.functionStep.functions.ConcatArraysOutputsResult': string,
    'survey-creator.functionStep.functions.ConcatArraysOutputsResultDescription': string,

    'survey-creator.functionStep.functions.AssignDescription': string,
    'survey-creator.functionStep.functions.AssignInputsValue': string,
    'survey-creator.functionStep.functions.AssignInputsValueDescription': string,
    'survey-creator.functionStep.functions.AssignOutputsResult': string,
    'survey-creator.functionStep.functions.AssignOutputsResultDescription': string,

    'survey-creator.functionStep.functions.ArrayFilterDescription': string,
    'survey-creator.functionStep.functions.ArrayFilterInputsArray': string,
    'survey-creator.functionStep.functions.ArrayFilterInputsArrayDescription': string,
    'survey-creator.functionStep.functions.ArrayFilterInputsArrayPlaceholder': string,
    'survey-creator.functionStep.functions.ArrayFilterInputsValue': string,
    'survey-creator.functionStep.functions.ArrayFilterInputsValueDescription': string,
    'survey-creator.functionStep.functions.ArrayFilterInputsValuePlaceholder': string,
    'survey-creator.functionStep.functions.ArrayFilterInputsPath': string,
    'survey-creator.functionStep.functions.ArrayFilterInputsPathDescription': string,
    'survey-creator.functionStep.functions.ArrayFilterInputsPathPlaceholder': string,
    'survey-creator.functionStep.functions.ArrayFilterInputsWithRegExp': string,
    'survey-creator.functionStep.functions.ArrayFilterInputsWithRegExpDescription': string,
    'survey-creator.functionStep.functions.ArrayFilterOutputsResult': string,
    'survey-creator.functionStep.functions.ArrayFilterOutputsResultDescription': string,

    'survey-creator.functionStep.functions.ArrayFindDescription': string,
    'survey-creator.functionStep.functions.ArrayFindInputsArray': string,
    'survey-creator.functionStep.functions.ArrayFindInputsArrayDescription': string,
    'survey-creator.functionStep.functions.ArrayFindInputsArrayPlaceholder': string,
    'survey-creator.functionStep.functions.ArrayFindInputsValue': string,
    'survey-creator.functionStep.functions.ArrayFindInputsValueDescription': string,
    'survey-creator.functionStep.functions.ArrayFindInputsValuePlaceholder': string,
    'survey-creator.functionStep.functions.ArrayFindInputsPath': string,
    'survey-creator.functionStep.functions.ArrayFindInputsPathDescription': string,
    'survey-creator.functionStep.functions.ArrayFindInputsPathPlaceholder': string,
    'survey-creator.functionStep.functions.ArrayFindInputsWithInclude': string,
    'survey-creator.functionStep.functions.ArrayFindInputsWithIncludeDescription': string,
    'survey-creator.functionStep.functions.ArrayFindOutputsResult': string,
    'survey-creator.functionStep.functions.ArrayFindOutputsResultDescription': string,

    'survey-creator.functionStep.functions.ArrayNthDescription': string,
    'survey-creator.functionStep.functions.ArrayNthInputsArray': string,
    'survey-creator.functionStep.functions.ArrayNthInputsArrayDescription': string,
    'survey-creator.functionStep.functions.ArrayNthInputsArrayPlaceholder': string,
    'survey-creator.functionStep.functions.ArrayNthInputsIndex': string,
    'survey-creator.functionStep.functions.ArrayNthInputsIndexDescription': string,
    'survey-creator.functionStep.functions.ArrayNthInputsIndexPlaceholder': string,
    'survey-creator.functionStep.functions.ArrayNthOutputsResult': string,
    'survey-creator.functionStep.functions.ArrayNthOutputsResultDescription': string,

    'survey-creator.functionStep.functions.StringReplaceInputString': string,
    'survey-creator.functionStep.functions.StringReplaceInputWithRegExp': string,
    'survey-creator.functionStep.functions.StringReplaceInputSearchValue': string,
    'survey-creator.functionStep.functions.StringReplaceInputReplaceValue': string,
    'survey-creator.functionStep.functions.StringReplaceOutputsResult': string,
    'survey-creator.functionStep.functions.StringReplaceDescription': string,
    'survey-creator.functionStep.functions.StringReplaceInputStringDescription': string,
    'survey-creator.functionStep.functions.StringReplaceInputWithRegExpDescription': string,
    'survey-creator.functionStep.functions.StringReplaceInputSearchValueDescription': string,
    'survey-creator.functionStep.functions.StringReplaceInputReplaceValueDescription': string,
    'survey-creator.functionStep.functions.StringReplaceOutputDescription': string,
    'survey-creator.functionStep.functions.FuzzyMatcherProcessorType': string,
    'survey-creator.functionStep.functions.FuzzyMatcherProcessorTypeDescription': string,
    'survey-creator.functionStep.functions.FuzzyMatcherIdField': string,
    'survey-creator.functionStep.functions.FuzzyMatcherIdFieldDescription': string,
    'survey-creator.functionStep.functions.FuzzyMatcherIdFieldPlaceholder': string,
    'survey-creator.functionStep.functions.FuzzyMatcherParentIdField': string,
    'survey-creator.functionStep.functions.FuzzyMatcherParentIdFieldDescription': string,
    'survey-creator.functionStep.functions.FuzzyMatcherParentIdFieldPlaceholder': string,

    'survey-creator.functionStep.functions.FuzzyMatcherDescription': string,
    'survey-creator.functionStep.functions.FuzzyMatcherInputsArray': string,
    'survey-creator.functionStep.functions.FuzzyMatcherInputsArrayDescription': string,
    'survey-creator.functionStep.functions.FuzzyMatcherInputsArrayPlaceholder': string,
    'survey-creator.functionStep.functions.FuzzyMatcherMatchingField': string,
    'survey-creator.functionStep.functions.FuzzyMatcherMatchingFieldDescription': string,
    'survey-creator.functionStep.functions.FuzzyMatcherMatchingFieldPlaceholder': string,
    'survey-creator.functionStep.functions.FuzzyMatcherTextToMatch': string,
    'survey-creator.functionStep.functions.FuzzyMatcherTextToMatchDescription': string,
    'survey-creator.functionStep.functions.FuzzyMatcherTextToMatchPlaceholder': string,
    'survey-creator.functionStep.functions.FuzzyMatcherCandidatesPreselectionField': string,
    'survey-creator.functionStep.functions.FuzzyMatcherCandidatesPreselectionFieldDescription': string,
    'survey-creator.functionStep.functions.FuzzyMatcherCandidatesPreselectionFieldPlaceholder': string,
    'survey-creator.functionStep.functions.FuzzyMatcherOutputsResult': string,
    'survey-creator.functionStep.functions.FuzzyMatcherOutputsResultDescription': string,

    'survey-creator.functionStep.functions.ArrayTransformDescription': string,
    'survey-creator.functionStep.functions.ArrayTransformInputsArray': string,
    'survey-creator.functionStep.functions.ArrayTransformInputsArrayDescription': string,
    'survey-creator.functionStep.functions.ArrayTransformInputsArrayPlaceholder': string,
    'survey-creator.functionStep.functions.ArrayTransformMappingArray': string,
    'survey-creator.functionStep.functions.ArrayTransformMappingArrayDescription': string,
    'survey-creator.functionStep.functions.ArrayTransformFromPath': string,
    'survey-creator.functionStep.functions.ArrayTransformFromPathDescription': string,
    'survey-creator.functionStep.functions.ArrayTransformFromPathPlaceholder': string,
    'survey-creator.functionStep.functions.ArrayTransformToPath': string,
    'survey-creator.functionStep.functions.ArrayTransformToPathDescription': string,
    'survey-creator.functionStep.functions.ArrayTransformToPathPlaceholder': string,
    'survey-creator.functionStep.functions.ArrayTransformFromAsValue': string,
    'survey-creator.functionStep.functions.ArrayTransformFromAsValueDescription': string,

    'survey.message.error': string
    'survey.date.message.error': string
    'survey.hour.message.error': string
    'survey.date.repeatMessages.error': string
    'survey.hour.repeatMessages.error': string
    'survey.repeatMessages.error': string
    'survey.followupMessage.error': string
    'survey.helpNeededMessage.error': string
    'survey.repeatMessage': string;
    'survey.saveRepeatMessage': string;
    'survey.failureMessage': string;
    'survey.saveFailureMessage': string;
    'survey.report.download': string;
    'survey.report.generate': string;
    'survey.reports.title': string;
    'survey.reportsList.dateLabel': string;
    'survey.reportsList.statusLabel': string;

    'unsavedChangesModal.text': string;

    'pillRemoveModal.text': string;

    'questionRemoveModal.text': string;

    'questionMoveModal.text': string;

    'confirmationModal.yes': string;
    'confirmationModal.no': string;

    'candidates.zone': string;
    'candidates.zone.withDrag': string;
    'candidates.processing': string;
    'candidates.reset': string;
    'candidates.successfulUpload': string;
    'candidates.noCandidates': string;
    'candidates.confirm': string;
    'candidates.confirmForce': string;
    'candidates.unknownError': string;
    'candidates.title': string;
    'candidates.errors': string;
    'candidates.candidates': string;

    'audioPlayer.noRecording': string;
    'audioPlayer.recordingError': string;

    'conversations.reports.close': string;
    'conversations.reports.title': string;
    'conversations.reports.reportTitle': string;
    'conversations.reports.ready': string;
    'conversations.reports.pending': string;
    'conversations.reports.generate': string;
    'conversations.reports.reportRequestDisabled': string;
    'conversations.reports.selectAll': string;
    'conversations.reports.deselectAll': string;
    'conversations.reports.questions': string;
    'conversations.reports.intents': string;
    'conversations.reports.conversationsLimit': string;

    'conversations.filter.allCalls': string;
    'conversations.filter.startedMinutes': string;
    'conversations.filter.incoming_call': string,
    'conversations.filter.call_answered': string,
    'conversations.filter.call_not_answered': string,
    'conversations.filter.voice_mail_detected': string,
    'conversations.filter.with_duplex': string,
    'conversations.filter.without_duplex': string,
    'conversations.filter.fatal_error_occurred': string,
    'conversations.filter.missing_value_of_variable': string,
    'conversations.filter.destination_module_is_missing': string,
    'conversations.filter.missing_text_or_recording': string,
    'conversations.filter.http_step_returned_an_error': string,
    'conversations.filter.infinite_loop_detected': string,
    'conversations.filter.invalid_condition': string,
    'conversations.filter.call_redirected': string,
    'conversations.filter.startedRedirectMinutes': string,
    'conversations.filter.user_ended_conversation': string,
    'conversations.filter.bot_ended_conversation': string,
    'conversations.filter.FUNCTION_INPUTS_VALIDATION_ERROR': string,
    'conversations.filter.FUNCTION_DOES_NOT_EXIST': string,

    'variables-text-area.add-button': string,

    'survey-creator.botTester.newChat': string,
    'survey-creator.botTester.suggestions.today': string,
    'survey-creator.botTester.suggestions.yesterday': string,
    'survey-creator.botTester.suggestions.tomorrow': string,
    'survey-creator.botTester.suggestions.tomorrowBetween': string,
    'survey-creator.botTester.suggestions.at': string,
    'survey-creator.botTester.suggestions.and': string,
    'survey-creator.botTester.notRecognized': string,
    'survey-creator.botTester.intent': string,
    'survey-creator.botTester.answer': string,
    'survey-creator.botTester.inputPlaceholder': string,
    'survey-creator.botTester.conversationFinished': string,

    'menuOption.rename.tooltip': string,
    'menuOption.duplicate.tooltip': string,
    'menuOption.delete.tooltip': string,

    'menuOption.disable.tooltip': string,

    'menuOption.enable.tooltip': string,
    'menuOption.move.tooltip': string,
    'menuOption.start.tooltip': string,
    'menuOption.group.tooltip': string,
    'menuOption.removeFromGroup.tooltip': string,
    'menuOption.removeGroup.tooltip': string,
    'menuOption.ungroup.tooltip': string,

    'settings.admin.sipHeaders.mappingsLabel': string,
    'settings.admin.sipHeaders.addMappingButton': string,
    'settings.admin.sipHeaders.variable': string,
    'settings.admin.sipHeaders.headerName': string,
    'settings.admin.sipHeaders.variableType': string,

    'issues.header': string,
    'issues.title': string,
    'issues.save': string,
    'issues.placeholder': string,
    'issues.successToast': string,
    'issues.errorToast': string,

    'reports.table.updatedAt': string,
    'reports.table.actions': string,
    'reports.table.phoneNumber': string,
    'reports.table.smsText': string,
    'reports.changeStatus.successToast': string,
    'reports.changeStatus.errorToast': string,
    'reports.emptyState.noData': string,
    'reports.emptyState.noDataNoCreator': string,
    'reports.emptyState.noDataForFilters': string,
    'reports.actions.status.selectPlaceholder': string;
    'reports.actions.status.DEFAULT': string;
    'reports.actions.status.COMPLETED': string;

    'reports.actions.preview': string,
    'reports.filters.search': string,

    'reportConfig.tooltip': string,
    'reportConfig.modal.title': string,
    'reportConfig.modal.scheduledReports.title': string,
    'reportConfig.modal.scheduledReports.text': string,
    'reportConfig.modal.scheduledReports.emailLabel': string,
    'reportConfig.modal.scheduledReports.passwordLabel': string,
    'passwordInput.passwordTooltip.show': string,
    'passwordInput.passwordTooltip.hide': string,
    'reportConfig.modal.scheduledReports.passwordValidation': string,
    'reportConfig.modal.scheduledReports.emailValidationMessage': string,
    'reportConfig.modal.notifications.title': string,
    'reportConfig.modal.notifications.text': string,
    'reportConfig.modal.notifications.toggleText': string,
    'reportConfig.modal.save': string,
    'reportConfig.successToast': string,
    'reportConfig.errorToast': string,

    'termsOfServiceModal.title': string,
    'termsOfServiceModal.accept': string,
    'termsOfServiceModal.download': string,
    'termsOfServiceModal.filename': string,
    'termsOfServiceModal.markdownFilename.eu-central-1.dev': string,
    'termsOfServiceModal.markdownFilename.eu-central-1.prd': string,
    'termsOfServiceModal.markdownFilename.us-east-2.us-prd': string,
    'termsOfServiceModal.filePath.eu-central-1.dev': string,
    'termsOfServiceModal.filePath.eu-central-1.prd': string,
    'termsOfServiceModal.filePath.us-east-2.us-prd': string,

    'tagFilterModal.title': string;
    'tagFilterModal.submitButton': string;
    'tagFilterModal.cancelButton': string;
    'tagFilterModal.description': string;
    'tagFilterModal.tagsToSelectEmpty': string;
};

const plPlMessages: Messages = {
    'timestamp.format': 'DD/MM/YYYY HH:mm',
    'hour.format': 'HH:mm',
    'date.format': 'DD/MM/YYYY',
    'month.format': 'MM/YYYY',
    'datetime.format': 'dddd HH:mm',
    'date.from': 'Data od',
    'date.to': 'Data do',
    'date.placeholder': 'Wybierz zakres dat...',
    'generalError': 'Wystąpił błąd',
    'close': 'Zamknij',

    'header.help': 'Przejdź do bazy wiedzy o portalu',
    'header.user': 'Zarządzanie kontem użytkownika',

    'cancelLabel': 'Anuluj',
    'downloadButton.errorToast': 'Nie udało się wygenerować pliku do pobrania.<br />Skontaktuj się z nami [support@talkie.ai](mailto:support@talkie.ai)',

    'callsList.timestamp': 'Godzina',
    'callsList.duration': 'Długość',
    'callsList.phoneNumber': 'Numer Tel.',
    'callsList.events': 'Zdarzenia',
    'callsList.events.hideMore': 'Zwiń',
    'callsList.recording': 'Nagranie',
    'callsList.name': 'Nazwa użytkownika',
    'callsList.done': 'Akcja',
    'callsList.done-button': 'Zakończ',
    'callsList.smsTranscriptHeader': 'Konwersacja SMS',
    'callsList.size': 'Liczba wyników',
    'callsList.count':
        '{count, plural, =0 {0 wyników} =1 {# wynik} =2 {# wyniki} =3 {# wyniki} =4 {# wyniki} other {# wyników}}',
    'callsList.loading': 'Wczytywanie...',
    'callsList.noData': 'Brak wyników. Zmień parametry filtrowania lub odśwież stronę.',
    'callsList.loadMore': 'Załaduj więcej',
    'callDetails.emptyState': 'Wybierz konwersacje, by załadować jej zapis',
    'callDetails.noTranscript': 'Brak transkrypcji rozmowy',
    'callDetails.noAudio': 'Brak nagrania rozmowy',
    'callDetails.textChat': 'Testowy czat tekstowy - brak nagrania rozmowy',
    'callDetails.loading': 'Wczytywanie...',
    'callDetails.emptyDetails': 'Brak nagrania dla wybranej konwersacji',
    'callDetails.statementNotRecognized': 'Cisza w słuchawce',
    'callDetails.smsTranscript': 'Konwersacja SMS',
    'callDetails.notFound': 'Nie znaleziono konwersacji',
    'callDetails.showSSMLTags': 'Pokaż tagi w konwersacji',
    'callDetails.recognitionSource.dtmf': 'DTMF',
    'conversationMarkCaller.description': 'Oznacz rozmówce tej konwersacji jako',
    'conversationMarkCaller.human': 'Człowiek',
    'conversationMarkCaller.voiceMail': 'Poczta głosowa',
    'conversationMarkCaller.error': 'Operacja nie powiodła się',
    'conversationMarkCaller.marked.MARKED_AS_HUMAN': 'Oznaczono jako człowiek',
    'conversationMarkCaller.marked.MARKED_AS_VOICEMAIL': 'Oznaczono jako poczta głosowa',

    'contacts.table.navigate': 'Nawiguj do rozmów',
    'contacts.table.phoneNumber': 'Numer telefonu',
    'contacts.table.status': 'Status',
    'contacts.table.lastInteraction': 'Poprzednie połączenie',
    'contacts.table.currentInteraction': 'Obecne połączenie',
    'contacts.table.followUpInteraction': 'Następne połączenie',
    'contacts.table.interactionsPerformed': 'Wykonane próby',
    'contacts.table.importDate': 'Data importu',
    'contacts.table.actions': 'Akcje',
    'contacts.table.status.ANSWERED': 'Odebrane połączenie',
    'contacts.table.status.NOT_ANSWERED': 'Nieodebrane połączenie',
    'contacts.table.status.VOICEMAIL_DETECTED': 'Poczta głosowa',
    'contacts.filters.clear': 'Wyczyść',
    'contacts.filters.apply': 'Filtruj',
    'contacts.filters.phone.label': 'Numer telefonu',
    'contacts.filters.status.label': 'Status',
    'contacts.statusFilter.status': 'Status połączenia',
    'contacts.statusFilter.phoneNumber': 'Numer telefonu...',
    'contacts.viewsSelect.contactsList': 'Lista kontaktów',
    'contacts.viewsSelect.scheduler': 'Planer',
    'contacts.report.buttonLabel': 'Pobierz raport',
    'contacts.report.fileName': 'raport',
    'contacts.report.noContacts': 'Brak kontaktów do wygenerowania raportu',
    'contacts.add.buttonLabel': 'Dodaj kontakty',
    'contacts.add.buttonLabel.disabled.noSchedule': 'Przed pierwszym dodaniem kontaktów musisz zaplanować swoje działania w planerze',
    'contacts.add.uploadLabel': 'Załaduj plik (.csv lub .xlsx)',
    'contacts.add.error.validation': '**Nie udało się dodać pliku “{filename}”**',
    'contacts.add.error.schedulerDisabled': 'Bład - scheduler jest wyłączony dla tego bota',
    'contacts.add.error.SHOULD_HAVE_AT_LEAST_ONE_CONTACT': 'Kolumna “phoneNumber” powinna zawierać przynajmniej jeden kontakt. **Dodaj numery i załaduj ponownie plik.**',
    'contacts.add.error.SHOULD_HAVE_A_CORRECT_NUMBER_OF_DIGITS': 'W kolumnie “phoneNumber” znajdują się numery z niepoprawną liczbą cyfr. **Sprawdź poprawność numerów i załaduj ponownie plik.**',
    'contacts.add.error.PHONE_NUMBER_FORMAT_IS_INVALID': 'W kolumnie “phoneNumber” znajdują się niepożądane znaki. **Sprawdź poprawność numerów i załaduj ponownie plik.**',
    'contacts.add.error.PHONE_NUMBER_COLUMN_REQUIRED': 'W pliku brakuje kolumny “phoneNumber”. **Dodaj brakującą kolumnę i załaduj ponownie plik.**',
    'contacts.add.error.PHONE_NUMBER_REQUIRED': 'W kolumnie "phoneNumber" znajduje się pusta komórka, która może sugerować, że arkusz jest niekompletny. **Usuń lub uzupełnij wiersz i załaduj ponownie plik.**',
    'contacts.add.error.PHONE_NUMBER_COUNTRY_CALLING_CODE_MISMATCH': 'Numer kierunkowy kraju różni się od kodu w ustawieniach bota. **Sprawdź poprawność kodów i załaduj plik ponownie.**',
    'contacts.add.error.UNSUPPORTED_FILE_EXTENSION': 'Próbujesz dodać plik w nieobsługiwanym przez nas formacie. Platforma akceptuje pliki w formacie .csv lub .xlsx. **Zmień rodzaj pliku i załaduj ponownie.**',
    'contacts.add.error.UNKNOWN_ERROR': 'Coś poszło nie tak. **Spróbuj ponownie później** lub skontaktuj się z nami [support@talkie.ai](mailto:support@talkie.ai).',
    'contacts.add.title': 'Dodawanie kontaktów',
    // react-intl requires html tags to be single quoted, otherwise it will treat
    // them as string interpolations, and won't interpolate strings in brackets
    'contacts.add.guidelines': `
Platforma akceptuje pliki w formacie .csv lub .xlsx. Przed dodaniem kontaktów musisz zaplanować swoje działania w planerze. Prawidłowo przygotowany dokument:
* zawiera numer telefonu "phoneNumber" w pierwszej kolumnie pliku (jest to jedyna wymagana wartość)
* zawiera poprawnie sformatowane numery np. 503111222 (jedynie cyfry, bez innych znaków)
* zawiera te same nazwy zmiennych we wgranym pliku oraz w konfiguracji bota
* nie zawiera formuł
    `,
    'contacts.add.footer.text': 'Pobierz szablon:',
    'contacts.add.body': `
'<span class='{muted}'>'[Przeczytaj więcej](https://help.talkie.ai/knowledge-base/dodawanie-pacjentow-do-obdzwonienia/) o dodawaniu kontaktów w platformie wiedzy.'</span>'

'<span class='{muted}'>'Jeżeli chcesz dodawać kontakty przy użyciu API [zapoznaj się z dokumentacją](https://docs.talkie.ai/).'</span>'
`,
    'contacts.add.confirm': 'Dodaj plik',
    'contacts.add.reload': 'Dodaj ponownie plik',
    'contacts.add.finish': 'Zakończ',
    'contacts.add.successBody': `
'<img class="'{icon} {centered}" src="{ToastIcon}" />Plik *{filename}* został dodany

'<span class='{muted}'>'Kontakty zostały dodane. Rekomendujemy sprawdzenie ustawień planera w celu kontroli przed startem kampanii.'</span>'
    `,
    'contacts.add.partiallyFailedBody': `
'<img class="'{icon} {centered}" src="{ToastWarningIcon}" />Plik *{filename}* został częściowo dodany
'<span class='{partiallyImportedContact}'>'Kontakty zaimportowane: **{contactsCreated}**'</span>'
'<span class='{partiallyImportedContact}'>'Kontakty niezaimportowane z powodu błędów: **{contactsFailed}**'</span>'
'<span class='{muted}'>'Rekomendujemy sprawdzenie ustawień planera w celu kontroli przed startem kampanii.'</span><br />'
'<span class='{muted}'>'[Przeczytaj więcej](https://help.talkie.ai/knowledge-base/how-to-add-a-contacts-list-to-an-outbound-campaign/) o dodawaniu kontaktów w platformie wiedzy.'</span>'
    `,
    'contacts.add.manualOption.name': 'Ręczne dodawanie kontaktów',
    'contacts.add.manualOption.description': 'Wgrywanie pliku excel lub .csv',
    'contacts.add.integrationOption.name': 'Zarządzanie importem kontaktów',
    'contacts.add.integrationOption.description': 'Pobieranie z systemu HIS',
    'contacts.add.integrationModal.cancel': 'Anuluj',
    'contacts.add.integrationModal.back': 'Cofnij',
    'contacts.add.integrationModal.next': 'Filtruj wizyty',
    'contacts.add.integrationModal.nextDisabledTooltip': 'Wybierz datę i warunek',
    'contacts.add.integrationModal.confirm': 'Zatwierdź listę wizyt',
    'contacts.add.integrationModal.activePlanner.cancel': 'Anuluj dodawanie',
    'contacts.add.integrationModal.activePlanner.import': 'Dodaj do obdzwonki',
    'contacts.add.integrationModal.inactivePlanner.import': 'Zapisz i uruchom później',
    'contacts.add.integrationModal.inactivePlanner.importAndRun': 'Uruchom planer',
    'contacts.add.integrationModal.import.successToast.disabledPlanner': 'Pomyślnie zaimportowano {count} kontaktów. Pamiętaj żeby {link}',
    'contacts.add.integrationModal.import.successToast.disabledPlannerLink': 'uruchomić planer aby zacząć obdzwonkę.',
    'contacts.add.integrationModal.import.successToast.enabledPlanner': 'Pomyślnie zaimportowano {count} kontaktów. Połączenia odbywają się {link}',
    'contacts.add.integrationModal.import.successToast.enabledPlannerLink': 'zgodnie z harmonogramem.',
    'contacts.add.integrationModal.import.warningToast.disabledPlanner': 'Pomyślnie zaimportowano {successCount} kontaktów. Pamiętaj żeby {resumePlannerLink} Nie zaimportowano {failureCount} kontaktów ze względu na niepoprawny numer telefonu. Sprawdź raport {notImportedVisitsLink}',
    'contacts.add.integrationModal.import.warningToast.resumePlannerLink': 'uruchomić planer aby zacząć obdzwonkę.',
    'contacts.add.integrationModal.import.warningToast.enabledPlanner': 'Pomyślnie zaimportowano {successCount} kontaktów. Połączenia odbywają się {plannerLink} Nie zaimportowano {failureCount} kontaktów ze względu na niepoprawny numer telefonu. Sprawdź raport {notImportedVisitsLink}',
    'contacts.add.integrationModal.import.warningToast.plannerLink': 'zgodnie z harmonogramem.',
    'contacts.add.integrationModal.import.warningToast.notImportedVisitsLink': '"Niezaimportowane wizyty".',
    'contacts.add.integrationModal.import.warningToast.notImportedVisitsReportName': 'Niezaimportowane%20wizyty',
    'contacts.add.integrationModal.import.errorToast': 'Nie udało się zaimportować kontaktów. Spróbuj ponownie później lub z skontaktuj się z nami pisząc na [support@talkie.ai](mailto:support@talkie.ai)',
    'contacts.add.integrationModal.step1.title':
      'Zarządzanie importem kontaktów | Ustawienie warunków importu',
    'contacts.add.integrationModal.step1.instruction':
      'Wybierz których pacjentów poinformować o zmianach. Na kolejnym etapie będziesz mógł wykluczyć pojedynczych pacjentów.',
    'contacts.add.integrationModal.step1.date.label': 'Określ przedział czasowy',
    'contacts.add.integrationModal.step1.date.placeholder': 'Pacjenci z dnia',
    'contacts.add.integrationModal.step1.time.label': 'Umówieni w godzinach',
    'contacts.add.integrationModal.step1.time.labelFrom': 'Od',
    'contacts.add.integrationModal.step1.time.labelTo': 'Do',
    'contacts.add.integrationModal.step1.condition.label': 'Warunek',
    'contacts.add.integrationModal.step1.condition.placeholder': 'Wybierz',
    'contacts.add.integrationModal.step1.conditionValue.label':
      'Wybrane wartości',
    'contacts.add.integrationModal.step1.conditionOption.DOCTORS': 'Lekarz',
    'contacts.add.integrationModal.step1.conditionOption.CLINICS': 'Placówka',
    'contacts.add.integrationModal.step1.conditionOption.SERVICES': 'Usługa',
    'contacts.add.integrationModal.step1.conditionOption.PRACTICES': 'Poradnia',
    'contacts.add.integrationModal.step1.addCondition': 'Dodaj warunek',
    'contacts.add.integrationModal.step1.preview': 'Podgląd importu',
    'contacts.add.integrationModal.step1.preview.phoneNumber': 'Numer telefonu',
    'contacts.add.integrationModal.step1.preview.patient': 'Pacjent',
    'contacts.add.integrationModal.step1.preview.visit': 'Wizyta',
    'contacts.add.integrationModal.step1.preview.doctor': 'Doktor',
    'contacts.add.integrationModal.step1.preview.service': 'Usługa',
    'contacts.add.integrationModal.step1.preview.localization': 'Lokalizacja',
    'contacts.add.integrationModal.step1.preview.practice': 'Poradnia',
    'contacts.add.integrationModal.step1.preview.emptyState': 'Nie znaleziono wyników dla wybranych filtrów. **Zmień wartości**, aby zobaczyć więcej.',
    'contacts.add.integrationModal.step2.title': 'Zarządzanie importem kontaktów | Wykluczenie pacjentów z obdzwonki',
    'contacts.add.integrationModal.step2.description': 'Zatwierdź listę pacjentów do obdzwonienia. Możesz też wykluczyć pojedynczych pacjentów.',
    'contacts.add.integrationModal.step2.query': '{contactsCount, plural, one {# wizyta} few {Lista # wizyt} many {Lista # wizyt}} z okresu **{dateTimeFrom} - {dateTimeTo}**',
    'contacts.add.integrationModal.step2.query.doctors': 'lekarze',
    'contacts.add.integrationModal.step2.query.clinics': 'kliniki',
    'contacts.add.integrationModal.step2.query.services': 'usługi',
    'contacts.add.integrationModal.step2.excludeContact': 'Wykluczenie z obdzwonki',
    'contacts.add.integrationModal.step2.excludedContacts': 'Wykluczeni z obdzwonki',
    'contacts.add.integrationModal.step3.title': 'Zarządzanie importem kontaktów | Zaplanowane interakcje',
    'contacts.add.integrationModal.step3.description': 'Zgodnie z ustawieniami planera zostaną wykonane następujące interakcje do',
    'contacts.add.integrationModal.step3.query': '{contactsCount, plural, one {# pacjenta} few {# pacjentów} many {# pacjentów}} z okresu {dateTimeFrom} - {dateTimeTo}',
    'contacts.add.integrationModal.step3.interactionStep.sms': 'SMS',
    'contacts.add.integrationModal.step3.interactionStep.call': 'Połączenie',
    'contacts.add.integrationModal.step3.interactionStep.http': 'Zapytanie HTTP',
    'contacts.add.integrationModal.step3.interactionStep.madeRightAway': 'wykonane od razu',
    'contacts.add.integrationModal.step3.interactionStep.madeAfterHoursAndMinutes': 'po upływie {hours, plural, one {# godziny} few {# godzin} many {# godzin}} i {minutes, plural, one {# minuty} few {# minut} many {# minut}}',
    'contacts.add.integrationModal.step3.interactionStep.madeAfterHours': 'po upływie {hours, plural, one {# godziny} few {# godzin} many {# godzin}}',
    'contacts.add.integrationModal.step3.interactionStep.madeAfterMinutes': 'po upływie {minutes, plural, one {# minuty} few {# minut} many {# minut}}',
    'contacts.add.integrationModal.step3.interactionStep.madeTodayAt': 'dzisiaj o godzinie {time}',
    'contacts.add.integrationModal.step3.interactionStep.madeTomorrowAt': 'jutro o godzinie {time}',
    'contacts.add.integrationModal.step3.interactionStep.madeOnDayAt': 'za {days, plural, one {# dzień} few {# dni} many {# dni}} o godzinie {time}',
    'contacts.cancel.allContactsButtonLabel': 'Anuluj wszystkie kontakty',
    'contacts.cancel.allContactsButtonLabelWithCount': 'Anuluj wszystkie kontakty ({count})',
    'contacts.cancel.selectedContactsButtonLabel': 'Anuluj wybrane kontakty',
    'contacts.cancel.selectedContactsButtonLabelWithCount': 'Anuluj wybrane kontakty ({count})',
    'contacts.cancel.noContactsTooltip': 'Nie masz kontaktów, które możesz anulować',
    'contacts.cancel.multipleContactsModal.header.allContacts': 'Anulowanie wszystkich kontaktów',
    'contacts.cancel.multipleContactsModal.header.selectedContacts': 'Anulowanie wybranych kontaktów',
    'contacts.cancel.multipleContactsModal.message.contacts': 'Czy na pewno chcesz **anulować {count, plural, one {# kontakt} few {# kontakty} many {# kontaktów}} ({inProgressCount} w trakcie i {createdCount, plural, one {# stworzony} few {# stworzone} many {# stworzonych}})?**',
    'contacts.cancel.multipleContactsModal.message.createdContacts': 'Czy na pewno chcesz **anulować {createdCount, plural, one {# stworzony kontakt} few {# stworzone kontakty} many {# stworzonych kontaktów}}?**',
    'contacts.cancel.multipleContactsModal.message.inProgressContacts': 'Czy na pewno chcesz **anulować {inProgressCount, plural, one {# kontakt} few {# kontakty} many {# kontaktów}} w trakcie?**',
    'contacts.cancel.multipleContactsModal.info': 'Anulowanie kontaktów spowoduje, że będzie można z nich skorzystać jedynie przez ponowne dodanie.',
    'contacts.cancel.multipleContactsModal.cancelButton': 'Zrezygnuj',
    'contacts.cancel.multipleContactsModal.confirmButton': 'Anuluj kontakty',
    'contacts.cancel.multipleContacts.toast.success': 'Anulowano **{count, plural, one {# kontakt} few {# kontakty} many {# kontaktów}}**',
    'contacts.cancel.multipleContacts.toast.warning': 'Udało się anulować **{cancelledCount, plural, one {# kontakt} few {# kontakty} many {# kontaktów}}**. Nie udało się anulować **{notCancelledCount, plural, one {pozostałego # kontaktu} few {pozostałych # kontaktów} many {pozostałych # kontaktów}}**. W trakcie operacji bot mógł zacząć nawiązywać z nimi połączenie.',
    'contacts.cancel.multipleContacts.toast.error': 'Nie udało się anulować wybranych kontaktów. **Spróbuj ponownie później** lub skontaktuj się z nami na [support@talkie.ai](mailto:support@talkie.ai)',
    'contacts.phoneNumberFilterLabel': 'Numer telefonu...',
    'contacts.status.created': 'Zaplanowane',
    'contacts.status.in_progress': 'W trakcie',
    'contacts.status.survey_complete': 'Zakończone z sukcesem',
    'contacts.status.survey_incomplete': 'Zakończone bez sukcesu',
    'contacts.status.cancelled': 'Anulowany',

    'contacts.interaction.result.delivered': 'Dostarczono',
    'contacts.interaction.result.undelivered': 'Nie dostarczono',
    'contacts.interaction.result.received': 'Odebrano',

    'contacts.interaction.result.failed': 'Błąd',
    'contacts.interaction.result.succeeded': 'Sukces',

    'contacts.interaction.result.not_answered': 'Nieodebrane',
    'contacts.interaction.result.all_required_questions_answered': 'Zakończone sukcesem',
    'contacts.interaction.result.voicemail': 'Poczta głosowa',
    'contacts.interaction.result.missing_required_question': 'Brak odpowiedzi na wymagane pytania',
    'contacts.interaction.result.postpone_request': 'Przełożony',
    'contacts.interaction.result.stop_calling': 'Prośba o niedzwonienie',

    'contacts.interaction.status.processed': 'Przetworzone',
    'contacts.interaction.status.pending': 'Oczekuje',
    'contacts.interaction.status.active': 'Aktywne',
    'contacts.interaction.status.failed': 'Błąd',
    'contacts.interaction.status.cancelled': 'Anulowany',

    'contacts.interaction.type.call-outbound': 'Połączenie',
    'contacts.interaction.type.call-inbound': 'Połączenie (przychodzące)',
    'contacts.interaction.type.sms-inbound': 'SMS',
    'contacts.interaction.type.sms-outbound': 'SMS',
    'contacts.interaction.type.http-inbound': 'HTTP',
    'contacts.interaction.type.http-outbound': 'HTTP',

    'contacts.interaction.dateLabel': 'Data: ',
    'contacts.interaction.statusLabel': 'Status: ',
    'contacts.interaction.typeLabel': 'Typ: ',
    'contacts.interaction.resultLabel': 'Rezultat: ',
    'contacts.interaction.messageLabel': 'Tekst SMS: ',

    'contacts.interaction.empty': 'Wybierz kontakt, aby zobaczyć szczegóły',

    'contacts.details.cancelContactButtonLabel': 'Anuluj kontakt',
    'contacts.details.cancelContactDescription': 'Anulować można tylko kontakty o statusie stworzony lub w trakcie (z wyjątkiem aktualnie wykonywanych połączeń)',
    'contacts.cancel.modal.header': 'Anulowanie kontaktu',
    'contacts.cancel.modal.body': `Czy na pewno chcesz **anulować kontakt**? 
    
'<span class='{muted}'>'**Anulowanie kontaktu** spowoduje, że będzie można z niego skorzystać jedynie przez ponowne dodanie.'</span>'`,
    'contacts.cancel.modal.confirm': 'Anuluj kontakt',
    'contacts.cancel.modal.cancel': 'Zrezygnuj',
    'contacts.cancel.successToast': 'Kontakt został **anulowany**',
    'contacts.cancel.errorToast': 'Nie udało się anulować kontaktu. **Spróbuj ponownie później.**',

    'contacts.noContacts.importFileExtensions': 'Platforma akceptuje pliki w formatach: .xlsx oraz .csv.',
    'contacts.noContacts.addContactFile': '{addContacts} do których mają zostać wykonane połączenia.',
    'contacts.noContacts.addContacts': 'Dodaj kontakty',
    'contacts.noContacts.readMoreInKnowledgeBase': '{readMore} o dodawaniu kontaktów w platformie wiedzy.',
    'contacts.noContacts.readMore': 'Przeczytaj więcej',
    'contacts.noContacts.readMoreUrl': 'https://help.talkie.ai/pl/knowledge-base/how-to-add-a-contacts-list-to-an-outbound-campaign/',
    'contacts.noContacts.addContactsViaAPI': 'Jeżeli chcesz dodawać kontakty przy użyciu API {referToDocs}',
    'contacts.noContacts.referToDocs': 'zapoznaj się z dokumentacją.',
    'contacts.noContactsForFilters.contactsNotFound': 'Nie znaleziono kontaktów dla wybranych filtrów. {changeValue}, aby zobaczyć więcej.',
    'contacts.noContactsForFilters.changeValue': 'Zmień wartości',
    'contacts.noContactsNoSchedule.activateScheduler': 'Przed pierwszym dodaniem kontaktów musisz zaplanować swoje działania w {scheduler}.',
    'contacts.noContactsNoSchedule.scheduler': 'planerze',
    'contacts.noContactsNoSchedule.nextSteps': 'Ustaw kolejne kroki i ramy dzwonienia, następnie wróć do listy kontaktów.',

    'dashboard.header.search.placeholder': 'Wyszukaj bota...',
    'dashboard.botsView.addBot.button': 'Nowy bot',
    'dashboard.botsView.manageOrganization.button': 'Zarządzaj organizacją',
    'dashboard.botsView.filterByTags.button': 'Filtruj po tagach',
    'dashboard.botsView.customerInvisibleBots.show': 'Ukryj boty niewidoczne dla klientów',
    'dashboard.botsView.customerInvisibleBots.hide': 'Pokaż boty niewidoczne dla klientów',
    'dashboard.clonePopup.title': 'Klonowanie bota',
    'dashboard.clonePopup.baseBotName': 'Bot bazowy',
    'dashboard.clonePopup.baseBotLanguage': 'Język bota',
    'dashboard.clonePopup.newBotName': 'Nazwa bota',
    'dashboard.clonePopup.client': 'Klient',
    'dashboard.clonePopup.client.placeholder': 'Wybierz klienta',
    'dashboard.clonePopup.aiModel': 'Model intencji',
    'dashboard.clonePopup.visibleForClient': 'Bot widoczny dla klienta',
    'dashboard.clonePopup.save': 'Utwórz',
    'dashboard.clonePopup.cancel': 'Anuluj',
    'dashboard.clonePopup.addNewClient': 'Utwórz klienta:',
    'dashboard.clonePopup.aiModelOptions.shareModel': 'Współdziel model bota bazowego',
    'dashboard.clonePopup.aiModelOptions.copyModel': 'Skopiuj model bota bazowego',
    'dashboard.clonePopup.aiModelOptions.createNew': 'Utwórz nowy model AI',
    'dashboard.clonePopup.aiModelOptions.placeholder': 'Wybierz strategię modelu AI',
    'dashboard.addBotPopup.title': 'Utwórz bota',
    'dashboard.addBotPopup.newBotName': 'Nazwa bota',
    'dashboard.addBotPopup.newBotLanguage': 'Język',
    'dashboard.addBotPopup.client': 'Klient',
    'dashboard.addBotPopup.visibleForClient': 'Bot widoczny dla klienta',
    'dashboard.addBotPopup.anonymized': 'Anonimizuj raporty',
    'dashboard.addBotPopup.save': 'Utwórz',
    'dashboard.addBotPopup.cancel': 'Anuluj',
    'dashboard.addBotPopup.languages.pl-PL': 'Polski',
    'dashboard.addBotPopup.languages.en-GB': 'Angielski Brytyjski',
    'dashboard.addBotPopup.languages.en-US': 'Angielski Amerykański',
    'dashboard.addBotPopup.languages.en-AU': 'Angielski Australijski',
    'dashboard.addBotPopup.languages.hu-HU': 'Węgierski',
    'dashboard.addBotPopup.languages.ru-RU': 'Rosyjski',
    'dashboard.addBotPopup.languages.fr-FR': 'Francuski',
    'dashboard.addBotPopup.language.placeholder': 'Wybierz język',
    'dashboard.addBotPopup.intentSet': 'Baza intencji',
    'dashboard.addBotPopup.intentSet.default': 'Globalna',
    'dashboard.addBotPopup.intentSet.default-medical': 'Globalna + medyczna',
    'dashboard.addBotPopup.client.placeholder': 'Wybierz klienta',
    'dashboard.addBotPopup.invalidTags': 'Niepoprawne tagi',
    'dashboard.editBotPopup.title': 'Edytuj ustawienia bota',
    'dashboard.editBotPopup.botName': 'Nazwa bota',
    'dashboard.editBotPopup.botLanguage': 'Język bota',
    'dashboard.editBotPopup.client': 'Klient',
    'dashboard.editBotPopup.botTags': 'Tagi przypisane do bota',
    'dashboard.editBotPopup.botTagsTooltip': 'Tagi pozwalają na łatwiejsze wyszukiwanie bota w platformie. Możesz dodać maksymalnie 7 tagów.',
    'dashboard.editBotPopup.tooManyTags': 'Możesz dodać maksymalnie 7 tagów',
    'dashboard.editBotPopup.visibleForClient': 'Bot widoczny dla klienta',
    'dashboard.editBotPopup.update': 'Zapisz zmiany',
    'dashboard.editBotPopup.cancel': 'Anuluj',

    'organization.header.organization': 'Organizacja: {organizationName}',
    'organization.tabs.sms': 'SMS',
    'organization.sms.smsSettings.heading': 'Dane konfiguracyjne wysyłki SMS {disclaimer}',
    'organization.sms.smsSettings.disclaimer': '(domyślne dla wszystkich botów w organizacji)',
    'organization.sms.additionalBotNumber.heading': 'Dodatkowe numery dla poszczególnych botów {disclaimer}',
    'organization.sms.additionalBotNumber.disclaimer': '(nadpisują domyślną konfigurację)',
    'organization.sms.providerName': 'Dostawca usługi',
    'organization.sms.phoneNumber': 'Nr do wysyłki SMSów lub nadawca wiadomości',
    'organization.sms.botName': 'Wybór bota',
    'organization.sms.supervoip.login': 'Login',
    'organization.sms.supervoip.password': 'Hasło',
    'organization.sms.supervoip.apiKey': 'Klucz API',
    'organization.sms.vonage.apiKey': 'API Key',
    'organization.sms.vonage.apiSecret': 'API Secret',
    'organization.sms.twilio.accountSid': 'Account Sid',
    'organization.sms.twilio.authToken': 'Auth Token',
    'organization.sms.parlance.applicationGuid': 'Application Guid',
    'organization.sms.parlance.apiKey': 'API Key',
    'organization.sms.parlance.baseUrl': 'Base URL',
    'organization.sms.saveChanges': 'Zapisz zmiany',
    'organization.sms.cancel': 'Anuluj',
    'organization.sms.addOverride': 'Przypisz dedykowany numer do bota',
    'organization.sms.update.successToast': 'Pomyślnie zmieniono konfigurację SMS',
    'organization.sms.update.errorToast': 'Nie udało się zmienić konfiguracji SMS',
    'organization.sms.testSms': 'Testowanie konfiguracji',
    'organization.sms.testSms.tooltip.disabled': 'Zapisz zmiany aby przetestować konfigurację',
    'organization.sms.testSms.title': 'Testowanie konfiguracji',
    'organization.sms.testSms.phoneNumber': 'Wpisz numer telefonu do przetestowania konfiguracji',
    'organization.sms.testSms.cancel': 'Anuluj',
    'organization.sms.testSms.send': 'Wyślij SMS',
    'organization.sms.testSms.success': 'SMS został wysłany',
    'organization.sms.testSms.error': 'Nie udało się wysłać SMSa. Sprawdź konfigurację i spróbuj ponownie',

    'scheduler.dateOptions.placeholder': 'Wybierz dni tygodnia',
    'scheduler.holidayOptions.text': 'Nie dzwoń w święta obowiązujące w',
    'scheduler.holidayOptions.country.selectText': 'Wybierz kraj',
    'scheduler.holidayOptions.country.Poland': 'Polsce',
    'scheduler.holidayOptions.country.USA': 'USA',
    'scheduler.callingDays.text': 'W jakie dni dzwonić',
    'scheduler.callingDays.Monday': 'Poniedziałek',
    'scheduler.callingDays.Tuesday': 'Wtorek',
    'scheduler.callingDays.Wednesday': 'Środa',
    'scheduler.callingDays.Thursday': 'Czwartek',
    'scheduler.callingDays.Friday': 'Piątek',
    'scheduler.callingDays.Saturday': 'Sobota',
    'scheduler.callingDays.Sunday': 'Niedziela',
    'scheduler.timeSettings.timezone': 'Wybierz strefę czasową',
    'scheduler.timeSettings.callingHours': 'Ramy godzinowe',
    'scheduler.steps.callStep.type.JustInTime': 'Wykonaj od razu',
    'scheduler.maxConcurrentCallsSettings.enabled': 'Ustaw limit jednoczesnych połączeń',
    'scheduler.acceptIncomingCallsSettings.enabled': 'Akceptuj połączenia przychodzące po zakończeniu ankiety',
    'scheduler.timeZoneLabel': 'Strefa czasowa',
    'scheduler.guideBox.stepOne.header': '1 - Określ ogólne ramy dzwonienia - w jakie dni i w jakich godzinach',
    'scheduler.guideBox.stepOne.content': '',
    'scheduler.guideBox.stepTwo.header': '2 - Zbuduj szczegółowy harmonogram dzwonienia dodając kroki',
    'scheduler.guideBox.stepTwo.content': '',
    'scheduler.guideBox.stepThree.header': '3 - Uruchom planer by rozpocząć realizację według planu',
    'scheduler.guideBox.stepThree.content': '',
    'scheduler.steps.callStep.type.FollowUpInGivenHours': 'Wykonaj po upływie czasu',
    'scheduler.steps.callStep.type.FollowUpInGivenDaysAt': 'Wykonaj o wybranej godzinie',
    'scheduler.steps.callStep.FollowUpInGivenDaysAt.currentDay': 'Tego samego dnia',
    'scheduler.steps.callStep.FollowUpInGivenDaysAt.before': 'Po',
    'scheduler.steps.callStep.FollowUpInGivenDaysAt.nextDay': 'Po 1 dniu',
    'scheduler.steps.callStep.FollowUpInGivenDaysAt.multipleDays': 'dniach',
    'scheduler.steps.titleLabel': 'Krok',
    'scheduler.steps.callHeader': 'Połączenie',
    'scheduler.steps.callDescription': 'Wykonaj połączenie wychodzące',
    'scheduler.steps.smsHeader': 'SMS',
    'scheduler.steps.smsCollapsed': 'Konfiguracja SMS',
    'scheduler.steps.smsDescription': 'Wyślij wiadomość sms',
    'scheduler.steps.httpHeader': 'HTTP',
    'scheduler.steps.httpCollapsed': 'Konfiguracja HTTP',
    'scheduler.steps.httpDescription': 'Komunikacja z systemem zewnętrznym',
    'scheduler.steps.addStep': 'Krok do planera',
    'scheduler.steps.addStepDescription': 'Wybierz typ kroku i ustaw zachowanie planera',
    'scheduler.steps.addCall': 'Połączenie',
    'scheduler.steps.addSms': 'Wiadomość SMS',
    'scheduler.steps.addHttp': 'Zapytanie HTTP',
    'scheduler.step.callSelect': 'Wykonaj połączenie',
    'scheduler.step.callSelectFirst': 'Wykonaj pierwsze połączenie',
    'scheduler.save': 'Zapisz zmiany',
    'scheduler.cancel': 'Anuluj',
    'scheduler.step.numberOfMinutesLabel': 'min.',
    'scheduler.step.numberOfHoursLabel': 'godz.',
    'scheduler.step.numberOfDaysLabel': 'o godzinie',
    'scheduler.step.callTimeLabel': 'Godzina połączenia',
    'scheduler.step.smsTemplateLabel': 'Treść wiadomości SMS',
    'scheduler.step.smsSenderNameLabel': 'Wyświetlany nadawca wiadomości',

    'scheduler.step.httpStep.url': 'URL',
    'scheduler.step.httpStep.method': 'Metoda',
    'scheduler.step.httpStep.headers': 'Nagłówki',
    'scheduler.step.httpStep.body': 'Treść',
    'scheduler.step.httpStep.timeout': 'Timeout (limit przewidziany na operację)',
    'scheduler.step.httpStep.resetTimeout': 'Przywróć domyślną wartość',

    'scheduler.botStatus.readyToCall': 'Uruchomiony, gotowy do dzwonienia',
    'scheduler.botStatus.calling': 'W trakcie dzwonienia',
    'scheduler.botStatus.paused': 'Wstrzymany',
    'scheduler.botStatus.noSchedule': 'Brak konfiguracji planera',
    'scheduler.botStatus.noScheduleAndPhoneNumber': 'Brak konfiguracji planera i nr tel.',
    'scheduler.botStatus.noPhoneNumber': 'Brak numeru telefonu',
    'scheduler.botStatus.label': 'Status bota:',

    'scheduler.contactStatuses.inQueue': 'Kontakty zaplanowane:',
    'scheduler.contactStatuses.inProgress': 'Kontakty w trakcie:',
    'scheduler.contactStatuses.called': 'Kontakty obsłużone:',

    'scheduler.status.tooltip.inQueue': 'Kontakty które zostały dodane do bota, ale jeszcze nie zostało z nimi nawiązane żadne połączenie.',
    'scheduler.status.tooltip.inProgress': 'Kontakty które są na liście połączeń w bocie i są do nich wykonywane zaplanowane połączenia, ale jeszcze żadne nie zostało zrealizowane z oczekiwanym rezultatem.',
    'scheduler.status.tooltip.called': 'Kontakty w których połączenie zostało odebrane lub została wykonana zaplanowana liczba połączeń.',

    'scheduler.button.tooltip.noScheduleAndPhoneNumber': 'Musisz dodać numer telefonu i skonfigurować planer, aby móc go uruchomić.',
    'scheduler.button.tooltip.noPhoneNumber': 'Napisz na support@talkie.ai w celu ustawienia numeru telefonu.',
    'scheduler.button.tooltip.noSchedule': 'Musisz skonfigurować planer, aby móc go uruchomić.',

    'scheduler.button.pause': 'Wstrzymaj',
    'scheduler.button.resume': 'Uruchom',

    'table.next': 'Następna',
    'table.previous': 'Poprzednia',
    'table.page': 'Strona',
    'table.of': 'z',
    'table.rows': 'wierszy',

    'conversations.filters.phone.label': 'Numer telefonu...',
    'conversations.filters.name.label': 'Nazwa Użytkownika',
    'conversations.filters.phrase.label': 'Zawierające treść rozmowy...',
    'conversations.filters.filter': 'Filtruj',
    'conversations.filters.cancel': 'Anuluj',
    'conversations.filters.filter.clear': 'Wyczyść',
    'conversations.filters.report': 'Raport',
    'conversations.filters.showAll': 'Wszystkie filtry',
    'conversations.filters.events.clear': 'Wyczyść zdarzenia',
    'conversations.filters.duration.label': '00m 00s - 00m 00s',

    'conversations.options.button': 'Dostosuj widok konwersacji',
    'conversations.options.modal.title': 'Dostosuj widok konwersacji',
    'conversations.options.modal.save': 'Zapisz',
    'conversations.options.modal.description': 'Zaznacz elementy które mają być widoczne w podglądzie konwersacji',
    'conversations.options.modal.deselectAll': 'Odznacz wszystko',
    'conversations.options.modal.selectAll': 'Zaznacz wszystko',
    'conversations.options.modal.ssmlTags': 'Tagi',
    'conversations.options.modal.module': 'Moduły',
    'conversations.options.modal.step_name': 'Nazwy kroków',
    'conversations.options.modal.http': 'Kroki HTTP',
    'conversations.options.modal.set_variable': 'Kroki ustaw zmienną',
    'conversations.options.modal.data_collection': 'Kroki zbierania danych',
    'conversations.options.modal.function': 'Kroki funkcyjne',
    'conversations.options.modal.send_sms': 'Kroki wysyłania SMS',
    'conversations.options.modal.redirect': 'Kroki przekierowania',
    'conversations.options.modal.interpretation': 'Interpretacje',
    'conversations.options.modal.dtmf': 'Odpowiedzi DTMF',

    'conversations.details.modal.stepLinkExpired': 'Krok lub jego moduł został usunięty.',

    'conversations.variables.modal.stepLink': 'Link do kroku',
    'conversations.variables.modal.name': 'Nazwa',
    'conversations.variables.modal.value': 'Wartość',

    'conversations.http.modal.stepLink': 'Link do kroku',
    'conversations.http.modal.url': 'URL',
    'conversations.http.modal.method': 'Metoda',
    'conversations.http.modal.headers': 'Nagłówki',
    'conversations.http.modal.requestBody': 'Treść zapytania',
    'conversations.http.modal.responseBody': 'Treść odpowiedzi',

    'conversations.sms.modal.stepLink': 'Link do kroku',
    'conversations.sms.modal.from': 'Od',
    'conversations.sms.modal.to': 'Do',
    'conversations.sms.modal.text': 'Treść',
    'conversations.sms.modal.provider': 'Dostawca',
    'conversations.sms.modal.status': 'Status',
    'conversations.sms.modal.errorMessage': 'Błąd',

    'conversations.redirect.modal.stepLink': 'Link do kroku',
    'conversations.redirect.modal.to': 'Do',
    'conversations.redirect.modal.from': 'Od',
    'conversations.redirect.modal.trunk': 'Trunk',
    'conversations.redirect.modal.type': 'Typ',
    'conversations.redirect.modal.destination': 'Destination',
    'conversations.redirect.modal.recordingAction': 'Akcja nagrywania',
    'conversations.redirect.modal.headers': 'Nagłówki SIP',

    'conversations.interpretation.entity': 'Encja',
    'conversations.interpretation.intent': 'Intencja',
    'conversations.interpretation.translation': 'Tłumaczenie',
    'conversations.interpretation.modal.interpretationResult': 'Rezultat interpretacji',

    'conversations.dataCollection.report': 'Raport',
    'conversations.dataCollection.modal.collectedData': 'Zebrane dane',

    'conversations.function.name': 'Nazwa funkcji',
    'conversations.function.inputs': 'Dane wejściowe',
    'conversations.function.outputs': 'Dane wyjściowe',
    'conversations.function.enabled': 'Włączone',
    'conversations.function.disabled': 'Wyłączone',

    'filters.date.from': 'Od:',
    'filters.date.to': 'do:',
    'filters.date.search': 'Szukaj',

    'statistics.period.day': 'Ostatnie 24 godziny',
    'statistics.period.week': 'Ostatnie 7 dni',
    'statistics.period.month': 'Ostatnie 30 dni',
    'statistics.period.year': 'Ostatni rok',
    'statistics.period.custom': 'Wybrany przedział',
    'statistics.period.placeholder': 'Wybrany przedział',
    'statistics.button.report.download': 'Pobierz raport',
    'statistics.report.fileName': 'raport',
    'statistics.chart.hourly': 'Wykres godzinny',
    'statistics.chart.daily': 'Wykres dzienny',
    'statistics.chart.monthly': 'Wykres miesięczny',
    'statistics.summary': 'Podsumowanie',
    'statistics.results': 'Rezultaty',
    'statistics.information': 'Informacje',
    'statistics.surveyQuestions': 'Scenariusz',
    'statistics.recognizedIntents': 'Intencje',
    'statistics.filters.containingText': 'Zawierające tekst:',
    'statistics.addons.minutesUsed': 'Wykorzystane minuty',
    'statistics.addons.tooltip.minutesUsed': 'Wszystkie rozpoczęte minuty (przychodzące i wychodzące), które zostały wykorzystane przez bota',

    'statistics.dashboard.call_answered': 'Połączenia wychodzące:{linebreak}odebrane',
    'statistics.dashboard.call_not_answered': 'Połączenia wychodzące:{linebreak}nieodebrane',
    'statistics.dashboard.voice_mail_detected': 'Poczta głosowa',
    'statistics.dashboard.incoming_call': 'Połączenia przychodzące',
    'statistics.dashboard.allCalls': 'Wszystkie połączenia',
    'statistics.dashboard.call_redirected': 'Przekierowania',
    'statistics.dashboard.average_call_duration': 'Średni czas{linebreak}rozmowy',
    'statistics.dashboard.unique_callers_count': 'Liczba unikatowych{linebreak}rozmówców',
    'statistics.dashboard.tooltip.call_answered': 'Liczba połączeń zainicjowanych przez bota podczas których została nawiązana rozmowa z klientem',
    'statistics.dashboard.tooltip.call_not_answered': 'Liczba połączeń zainicjowanych przez bota podczas których klient nie odebrał połączenia',
    'statistics.dashboard.tooltip.voice_mail_detected': 'Liczba połączeń zainicjowanych przez bota, gdzie po stronie klienta została wykryta poczta głosowa',
    'statistics.dashboard.tooltip.incoming_call': 'Liczba połączeń zainicjowanych przez klienta, które zostały odebrane przez bota',
    'statistics.dashboard.tooltip.allCalls': 'Suma wszystkich typów połączeń',
    'statistics.dashboard.tooltip.call_redirected': 'Liczba rozmów przekierowanych na inny numer, na przykład do konsultanta',
    'statistics.dashboard.tooltip.average_call_duration': 'Średni czas rozmowy',
    'statistics.dashboard.tooltip.unique_callers_count': 'Liczba unikatowych rozmówców',

    'statistics.scenarioStepsDashboard.savingChangesToastError': 'Nie udało się zapisać zmian. **Spróbuj ponownie później.**',
    'statistics.scenarioStepsDashboard.pinnedSteps': 'Przypięte kroki',
    'statistics.scenarioStepsDashboard.remainingSteps': 'Pozostałe kroki',
    'statistics.scenarioStepsDashboard.allSteps': 'Wszystkie kroki',
    'statistics.scenarioStepsDashboard.pinAllSteps': 'Przypnij wszystkie kroki',
    'statistics.scenarioStepsDashboard.unpinAllSteps': 'Odepnij wszystkie przypięte kroki',

    'statistics.scenarioStepsDashboard.userHelp.someStepsPinned.description': 'Pamiętaj, że w scenariuszu może być więcej kroków niż widzisz.',
    'statistics.scenarioStepsDashboard.userHelp.someStepsPinned.callToAction': 'Kliknij “Edytuj widok”, by zobaczyć wszystkie dostępne kroki.',

    'statistics.scenarioStepsDashboard.userHelp.allStepsPinned.description': 'Boty mają wiele kroków, ale czasami chcemy widzieć tylko te najistotniejsze.',
    'statistics.scenarioStepsDashboard.userHelp.allStepsPinned.callToAction': 'Kliknij "Edytuj widok" i przypnij najważniejsze kroki.',

    'statistics.scenarioStepsDashboard.tooltip.pin': 'Przypnij',
    'statistics.scenarioStepsDashboard.tooltip.unpin': 'Odepnij',

    'statistics.scenarioStepsDashboard.emptyState.header': 'Ustaw statystyki, by dawały Ci największą wartość',
    'statistics.scenarioStepsDashboard.emptyState.description': 'Boty mogą mieć wiele kroków, ale czasami chcemy widzieć tylko te, które mają dla nas największe znaczenie.',
    'statistics.scenarioStepsDashboard.emptyState.callToAction': 'Przejdź do edycji widoku i wybierz, które są dla Ciebie najważniejsze.',
    'statistics.scenarioStepsDashboard.emptyState.editView': 'Edytuj widok',

    'statistics.scenarioStepsDashboard.navbar.editView': 'Edytuj widok',
    'statistics.scenarioStepsDashboard.navbar.cancel': 'Anuluj',
    'statistics.scenarioStepsDashboard.navbar.saveChanges': 'Zapisz zmiany',
    'statistics.scenarioStepsDashboard.navbar.pinDescription': 'Przypnij kroki ze scenariusza, które mają być widoczne w statystykach.',

    'header.conversations': 'Konwersacje',
    'header.done-conversations': 'Zakończone',
    'header.live-conversations': 'Na żywo',
    'header.reports': 'Raporty',
    'header.statistics': 'Statystyki',
    'header.summary': 'Podsumowanie',
    'header.learning': 'Douczanie',
    'header.settings': 'Ustawienia',
    'header.logout': 'Wyloguj',
    'header.termsOfService': 'Regulamin platformy',
    'header.changePassword': 'Zmiana hasła',
    'header.inspector': 'Inspektor',
    'header.survey-creator': 'Kreator',
    'header.contacts': 'Połączenia',
    'header.candidates': 'Kandydaci',

    'header.changePasswordModal.formHeader': 'Zmiana hasła',
    'header.changePasswordModal.confirmationHeader': 'Twoje hasło zostało zmienione.',
    'header.changePasswordModal.submit': 'Zmień hasło i przeloguj',
    'header.changePasswordModal.cancel': 'Anuluj',
    'header.changePasswordModal.oldPasswordLabel': 'Stare hasło',
    'header.changePasswordModal.newPasswordLabel': 'Nowe hasło',
    'header.changePasswordModal.repeatNewPasswordLabel': 'Powtórz nowe hasło',
    'header.changePasswordModal.confirmationDescription': 'Twoje hasło zostało zmienione. Za chwilę zalogujemy Cię ponownie używając nowego hasła.',

    'survey.externalVariables.dictionary': 'Wartości...',
    'survey.externalVariables.save': 'Zapisz zmienne',
    'survey.triggerCalls': 'Rozpocznij dzwonienie',
    'survey.triggerSkippedCalls': 'Obdzwoń ponownie',
    'survey.config.tabs.general': 'Ustawienia ogólne',
    'survey.config.tabs.phone': 'Numery bota',
    'survey.config.tabs.admin': 'Zmienne oraz nagłówki SIP',
    'survey.config.tabs.api': 'Dane identyfikujące bota (API)',
    'survey.config.tabs.api.docsButtonLabel': 'Otwórz dokumentację API',
    'survey.config.tabs.api.show': 'Wyświetl',
    'survey.config.tabs.api.copy': 'Skopiuj',
    'survey.config.tabs.api.copied': 'Skopiowano!',
    'survey.config.returnCallToggleLabel': 'Oddzwanianie?',
    'survey.config.returnCallMaxCallsLabel': 'Ilość oddzwonień',
    'survey.config.returnCallIntervalLabel': 'Oddzwonienie po (min)',
    'survey.config.phoneConfigs': 'Konfiguracja numerów',
    'survey.config.addPhone': 'Dodaj',
    'survey.config.countryCallingCode': 'Numer kierunkowy kraju',
    'survey.config.countryCallingCodeNote': 'Numer kierunkowy kraju obowiązuje dla wszystkich numerów bota i kontaktowych.',
    'survey.config.addNumber': 'Dodaj numer',
    'survey.config.phoneNumber': 'Numer telefonu',
    'survey.config.serverAddress': 'Adres serwera',
    'survey.config.anonymized': 'Anonimizuj dane osobowe w raportach',
    'survey.config.timeZone': 'Strefa czasowa, w której funkcjonuje bot',
    'survey.config.admin.addVariable': 'Zmienną',
    'survey.config.admin.addVariable.title.contact': 'Zmienne z kontaktów',
    'survey.config.admin.addVariable.title.external': 'Zmienne SIP',
    'survey.config.voiceConfig.label': 'Konfiguracja głosu',
    'survey.config.voiceConfig.language': 'Język głosu',
    'survey.config.voiceConfig.defaultContent': 'Zmieniając rodzaj głosu wybierasz ten, którym będzie posługiwał się Twój bot. Wpisz inny tekst, aby sprawdzić jak brzmi w konkretnych przypadkach.',
    'survey.config.voiceConfig.voiceName': 'Nazwa głosu',
    'survey.config.voiceConfig.googleLink': 'https://cloud.google.com/text-to-speech/docs/voices',
    'survey.config.voiceConfig.googleLinkText': 'Przeczytaj więcej o wspieranych głosach i językach',
    'survey.config.voiceConfig.speed': 'Tempo głosu',
    'survey.config.voiceConfig.pitch': 'Wysokość głosu',
    'survey.config.voiceConfig.voiceTestContent': 'Treść testowa',
    'survey.config.voiceConfig.voiceTest': 'Przetestuj głos',
    'survey.config.api.username': 'Nazwa użytkownika',
    'survey.config.api.password': 'Hasło',
    'survey.config.api.botId': 'ID bota',
    'survey.config.save': 'Zapisz',

    'inspector.filters.phrase': 'Zawierające tekst...',
    'inspector.filters.withoutAnswers': 'Nie rozpoznano odpowiedzi',
    'inspector.filters.withoutIntents': 'Nie rozpoznano intencji',
    'inspector.filters.question': 'Filtruj po pytaniu',
    'inspector.filters.question.tooltip': 'Zobacz odpowiedzi udzielone na wybrane pytanie',
    'inspector.filters.intent': 'Filtruj po intencji',
    'inspector.filters.intent.tooltip': 'Zobacz wypowiedzi zinterpretowane jako wybrana intencja',
    'inspector.text': 'Transkrypcja wypowiedzi',
    'inspector.recognizedIntent': 'Rozpoznana intencja',
    'inspector.recognizedAnswer': 'Rozpoznana odpowiedź',
    'inspector.occurrences': 'Wystąpienia',
    'inspector.stepName': 'Nazwa kroku',
    'inspector.wordQuestionValue': 'Poprawna wartość',
    'inspector.actions.goToConversation': 'Przejdź do konwersacji',
    'inspector.actions.remove': 'Usuń',
    'inspector.train': 'Doucz',
    'inspector.train.sentencesLabel': 'Zaznaczone wypowiedzi',
    'inspector.train.addToLabel': 'Dodaj do',
    'inspector.train.addToStep': 'Kroku',
    'inspector.train.addToIntent': 'Intencji',
    'inspector.train.answerInLabel': 'Odpowiedzi w',
    'inspector.train.intentLabel': 'Intencja',
    'inspector.train.scopeLabel': 'Zakres douczenia',
    'inspector.train.scopeLocalOption': 'Lokalny zbiór',
    'inspector.train.scopeGlobalOption': 'Globalny zbiór',
    'inspector.train.header': 'Dodawanie nowych definicji',

    'learning.entityName': 'Nazwa encji',
    'learning.value': 'Wartość',
    'learning.learn': 'Naucz',
    'learning.addIntent': 'Dodaj zdanie',
    'learning.learn.dev': 'Naucz dev',
    'learning.learn.prod': 'Naucz',
    'learning.trainingStatus.dev': 'Status uczenia dev:',
    'learning.trainingStatus.prod': 'Status uczenia:',
    'learning.trainingStatus.IDLE': 'Gotowy',
    'learning.trainingStatus.TRAINING': 'W trakcie douczania modelu',
    'learning.trainingStatus.DISABLED': 'Nieznany',
    'learning.trainingStatus.ERROR': 'Spróbuj jeszcze raz',
    'learning.interpretation': 'Interpretacja',
    'learning.evaluate.dev': 'Ewaluacja Dev',
    'learning.evaluate.prod': 'Ewaluacja Prod',
    'learning.evaluate.accuracy': 'Dokładność',
    'learning.globalIntents': 'Globalne intencje, ostrożnie!',
    'learning.multipleSentences': 'Dodaj wiele zdań',
    'learning.cancel': 'Anuluj',
    'learning.intentTraining.intent': 'Intencja',
    'learning.intentTraining.error': 'Wystąpił błąd podczas weryfikacji zdania',
    'learning.intentTraining.statusLabel': 'Status bota:',
    'learning.intentTraining.trainingBotButton': 'Doucz bota',
    'learning.intentTraining.intentSelect.placeholder': 'Wybierz intencję',
    'learning.intentTraining.addUtterance': 'Dodaj wypowiedź',
    'learning.intentTraining.addMultiple': 'Dodaj wiele',
    'learning.intentTraining.intentInput.placeholder': 'Wpisz wypowiedź do sprawdzenia/dodania...',
    'learning.intentTraining.recognizedIntentLabel': 'Rozpoznana intencja',
    'learning.intentTraining.confidenceLabel': 'Dopasowanie',
    'wrong-sentences.table.text': 'Tekst',
    'wrong-sentences.table.intent': 'Oczekiwana',
    'wrong-sentences.table.predicted': 'Rozpoznana',
    'wrong-sentences.table.confidence': 'Pewność',
    'train-data-duplicate.popup.text': 'Poniżej wylistowane dane trenujące nie zostały dodane, ponieważ istnieją w systemie jako duplikaty dla innych intencji.',
    'train-data-duplicate.table.text': 'Zdanie trenujące',
    'train-data-duplicate.table.intent': 'Intencja',

    'learning.view.intents': 'Intencje',

    'learning.view.intent.trainData.shouldRemove': 'Czy na pewno chcesz usunąć daną trenującą?',
    'learning.view.intent.trainData.shouldRemove.yes': 'Tak',
    'learning.view.intent.trainData.shouldRemove.no': 'Nie',

    'learning.view.globalSynonymsWarning': 'Uwaga! Globalne synonimy aplikują się do wszystkich botów - ostrożnie z dodawaniem i usuwaniem',
    'learning.view.addGlobalSynonym': 'Dodaj',
    'learning.view.globalSynonyms': 'Globalne Synonimy',

    'learning.globalSynonyms.newSynonymConfirmationText': 'Uwaga, zaraz dodasz nowe globalne słowo. Czy potwierdzasz, że chcesz to zrobić?',
    'learning.globalSynonyms.selectPlaceholder': 'Wpisz słowo',
    'learning.globalSynonyms.synonymPlaceholder': 'Wpisz synonim do dodania',

    'learning.globalWord.keyHeader': 'Słowo',
    'learning.globalWord.synonymsHeader': 'Synonimy',
    'learning.globalWord.removeSynonymText': 'Czy potwierdzasz chęć usunięcia synonimu globalnego słowa?',
    'learning.globalWord.removeEntityText': 'Czy potwierdzasz chęć usunięcia globalnego słowa, wraz z jej synonimami?',

    'entity.table.key': 'Klucz',
    'entity.table.values': 'Wartości',

    'names-dictionary.table.key': 'Klucz',
    'names-dictionary.table.values': 'Wartości',
    'names-dictionary.input.key': 'Klucz',
    'names-dictionary.input.label': 'Wartość',
    'names-dictionary.input.category': 'Kategoria',
    'names-dictionary.add.button': 'Dodaj',

    'auth.goBack': 'Wróć do logowania',
    'auth.signIn.header': 'Logowanie',
    'auth.signIn.username': 'Użytkownik',
    'auth.signIn.email': 'Email',
    'auth.signIn.password': 'Hasło',
    'auth.signIn.forgot': 'Przypomnij hasło',
    'auth.signIn.signIn': 'Zaloguj się',
    'auth.signIn.userNotExist': 'Podany użytkownik nie istnieje',
    'auth.signIn.loginDetailsAreIncorrect': 'Wpisane hasło lub email są nieprawidłowe. W razie potrzeby napisz do nas na {mail}',
    'auth.signIn.resetPasswordDescription': 'Na jaki adres email wysłać link do resetu hasła?',
    'auth.signIn.resetPasswordButtonLabel': 'Resetuj hasło',
    'auth.signIn.backToLoginButtonLabel': 'Wróć do logowania',
    'auth.signIn.resetPasswordConfirmationHeader': 'Zmiana hasła',
    'auth.signIn.resetPasswordConfirmationDescription': 'Na podany adres email <b>wysłaliśmy link do zmiany hasła.</b>{linebreak}Przejdź do swojej skrzynki i kliknij w link, żeby zapisać{linebreak}nowe hasło. Sprawdź również <b>folder Spam.</b>',
    'auth.goBack.signIn.setNewPasswordHelp': 'Hasło musi mieć min. 14 znaków, 1 cyfrę, 1 znak specjalny oraz składać się z dużych i małych liter',
    'auth.signIn.setNewPasswordHeader': 'Ustawienie nowego hasła',
    'auth.signIn.registrationTitle': 'Rejestracja',
    'auth.signIn.registration': 'Ustaw hasło do logowania się do platformy',
    'auth.signIn.register': 'Zarejestruj się',
    'auth.signIn.registerTooltip': 'Akceptacja “Warunków korzystania z platformy” jest obowiązkowa w celu korzystania z platformy',
    'auth.signIn.termsOfServiceCheckbox': 'Rejestrując się do platformy Talkie akceptuje {termsOfService} w aktualnym brzmieniu obowiązującym od dnia {date}.',
    'auth.signIn.termsOfService': 'Warunki korzystania z platformy',
    'auth.signIn.termsOfServiceDateFormat': 'DD.MM.YYYY',
    'auth.signIn.setNewPasswordConfirmation': 'Hasło zostało zapisane.{linebreak}Użyj nowego hasła przy logowaniu.',
    'auth.signIn.activationLinkAlreadyUsed': 'Link do aktywacji konta został już użyty.',
    'auth.signIn.activationLinkExpired': 'Ważność linku aktywacyjnego wygasła. Skontaktuj się z nami pisząc na {mail}',
    'auth.signIn.somethingWentWrong': 'Coś poszło nie tak. W razie potrzeby napisz do nas na {mail}',
    'auth.signIn.supportEmail': 'support@talkie.ai',
    'auth.signIn.termsOfServiceLoginReminder': 'Logując się do platformy Talkie akceptujesz {termsOfService} w\xa0aktualnym brzmieniu obowiązującym od dnia {date}.',
    'auth.signIn.termsOfServiceLoginReminder.termsOfService': 'Regulamin platformy',
    'auth.sendCode.header': 'Przypomnij hasło',
    'auth.sendCode.username': 'Użytkownik',
    'auth.sendCode.sendCode': 'Wyślij kod',
    'auth.sendCode.userNotExist': 'Podany użytkownik nie istnieje',
    'auth.resetPassword.header': 'Zresetuj hasło',
    'auth.resetPassword.code': 'Kod',
    'auth.resetPassword.newPassword': 'Nowe hasło',
    'auth.resetPassword.repeatPassword': 'Powtórz hasło',
    'auth.resetPassword.repeatNewPassword': 'Powtórz nowe hasło',
    'auth.resetPassword.codeErrorMessage': 'Niepoprawny kod',
    'auth.resetPassword.newPasswordErrorMessage': 'Hasło musi mieć przynajmniej 6 znaków',
    'auth.resetPassword.button': 'Resetuj',
    'auth.newPassword.header': 'Zmień hasło',
    'auth.newPassword.password': 'Nowe hasło',
    'auth.newPassword.changeButton': 'Zmień',
    'auth.newPassword.error': 'Hasło musi mieć przynajmniej 6 znaków',
    'auth.newPassword.changePasswordLinkExpired': 'Link do zmiany hasła już wygasł. Podaj email pod, który mamy wysłać nowy link',
    'auth.mfaCode.header': 'Weryfikacja dwuetapowa',
    'auth.mfaCode.description': 'Wpisz kod weryfikacyjny otrzymany w wiadomości SMS na numer {phoneNumber}',
    'auth.mfaCode.code': 'Kod z wiadomości SMS',
    'auth.mfaCode.codeLength': 'Kod musi mieć dokładnie 6 znaków',
    'auth.mfaCode.verificationCodeInvalid': 'Wpisany kod weryfikacyjny jest niepoprawny',
    'auth.mfaCode.verifyButton': 'Zweryfikuj',
    'auth.mfaCode.resendCodeDescription': 'Wygenerowanie kodu może chwilę potrwać. Nie otrzymałeś go?',
    'auth.mfaCode.resendCodeLink': 'Wygeneruj nowy kod',
    'auth.mfaCode.resendCodeLinkSuccessToast': 'SMS z kodem weryfikacyjnym został wysłany ponownie na numer {phoneNumber}',
    'auth.mfaCode.resendCodeLinkFailureToast': 'Nie udało się wysłać SMS z kodem weryfikacyjnym na numer {phoneNumber}. Skontaktuj się z nami pisząc na {mail}',

    'auth.errors.complexity': 'Hasło nie spełnia wymagań.{linebreak}Wymagania hasła - zobacz {HelpIcon}.',
    'auth.errors.repeat': 'Wpisane hasła nie są identyczne.',

    'errors.VALUE_REQUIRED': 'Pole nie może być puste',

    'select.search': 'Wyszukaj bota...',
    'select.noResult': 'Brak wyników',

    'no-bots.message_1': 'Nie posiadasz żadnych botów przypisanych do tego konta',
    'no-bots.message_2': 'Powiadom nas o tym pod adresem ',

    'select.placeholder': 'Wybierz...',
    'select.noOptions': 'Brak opcji',

    'survey-creator.command-palette.categories.modules': 'Moduły',
    'survey-creator.command-palette.categories.steps': 'Kroki',
    'survey-creator.command-palette.categories.intents': 'Intencje',
    'survey-creator.command-palette.categories.texts': 'Teksty w krokach',
    'survey-creator.command-palette.categories.urls': 'Adresy URL',
    'survey-creator.command-palette.categories.paths': 'Ścieżki w krokach HTTP',
    'survey-creator.command-palette.categories.actions': 'Akcje',

    'survey-creator.command-palette.actions.copy-bot-id': 'Skopiuj ID bota',
    'survey-creator.command-palette.actions.copy-bot-name': 'Skopiuj nazwę bota',
    'survey-creator.command-palette.actions.call-me': 'Zadzwoń do mnie',
    'survey-creator.command-palette.actions.creator-list-view': 'Zmień widok kreatora - lista',
    'survey-creator.command-palette.actions.creator-visual-view': 'Zmień widok kreatora - edytor wizualny',
    'survey-creator.command-palette.actions.creator-rollback': 'Przywróć',

    'survey-creator.command-palette.footer': 'Wciśnij {enter} aby wybrać, {arrowup}{arrowdown} aby zaznaczyć, {esc} aby zamknąć. {altAction} aby otworzyć w nowej karcie.',

    'survey-creator.history.modal.title': 'Przywróć poprzednią wersję scenariusza',
    'survey-creator.history.modal.cancel': 'Anuluj',
    'survey-creator.history.modal.rollback': 'Przywróć',
    'survey-creator.history.modal.annotate': 'Nazwij',
    'survey-creator.history.modal.changeAnnotation': 'Zmień nazwę',
    'survey-creator.history.modal.save': 'Zapisz',
    'survey-creator.history.modal.snapshotTitle': 'Wersja nadpisana {overwrittenAt} przez {overwrittenBy}',
    'survey-creator.history.modal.emptyState': 'Brak wcześniejszych wersji scenariusza',

    'survey-creator.intentDetails.training': 'Douczanie',
    'survey-creator.intentDetails.reaction': 'Reakcja',
    'survey-creator.intentDetails.entities': 'Encje',
    'survey-creator.intentDetails.reactionType': 'Rodzaj reakcji',

    'survey-creator.intentDetails.entities.addEntity': 'Dodaj encję',
    'survey-creator.intentDetails.entities.addEntityDescription': 'Wybierz encję które powinny być wychwycone w tej intencji. Zapisz je jako zmienne aby użyć ich w krokach w konwersacji.',

    'survey-creator.intentDetails.entities.number': 'numer (NER)',
    'survey-creator.intentDetails.entities.datetime': 'data i czas (NER)',
    'survey-creator.intentDetails.entities.district': 'rejon (NER)',
    'survey-creator.intentDetails.entities.country': 'kraj (NER)',
    'survey-creator.intentDetails.entities.person': 'osoba (NER)',
    'survey-creator.intentDetails.entities.date': 'data (NER)',
    'survey-creator.intentDetails.entities.building_number': 'numer budynku (NER)',
    'survey-creator.intentDetails.entities.street': 'ulica (NER)',
    'survey-creator.intentDetails.entities.time': 'czas (NER)',
    'survey-creator.intentDetails.entities.town': 'miasto (NER)',
    'survey-creator.intentDetails.entities.company': 'firma (NER)',
    'survey-creator.intentDetails.entities.POI': 'POI (NER)',
    'survey-creator.intentDetails.entities.organization': 'organizacja (NER)',
    'survey-creator.intentDetails.entities.location': 'lokalizacja (NER)',
    'survey-creator.intentDetails.entities.gpe': 'gpe (NER)',

    'survey-creator.defaultNamePrefix': 'Pytanie',
    'survey-creator.defaultNamePrefix.redirect': 'Przekierowanie',
    'survey-creator.defaultNamePrefix.numerical': 'Pytanie numeryczne',
    'survey-creator.defaultNamePrefix.word': 'Pytanie słownikowe',
    'survey-creator.defaultNamePrefix.conditional': 'Warunkowe',
    'survey-creator.defaultNamePrefix.datetime': 'Pytanie o datę i godzinę',
    'survey-creator.defaultNamePrefix.date': 'Pytanie o datę',
    'survey-creator.defaultNamePrefix.end': 'Koniec',
    'survey-creator.defaultNamePrefix.go_to_block': 'Przejście do modułu',
    'survey-creator.defaultNamePrefix.open': 'Pytanie otwarte',
    'survey-creator.defaultNamePrefix.spelling': 'Dyktowanie',
    'survey-creator.defaultNamePrefix.send_sms': 'SMS',
    'survey-creator.defaultNamePrefix.send_email': 'E-mail',
    'survey-creator.defaultNamePrefix.http': 'Krok HTTP',
    'survey-creator.defaultNamePrefix.set_variable': 'Ustawianie zmiennej',
    'survey-creator.defaultNamePrefix.statement': 'Wypowiedź',
    'survey-creator.defaultNamePrefix.help_propose': 'Pytane o pomoc',
    'survey-creator.defaultNamePrefix.generic_order': 'Zamówienie',
    'survey-creator.defaultNamePrefix.city_or_community': 'Miasto województwo',
    'survey-creator.defaultNamePrefix.confirmation': 'Pytanie Tak/Nie',
    'survey-creator.defaultNamePrefix.data_collection': 'Raport',
    'survey-creator.defaultNamePrefix.function': 'Funkcja',
    'survey-creator.emptyNamePlaceholder': 'Brak nazwy kroku',
    'survey-creator.cloneLabel': 'kopia',
    'survey-creator.defaultBlockNamePrefix': 'Moduł',
    'survey-creator.cancel': 'Anuluj',
    'survey-creator.save': 'Zapisz zmiany',
    'survey-creator.blocksLabel': 'Moduły',
    'survey-creator.blocksTooltip': `Element składowy bota, składający się z dowolnej \n
   ilości kroków. Możesz zmienić nazwę modułu klikając \n
   szybko dwa razy na tekst. Aby ułożyć je w innej \n
   kolejności przeciągnij w górę lub w dół.`,
    'survey-creator.intentsTooltip': 'Element, który użytkownik może wywołać w dowolnym momencie rozmowy z botem np. by zadać pytanie "Skąd macie mój numer?"',
    'survey-creator.callbacksTooltip': 'Krok, który wysyła informację (SMS lub dane na serwer) po konkretnych wydarzeniach (na końcu rozmowy)',
    'survey-creator.addBlockButtonLabel': 'Moduł lub intencje',
    'survey-creator.addEntityButtonLabel': 'Encja',
    'survey-creator.addBlockItemBlockLabel': 'Moduł',
    'survey-creator.addBlockItemIntentLabel': 'Intencja',
    'survey-creator.addBlockItemCallbackLabel': 'Callback',
    'survey-creator.addBlockItemDescriptionBlock': 'Sekcja scenariusza rozmowy',
    'survey-creator.addBlockItemDescriptionIntent': 'Wypowiedź rozmówcy, która może paść w każdym momencie rozmowy',
    'survey-creator.addBlockItemDescriptionCallback': 'Wysłanie informacji zwrotnej',
    'survey-creator.addBlockItemDescriptionCallbackDisabled': 'Dodano już wszystkie dostępne',
    'survey-creator.addQuestionButton': 'Dodaj krok w module',
    'survey-creator.addCallbackStepButton': 'Dodaj krok',
    'survey-creator.intent.addNewIntentLabel': 'Stwórz nową intencję',
    'survey-creator.intent.placeholder': 'Szukaj wśród dostępnych intencji lub stwórz nową',
    'survey-creator.intentsLabel': 'Intencje',
    'survey-creator.callbacksLabel': 'Callbacki',
    'survey-creator.callbacks.callFinished': 'Rozmowa zakończona',
    'survey-creator.callbacks.voiceMailDetected': 'Wykryta poczta głosowa',
    'survey-creator.callbacks.callNotAnswered': 'Rozmowa nieodebrana',
    'survey-creator.callback.callFinished.emptyState': 'Callback „Rozmowa zakończona” jest specjalnym modułem, który uruchamia się po zakończeniu rozmowy z botem. Może służyć na przykład do asynchronicznego zapisania danych w systemie klienta lub do odłożenia informacji do raportu.',
    'survey-creator.callback.voiceMailDetected.emptyState': 'Callback „Wykryta poczta głosowa” jest specjalnym modułem, który uruchamia się w momencie, gdy bot, dzwoniąc do rozmówcy, wykryje pocztę głosową. Może służyć na przykład do asynchronicznego zapisania danych w systemie klienta lub do odłożenia informacji do raportu. W tym callbacku nie należy używać zmiennych definiowanych w scenariuszu rozmowy, ponieważ nie będą one zawierały żadnych wartości.',
    'survey-creator.callback.callNotAnswered.emptyState': 'Callback „Rozmowa nieodebrana” jest specjalnym modułem, który uruchamia się w momencie, gdy bot nie dodzwoni się do rozmówcy. Może służyć na przykład do asynchronicznego zapisania danych w systemie klienta lub do odłożenia informacji do raportu. W tym callbacku nie należy używać zmiennych definiowanych w scenariuszu rozmowy, ponieważ nie będą one zawierały żadnych wartości.',
    'survey-creator.callbacks.addPlaceholder': 'Wybierz jeden z dostępnych callbacków',
    'survey-creator.textLabel': 'Tekst:',
    'survey-creator.audioLabel': 'Audio:',
    'survey-creator.messageLabel': 'Treść pytania',
    'survey-creator.repeatMessageLabel': 'Treść powtórnego zapytania',
    'survey-creator.repeatMessageLabelAlternative': 'Alternatywna treść powtórnego zapytania',
    'survey-creator.followupMessageLabel': 'Treść powtórnego zapytania',
    'survey-creator.helpNeededMessageLabel': 'Treść pytania o pomoc',
    'survey-creator.fromIntentMessageLabel': 'Treść pytania po powrocie z intencji',
    'survey-creator.statementLabel': 'Treść wypowiedzi',
    'survey-creator.addRepeatMessageButton': 'Alternatywną wersję',
    'survey-creator.messageAudio.upload': 'Wgraj plik',
    'survey-creator.messageAudio.remove': 'Usuń',

    'survey-creator.goTo.titleLabel': 'Reakcja na odpowiedź',
    'survey-creator.goTo.falseLabel': 'Zaprzeczenie',
    'survey-creator.goTo.repeatedLabel': 'Niezrozumienie',
    'survey-creator.goTo.outOfRangeLabel': 'Poza zakresem',
    'survey-creator.goTo.invalidLengthLabel': 'Niepoprawa długość',
    'survey-creator.goTo.falseDescription': 'Ta reakcja uruchomi się, gdy rozmówca odpowie przecząco na zadane pytanie.',
    'survey-creator.goTo.repeatedDescription': 'Ta reakcja uruchomi się, gdy bot nie zrozumie rozmówcy, czyli nie będzie w stanie rozpoznać żadnej ze zdefiniowanej odpowiedzi.',
    'survey-creator.goTo.outOfRangeDescription': 'Ta reakcja uruchomi się, gdy rozmówca poda liczbę poza zdefiniowanym zakresem.',
    'survey-creator.goTo.invalidLengthDescription': 'Ta reakcja uruchomi się, gdy rozmówca poda ciąg liczb spoza zdefiniowanego ograniczenia.',
    'survey-creator.goTo.continueLabel': 'Kontynuacja',
    'survey-creator.goTo.repeatLabel': 'Powtórzenie',
    'survey-creator.goTo.invalidLabel': 'Nieistniejący moduł',

    'survey-creator.possibleQuestionsLabel': 'Intencje',
    'survey-creator.possibleQuestionsDescription': 'Wybierz intencje, które mają być obsłużone w tym kroku w specyficzny sposób.',
    'survey-creator.possibleQuestionsIntent': 'Na intencję:',
    'survey-creator.possibleQuestionsMessageText': 'Tekst:',
    'survey-creator.possibleQuestionsMessageAudio': 'Audio:',
    'survey-creator.numericalQuestions.rangesLabel': 'Zakres liczbowy',
    'addLabel': 'Dodaj',

    'survey-creator.confirmationStepLabel': 'Tak/Nie',
    'survey-creator.confirmationStepTooltip': 'Pytanie z odpowiedzią tak lub nie',
    'survey-creator.wordStepLabel': 'Pytanie słownikowe',
    'survey-creator.wordStepTooltip': 'Pytanie z wieloma odpowiedziami.',
    'survey-creator.numericalStepLabel': 'Numeryczny',
    'survey-creator.numericalStepTooltip': 'Pytanie z odpowiedzią liczbową',
    'survey-creator.datetimeStepLabel': 'Data/Godzina',
    'survey-creator.datetimeStepTooltip': 'Pytanie o datę/godzinę np. umówienie wizyty',
    'survey-creator.dateStepLabel': 'Data',
    'survey-creator.dateStepTooltip': 'Pytanie o datę np. rok urodzenia',
    'survey-creator.endStepLabel': 'Koniec',
    'survey-creator.endStepTooltip': 'Zakończenie rozmowy',
    'survey-creator.conditionalStepLabel': 'Warunkowe',
    'survey-creator.conditionalStepTooltip': 'Warunkowe',
    'survey-creator.openStepLabel': 'Pytanie otwarte',
    'survey-creator.openStepTooltip': 'Pytanie nie mające zdefiniowanej odpowiedzi.',
    'survey-creator.spellingStepLabel': 'Dyktowanie',
    'survey-creator.spellingStepTooltip': 'Pytanie o ciąg znaków',
    'survey-creator.send_smsStepLabel': 'SMS',
    'survey-creator.send_smsStepTooltip': 'Ustawienie i wysłanie SMS',
    'survey-creator.send_emailStepLabel': 'E-mail',
    'survey-creator.send_emailStepTooltip': 'Ustawienie i wysłanie wiadomości e-mail',
    'survey-creator.httpStepLabel': 'HTTP',
    'survey-creator.httpStepTooltip': 'Ustawienie i wysłanie HTTP',
    'survey-creator.set_variableStepLabel': 'Ustaw zmienną',
    'survey-creator.set_variableStepTooltip': 'Definicja i ustawienie zmiennej',
    'survey-creator.functionStepLabel': 'Funkcja',
    'survey-creator.functionStepTooltip': 'Krok wykonujący funkcję',
    'survey-creator.functionStepAddInputArrayButton': 'Dodaj pola',
    'survey-creator.statementStepLabel': 'Wypowiedź',
    'survey-creator.statementStepTooltip': 'Wypowiedź',
    'survey-creator.redirectStepLabel': 'Przekierowanie',
    'survey-creator.redirectStepTooltip': 'Przekierowanie na inny numer np. konsultant',
    'survey-creator.help_proposeStepLabel': 'Pytanie o pomoc',
    'survey-creator.help_proposeStepTooltip': 'Pytanie o pomoc',
    'survey-creator.go_to_blockStepLabel': 'Przejdź do modułu',
    'survey-creator.go_to_blockStepTooltip': 'Przejdź do modułu',
    'survey-creator.data_collectionStepLabel': 'Zbieranie danych',
    'survey-creator.data_collectionStepTooltip': 'Zbieranie danych do raportu',
    'survey-creator.data_collection.column.name': 'Nazwa kolumny',
    'survey-creator.data_collection.column.value': 'Wartości uwzględnione w raporcie',
    'survey-creator.data_collection.addColumnButton': 'Dodaj kolejną kolumnę',
    'survey-creator.data_collection.phone_number': 'Numer telefonu',
    'survey-creator.data_collection.conversation_details': 'Szczegóły rozmowy',
    'survey-creator.data_collection.error.VALUE_REQUIRED': 'Pole nie może być puste',
    'survey-creator.data_collection.error.ITEM_DUPLICATED': 'Nazwy kolumn muszą być unikalne',
    'survey-creator.system_variable.phoneNumber': 'Numer telefonu',
    'survey-creator.system_variable.botPhoneNumber': 'Numer telefonu bota',
    'survey-creator.system_variable.hangUpCause': 'Przyczyna rozłączenia',
    'survey-creator.system_variable.hangUpCause.clientHungUp': 'Klient się rozłączył',
    'survey-creator.system_variable.hangUpCause.botHungUp': 'Bot się rozłączył',
    'survey-creator.system_variable.callDuration': 'Długość rozmowy',
    'survey-creator.system_variable.conversationId': 'ID rozmowy',
    'survey-creator.system_variable.interactionDate': 'Data interakcji',
    'survey-creator.system_variable.followUpInteraction': 'Data następnej interakcji',
    'survey-creator.system_variable.lastUserMessage': 'Ostatnia wiadomość użytkownika',
    'survey-creator.system_variable.transcript': 'Transkrypcja',
    'survey-creator.system_variable.callDirection': 'Kierunek połączenia',
    'survey-creator.system_variable.currentHour': 'Obecna godzina',
    'survey-creator.system_variable.currentDay': 'Obecny dzień',
    'survey-creator.system_variable.currentDay.Monday': 'Poniedziałek',
    'survey-creator.system_variable.currentDay.Tuesday': 'Wtorek',
    'survey-creator.system_variable.currentDay.Wednesday': 'Środa',
    'survey-creator.system_variable.currentDay.Thursday': 'Czwartek',
    'survey-creator.system_variable.currentDay.Friday': 'Piątek',
    'survey-creator.system_variable.currentDay.Saturday': 'Sobota',
    'survey-creator.system_variable.currentDay.Sunday': 'Niedziela',
    'survey-creator.system_variable.currentDate': 'Obecna data',
    'survey-creator.system_variable.callDirection.inbound': 'Przychodzące',
    'survey-creator.system_variable.callDirection.outbound': 'Wychodzące',
    'survey-creator.system_variable.preferredLanguage': 'Preferowany język',
    'survey-creator.system_variable.patientId': 'ID pacjenta',
    'survey-creator.system_variable.visitId': 'ID wizyty',

    'survey-creator.goToBlock.selectLabel': 'Przejdź do modułu',
    'survey-creator.goToBlock.blockPreviewPlaceholder': 'Nie wybrano modułu',
    'survey-creator.goToBlock.notExistingBlockPreviewPlaceholder': 'Nieistniejący moduł',

    'survey-creator.dictionary.label': 'Możliwe odpowiedzi',
    'survey-creator.dictionary.chipsLabel': 'Słownik',
    'survey-creator.dictionary.chipsGlobalLabel': 'Synonimy z bazy',
    'survey-creator.dictionary.chipsGlobalLabel.hide': 'Ukryj',

    'survey-creator.word.answerIdLabel': 'Identyfikator odpowiedzi',
    'survey-creator.word.readingAnswersToggle': 'Odczytywanie możliwych odpowiedzi w kolejności',
    'survey-creator.word.answerMessageLabel': 'Treść do odczytania',
    'survey-creator.word.readAnswerType.PERMUTATE': 'losowej',
    'survey-creator.word.readAnswerType.SEQUENCE': 'ułożenia',

    'survey-creator.word.addAnswerLabel': 'Dodaj odpowiedź',
    'survey-creator.word.addAnswerButton': 'Odpowiedź',
    'survey-creator.word.addAnswerDescription': 'Dodaj nową odpowiedź',

    'survey-creator.word.notPreciseLinkDescription': 'Użyte zostały takie same synonimy w innej odpowiedzi, wymagane jest ustawienie doprecyzowania.',
    'survey-creator.word.notPreciseLink': 'Przejdź do doprecyzowania',

    'survey-creator.word.notPreciseAnswers.answersTitle': 'Idetyfikatory odpowiedzi do doprecyzowania',
    'survey-creator.word.notPreciseAnswer.description': 'Doprecyzowanie służy dopytaniu się rozmówcy, gdy bot nie jest pewny, która z odpowiedzi zawierająca wspólne synonimy jest poprawna.',
    'survey-creator.word.notPreciseAnswer.messageLabel': 'Treść doprecyzowania',
    'survey-creator.word.notPreciseAnswer.repeatMessageLabel': 'Treść powtórnego doprecyzowania',
    'survey-creator.word.notPreciseAnswer.messageAlternativeLabel': 'Alternatywna treść powtórnego doprecyzowania',

    'survey-creator.numerical.addRangeButton': 'Zakres liczbowy',
    'survey-creator.numerical.addRangeDescription': 'Wybierz przedział liczb, które zakwalifikują się jako odpowiedź',
    'survey-creator.numerical.range.label': 'Zakres liczbowy',
    'survey-creator.numerical.range.from': 'Od',
    'survey-creator.numerical.range.to': 'Do',

    'survey-creator.numerical.validation.length': 'Walidacja na długość ciągu cyfr',
    'survey-creator.numerical.validation.min': 'Minimum',
    'survey-creator.numerical.validation.max': 'Maksimum',

    'survey-creator.datetime.dateLabel': 'Data',
    'survey-creator.datetime.timeLabel': 'Godzina',

    'survey-creator.date.forbidDatesInTheFuture': 'Nie akceptuj dat w przyszłości',
    'survey-creator.date.forbidDatesInThePast': 'Nie akceptuj dat w przeszłości',

    'survey-creator.intentDetails.goToModule': 'Przejdź do modułu',
    'survey-creator.reactionTypeSelect.blockOptionLabel': 'Przejście do modułu',
    'survey-creator.reactionTypeSelect.confirmationOptionLabel': 'Pytanie',
    'survey-creator.reactionTypeSelect.wordOptionLabel': 'Pytanie',
    'survey-creator.reactionTypeSelect.statementOptionLabel': 'Wypowiedź',
    'survey-creator.reactionTypeSelect.endOptionLabel': 'Zakończenie',

    'survey-creator.intentDetails.localSentences': 'Zdania lokalne',
    'survey-creator.intentDetails.talkieSentences': 'Zdania z bazy Talkie',
    'survey-creator.intentDetails.enqueuedSentences': 'Zdania w kolejce do douczenia',
    'survey-creator.intentDetails.trainingDataHint': 'Jakie pytania lub stwierdzenia rozmówców uruchamiają tę reakcję? Wpisz je w osobnych linijkach.',
    'survey-creator.intentDetails.addSentences': 'Dodaj zdania',
    'survey-creator.intentDetails.startTraining': 'Doucz bota',
    'survey-creator.intentDetails.duplicateSentencesHeader': 'Frazy, które chcesz duczyć do intencji już istnieją.',
    'survey-creator.intentDetails.duplicateSentencesList': 'Poniżej wymienione frazy nie zostaną dodane:',
    'survey-creator.intentDetails.confirmDuplicates': 'Rozumiem',
    'survey-creator.intentDetails.removeGlobalSentenceText': 'Czy na pewno chcesz usunąć globalną daną trenującą?',

    'survey-creator.dictionaryPlaceholder': 'Podaj synonimy...',
    'survey-creator.dictionary.toggleToLemma': 'Obsługuj wszystkie formy tego słowa',
    'survey-creator.dictionary.toggleToVerbatim': 'Przywróć słowo/frazę',
    'survey-creator.dictionary.deleteSynonym': 'Usuń',

    'survey-creator.tryId': 'Wypróbuj!',

    'survey-creator.condition.eq': 'równe',
    'survey-creator.condition.neq': 'różne od',
    'survey-creator.condition.in': 'jest równa jednej z',
    'survey-creator.condition.lt': 'mniejsze niż',
    'survey-creator.condition.gt': 'większe od',
    'survey-creator.condition.truthy': 'potwierdzenie',
    'survey-creator.condition.falsy': 'zaprzeczenie',
    'survey-creator.condition.exists': 'wypełnione',
    'survey-creator.condition.exists.true': 'tak',
    'survey-creator.condition.exists.false': 'nie',
    'survey-creator.condition.includes': 'zawiera jedno z',
    'survey-creator.condition.includes.chipsPlaceholder': 'Wpisz...',
    'survey-creator.condition.invalid': 'ma niepoprawną wartość',

    'survey-creator.conditionalQuestion.true': 'Tak',
    'survey-creator.conditionalQuestion.false': 'Nie',
    'survey-creator.conditionalQuestion.default': 'Domyślne',

    'survey-creator.conditional.toggleLabel': 'Uruchom krok jeśli zostanie spełniony warunek',
    'survey-creator.conditional.addButtonLabel': 'Dodaj warunek do tej grupy',
    'survey-creator.conditional.conditionLabel': 'Warunek',
    'survey-creator.conditional.addGroupButtonLabel': 'Dodaj kolejną grupę warunków',
    'survey-creator.conditional.rootConnectiveLabel': 'Typ zależności między grupami warunków',
    'survey-creator.conditional.groupConnectiveLabel': 'Typ zależności dla grupy',
    'survey-creator.conditional.connectiveOR': 'Lub',
    'survey-creator.conditional.connectiveAND': 'Oraz',
    'survey-creator.conditional.addDescription': 'Wybierz zmienną, która ma spełniać warunek',
    'survey-creator.conditional.multiSelectPlaceholder': 'Wybierz lub wyszukaj',
    'survey-creator.conditional.selectConditionOperatorPlaceholder': 'Wybierz typ warunku',
    'survey-creator.conditional.selectVariablePlaceholder': 'Wybierz zmienną',
    'survey-creator.conditional.selectOperandPlaceholder': 'Wartość',
    'survey-creator.conditional.messageVariableSelectLabel': 'Wybierz zmienną warunkową',
    'survey-creator.conditional.separatorLabel': 'ORAZ',
    'survey-creator.saveAs.label': 'Zapisz do zmiennej',
    'survey-creator.saveAs.inputLabel': 'Nazwa zmiennej',
    'survey-creator.saveAs.inputDescription': 'Zmienne umożliwiają zbieranie informacji oraz rozgałęzianie scenariuszy przez użycie warunków.',
    'survey-creator.saveAs.changeVariableOptionTooltip': 'Wybierz lub stwórz zmienną',
    'survey-creator.saveAs.renameVariableOptionTooltip': 'Zmień nazwę',
    'survey-creator.saveAs.renameVariableOptionError': 'Zmienna o tej nazwie już istnieje',
    'survey-creator.dtmf.label': 'Rozpoznawanie DTMF',
    'survey-creator.dtmf.inputDescription': 'Zezwól na wprowadzenie cyfr przez klawiaturę telefonu.',
    'survey-creator.dtmf.minDigits': 'Minimalna liczba cyfr',
    'survey-creator.dtmf.maxDigits': 'Maksymalna liczba cyfr',
    'survey-creator.dtmf.digitsTimeout': 'Limit czasu pomiędzy cyframi',
    'survey-creator.dtmf.maxTimeout': 'Całkowity czas oczekiwania',
    'survey-creator.createVariableButton': 'Wybierz lub dodaj nową zmienną',
    'survey-creator.createVariableLabel': 'Dodaj',
    'survey-creator.repeatQuestionLimitLabel': 'Maksymalna liczba powtórzeń pytania',
    'survey-creator.numberOfRepeatQuestionLimitLabel': 'Liczba możliwych powtórzeń zapytania',
    'survey-creator.conditionalMessageLabel': 'Treść warunkowa?',
    'survey-creator.conditionalMessageButtonLabel': 'Treść warunkową',
    'survey-creator.dictionaryMessageToggle': 'Czytana odpowiedź?',
    'survey-creator.readOption': 'Kolejność odpowiedzi',
    'survey-creator.readOptionFIRST': 'Czytana jako pierwsza',
    'survey-creator.readOptionLAST': 'Czytana jako ostatnia',
    'survey-creator.readOptionSEQUENCE': 'Czytana w kolejności ułożenia',
    'survey-creator.readOptionPERMUTATE': 'Czytana w kolejności losowej',
    'survey-creator.readOptionDONT_READ': 'Nieczytana',
    'survey-creator.permuteAnswers': 'Wymieszane odpowiedzi?',
    'survey-creator.wordQuestionAnswersCount': 'Liczba akceptowanych rozpoznanych odpowiedzi',
    'survey-creator.answersCount.min': 'Min. ilość odpowiedzi',
    'survey-creator.answersCount.max': 'Maks. ilość odpowiedzi',
    'survey-creator.ignorableLabel': 'Możliwy brak odpowiedzi',
    'survey-creator.duplexLabel': 'Nasłuchuj rozmówcy podczas całego trwania kroku',
    'survey-creator.duplexStopSpeechLabel': 'Przerwij wypowiedź bota, jeżeli rozmówca odezwie się w jej trakcie',
    'survey-creator.anonymizeAnswerLabel': 'Odpowiedź powinna być zanonimizowana w transkrypcji',
    'survey-creator.waitForRedirectionLabel': 'Oczekuj na przekierowanie',
    'survey-creator.disableInterpretation': 'Wyłącz interpretację odpowiedzi',
    'survey-creator.isPostponeRequest': 'Czy przełożyć rozmowę?',
    'survey-creator.anyIfMultipleAnswersLabel': 'Akceptuj losową odpowiedź w przypadku wielu',
    'survey-creator.acceptAnyAnswerLabel': 'Akceptuj odpowiedzi spoza słownika',
    'survey-creator.useDynamicPhonemizationLabel': 'Akceptuj odpowiedzi bliskobrzmiące',
    'survey-creator.requiredAnswer': 'Odpowiedź na pytanie jest wymagana',
    'survey-creator.requiredAnswerDescription': 'Odpowiedź na pytanie będzie wymagane by uznać, że kontakt został obdzwoniony.',
    'survey-creator.joinNumbers': 'Czy złączyć numery pojawiające się w odpowiedzi?',
    'survey-creator.customStatus': 'Opcjonalne: ustaw status',
    'survey-creator.changeRecognizer': 'System do rozpoznawania mowy',
    'survey-creator.recognizer.default': 'Domyślny',
    'survey-creator.recognizer.google_default': 'Google (Default)',
    'survey-creator.recognizer.google_latest_long': 'Google (Latest Long)',
    'survey-creator.recognizer.google_latest_short': 'Google (Latest Short)',
    'survey-creator.recognizer.google_phone_call': 'Google (Phone Call)',
    'survey-creator.recognizer.google_medical_conversation': 'Google (Medical Conversation)',
    'survey-creator.recognizer.google_telephony': 'Google (Telephony)',
    'survey-creator.recognizer.google_telephony_short': 'Google (Telephony Short)',
    'survey-creator.recognizer.techmo_default': 'Techmo',
    'survey-creator.recognizer.techmo_medical': 'Techmo Medyczny',
    'survey-creator.recognizer.deepgram_nova-2-phonecall': 'Deepgram (Phone Call)',
    'survey-creator.recognizer.deepgram_nova-2-general': 'Deepgram (General)',
    'survey-creator.recognizer.deepgram_nova-2-medical': 'Deepgram (Medical)',
    'survey-creator.changePreSpeechTimeout': 'Długość czasu oczekiwania na rozpoczęcie wypowiedzi (prespeech)',
    'survey-creator.resetPreSpeechTimeout': 'Przywróć domyślną wartość',
    'survey-creator.changePostSpeechTimeout': 'Długość czasu reakcji bota po odpowiedzi rozmówcy',
    'survey-creator.resetPostSpeechTimeout': 'Przywróć domyślną wartość',

    'survey-creator.customStatus.voicemail': 'Poczta głosowa',
    'survey-creator.customStatus.stop_calling': 'Koniec dzwonienia',
    'survey-creator.customStatus.all_required_questions_answered': 'Odpowiedziano na wszystkie wymagane pytania',

    'survey-creator.audio.addRecording': 'Dodaj nagranie',
    'survey-creator.audio.replaceRecording': 'Zmień nagranie',
    'survey-creator.audio.downloadRecording': 'Pobierz nagranie',
    'survey-creator.audio.deleteRecording': 'Usuń nagranie',

    'survey-creator.confirmationDictionaryYES': 'Tak',
    'survey-creator.confirmationDictionaryNO': 'Nie',

    'survey-creator.endLabel': 'Koniec',
    'survey-creator.nextBlockLabel': 'Następny moduł',

    'survey-creator.wordAnswerGoToLabel': 'Reakcja na odpowiedź',
    'survey-creator.ignorableIntentsLabel': 'Ignorowalne intencje',
    'survey-creator.ignorableIntentsDescription': 'Wybierz intencje, które mają być ignorowane tym kroku.',

    'survey-creator.smsStep.message': 'Treść SMS',
    'survey-creator.smsStep.senderNamePlaceholder': 'Podaj nazwę lub pozostaw puste pole, by wyświetlić numer telefonu',
    'survey-creator.smsStep.fromNumber': 'Wyświetlany nadawca wiadomości',
    'survey-creator.smsStep.toNumber': 'Numer odbiorcy (opcjonalnie)',

    'survey-creator.emailStep.to': 'Odbiorca',
    'survey-creator.emailStep.subject': 'Tytuł',
    'survey-creator.emailStep.message': 'Treść wiadomości',

    'survey-creator.httpStep.url': 'URL',
    'survey-creator.httpStep.urlPlaceholder': 'Adres URL do API',
    'survey-creator.httpStep.method': 'Metoda',
    'survey-creator.httpStep.body': 'Treść',
    'survey-creator.httpStep.headersLabel': 'Nagłówki HTTP',
    'survey-creator.httpStep.headerName': 'Nazwa nagłówka',
    'survey-creator.httpStep.headerValue': 'Wartość',
    'survey-creator.httpStep.addHeaderButton': 'Nagłówek',

    'survey-creator.mapping.mappingsLabel': 'Mapowania',
    'survey-creator.mapping.mappingVariable': 'Zmienna',
    'survey-creator.mapping.mappingPath': 'Ścieżka',
    'survey-creator.mapping.mappingPossibleValues': 'Możliwe wartości...',
    'survey-creator.mapping.addMappingButton': 'Mapowanie',
    'survey-creator.mapping.mappingType': 'Typ',

    'survey-creator.setVariableStep.variable': 'Nazwa zmiennej',
    'survey-creator.setVariableStep.value': 'Wartość zmiennej',
    'survey-creator.createVariableDictionaryValue': 'Utwórz nową wartość słownikową',
    'survey-creator.clearVariable': '(Pusta wartość)',
    'survey-creator.setVariable.assignValue.dictionary': 'Przypisz lub stwórz wartość słownikową',
    'survey-creator.setVariable.assignValue.variable': 'Przypisz wartość ze zmiennej',
    'survey-creator.setVariable.assignValue.plainText': 'Przypisz wartość tekstową',

    'survey-creator.redirectStep.redirectTo': 'Przekieruj na numer',
    'survey-creator.redirectStep.redirectFrom': 'Przekieruj z numeru',
    'survey-creator.redirectStep.redirectFromButton': 'Przekieruj z numeru',
    'survey-creator.redirectStep.stopRecording': 'Zatrzymaj nagrywanie po przekierowaniu',
    'survey-creator.redirectStep.redirectTrunk': 'Trunk',
    'survey-creator.redirectStep.redirectType': 'Typ',
    'survey-creator.redirectStep.redirectTypeOptions.dial': 'Dial',
    'survey-creator.redirectStep.redirectTypeOptions.transfer': 'Transfer',
    'survey-creator.redirectStep.redirectTypeOptions.local': 'Local',
    'survey-creator.redirectStep.redirectHeadersLabel': 'Nagłówki SIP',
    'survey-creator.redirectStep.addRedirectHeaderButton': 'Nagłówek',
    'survey-creator.redirectStep.redirectHeaderName': 'Nazwa',
    'survey-creator.redirectStep.redirectHeaderValue': 'Wartość',
    'survey-creator.redirectStep.destination': 'Destination',

    'survey-creator.functionStep.functions.ConcatArraysDescription': 'Łączy dwie tablice, zwraca wynikową tablicę',
    'survey-creator.functionStep.functions.ConcatArraysInputsArrayA': 'Tablica A',
    'survey-creator.functionStep.functions.ConcatArraysInputsArrayADescription': 'Pierwsza tablica do złączenia',
    'survey-creator.functionStep.functions.ConcatArraysInputsArrayB': 'Tablica B',
    'survey-creator.functionStep.functions.ConcatArraysInputsArrayBDescription': 'Druga tablica do złączenia',
    'survey-creator.functionStep.functions.ConcatArraysOutputsResult': 'Wynik',
    'survey-creator.functionStep.functions.ConcatArraysOutputsResultDescription': 'Tablica zawierająca elementy z tablic A i B',

    'survey-creator.functionStep.functions.AssignDescription': 'Przypisuje wartość do innej zmiennej',
    'survey-creator.functionStep.functions.AssignInputsValue': 'Wartość',
    'survey-creator.functionStep.functions.AssignInputsValueDescription': 'Wartość do przypisania do zmiennej',
    'survey-creator.functionStep.functions.AssignOutputsResult': 'Wynik',
    'survey-creator.functionStep.functions.AssignOutputsResultDescription': 'Zmienna do której przypiszemy wartość',

    'survey-creator.functionStep.functions.ArrayFilterDescription': 'Znajdź elementy zawierające określony parametr, zwraca tablicę dopasowanych elementów',
    'survey-creator.functionStep.functions.ArrayFilterInputsArray': 'Tablica',
    'survey-creator.functionStep.functions.ArrayFilterInputsArrayDescription': 'Zawartość tablicy do filtrowania',
    'survey-creator.functionStep.functions.ArrayFilterInputsArrayPlaceholder': '[{contact:{name: "John"}}] lub ${variable}',
    'survey-creator.functionStep.functions.ArrayFilterInputsValue': 'Wartość',
    'survey-creator.functionStep.functions.ArrayFilterInputsValueDescription': 'Wartość do porównania z elementami tablicy',
    'survey-creator.functionStep.functions.ArrayFilterInputsValuePlaceholder': 'John',
    'survey-creator.functionStep.functions.ArrayFilterInputsPath': 'Ścieżka',
    'survey-creator.functionStep.functions.ArrayFilterInputsPathDescription': 'Wskazuje ścieżkę do porównywanej wartości elementu tablicy',
    'survey-creator.functionStep.functions.ArrayFilterInputsPathPlaceholder': 'contact.name',
    'survey-creator.functionStep.functions.ArrayFilterInputsWithRegExp': 'Wyrażenie regularne',
    'survey-creator.functionStep.functions.ArrayFilterInputsWithRegExpDescription': 'Traktuj wartość jako wyrażenie regularne',
    'survey-creator.functionStep.functions.ArrayFilterOutputsResult': 'Wynik',
    'survey-creator.functionStep.functions.ArrayFilterOutputsResultDescription': 'Mapowanie wartości z przefiltrowanej tablicy',

    'survey-creator.functionStep.functions.ArrayFindDescription': 'Znajdź pierwszy element zawierający określony parametr, zwraca 1 element tablicy',
    'survey-creator.functionStep.functions.ArrayFindInputsArray': 'Tablica',
    'survey-creator.functionStep.functions.ArrayFindInputsArrayDescription': 'Zawartość tablicy do wyszukiwania',
    'survey-creator.functionStep.functions.ArrayFindInputsArrayPlaceholder': '[{contact:{name: "John"}}] lub ${variable}',
    'survey-creator.functionStep.functions.ArrayFindInputsValue': 'Wartość',
    'survey-creator.functionStep.functions.ArrayFindInputsValueDescription': 'Wartość do porównania z elementami tablicy',
    'survey-creator.functionStep.functions.ArrayFindInputsValuePlaceholder': 'John',
    'survey-creator.functionStep.functions.ArrayFindInputsPath': 'Ścieżka',
    'survey-creator.functionStep.functions.ArrayFindInputsPathDescription': 'Wskazuje ścieżkę do porównywanej wartości elementu tablicy',
    'survey-creator.functionStep.functions.ArrayFindInputsPathPlaceholder': 'contact.name',
    'survey-creator.functionStep.functions.ArrayFindInputsWithInclude': 'Zawiera się',
    'survey-creator.functionStep.functions.ArrayFindInputsWithIncludeDescription': 'Dopasuj wartość gdy zawiera się w elemencie tablicy',
    'survey-creator.functionStep.functions.ArrayFindOutputsResult': 'Wynik',
    'survey-creator.functionStep.functions.ArrayFindOutputsResultDescription': 'Mapowanie wartości z znalezionego elementu tablicy',

    'survey-creator.functionStep.functions.ArrayNthDescription': 'Zwraca n-ty element tablicy',
    'survey-creator.functionStep.functions.ArrayNthInputsArray': 'Tablica',
    'survey-creator.functionStep.functions.ArrayNthInputsArrayDescription': 'Tablica, z której chcesz wybrać element',
    'survey-creator.functionStep.functions.ArrayNthInputsArrayPlaceholder': '[{contact:{name: "John"}}] lub ${variable}',
    'survey-creator.functionStep.functions.ArrayNthInputsIndex': 'Indeks',
    'survey-creator.functionStep.functions.ArrayNthInputsIndexDescription': 'Indeks elementu, który chcesz wybrać',
    'survey-creator.functionStep.functions.ArrayNthInputsIndexPlaceholder': '0',
    'survey-creator.functionStep.functions.ArrayNthOutputsResult': 'Wynik',
    'survey-creator.functionStep.functions.ArrayNthOutputsResultDescription': 'Mapowanie wartości z wybranego elementu tablicy',

    'survey-creator.functionStep.functions.StringReplaceInputString': 'Napis wejściowy',
    'survey-creator.functionStep.functions.StringReplaceInputWithRegExp': 'Napis wejściowy z RegExp',
    'survey-creator.functionStep.functions.StringReplaceInputSearchValue': 'Wartość wyszukiwana',
    'survey-creator.functionStep.functions.StringReplaceInputReplaceValue': 'Wartość zamiany',
    'survey-creator.functionStep.functions.StringReplaceOutputsResult': 'Wynik',
    'survey-creator.functionStep.functions.StringReplaceDescription': 'Funkcja do zamiany części napisu na nową wartość',
    'survey-creator.functionStep.functions.StringReplaceInputStringDescription': 'Wprowadź oryginalny napis, który chcesz zmodyfikować',
    'survey-creator.functionStep.functions.StringReplaceInputWithRegExpDescription': 'Użyj wyrażenia regularnego do zaawansowanego wyszukiwania i zamiany',
    'survey-creator.functionStep.functions.StringReplaceInputSearchValueDescription': 'Wartość lub wzorzec, który chcesz wyszukać w napisie',
    'survey-creator.functionStep.functions.StringReplaceInputReplaceValueDescription': 'Nowa wartość do zamiany wartości wyszukiwanej',
    'survey-creator.functionStep.functions.StringReplaceOutputDescription': 'Wynikowy napis po zamianie',
    'survey-creator.functionStep.functions.FuzzyMatcherProcessorType': '(opcjonalnie) Typ procesora',
    'survey-creator.functionStep.functions.FuzzyMatcherProcessorTypeDescription': 'Typ procesora AI',
    'survey-creator.functionStep.functions.FuzzyMatcherIdField': '(opcjonalnie) Pole ID',
    'survey-creator.functionStep.functions.FuzzyMatcherIdFieldDescription': 'Pole ID w tablicy',
    'survey-creator.functionStep.functions.FuzzyMatcherIdFieldPlaceholder': 'id',
    'survey-creator.functionStep.functions.FuzzyMatcherParentIdField': '(opcjonalnie) Pole ID rodzica',
    'survey-creator.functionStep.functions.FuzzyMatcherParentIdFieldDescription': 'Pole ID rodzica w tablicy',
    'survey-creator.functionStep.functions.FuzzyMatcherParentIdFieldPlaceholder': 'parent_id',
    'survey-creator.functionStep.functions.FuzzyMatcherCandidatesPreselectionField': '(opcjonalnie) Pole do wstępnej selekcji',
    'survey-creator.functionStep.functions.FuzzyMatcherCandidatesPreselectionFieldDescription': 'Ścieżka do pola w danych używana do wstępnej selekcji',
    'survey-creator.functionStep.functions.FuzzyMatcherCandidatesPreselectionFieldPlaceholder': 'surname',

    'survey-creator.functionStep.functions.FuzzyMatcherDescription': 'Wyszukiwanie przybliżone, które wykorzystuje nasz serwis AI',
    'survey-creator.functionStep.functions.FuzzyMatcherInputsArray': 'Tablica',
    'survey-creator.functionStep.functions.FuzzyMatcherInputsArrayDescription': 'Tablica do przeszukania',
    'survey-creator.functionStep.functions.FuzzyMatcherInputsArrayPlaceholder': '[{"id":1,"name":"Alice","type":"nurse"},{"id":2,"name":"Bob","type":"doctor"}] lub ${variable}',
    'survey-creator.functionStep.functions.FuzzyMatcherMatchingField': 'Pole do porównania',
    'survey-creator.functionStep.functions.FuzzyMatcherMatchingFieldDescription': 'Pole do porównania w tablicy',
    'survey-creator.functionStep.functions.FuzzyMatcherMatchingFieldPlaceholder': 'name',
    'survey-creator.functionStep.functions.FuzzyMatcherTextToMatch': 'Tekst do porównania',
    'survey-creator.functionStep.functions.FuzzyMatcherTextToMatchDescription': 'Tekst do wyszukania w tablicy',
    'survey-creator.functionStep.functions.FuzzyMatcherTextToMatchPlaceholder': 'Alice',
    'survey-creator.functionStep.functions.FuzzyMatcherOutputsResult': 'Wynik',
    'survey-creator.functionStep.functions.FuzzyMatcherOutputsResultDescription': 'Wynik wyszukiwania przybliżonego',

    'survey-creator.functionStep.functions.ArrayTransformDescription': 'Transformuje tablicę obiektów, zwraca tablicę obiektów',
    'survey-creator.functionStep.functions.ArrayTransformInputsArray': 'Tablica do transformacji',
    'survey-creator.functionStep.functions.ArrayTransformInputsArrayDescription': 'Tablica obiektów do transformacji',
    'survey-creator.functionStep.functions.ArrayTransformInputsArrayPlaceholder': '[{"id":1,"name":"Alice","type":"nurse"},{"id":2,"name":"Bob","type":"doctor"}] lub ${variable}',
    'survey-creator.functionStep.functions.ArrayTransformMappingArray': 'Mapowanie',
    'survey-creator.functionStep.functions.ArrayTransformMappingArrayDescription': 'Mapowanie wartości w tablicy',
    'survey-creator.functionStep.functions.ArrayTransformFromPath': 'Ścieżka źródłowa',
    'survey-creator.functionStep.functions.ArrayTransformFromPathDescription': 'Ścieżka do wartości źródłowej',
    'survey-creator.functionStep.functions.ArrayTransformFromPathPlaceholder': 'name',
    'survey-creator.functionStep.functions.ArrayTransformToPath': 'Ścieżka docelowa',
    'survey-creator.functionStep.functions.ArrayTransformToPathDescription': 'Ścieżka do wartości docelowej',
    'survey-creator.functionStep.functions.ArrayTransformToPathPlaceholder': 'details.name',
    'survey-creator.functionStep.functions.ArrayTransformFromAsValue': 'Zapisz jako wartość',
    'survey-creator.functionStep.functions.ArrayTransformFromAsValueDescription': 'Zapisuje wartość zamiast wartości pod daną ścieżką',

    'survey-creator.genericOrder.initialMessage': 'Wiadomość powitalna',
    'survey-creator.genericOrder.productAddedMessage': 'Wiadomość gdy dodany produkt',
    'survey-creator.genericOrder.resignationMessage': 'Wiadomość po rezygnacji z produktu',
    'survey-creator.genericOrder.nameLabel': 'Nazwa',
    'survey-creator.genericOrder.typeLabel': 'Typ',
    'survey-creator.genericOrder.numericPropertyValue': 'Czy numeryczna cecha',
    'survey-creator.genericOrder.propertyValueMessageText': 'Treść wiadomości wartości cechy',
    'survey-creator.genericOrder.synonyms': 'Synonimy',
    'survey-creator.genericOrder.propertyMessage': 'Wiadomość dopytywania o cechę',
    'survey-creator.genericOrder.orderLabel': 'Kolejność',
    'survey-creator.genericOrder.positionLabel': 'Pozycja',
    'survey-creator.genericOrder.textFormattingMessageSingularLabel': 'Tekst dla liczby pojedynczej cechy',
    'survey-creator.genericOrder.textFormattingMessagePluralLabel': 'Tekst dla liczby mnogiej cechy (kończącej się cyframi 2-4)',
    'survey-creator.genericOrder.textFormattingMessagePluralGenitiveLabel': 'Tekst dla liczby mnogiej cechy (kończącej się cyframi 0,5-9)',
    'survey-creator.genericOrder.textFormattingMessageLabel': 'Tekst dla cechy',
    'survey-creator.genericOrder.propertyLabel': 'Cecha',
    'survey-creator.genericOrder.propertyValuesLabel': 'Wartości cechy',
    'survey-creator.genericOrder.optionalProperty': 'Opcjonalna cecha',
    'survey-creator.genericOrder.customMessageProperty': 'Czy spersonalizowana wiadomość dla cechy',
    'survey-creator.genericOrder.propertiesLabel': 'Cechy',
    'survey-creator.genericOrder.propertiesValuesLabel': 'Wartości cechy',
    'survey-creator.genericOrder.textFormattingLabel': 'Formatowanie tekstu',
    'survey-creator.genericOrder.productsLabel': 'Produkty',
    'survey-creator.genericOrder.metadataLabel': 'Metadane',

    'survey-creator.tryIt.label': 'Tel. testowy:',
    'survey-creator.tryIt.placeholder': 'Wpisz numer telefonu',

    'survey-creator.addNewGroup': 'Dodaj grupę',
    'survey-creator.assignToGroupPlaceholder': 'Szukaj wśród dostępnych grup lub stwórz nową',
    'survey-creator.ungroup': 'Czy chcesz rozgrupować grupę?',
    'survey-creator.ungroup.confirm': 'Rozgrupuj',
    'survey-creator.ungroup.cancel': 'Anuluj',
    'survey-creator.removeGroup': 'Czy chcesz usunąć grupę wraz z zawartością?',
    'survey-creator.removeGroup.confirm': 'Usuń grupę',
    'survey-creator.removeGroup.cancel': 'Anuluj',

    'survey-creator.block.description.preview': 'Podgląd',
    'survey-creator.block.description.edit': 'Edycja',
    'survey-creator.addBlockDescription': 'Moduł może zawierać opis, który pomaga zrozumieć jego cel i zastosowanie. Dzięki temu łatwiej odnajdziesz się w strukturze i działaniu bota przed przejściem do szczegółów poszczególnych kroków.',
    'survey-creator.addBlockDescriptionButton': 'Dodaj opis',

    'survey-creator.primary-answers': 'Odpowiedzi główne',
    'survey-creator.associated-answers': 'Odpowiedzi powiązane',
    'survey-creator.entities': 'Encje',
    'survey-creator.addEntitiesDescription': 'Wybierz powiązane encje, które mogą wystąpić w odpowiedzi.',
    'survey-creator.addNewEntityLabel': 'Stwórz nową encję',
    'survey-creator.addNewEntityPlaceholderLabel': 'Wybierz encję',
    'survey-creator.associatedAnswerEntityLabel': 'Encja',
    'survey-creator.associatedAnswerVariable': 'Zmienna',
    'survey-creator.entities-tabs.answers': 'Odpowiedzi',
    'survey-creator.entityCreateError': 'Encja o tej nazwie już istnieje',
    'survey-creator.entityAnswerId': 'Identyfikator odpowiedzi',
    'survey-creator.entitySynonyms': 'Synonimy',
    'survey-creator.entityGlobalSynonyms': 'Synonimy z bazy',
    'survey-creator.associatedAnswerGoToEntity': 'Przejdź do szczegółów encji',
    'survey-creator.associatedAnswerSystemEntity': 'Encja systemowa',
    'survey-creator.associatedAnswerReaction': 'Reakcja',
    'survey-creator.associatedAnswerReaction.noReaction': 'Brak reakcji',
    'survey-creator.associatedAnswerReaction.continue': 'Kontynuacja',
    'survey-creator.fallback': 'Domyślna reakcja na błędy',
    'survey-creator.fallback.emptyState': 'Domyślna reakcja na błędy jest specjalnym modułem, który uruchamia się w momencie wystąpienia nieoczekiwanego błędu podczas realizacji rozmowy – na przykład w przypadku braku wartości zmiennej, błędu w zapytaniu HTTP lub innego problemu technicznego.',
    'survey-creator.silenceFallback': 'Domyślna reakcja na ciszę',
    'survey-creator.silenceFallback.emptyState': 'Domyślną reakcją na pierwszą ciszę jest użycie “Treści powtórnego pytania” z kroku w którym nie wykryto reakcji rozmówcy.{linebreak}W przypadku wykrycia kolejnej ciszy rozmowa kontynuowana jest zgodnie ze scenariuszem. Aby ustawić przekierowanie lub zakończenie rozmowy dodaj krok w tym module.',

    'survey-creator.tabs.scenario': 'Scenariusz',
    'survey-creator.tabs.entities': 'Encje',

    'survey-creator.entities-tabs.answers.addAnswerButton': 'Odpowiedź',
    'survey-creator.entities-tabs.answers.addAnswerDescription': 'Dodaj odpowiedzi, które tworzą tę encję ',

    'survey-creator.specialCharacters.addNewSpecialCharacter.buttonLabel': 'Znak specjalny',
    'survey-creator.specialCharacters.addNewSpecialCharacter.description': 'Wybierz znaki specjalne, które mają być rozpoznane{linebreak}w kroku dyktowanie.',
    'survey-creator.specialCharacters.addNewSpecialCharacter.placeholder': 'Wybierz znaki specjalne, które chcesz rozpoznawać.',
    'survey-creator.specialCharacters.specialCharacterDefinition': 'Definicja znaku specjalnego {character}',
    'survey-creator.specialCharacters.characters.space': '[ spacja ]',

    'survey-creator.executableFunctions.functionNames.ConcatArrays': 'Złącz tablice',
    'survey-creator.executableFunctions.functionNames.Assign': 'Przypisz',
    'survey-creator.executableFunctions.functionNames.ArrayFilter': 'Filtruj tablicę',
    'survey-creator.executableFunctions.functionNames.ArrayFind': 'Znajdź w tablicy',
    'survey-creator.executableFunctions.functionNames.StringReplace': 'Zamień ciąg znaków',
    'survey-creator.executableFunctions.functionNames.ArrayNth': 'Wybierz n-ty element tablicy',
    'survey-creator.executableFunctions.functionNames.FuzzyMatcher': 'Fuzzy Matcher',
    'survey-creator.executableFunctions.functionNames.ArrayTransform': 'Transformuj tablicę',

    'step.tabs.error': 'Brakuje treści pytania w zakładce - {tab}',
    'step.tabs.conditions.error': 'Niedokończona konfiguracja warunków w zakładce - Ustawienia',
    'step.tabs.conditions.variableId.error': 'Wybierz zmienną',
    'step.tabs.conditions.operator.error': 'Wybierz typ warunku',
    'step.tabs.conditions.operand.error': 'Wypełnij wartość warunku',
    'step.tabs.question': 'Pytanie',
    'step.tabs.question.statement': 'Wypowiedź',
    'step.tabs.question.redirect': 'Przekierowanie',
    'step.tabs.question.sms': 'SMS',
    'step.tabs.question.email': 'E-mail',
    'step.tabs.question.end': 'Wypowiedź',
    'step.tabs.answer': 'Odpowiedź',
    'step.tabs.general': 'Ustawienia',
    'step.tabs.intents': 'Intencje',
    'step.tabs.specialCharacters': 'Znaki specjalne',
    'step.name': 'Nazwa kroku',
    'step.name.error.VALUE_REQUIRED': 'Nazwa kroku jest wymagana',

    'survey.message.error': 'Musisz wpisać treść pytania aby przejść dalej',
    'survey.date.message.error': 'Musisz wpisać treść pytania dla daty aby przejść dalej',
    'survey.hour.message.error': 'Musisz wpisać treść pytania dla godziny aby przejść dalej',
    'survey.date.repeatMessages.error': 'Musisz wpisać treść pytania powtórnego dla daty aby przejść dalej',
    'survey.hour.repeatMessages.error': 'Musisz wpisać treść pytania powtórnego dla godziny aby przejść dalej',
    'survey.repeatMessages.error': 'Musisz wpisać treść pytania powtórnego aby przejść dalej',
    'survey.followupMessage.error': 'Musisz wpisać treść pytania powtórnego aby przejść dalej',
    'survey.helpNeededMessage.error': 'Musisz wpisać treść pytania o pomoc aby przejść dalej',
    'survey.repeatMessage': 'Wiadomość na powtórzenie',
    'survey.saveRepeatMessage': 'Zapisz wiadomość na powtórzenie',
    'survey.failureMessage': 'Wiadomość na błąd',
    'survey.saveFailureMessage': 'Zapisz wiadomość na błąd',
    'survey.report.download': 'Pobierz',
    'survey.report.generate': 'Generuj',
    'survey.reports.title': 'Raporty',
    'survey.reportsList.dateLabel': 'Data: ',
    'survey.reportsList.statusLabel': 'Status: ',

    'unsavedChangesModal.text': 'Masz niezapisane zmiany. Czy chcesz je odrzucić?',

    'pillRemoveModal.text': 'Czy chcesz usunąć wybrany element?',

    'questionRemoveModal.text': 'Czy chcesz usunąć wybrane pytanie?',

    'questionMoveModal.text': 'Wybierz moduł, do którego chcesz przenieść ten krok...',

    'confirmationModal.yes': 'Tak',
    'confirmationModal.no': 'Nie',

    'candidates.zone': '',
    'candidates.zone.withDrag': '',
    'candidates.processing': '',
    'candidates.reset': '',
    'candidates.successfulUpload': '',
    'candidates.noCandidates': '',
    'candidates.confirm': '',
    'candidates.confirmForce': '',
    'candidates.unknownError': '',
    'candidates.title': '',
    'candidates.errors': '',
    'candidates.candidates': '',

    'audioPlayer.noRecording': 'Brak nagrania rozmowy',
    'audioPlayer.recordingError': 'Wystąpił błąd, plik audio niedostępny',

    'conversations.reports.close': 'Zakmnij',
    'conversations.reports.reportTitle': '{reportTitle}',
    'conversations.reports.ready': 'Gotowy',
    'conversations.reports.pending': 'W przygotowaniu',
    'conversations.reports.title': 'Raporty',
    'conversations.reports.generate': 'Generuj nowy raport',
    'conversations.reports.reportRequestDisabled': 'Zaznacz zakres dat, aby wygenerować raport',
    'conversations.reports.selectAll': 'Zaznacz wszystko',
    'conversations.reports.deselectAll': 'Odznacz wszystko',
    'conversations.reports.questions': 'Pytania:',
    'conversations.reports.intents': 'Intencje:',
    'conversations.reports.conversationsLimit': 'Raport został ograniczony do pierwszych {conversationsLimit} konwersacji',

    'conversations.filter.allCalls': 'Wszystkie połączenia',
    'conversations.filter.startedMinutes': 'Wykorzystane minuty',
    'conversations.filter.incoming_call': 'Połączenie przychodzące',
    'conversations.filter.call_answered': 'Połączenia wychodzące: odebrane',
    'conversations.filter.call_not_answered': 'Połączenia wychodzące: nieodebrane',
    'conversations.filter.voice_mail_detected': 'Poczta głosowa',
    'conversations.filter.with_duplex': 'Z duplexem',
    'conversations.filter.without_duplex': 'Bez duplexu',
    'conversations.filter.fatal_error_occurred': 'Błąd - Nieoczekiwany błąd',
    'conversations.filter.missing_value_of_variable': 'Błąd - Brakuje wartości zmiennej',
    'conversations.filter.destination_module_is_missing': 'Błąd - Brakujący moduł docelowy',
    'conversations.filter.missing_text_or_recording': 'Błąd - Brakuje tekstu lub nagrania',
    'conversations.filter.http_step_returned_an_error': 'Błąd - Nieprawidłowość w kroku HTTP',
    'conversations.filter.infinite_loop_detected': 'Błąd - Wykryto nieskończoną pętlę',
    'conversations.filter.invalid_condition': 'Błąd - Niepoprawny warunek',
    'conversations.filter.call_redirected': 'Przekierowania',
    'conversations.filter.startedRedirectMinutes': 'Minuty po przekierowaniu',
    'conversations.filter.user_ended_conversation': 'Zakończone przez człowieka',
    'conversations.filter.bot_ended_conversation': 'Zakończone przez bota',
    'conversations.filter.FUNCTION_INPUTS_VALIDATION_ERROR': 'Błąd - Niepoprawne dane wejściowe funkcji',
    'conversations.filter.FUNCTION_DOES_NOT_EXIST': 'Błąd - Funkcja nie istnieje',

    'variables-text-area.add-button': 'Zmienna \$\{ \}',

    'survey-creator.botTester.newChat': 'Nowy czat tekstowy',
    'survey-creator.botTester.suggestions.today': 'Dzisiaj',
    'survey-creator.botTester.suggestions.yesterday': 'Wczoraj',
    'survey-creator.botTester.suggestions.tomorrow': 'Jutro',
    'survey-creator.botTester.suggestions.tomorrowBetween': 'Jutro pomiędzy',
    'survey-creator.botTester.suggestions.at': 'o',
    'survey-creator.botTester.suggestions.and': 'a',
    'survey-creator.botTester.notRecognized': 'Nie rozpoznano odpowiedzi',
    'survey-creator.botTester.intent': 'INTENCJA',
    'survey-creator.botTester.answer': 'ODPOWIEDŹ',
    'survey-creator.botTester.inputPlaceholder': 'Napisz odpowiedź lub wywołaj intencje',
    'survey-creator.botTester.conversationFinished': 'Rozmowa zakończona',

    'menuOption.rename.tooltip': 'Zmień nazwę',
    'menuOption.duplicate.tooltip': 'Sklonuj',
    'menuOption.delete.tooltip': 'Usuń',
    'menuOption.disable.tooltip': 'Dezaktywuj krok',
    'menuOption.enable.tooltip': 'Aktywuj krok',
    'menuOption.move.tooltip': 'Przenieś do',
    'menuOption.start.tooltip': 'Ustaw jako startowy',
    'menuOption.group.tooltip': 'Umieść w grupie',
    'menuOption.removeFromGroup.tooltip': 'Usuń z grupy',
    'menuOption.removeGroup.tooltip': 'Usuń grupę',
    'menuOption.ungroup.tooltip': 'Rozgrupuj',



    'settings.admin.sipHeaders.mappingsLabel': 'Nagłówki SIP',
    'settings.admin.sipHeaders.addMappingButton': 'Nagłówek',
    'settings.admin.sipHeaders.variable': 'Zmienna',
    'settings.admin.sipHeaders.headerName': 'Nazwa nagłówka',
    'settings.admin.sipHeaders.variableType': 'Typ',

    'issues.header': 'Dodawanie notatki',
    'issues.title': 'Tytuł',
    'issues.save': 'Dodaj notatkę',
    'issues.placeholder': 'Dodatkowe informacje',
    'issues.successToast': 'Pomyślnie dodano notatkę',
    'issues.errorToast': 'Nie udało się dodać notatki',

    'reports.table.updatedAt': 'Ostatnia aktualizacja',
    'reports.table.actions': 'Akcje',
    'reports.table.phoneNumber': 'Numer telefonu',
    'reports.table.smsText': 'Tekst SMS',
    'reports.changeStatus.successToast': 'Pomyślnie zmieniono status wyniku',
    'reports.changeStatus.errorToast': 'Nie udało się zmienić statusu wyniku',
    'reports.emptyState.noData': `Nie zebrano jeszcze danych do żadnego raportu.

&nbsp;

Przejdź do [Kreatora]({creatorLink}) **i upewnij się, że skonfigurowałeś przynajmniej jeden krok “Zbieranie danych”**,
a następnie poczekaj aż robot zacznie gromadzić dane.`,
    'reports.emptyState.noDataNoCreator': 'Raporty mogą znacznie ułatwić Twoją pracę. Napisz do nas na adres [kontakt@talkie.ai](mailto:kontakt@talkie.ai) i dowiedz się co możemy Ci zaoferować.',
    'reports.emptyState.noDataForFilters': 'Nie znaleziono wyników dla wybranych filtrów. **Zmień wartości**, aby zobaczyć więcej.',
    'reports.actions.status.selectPlaceholder': 'Status zgłoszenia',
    'reports.actions.status.DEFAULT': 'Nowe',
    'reports.actions.status.COMPLETED': 'Zrealizowane',
    'reports.actions.preview': 'Podgląd konwersacji',
    'reports.filters.search': 'Wyszukaj wpisując np. PESEL, numer telefonu...',

    'reportConfig.tooltip': 'Automatyczna wysyłka raportu',
    'reportConfig.modal.title': 'Ustawienia raportu "{report}"',
    'reportConfig.modal.scheduledReports.title': 'Automatyczna wysyłka raportu',
    'reportConfig.modal.scheduledReports.text': 'Regularna wysyłka raportów odbywa się raz dziennie po północy. Wysyłane są dane zebrane w poprzednim dniu.<br />Otrzymane pliki będą zabezpieczone wybranym hasłem.',
    'reportConfig.modal.scheduledReports.emailLabel': 'Adresy email:',
    'reportConfig.modal.scheduledReports.passwordLabel': 'Hasło do pliku (nie może zawierać polskich znaków):',
    'passwordInput.passwordTooltip.show': 'Pokaż hasło',
    'passwordInput.passwordTooltip.hide': 'Ukryj hasło',
    'reportConfig.modal.scheduledReports.passwordValidation': 'Hasło nie może zawierać polskich znaków. Zmień wybrane hasło.',
    'reportConfig.modal.scheduledReports.emailValidationMessage': 'Adres e-mail jest niepoprawny. Sprawdź, czy zawiera znak @.',
    'reportConfig.modal.notifications.title': 'Notyfikacje',
    'reportConfig.modal.notifications.text': 'Platforma może wyświetlić informację o nowych, zebranych danych. Wskaźnik będzie informował o liczbie niezrealizowanych zgłoszeń w określonym raporcie.',
    'reportConfig.modal.notifications.toggleText': 'Wyświetlaj informację o nowych zgłoszeniach',
    'reportConfig.modal.save': 'Zapisz zmiany',
    'reportConfig.successToast': 'Pomyślnie zmieniono konfigurację raportu',
    'reportConfig.errorToast': 'Nie udało się zmienić konfiguracji raportu',

    'termsOfServiceModal.title': 'Regulamin platformy',
    'termsOfServiceModal.accept': 'Akceptuję',
    'termsOfServiceModal.download': 'Pobierz',
    'termsOfServiceModal.filename': 'Regulamin Talkie.pdf',

    'termsOfServiceModal.markdownFilename.eu-central-1.dev': 'https://talkie-dev-terms-of-service.s3.eu-central-1.amazonaws.com/terms-of-service-pl.md',
    'termsOfServiceModal.markdownFilename.eu-central-1.prd': 'https://talkie-prd-terms-of-service.s3.eu-central-1.amazonaws.com/terms-of-service-pl.md',
    'termsOfServiceModal.markdownFilename.us-east-2.us-prd': 'https://talkie-us-prd-terms-of-service.s3.us-east-2.amazonaws.com/terms-of-service-pl.md',

    'termsOfServiceModal.filePath.eu-central-1.dev': 'https://talkie-dev-terms-of-service.s3.eu-central-1.amazonaws.com/talkie-warunki-swiadczenia-uslug.pdf',
    'termsOfServiceModal.filePath.eu-central-1.prd': 'https://talkie-prd-terms-of-service.s3.eu-central-1.amazonaws.com/talkie-warunki-swiadczenia-uslug.pdf',
    'termsOfServiceModal.filePath.us-east-2.us-prd': 'https://talkie-us-prd-terms-of-service.s3.us-east-2.amazonaws.com/talkie-warunki-swiadczenia-uslug.pdf',

    'tagFilterModal.title': 'Filtrowanie po tagach',
    'tagFilterModal.submitButton': 'Zastosuj',
    'tagFilterModal.cancelButton': 'Anuluj',
    'tagFilterModal.description':
      'Wybierz tagi, po których chcesz odfiltrować boty:',
    'tagFilterModal.tagsToSelectEmpty': 'Kliknij tag poniżej aby wybrać',
};

const enUsMessages: Messages = {
    'timestamp.format': 'MM/DD/YYYY h:mm a',
    'hour.format': 'h:mm a',
    'date.format': 'MM/DD/YYYY',
    'month.format': 'MMM YYYY',
    'datetime.format': 'dddd hh:mm a',
    'date.from': 'Date from',
    'date.to': 'Date to',
    'date.placeholder': 'Select date range...',
    'generalError': 'An error occurred.',
    'close': 'Close',

    'header.help': 'Go to the portal knowledge base',
    'header.user': 'User account management',

    'cancelLabel': 'Cancel',
    'downloadButton.errorToast': 'We could not generate a file to download.<br />Contact us by email: [support@talkie.ai](mailto:support@talkie.ai)',

    'callsList.timestamp': 'Timestamp',
    'callsList.duration': 'Duration',
    'callsList.phoneNumber': 'Phone number',
    'callsList.events': 'Events',
    'callsList.events.hideMore': 'Hide',
    'callsList.recording': 'Recording',
    'callsList.name': 'Username',
    'callsList.done': 'Action',
    'callsList.done-button': 'Done',
    'callsList.smsTranscriptHeader': 'SMS Conversation',
    'callsList.size': 'Results count',
    'callsList.count': '{count, plural, =0 {No results} =1 {# result} other {# results}}',
    'callsList.loading': 'Loading...',
    'callsList.noData': 'No data found. Change filters or refresh the page.',
    'callsList.loadMore': 'Load more',

    'callDetails.emptyState': 'Choose a conversation',
    'callDetails.noTranscript': 'No transcript found for selected conversation',
    'callDetails.noAudio': 'No recording found',
    'callDetails.textChat': 'Test text chat - no recording of the conversation',
    'callDetails.loading': 'Loading...',
    'callDetails.emptyDetails': 'No recording found for selected conversation',
    'callDetails.statementNotRecognized': 'Silence from caller',
    'callDetails.smsTranscript': 'SMS conversation',
    'callDetails.notFound': 'Call not found',
    'callDetails.showSSMLTags': 'Show tags in conversation',
    'callDetails.recognitionSource.dtmf': 'DTMF',
    'conversationMarkCaller.description': 'Mark caller of this conversation as',
    'conversationMarkCaller.human': 'Human',
    'conversationMarkCaller.voiceMail': 'Voice mail',
    'conversationMarkCaller.error': 'An error occurred during marking',
    'conversationMarkCaller.marked.MARKED_AS_HUMAN': 'Marked as human',
    'conversationMarkCaller.marked.MARKED_AS_VOICEMAIL': 'Marked as voice mail',

    'contacts.table.navigate': 'Navigate to conversations',
    'contacts.table.phoneNumber': 'Phone number',
    'contacts.table.status': 'Status',
    'contacts.table.lastInteraction': 'Last interaction',
    'contacts.table.currentInteraction': 'Current interaction',
    'contacts.table.followUpInteraction': 'Follow-Up interaction',
    'contacts.table.interactionsPerformed': 'Interactions performed',
    'contacts.table.importDate': 'Import date',
    'contacts.table.actions': 'Actions',
    'contacts.table.status.ANSWERED': 'Call answered',
    'contacts.table.status.NOT_ANSWERED': 'Not answered',
    'contacts.table.status.VOICEMAIL_DETECTED': 'Voicemail',
    'contacts.filters.clear': 'Clear',
    'contacts.filters.apply': 'Filter',
    'contacts.filters.phone.label': 'Phone number',
    'contacts.filters.status.label': 'Status',
    'contacts.statusFilter.status': 'Call status',
    'contacts.statusFilter.phoneNumber': 'Phone number...',
    'contacts.viewsSelect.contactsList': 'Contact list',
    'contacts.viewsSelect.scheduler': 'Scheduler',
    'contacts.report.buttonLabel': 'Download report',
    'contacts.report.fileName': 'report',
    'contacts.report.noContacts': 'No data to generate a report',
    'contacts.add.buttonLabel': 'Add contacts',
    'contacts.add.buttonLabel.disabled.noSchedule': 'Schedule your activities in the scheduler then return to your contact list to add new contacts',
    'contacts.add.uploadLabel': 'Upload file (.csv or .xlsx)',
    'contacts.add.error.validation': 'Validation error. Check file.',
    'contacts.add.error.schedulerDisabled': 'Error - scheduler is disabled for the bot',
    'contacts.add.error.SHOULD_HAVE_AT_LEAST_ONE_CONTACT': 'The "phoneNumber" column should contain at least one contact. **Add the numbers and reload the file.**',
    'contacts.add.error.SHOULD_HAVE_A_CORRECT_NUMBER_OF_DIGITS': 'The "phoneNumber" column contains numbers with an incorrect number of digits. **Verify the numbers are correct and reload the file**',
    'contacts.add.error.PHONE_NUMBER_FORMAT_IS_INVALID': 'There are invalid characters in the "phoneNumber" column. **Verify the numbers are correct and reload the file.**',
    'contacts.add.error.PHONE_NUMBER_COLUMN_REQUIRED': 'The "phoneNumber" column is missing from the file. **Add the missing column and reload the file.**',
    'contacts.add.error.PHONE_NUMBER_REQUIRED': 'There is an empty cell in the "phoneNumber" column, which may suggest that the sheet is incomplete. **Delete or complete the row and reload the file.**',
    'contacts.add.error.PHONE_NUMBER_COUNTRY_CALLING_CODE_MISMATCH': 'The country code is different from the code in the bot settings. **Verify the correctness of the codes and reload the file.**',
    'contacts.add.error.UNSUPPORTED_FILE_EXTENSION': 'You are trying to add a file in an unsupported format. The platform accepts files in .csv or .xlsx format. **Change the file type and upload again.**',
    'contacts.add.error.UNKNOWN_ERROR': 'Something went wrong. Please **try again later** or contact us by email: [support@talkie.ai](mailto:support@talkie.ai)',
    'contacts.add.title': 'Adding contacts',
    'contacts.add.guidelines': `
The platform accepts files in .csv or .xlsx format. Schedule your activities in the dialer then return to your contact list to add new contacts. A correctly prepared document:
* contains the phone number in the first column of the file labelled "phoneNumber" (this is the only required value)
* contains correctly formatted numbers e.g. 503111222 (only digits, no other characters)
* contains the same variable names in the uploaded file and in bot configuration
* does not contain formulas
    `,
    'contacts.add.footer.text': 'Download template:',
    'contacts.add.body': `
'<span class='{muted}'>'[Read more](https://help.talkie.ai/knowledge-base/dodawanie-pacjentow-do-obdzwonienia/) about adding contacts in our knowledge base.'</span>'

'<span class='{muted}'>'If you want to add contacts using API [please refer to the documentation](https://docs.talkie.ai/).'</span>'
    `,
    'contacts.add.confirm': 'Add file',
    'contacts.add.reload': 'Reload file',
    'contacts.add.finish': 'Close',
    'contacts.add.successBody': `
'<img class="'{icon} {centered}" src="{ToastIcon}" />File *{filename}* has been added

'<span class='{muted}'>'The contacts have been added. We recommend that you check your planner settings before the campaign starts.'</span>'
    `,
    'contacts.add.partiallyFailedBody': `
'<img class="'{icon} {centered}" src="{ToastWarningIcon}" />File *{filename}* has been imported partly successfully
'<span class='{partiallyImportedContact}'>'Contacts imported: **{contactsCreated}**'</span>'
'<span class='{partiallyImportedContact}'>'Contacts not imported due to errors: **{contactsFailed}**'</span>'
'<span class='{muted}'>'We recommend that you check your planner settings before the campaign starts.'</span><br />'
'<span class='{muted}'>'[Read more](https://help.talkie.ai/knowledge-base/how-to-add-a-contacts-list-to-an-outbound-campaign/) about adding contacts in our knowledge base.'</span>'
    `,
    'contacts.add.manualOption.name': 'Add contacts manually',
    'contacts.add.manualOption.description': 'Upload an excel or .csv file',
    'contacts.add.integrationOption.name': 'Contact import management',
    'contacts.add.integrationOption.description': 'Download from the HIS system',
    'contacts.add.integrationModal.cancel': 'Cancel',
    'contacts.add.integrationModal.back': 'Back',
    'contacts.add.integrationModal.next': 'Filter visits',
    'contacts.add.integrationModal.nextDisabledTooltip': 'Pick a date and a condition',
    'contacts.add.integrationModal.confirm': 'Confirm the list of visits',
    'contacts.add.integrationModal.activePlanner.cancel': 'Cancel adding',
    'contacts.add.integrationModal.activePlanner.import': 'Add to call list',
    'contacts.add.integrationModal.inactivePlanner.import': 'Save and run later',
    'contacts.add.integrationModal.inactivePlanner.importAndRun': 'Start the planner',
    'contacts.add.integrationModal.import.successToast.disabledPlanner': 'Successfully imported {count} contacts. Remember to {link}',
    'contacts.add.integrationModal.import.successToast.disabledPlannerLink': 'run the planner to start ringing.',
    'contacts.add.integrationModal.import.successToast.enabledPlanner': 'Successfully imported {count} contacts. {link}',
    'contacts.add.integrationModal.import.successToast.enabledPlannerLink': 'Calls are taking place as scheduled.',
    'contacts.add.integrationModal.import.warningToast.disabledPlanner': 'Successfully imported {successCount} contacts. Remember to {resumePlannerLink} Not imported {failureCount} contacts because of invalid phone number. See the report {notImportedVisitsLink}',
    'contacts.add.integrationModal.import.warningToast.resumePlannerLink': 'run the planner to start ringing.',
    'contacts.add.integrationModal.import.warningToast.enabledPlanner': 'Successfully imported {successCount} contacts. {plannerLink} Not imported {failureCount} contacts because of invalid phone number. See the report {notImportedVisitsLink}',
    'contacts.add.integrationModal.import.warningToast.plannerLink': 'Calls are taking place as scheduled.',
    'contacts.add.integrationModal.import.warningToast.notImportedVisitsLink': '"Not imported visits".',
    'contacts.add.integrationModal.import.warningToast.notImportedVisitsReportName': 'Niezaimportowane%20wizyty',
    'contacts.add.integrationModal.import.errorToast': 'Failed to import contacts. Please try again later or contact us at [support@talkie.ai](mailto:support@talkie.ai)',
    'contacts.add.integrationModal.step1.title':
      'Contact import management | Setting import conditions',
    'contacts.add.integrationModal.step1.instruction':
      'Select which patients to inform of the changes. Choose which patients to inform of the changes. At the next stage, you will be able to exclude individual patients.',
    'contacts.add.integrationModal.step1.date.label': 'Determine the time period',
    'contacts.add.integrationModal.step1.date.placeholder':
      'Patients scheduled on',
    'contacts.add.integrationModal.step1.time.label': 'Appointments',
    'contacts.add.integrationModal.step1.time.labelFrom': 'From',
    'contacts.add.integrationModal.step1.time.labelTo': 'To',
    'contacts.add.integrationModal.step1.condition.label': 'Condition',
    'contacts.add.integrationModal.step1.condition.placeholder': 'Select',
    'contacts.add.integrationModal.step1.conditionValue.label': 'Selected values',
    'contacts.add.integrationModal.step1.conditionOption.DOCTORS': 'Doctor',
    'contacts.add.integrationModal.step1.conditionOption.CLINICS': 'Clinic',
    'contacts.add.integrationModal.step1.conditionOption.SERVICES': 'Service',
    'contacts.add.integrationModal.step1.conditionOption.PRACTICES': 'Practice',
    'contacts.add.integrationModal.step1.addCondition': 'Add condition',
    'contacts.add.integrationModal.step1.preview': 'Import preview',
    'contacts.add.integrationModal.step1.preview.phoneNumber': 'Phone number',
    'contacts.add.integrationModal.step1.preview.patient': 'Patient',
    'contacts.add.integrationModal.step1.preview.visit': 'Visit',
    'contacts.add.integrationModal.step1.preview.doctor': 'Doctor',
    'contacts.add.integrationModal.step1.preview.service': 'Service',
    'contacts.add.integrationModal.step1.preview.localization': 'Localization',
    'contacts.add.integrationModal.step1.preview.practice': 'Practice',
    'contacts.add.integrationModal.step1.preview.emptyState': 'No results found for the selected filter. **Try changing your filters** for more results.',
    'contacts.add.integrationModal.step2.title': 'Contact import management | Exclusion of patients from the call list',
    'contacts.add.integrationModal.step2.description': 'Approve the list of patients to call. You can also exclude individual patients.',
    'contacts.add.integrationModal.step2.query': '{contactsCount, plural, one {# visit} few {List of # visits} many {List of # visits}} from period **{dateTimeFrom} - {dateTimeTo}**',
    'contacts.add.integrationModal.step2.query.doctors': 'doctors',
    'contacts.add.integrationModal.step2.query.clinics': 'clinics',
    'contacts.add.integrationModal.step2.query.services': 'services',
    'contacts.add.integrationModal.step2.excludeContact': 'Exclusion from the call list',
    'contacts.add.integrationModal.step2.excludedContacts': 'Excluded from call list',
    'contacts.add.integrationModal.step3.title': 'Contact import management | Scheduled interactions',
    'contacts.add.integrationModal.step3.description': 'According to the settings of the planner, the following interactions will be performed to',
    'contacts.add.integrationModal.step3.query': '{contactsCount, plural, one {# patient} few {# patients} many {# patients}} from period {dateTimeFrom} - {dateTimeTo}',
    'contacts.add.integrationModal.step3.interactionStep.sms': 'SMS',
    'contacts.add.integrationModal.step3.interactionStep.call': 'Call',
    'contacts.add.integrationModal.step3.interactionStep.http': 'HTTP Request',
    'contacts.add.integrationModal.step3.interactionStep.madeRightAway': 'execute immediately',
    'contacts.add.integrationModal.step3.interactionStep.madeAfterHoursAndMinutes': 'execute after {hours, plural, one {# hours} few {# hours} many {# hours}} and {hours, plural, one {# minute} few {# minutes} many {# minutes}}',
    'contacts.add.integrationModal.step3.interactionStep.madeAfterHours': 'execute after {hours, plural, one {# hours} few {# hours} many {# hours}}',
    'contacts.add.integrationModal.step3.interactionStep.madeAfterMinutes': 'execute after {hours, plural, one {# minute} few {# minutes} many {# minutes}}',
    'contacts.add.integrationModal.step3.interactionStep.madeTodayAt': 'execute today at {time}',
    'contacts.add.integrationModal.step3.interactionStep.madeTomorrowAt': 'execute tomorrow at {time}',
    'contacts.add.integrationModal.step3.interactionStep.madeOnDayAt': 'execute after {days, plural, one {# day} few {# days} many {# days}} at {time}',
    'contacts.cancel.allContactsButtonLabel': 'Cancel multiple contacts',
    'contacts.cancel.allContactsButtonLabelWithCount': 'Cancel multiple contacts ({count})',
    'contacts.cancel.selectedContactsButtonLabel': 'Cancel selected contacts',
    'contacts.cancel.selectedContactsButtonLabelWithCount': 'Cancel selected contacts ({count})',
    'contacts.cancel.noContactsTooltip': 'You don\'t have contacts to cancel',
    'contacts.cancel.multipleContactsModal.header.allContacts': 'Cancelling all contacts',
    'contacts.cancel.multipleContactsModal.header.selectedContacts': 'Cancelling selected contacts',
    'contacts.cancel.multipleContactsModal.message.contacts': 'Are you sure you want to **cancel {count, plural, =1 {# contact} other {# contacts}} ({inProgressCount} in progress and {createdCount} created)?**',
    'contacts.cancel.multipleContactsModal.message.createdContacts': 'Are you sure you want to **cancel {createdCount, plural, =1 {# created contact} other {# created contacts}}?**',
    'contacts.cancel.multipleContactsModal.message.inProgressContacts': 'Are you sure you want to **cancel {inProgressCount, plural, =1 {# in progress contact} other {# in progress contacts}}?**',
    'contacts.cancel.multipleContactsModal.info': 'Cancelling contacts will only make them usable by adding them again.',
    'contacts.cancel.multipleContactsModal.cancelButton': 'Resign',
    'contacts.cancel.multipleContactsModal.confirmButton': 'Cancel contacts',
    'contacts.cancel.multipleContacts.toast.success': '**{count, plural, =1 {# contact} other {# contacts}}** have been cancelled',
    'contacts.cancel.multipleContacts.toast.warning': '**{cancelledCount, plural, =1 {# contact} other {# contacts}}** were successfully cancelled. There **{notCancelledCount, plural, =1 {is # contact} other {are # contacts}}** that could not be cancelled. The bot was not able to start a connection with them.',
    'contacts.cancel.multipleContacts.toast.error': 'Failed to cancel selected contacts. Please **try again later** or contact us by email: [support@talkie.ai](mailto:support@talkie.ai)',
    'contacts.phoneNumberFilterLabel': 'Phone number...',
    'contacts.status.created': 'Scheduled',
    'contacts.status.in_progress': 'In progress',
    'contacts.status.survey_complete': 'Completed with success',
    'contacts.status.survey_incomplete': 'Completed without success',
    'contacts.status.cancelled': 'Cancelled',

    'contacts.interaction.result.delivered': 'Delivered',
    'contacts.interaction.result.undelivered': 'Not delivered',
    'contacts.interaction.result.received': 'Received',

    'contacts.interaction.result.failed': 'Failed',
    'contacts.interaction.result.succeeded': 'Succeeded',

    'contacts.interaction.result.not_answered': 'Not answered',
    'contacts.interaction.result.all_required_questions_answered': 'Finished successfully',
    'contacts.interaction.result.voicemail': 'Voice mail',
    'contacts.interaction.result.missing_required_question': 'Not answered required questions',
    'contacts.interaction.result.postpone_request': 'Postpone call request',
    'contacts.interaction.result.stop_calling': 'Stop calling request',

    'contacts.interaction.status.processed': 'Processed',
    'contacts.interaction.status.pending': 'Pending',
    'contacts.interaction.status.active': 'Active',
    'contacts.interaction.status.failed': 'Failed',
    'contacts.interaction.status.cancelled': 'Cancelled',

    'contacts.interaction.type.call-outbound': 'Call',
    'contacts.interaction.type.call-inbound': 'Call (incoming)',
    'contacts.interaction.type.sms-inbound': 'SMS',
    'contacts.interaction.type.sms-outbound': 'SMS',
    'contacts.interaction.type.http-inbound': 'HTTP',
    'contacts.interaction.type.http-outbound': 'HTTP',

    'contacts.interaction.dateLabel': 'Date: ',
    'contacts.interaction.statusLabel': 'Status: ',
    'contacts.interaction.typeLabel': 'Type: ',
    'contacts.interaction.resultLabel': 'Result: ',
    'contacts.interaction.messageLabel': 'Text SMS: ',

    'contacts.interaction.empty': 'Select contact to see details',

    'contacts.details.cancelContactButtonLabel': 'Cancel contact',
    'contacts.details.cancelContactDescription': 'Only contacts with status created or in progress can be cancelled (except current calls)',
    'contacts.cancel.modal.header': 'Cancelling contact',
    'contacts.cancel.modal.body': `Are you sure you want to **cancel the contact**? 
    
'<span class='{muted}'>'**Canceling contacts** will only make them usable by adding them again.'</span>'`,
    'contacts.cancel.modal.confirm': 'Cancel contact',
    'contacts.cancel.modal.cancel': 'Resign',
    'contacts.cancel.successToast': 'Contact has been **cancelled**',
    'contacts.cancel.errorToast': 'The contact could not be cancelled. **Please try again later.**',

    'contacts.noContacts.importFileExtensions': 'The platform accepts files in .xlsx and .csv formats.',
    'contacts.noContacts.addContactFile': '{addContacts} you want to call.',
    'contacts.noContacts.addContacts': 'Add the contacts',
    'contacts.noContacts.readMoreInKnowledgeBase': '{readMore} about adding contacts in our knowledge base.',
    'contacts.noContacts.readMore': 'Read more',
    'contacts.noContacts.readMoreUrl': 'https://help.talkie.ai/knowledge-base/how-to-add-a-contacts-list-to-an-outbound-campaign/',
    'contacts.noContacts.addContactsViaAPI': 'If you want to add contacts using API please {referToDocs}',
    'contacts.noContacts.referToDocs': 'refer to the documentation.',
    'contacts.noContactsForFilters.contactsNotFound': 'No contacts found for the selected filters. {changeValue} to see more.',
    'contacts.noContactsForFilters.changeValue': 'Change the values',
    'contacts.noContactsNoSchedule.activateScheduler': 'To add contacts for the first time, schedule your activities in the {scheduler}.',
    'contacts.noContactsNoSchedule.scheduler': 'scheduler',
    'contacts.noContactsNoSchedule.nextSteps': 'Set up your next steps and campaign\'s time frame, then return to your contact list.',

    'dashboard.header.search.placeholder': 'Search bot..',
    'dashboard.botsView.addBot.button': 'New bot',
    'dashboard.botsView.manageOrganization.button': 'Manage organization',
    'dashboard.botsView.filterByTags.button': 'Filter by tags',
    'dashboard.botsView.customerInvisibleBots.show': 'Hide bots invisible to customers',
    'dashboard.botsView.customerInvisibleBots.hide': 'Show bots invisible to customers',
    'dashboard.clonePopup.title': 'Cloning bot',
    'dashboard.clonePopup.baseBotName': 'Base bot',
    'dashboard.clonePopup.baseBotLanguage': 'Bot language',
    'dashboard.clonePopup.newBotName': 'Bot name',
    'dashboard.clonePopup.client': 'Client',
    'dashboard.clonePopup.client.placeholder': 'Choose a client',
    'dashboard.clonePopup.aiModel': 'Intent model',
    'dashboard.clonePopup.visibleForClient': 'Bot visible for the client',
    'dashboard.clonePopup.save': 'Create',
    'dashboard.clonePopup.cancel': 'Cancel',
    'dashboard.clonePopup.addNewClient': 'Create client:',
    'dashboard.clonePopup.aiModelOptions.shareModel': 'Share base bot model',
    'dashboard.clonePopup.aiModelOptions.copyModel': 'Copy base bot model',
    'dashboard.clonePopup.aiModelOptions.createNew': 'Create new AI model',
    'dashboard.clonePopup.aiModelOptions.placeholder': 'Choose AI model policy',
    'dashboard.addBotPopup.title': 'Create a bot',
    'dashboard.addBotPopup.newBotName': 'Bot name',
    'dashboard.addBotPopup.newBotLanguage': 'Language',
    'dashboard.addBotPopup.client': 'Client',
    'dashboard.addBotPopup.visibleForClient': 'Bot visible for the client',
    'dashboard.addBotPopup.anonymized': 'Anonymize reports',
    'dashboard.addBotPopup.save': 'Create',
    'dashboard.addBotPopup.cancel': 'Cancel',
    'dashboard.addBotPopup.languages.pl-PL': 'Polish',
    'dashboard.addBotPopup.languages.en-GB': 'English British',
    'dashboard.addBotPopup.languages.en-US': 'English American',
    'dashboard.addBotPopup.languages.en-AU': 'English Australian',
    'dashboard.addBotPopup.languages.hu-HU': 'Hungarian',
    'dashboard.addBotPopup.languages.ru-RU': 'Russian',
    'dashboard.addBotPopup.languages.fr-FR': 'French',
    'dashboard.addBotPopup.language.placeholder': 'Choose language',
    'dashboard.addBotPopup.intentSet': 'Intent\'s database',
    'dashboard.addBotPopup.intentSet.default': 'Global',
    'dashboard.addBotPopup.intentSet.default-medical': 'Global + medical',
    'dashboard.addBotPopup.client.placeholder': 'Choose client',
    'dashboard.addBotPopup.invalidTags': 'Invalid tags',
    'dashboard.editBotPopup.title': 'Edit bot settings',
    'dashboard.editBotPopup.botName': 'Bot name',
    'dashboard.editBotPopup.botLanguage': 'Bot language',
    'dashboard.editBotPopup.client': 'Client',
    'dashboard.editBotPopup.botTags': 'Tags assigned to the bot',
    'dashboard.editBotPopup.botTagsTooltip': 'Tags are used to group bots. You can assign up to 7 tags to a bot.',
    'dashboard.editBotPopup.tooManyTags': 'You can add up to 7 tags',
    'dashboard.editBotPopup.visibleForClient': 'Bot visible for the client',
    'dashboard.editBotPopup.update': 'Save changes',
    'dashboard.editBotPopup.cancel': 'Cancel',
    
    'organization.header.organization': 'Organization: {organizationName}',
    'organization.tabs.sms': 'SMS',
    'organization.sms.smsSettings.heading': 'SMS configuration data {disclaimer}',
    'organization.sms.smsSettings.disclaimer': '(default for all bots in the organization)',
    'organization.sms.additionalBotNumber.heading': 'Additional numbers for individual bots {disclaimer}',
    'organization.sms.additionalBotNumber.disclaimer': '(overwrite default configuration)',
    'organization.sms.providerName': 'Provider',
    'organization.sms.phoneNumber': 'Number for sending SMS or message sender',
    'organization.sms.botName': 'Bot selection',
    'organization.sms.supervoip.login': 'Login',
    'organization.sms.supervoip.password': 'Password',
    'organization.sms.supervoip.apiKey': 'API Key',
    'organization.sms.vonage.apiKey': 'API Key',
    'organization.sms.vonage.apiSecret': 'API Secret',
    'organization.sms.twilio.accountSid': 'Account Sid',
    'organization.sms.twilio.authToken': 'Auth Token',
    'organization.sms.parlance.applicationGuid': 'Application Guid',
    'organization.sms.parlance.apiKey': 'API Key',
    'organization.sms.parlance.baseUrl': 'Base URL',
    'organization.sms.saveChanges': 'Save changes',
    'organization.sms.cancel': 'Cancel',
    'organization.sms.addOverride': 'Assign a dedicated number to a bot',
    'organization.sms.update.successToast': 'Successfully changed SMS configuration',
    'organization.sms.update.errorToast': 'Failed to change SMS configuration',
    'organization.sms.testSms': 'Configuration testing',
    'organization.sms.testSms.tooltip.disabled': 'Save changes to enable testing',
    'organization.sms.testSms.title': 'Configuration testing',
    'organization.sms.testSms.phoneNumber': 'Enter the phone number to test the configuration',
    'organization.sms.testSms.cancel': 'Cancel',
    'organization.sms.testSms.send': 'Send SMS',
    'organization.sms.testSms.success': 'SMS sent successfully',
    'organization.sms.testSms.error': 'Failed to send SMS. Please check the configuration and try again.',
    
    'scheduler.dateOptions.placeholder': 'Select days of the week',
    'scheduler.holidayOptions.text': 'Don\'t call during holidays in',
    'scheduler.holidayOptions.country.selectText': 'Select country',
    'scheduler.holidayOptions.country.Poland': 'Poland',
    'scheduler.holidayOptions.country.USA': 'USA',
    'scheduler.callingDays.text': 'Run campaign on the following days',
    'scheduler.callingDays.Monday': 'Monday',
    'scheduler.callingDays.Tuesday': 'Tuesday',
    'scheduler.callingDays.Wednesday': 'Wednesday',
    'scheduler.callingDays.Thursday': 'Thursday',
    'scheduler.callingDays.Friday': 'Friday',
    'scheduler.callingDays.Saturday': 'Saturday',
    'scheduler.callingDays.Sunday': 'Sunday',
    'scheduler.timeSettings.timezone': 'Select timezone',
    'scheduler.timeSettings.callingHours': 'Run between',
    'scheduler.steps.callStep.type.JustInTime': 'Execute immediately',
    'scheduler.maxConcurrentCallsSettings.enabled': 'Select the number of concurrent calls',
    'scheduler.acceptIncomingCallsSettings.enabled': 'Accept incoming calls for 24 hours after the campaign ends',
    'scheduler.timeZoneLabel': 'Timezone',
    'scheduler.guideBox.stepOne.header': '1 - Set up your campaign’s time frame — select days and working hours.',
    'scheduler.guideBox.stepOne.content': '',
    'scheduler.guideBox.stepTwo.header': '2 - Add steps to create a specific campaign plan.',
    'scheduler.guideBox.stepTwo.content': '',
    'scheduler.guideBox.stepThree.header': '3 - Start the campaign.',
    'scheduler.guideBox.stepThree.content': '',
    'scheduler.steps.callStep.type.FollowUpInGivenHours': 'Execute after a given time',
    'scheduler.steps.callStep.type.FollowUpInGivenDaysAt': 'Execute at a specific time',
    'scheduler.steps.callStep.FollowUpInGivenDaysAt.currentDay': 'Current day',
    'scheduler.steps.callStep.FollowUpInGivenDaysAt.before': 'In',
    'scheduler.steps.callStep.FollowUpInGivenDaysAt.nextDay': 'Next day',
    'scheduler.steps.callStep.FollowUpInGivenDaysAt.multipleDays': 'days',
    'scheduler.steps.titleLabel': 'Step',
    'scheduler.steps.callHeader': 'Call',
    'scheduler.steps.callDescription': 'Make an outgoing call',
    'scheduler.steps.smsHeader': 'SMS',
    'scheduler.steps.smsCollapsed': 'SMS step',
    'scheduler.steps.smsDescription': 'Send a text message',
    'scheduler.steps.httpHeader': 'HTTP',
    'scheduler.steps.httpCollapsed': 'HTTP step',
    'scheduler.steps.httpDescription': 'Communicate with an external system',
    'scheduler.steps.addStep': 'Add step',
    'scheduler.steps.addStepDescription': 'Create a specific campaign plan',
    'scheduler.steps.addCall': 'Call',
    'scheduler.steps.addSms': 'SMS Message',
    'scheduler.steps.addHttp': 'HTTP Request',
    'scheduler.step.callSelect': 'Execute call',
    'scheduler.step.callSelectFirst': 'Execute first call',
    'scheduler.save': 'Save changes',
    'scheduler.cancel': 'Cancel',
    'scheduler.step.numberOfMinutesLabel': 'Minutes',
    'scheduler.step.numberOfHoursLabel': 'Hours',
    'scheduler.step.numberOfDaysLabel': 'at',
    'scheduler.step.callTimeLabel': 'Call hour',
    'scheduler.step.smsTemplateLabel': 'SMS text',
    'scheduler.step.smsSenderNameLabel': 'Sender name',

    'scheduler.step.httpStep.url': 'URL',
    'scheduler.step.httpStep.method': 'Method',
    'scheduler.step.httpStep.headers': 'Headers',
    'scheduler.step.httpStep.body': 'Body',
    'scheduler.step.httpStep.timeout': 'Timeout (limit expected for operation)',
    'scheduler.step.httpStep.resetTimeout': 'Restore default value',

    'scheduler.botStatus.readyToCall': 'Launched, ready to call',
    'scheduler.botStatus.calling': 'Calling',
    'scheduler.botStatus.paused': 'Paused',
    'scheduler.botStatus.noSchedule': 'No dialer configuration',
    'scheduler.botStatus.noScheduleAndPhoneNumber': 'No dialer configuration and phone no',
    'scheduler.botStatus.noPhoneNumber': 'No phone number',
    'scheduler.botStatus.label': 'Bot status:',

    'scheduler.contactStatuses.inQueue': 'Planned contacts:',
    'scheduler.contactStatuses.inProgress': 'Contacts in progress:',
    'scheduler.contactStatuses.called': 'Contacts handled:',

    'scheduler.status.tooltip.inQueue': 'These are the contacts the bot is scheduled to call but hasn\'t initiated calling yet.',
    'scheduler.status.tooltip.inProgress': 'These are the contacts the bot is trying to contact or in the process of contacting again.',
    'scheduler.status.tooltip.called': 'Total calls handled including the calls completed successfully and without success.',

    'scheduler.button.tooltip.noScheduleAndPhoneNumber': 'You need to add a phone number configure the planner to be able to run it.',
    'scheduler.button.tooltip.noPhoneNumber': 'Write to support@talkie.ai to set up a phone number.',
    'scheduler.button.tooltip.noSchedule': 'You need to configure the planner to be able to run it.',

    'scheduler.button.pause': 'Pause',
    'scheduler.button.resume': 'Start',

    'table.next': 'Next',
    'table.previous': 'Previous',
    'table.page': 'Page',
    'table.of': 'of',
    'table.rows': 'rows',

    'conversations.filters.phone.label': 'Phone number...',
    'conversations.filters.name.label': 'Username',
    'conversations.filters.phrase.label': 'Including text...',
    'conversations.filters.filter': 'Filter',
    'conversations.filters.cancel': 'Cancel',
    'conversations.filters.filter.clear': 'Clear',
    'conversations.filters.report': 'Report',
    'conversations.filters.showAll': 'All filters',
    'conversations.filters.events.clear': 'Clear events',
    'conversations.filters.duration.label': '00m 00s - 00m 00s',

    'conversations.options.button': 'Customize conversation preview',
    'conversations.options.modal.title': 'Customize conversation preview',
    'conversations.options.modal.save': 'Save',
    'conversations.options.modal.description': 'Select the items you want to see in the conversation preview',
    'conversations.options.modal.deselectAll': 'Deselect all',
    'conversations.options.modal.selectAll': 'Select all',
    'conversations.options.modal.ssmlTags': 'Tags',
    'conversations.options.modal.module': 'Modules',
    'conversations.options.modal.step_name': 'Step names',
    'conversations.options.modal.http': 'HTTP steps',
    'conversations.options.modal.set_variable': 'Set variable steps',
    'conversations.options.modal.data_collection': 'Data collection steps',
    'conversations.options.modal.function': 'Function steps',
    'conversations.options.modal.send_sms': 'SMS steps',
    'conversations.options.modal.redirect': 'Redirect steps',
    'conversations.options.modal.interpretation': 'Interpretations',
    'conversations.options.modal.dtmf': 'DTMF answers',

    'conversations.details.modal.stepLinkExpired': 'This step, or the module it was in, has been removed.',

    'conversations.variables.modal.stepLink': 'Link to step',
    'conversations.variables.modal.name': 'Name',
    'conversations.variables.modal.value': 'Value',

    'conversations.http.modal.stepLink': 'Link to step',
    'conversations.http.modal.url': 'URL',
    'conversations.http.modal.method': 'Method',
    'conversations.http.modal.headers': 'Headers',
    'conversations.http.modal.requestBody': 'Request body',
    'conversations.http.modal.responseBody': 'Response body',
    
    'conversations.sms.modal.stepLink': 'Link to step',
    'conversations.sms.modal.from': 'From',
    'conversations.sms.modal.to': 'To',
    'conversations.sms.modal.text': 'Text',
    'conversations.sms.modal.provider': 'Provider',
    'conversations.sms.modal.status': 'Status',
    'conversations.sms.modal.errorMessage': 'Error',

    'conversations.redirect.modal.stepLink': 'Link to step',
    'conversations.redirect.modal.to': 'To',
    'conversations.redirect.modal.from': 'From',
    'conversations.redirect.modal.trunk': 'Trunk',
    'conversations.redirect.modal.type': 'Type',
    'conversations.redirect.modal.destination': 'Destination',
    'conversations.redirect.modal.recordingAction': 'Recording action',
    'conversations.redirect.modal.headers': 'SIP headers',

    'conversations.interpretation.entity': 'Entity',
    'conversations.interpretation.intent': 'Intent',
    'conversations.interpretation.translation': 'Translation',
    'conversations.interpretation.modal.interpretationResult': 'Interpretation result',

    'conversations.dataCollection.report': 'Report',
    'conversations.dataCollection.modal.collectedData': 'Collected data',

    'conversations.function.name': 'Function name',
    'conversations.function.inputs': 'Input data',
    'conversations.function.outputs': 'Output data',
    'conversations.function.enabled': 'Enabled',
    'conversations.function.disabled': 'Disabled',

    'filters.date.from': 'From:',
    'filters.date.to': 'to:',
    'filters.date.search': 'Search',

    'statistics.period.day': 'Last 24 hours',
    'statistics.period.week': 'Last 7 days',
    'statistics.period.month': 'Last 30 days',
    'statistics.period.year': 'Last year',
    'statistics.period.custom': 'Custom date range',
    'statistics.period.placeholder': 'Custom date range',
    'statistics.button.report.download': 'Download report',
    'statistics.report.fileName': 'report',
    'statistics.chart.hourly': 'Hourly chart',
    'statistics.chart.daily': 'Daily chart',
    'statistics.chart.monthly': 'Monthly chart',
    'statistics.summary': 'Summary',
    'statistics.results': 'Results',
    'statistics.information': 'Information',
    'statistics.surveyQuestions': 'Scenario',
    'statistics.recognizedIntents': 'Intents',
    'statistics.filters.containingText': 'Including text:',
    'statistics.addons.minutesUsed': 'Minutes used',
    'statistics.addons.tooltip.minutesUsed': 'Total minutes used by the bot (incoming and outgoing)',

    'statistics.dashboard.call_answered': 'Outgoing calls:{linebreak}answered',
    'statistics.dashboard.call_not_answered': 'Outgoing calls:{linebreak}not answered',
    'statistics.dashboard.voice_mail_detected': 'Voice mail',
    'statistics.dashboard.incoming_call': 'Incoming call',
    'statistics.dashboard.allCalls': 'All calls',
    'statistics.dashboard.call_redirected': 'Transfers',
    'statistics.dashboard.average_call_duration': 'Average conversation{linebreak}duration',
    'statistics.dashboard.unique_callers_count': 'Number of unique{linebreak}callers',
    'statistics.dashboard.tooltip.call_answered': 'Number of calls initiated by the bot in which a conversation with a customer was established',
    'statistics.dashboard.tooltip.call_not_answered': 'Number of calls initiated by the bot in which a conversation with a customer wasn\'t established',
    'statistics.dashboard.tooltip.voice_mail_detected': 'Number of calls initiated by the bot in which the call was answered by client\'s voicemail service',
    'statistics.dashboard.tooltip.incoming_call': 'Number of calls initiated by the client that were answered by the bot',
    'statistics.dashboard.tooltip.allCalls': 'How many calls were handled in total',
    'statistics.dashboard.tooltip.call_redirected': 'Number of calls redirected to another number, e.g. to a consultant',
    'statistics.dashboard.tooltip.average_call_duration': 'Average conversation duration',
    'statistics.dashboard.tooltip.unique_callers_count': 'Number of unique callers',

    'statistics.scenarioStepsDashboard.savingChangesToastError': 'The changes could not be saved. **Please try again later.**',
    'statistics.scenarioStepsDashboard.pinnedSteps': 'Pinned steps',
    'statistics.scenarioStepsDashboard.remainingSteps': 'Remaining steps',
    'statistics.scenarioStepsDashboard.allSteps': 'All steps',
    'statistics.scenarioStepsDashboard.pinAllSteps': 'Pin all steps',
    'statistics.scenarioStepsDashboard.unpinAllSteps': 'Unpin all pinned steps',

    'statistics.scenarioStepsDashboard.userHelp.someStepsPinned.description': 'There may be more steps in your scenario than you see.',
    'statistics.scenarioStepsDashboard.userHelp.someStepsPinned.callToAction': 'Click "Edit View" to see all available steps.',

    'statistics.scenarioStepsDashboard.userHelp.allStepsPinned.description': 'Customize your statistics reports.',
    'statistics.scenarioStepsDashboard.userHelp.allStepsPinned.callToAction': 'Click "Edit view" to pin only your most important voicebot steps.',

    'statistics.scenarioStepsDashboard.tooltip.pin': 'Pin it',
    'statistics.scenarioStepsDashboard.tooltip.unpin': 'Unpin',

    'statistics.scenarioStepsDashboard.emptyState.header': 'Customize your statistics to give the most value',
    'statistics.scenarioStepsDashboard.emptyState.description': 'Bots can have many steps, but sometimes only some of them matter to us.',
    'statistics.scenarioStepsDashboard.emptyState.callToAction': 'Go to settings view and select the most important steps.',
    'statistics.scenarioStepsDashboard.emptyState.editView': 'Edit view',

    'statistics.scenarioStepsDashboard.navbar.editView': 'Edit view',
    'statistics.scenarioStepsDashboard.navbar.cancel': 'Cancel',
    'statistics.scenarioStepsDashboard.navbar.saveChanges': 'Save changes',
    'statistics.scenarioStepsDashboard.navbar.pinDescription': 'Pin voicebot steps from the scenario to see them in the statistics report.',

    'header.conversations': 'Conversations',
    'header.done-conversations': 'Finished',
    'header.live-conversations': 'Live',
    'header.reports': 'Reports',
    'header.statistics': 'Statistics',
    'header.summary': 'Summary',
    'header.learning': 'Learning',
    'header.settings': 'Settings',
    'header.logout': 'Log out',
    'header.termsOfService': 'Terms of service',
    'header.changePassword': 'Change password',
    'header.inspector': 'Inspector',
    'header.survey-creator': 'Designer',
    'header.contacts': 'Dialer',
    'header.candidates': 'Candidates',

    'header.changePasswordModal.formHeader': 'Change password',
    'header.changePasswordModal.confirmationHeader': 'Your password has been changed.',
    'header.changePasswordModal.submit': 'Change password & login',
    'header.changePasswordModal.cancel': 'Cancel',
    'header.changePasswordModal.oldPasswordLabel': 'Old password',
    'header.changePasswordModal.newPasswordLabel': 'New password',
    'header.changePasswordModal.repeatNewPasswordLabel': 'Repeat new password',
    'header.changePasswordModal.confirmationDescription': 'Your password has been changed successfully. You will be logged in using the new password in a moment.',

    'survey.externalVariables.dictionary': 'Variables...',
    'survey.externalVariables.save': 'Save variables',
    'survey.triggerCalls': 'Start calling',
    'survey.triggerSkippedCalls': 'Start skipped calls',
    'survey.config.tabs.general': 'General settings',
    'survey.config.tabs.phone': 'Bot phone numbers',
    'survey.config.tabs.admin': 'Variables and SIP headers',
    'survey.config.tabs.api': 'Bot identification data (API)',
    'survey.config.tabs.api.docsButtonLabel': 'Open API documentation',
    'survey.config.tabs.api.show': 'Show',
    'survey.config.tabs.api.copy': 'Copy',
    'survey.config.tabs.api.copied': 'Copied!',
    'survey.config.returnCallToggleLabel': 'Return calls?',
    'survey.config.returnCallMaxCallsLabel': 'Return calls count',
    'survey.config.returnCallIntervalLabel': 'Return call interval (min)',
    'survey.config.phoneConfigs': 'Phones configuration',
    'survey.config.addPhone': 'Add',
    'survey.config.countryCallingCode': 'Country calling code',
    'survey.config.countryCallingCodeNote': 'Country calling code is valid for bot\'s own numbers and contacts.',
    'survey.config.addNumber': 'Add a number',
    'survey.config.phoneNumber': 'Phone number',
    'survey.config.serverAddress': 'Server address',
    'survey.config.anonymized': 'Anonymize personal data in reports',
    'survey.config.timeZone': 'Time zone in which the bot operates',
    'survey.config.admin.addVariable': 'Variable',
    'survey.config.admin.addVariable.title.contact': 'Contact variables',
    'survey.config.admin.addVariable.title.external': 'SIP variables',
    'survey.config.voiceConfig.label': 'Voice configuration',
    'survey.config.voiceConfig.language': 'Language',
    'survey.config.voiceConfig.defaultContent': 'By changing the voice you can select the one your bot will use. Enter various text to see how it sounds in specific cases.',
    'survey.config.voiceConfig.voiceName': 'Voice name',
    'survey.config.voiceConfig.googleLink': 'https://cloud.google.com/text-to-speech/docs/voices',
    'survey.config.voiceConfig.googleLinkText': 'Read more about supported voices and languages',
    'survey.config.voiceConfig.speed': 'Voice tempo',
    'survey.config.voiceConfig.pitch': 'Voice pitch',
    'survey.config.voiceConfig.voiceTestContent': 'Test content',
    'survey.config.voiceConfig.voiceTest': 'Test the voice',
    'survey.config.api.username': 'Username',
    'survey.config.api.password': 'Password',
    'survey.config.api.botId': 'Bot ID',
    'survey.config.save': 'Save',

    'inspector.filters.phrase': 'Including text...',
    'inspector.filters.withoutAnswers': 'Answer not recognized',
    'inspector.filters.withoutIntents': 'Intent not recognized',
    'inspector.filters.question': 'Question',
    'inspector.filters.intent': 'Intent',
    'inspector.filters.question.tooltip': 'View all answers to a selected question',
    'inspector.filters.intent.tooltip': 'View statements that were interpreted as a selected intent',
    'inspector.text': 'User transcript',
    'inspector.recognizedIntent': 'Recognized intent',
    'inspector.recognizedAnswer': 'Recognized answer',
    'inspector.occurrences': 'Occurrences',
    'inspector.stepName': 'Step name',
    'inspector.wordQuestionValue': 'Correct value',
    'inspector.actions.goToConversation': 'Navigate to conversation',
    'inspector.actions.remove': 'Remove',
    'inspector.train': 'Train',
    'inspector.train.sentencesLabel': 'Selected sentences',
    'inspector.train.addToLabel': 'Add to',
    'inspector.train.addToStep': 'Step',
    'inspector.train.addToIntent': 'Intent',
    'inspector.train.answerInLabel': 'Answers within',
    'inspector.train.intentLabel': 'Intent',
    'inspector.train.scopeLabel': 'Training scope',
    'inspector.train.scopeLocalOption': 'Local database',
    'inspector.train.scopeGlobalOption': 'Global database',
    'inspector.train.header': 'Adding new definitions',

    'learning.entityName': 'Entity name',
    'learning.value': 'Value',
    'learning.learn': 'Train',
    'learning.addIntent': 'Add sentence',
    'learning.learn.dev': 'Train dev',
    'learning.learn.prod': 'Train',
    'learning.trainingStatus.dev': 'Training dev status:',
    'learning.trainingStatus.prod': 'Training status:',
    'learning.trainingStatus.IDLE': 'Ready',
    'learning.trainingStatus.TRAINING': 'Training in progress',
    'learning.trainingStatus.DISABLED': 'Unknown',
    'learning.trainingStatus.ERROR': 'Please try again',
    'learning.interpretation': 'Interpretation',
    'learning.evaluate.dev': 'Evaluate Dev',
    'learning.evaluate.prod': 'Evaluate Prod',
    'learning.evaluate.accuracy': 'Accuracy',
    'learning.globalIntents': 'Global intents',
    'learning.multipleSentences': 'Add multiple',
    'learning.cancel': 'Cancel',
    'learning.intentTraining.intent': 'Intent',
    'learning.intentTraining.error': 'Error parsing intent',
    'learning.intentTraining.statusLabel': 'Bot status:',
    'learning.intentTraining.trainingBotButton': 'Train bot',
    'learning.intentTraining.intentSelect.placeholder': 'Select an intent',
    'learning.intentTraining.addUtterance': 'Add utterance',
    'learning.intentTraining.addMultiple': 'Add multiple',
    'learning.intentTraining.intentInput.placeholder': 'Enter a statement to check/add...',
    'learning.intentTraining.recognizedIntentLabel': 'Recognized intent',
    'learning.intentTraining.confidenceLabel': 'Match',
    'wrong-sentences.table.text': 'Text',
    'wrong-sentences.table.intent': 'Expected',
    'wrong-sentences.table.predicted': 'Predicted',
    'wrong-sentences.table.confidence': 'Confidence',
    'train-data-duplicate.popup.text': 'Sentences listed below were not added to train data. Those sentences already exist for other intents.',
    'train-data-duplicate.table.text': 'Train sentence',
    'train-data-duplicate.table.intent': 'Intent',

    'learning.view.intents': 'Intents',

    'learning.view.intent.trainData.shouldRemove': 'Do you really want to delete following train data?',
    'learning.view.intent.trainData.shouldRemove.yes': 'Yes',
    'learning.view.intent.trainData.shouldRemove.no': 'No',

    'learning.view.globalSynonymsWarning': 'Warning! Global entities are applied to all bots - be careful when adding and removing',
    'learning.view.addGlobalSynonym': 'Add',
    'learning.view.globalSynonyms': 'Global Entities',

    'learning.globalSynonyms.newSynonymConfirmationText': 'Warning, you are about to add new entity. Do you confirm?',
    'learning.globalSynonyms.selectPlaceholder': 'Select word',
    'learning.globalSynonyms.synonymPlaceholder': 'Type synonym to add',

    'learning.globalWord.keyHeader': 'Entity',
    'learning.globalWord.synonymsHeader': 'Synonyms',
    'learning.globalWord.removeSynonymText': 'Do you really want to remove global entity synonym?',
    'learning.globalWord.removeEntityText': 'Do you really want to remove global entity and associated synonyms?',

    'entity.table.key': 'Key',
    'entity.table.values': 'Values',

    'names-dictionary.table.key': 'Key',
    'names-dictionary.table.values': 'Values',
    'names-dictionary.input.key': 'Key',
    'names-dictionary.input.label': 'Label',
    'names-dictionary.input.category': 'Category',
    'names-dictionary.add.button': 'Add',

    'auth.goBack': 'Back to Sign in',
    'auth.signIn.header': 'Log in',
    'auth.signIn.username': 'Username',
    'auth.signIn.email': 'Email',
    'auth.signIn.password': 'Password',
    'auth.signIn.forgot': 'Forgot password',
    'auth.signIn.signIn': 'Sign in',
    'auth.signIn.userNotExist': 'This user does not exist',
    'auth.signIn.loginDetailsAreIncorrect': 'Your email or password is incorrect. For help logging in, email us at {mail}',
    'auth.signIn.resetPasswordDescription': 'Where should we send the password reset link?',
    'auth.signIn.resetPasswordButtonLabel': 'Reset password',
    'auth.signIn.backToLoginButtonLabel': 'Back to log in',
    'auth.signIn.resetPasswordConfirmationHeader': 'Change password',
    'auth.signIn.resetPasswordConfirmationDescription': '<b>We\'ve sent a message with a password reset link</b> to this email address.{linebreak}Please navigate to your mailbox and follow the link to{linebreak}change your password. Also check your <b>Spam folder.</b>',
    'auth.goBack.signIn.setNewPasswordHelp': 'Password must have at least 14 characters, 1 number, 1 special character and consist of lower and uppercase letters',
    'auth.signIn.setNewPasswordHeader': 'Set a new password',
    'auth.signIn.registrationTitle': 'Registration',
    'auth.signIn.registration': 'Set your password to log in to the platform',
    'auth.signIn.register': 'Register',
    'auth.signIn.registerTooltip': 'Acceptance of the "Terms of Use” is mandatory in order to use the platform',
    'auth.signIn.termsOfServiceCheckbox': 'By registering for the Talkie platform, the user accepts the {termsOfService} of the platform in the current wording in effect as of {date}.',
    'auth.signIn.termsOfService': 'Terms of Use',
    'auth.signIn.termsOfServiceDateFormat': 'MM.DD.YYYY',
    'auth.signIn.setNewPasswordConfirmation': 'Your new password has been saved.{linebreak}Please use it to log in.',
    'auth.signIn.activationLinkAlreadyUsed': 'This activation link has already been used.',
    'auth.signIn.activationLinkExpired': 'Your activation link has expired. For further assistance, contact us by email: {mail}',
    'auth.signIn.somethingWentWrong': 'Oops! Something went wrong. For further assistance, contact us by email: {mail}',
    'auth.signIn.supportEmail': 'support@talkie.ai',
    'auth.signIn.termsOfServiceLoginReminder': 'By logging into the platform the user accepts the {termsOfService} of the platform in the current wording in effect as of {date}.',
    'auth.signIn.termsOfServiceLoginReminder.termsOfService': 'Terms and Conditions',
    'auth.sendCode.header': 'Forgot password',
    'auth.sendCode.username': 'Username',
    'auth.sendCode.sendCode': 'Send code',
    'auth.sendCode.userNotExist': 'This user does not exist',
    'auth.resetPassword.header': 'Reset password',
    'auth.resetPassword.code': 'Code',
    'auth.resetPassword.newPassword': 'New password',
    'auth.resetPassword.repeatPassword': 'Repeat the password',
    'auth.resetPassword.repeatNewPassword': 'Repeat new password',
    'auth.resetPassword.codeErrorMessage': 'Incorrect code',
    'auth.resetPassword.newPasswordErrorMessage': 'Password has to contain at least 6 characters',
    'auth.resetPassword.button': 'Reset',
    'auth.newPassword.header': 'Change password',
    'auth.newPassword.password': 'New password',
    'auth.newPassword.changeButton': 'Change',
    'auth.newPassword.error': 'Password has to contain at least 6 characters',
    'auth.newPassword.changePasswordLinkExpired': 'The link you have used has expired, please let us know where to send a new link.',
    'auth.mfaCode.header': 'Two factor authentication',
    'auth.mfaCode.description': 'Please confirm your account by entering the verification SMS code sent to {phoneNumber}',
    'auth.mfaCode.code': 'Code from SMS',
    'auth.mfaCode.codeLength': 'Code must be exactly 6 characters',
    'auth.mfaCode.verificationCodeInvalid': 'Provided verification code is incorrect',
    'auth.mfaCode.verifyButton': 'Verify',
    'auth.mfaCode.resendCodeDescription': 'It may take a minute to receive your code. Haven\'t received it?',
    'auth.mfaCode.resendCodeLink': 'Resend a new code',
    'auth.mfaCode.resendCodeLinkSuccessToast': 'SMS with verification code has been sent again to {phoneNumber}',
    'auth.mfaCode.resendCodeLinkFailureToast': 'The SMS with the verification code to {phoneNumber} could not be sent. For further assistance, contact us by email: {mail}',

    'auth.errors.complexity': 'Password does not meet requirements.{linebreak}See {HelpIcon} for password help.',
    'auth.errors.repeat': 'Passwords do not match.',

    'errors.VALUE_REQUIRED': 'Field can not be empty',

    'select.search': 'Search bots...',
    'select.noResult': 'No results',

    'no-bots.message_1': `You don't have any bots assigned to this account`,
    'no-bots.message_2': `Please contact us at `,

    'select.placeholder': 'Select...',
    'select.noOptions': 'No Options',

    'survey-creator.command-palette.categories.modules': 'Modules',
    'survey-creator.command-palette.categories.steps': 'Steps',
    'survey-creator.command-palette.categories.intents': 'Intents',
    'survey-creator.command-palette.categories.texts': 'Messages',
    'survey-creator.command-palette.categories.urls': 'URLs',
    'survey-creator.command-palette.categories.paths': 'Paths in HTTP steps',
    'survey-creator.command-palette.categories.actions': 'Actions',

    'survey-creator.command-palette.actions.copy-bot-id': 'Copy bot ID',
    'survey-creator.command-palette.actions.copy-bot-name': 'Copy bot name',
    'survey-creator.command-palette.actions.call-me': 'Call me',
    'survey-creator.command-palette.actions.creator-list-view': 'Change creator view - list',
    'survey-creator.command-palette.actions.creator-visual-view': 'Change creator view - visual editor',
    'survey-creator.command-palette.actions.creator-rollback': 'Rollback',

    'survey-creator.command-palette.footer': 'Press {enter} to select, {arrowup}{arrowdown} to navigate, {esc} to close. {altAction} to open in a new tab.',

    'survey-creator.history.modal.title': 'Rollback a previous version of the scenario',
    'survey-creator.history.modal.cancel': 'Cancel',
    'survey-creator.history.modal.rollback': 'Rollback',
    'survey-creator.history.modal.annotate': 'Name',
    'survey-creator.history.modal.changeAnnotation': 'Change name',
    'survey-creator.history.modal.save': 'Save',
    'survey-creator.history.modal.snapshotTitle': 'Version overwritten {overwrittenAt} by {overwrittenBy}',
    'survey-creator.history.modal.emptyState': 'No previous versions of the scenario',

    'survey-creator.intentDetails.training': 'Learning',
    'survey-creator.intentDetails.reaction': 'Reaction',
    'survey-creator.intentDetails.entities': 'Entities',
    'survey-creator.intentDetails.reactionType': 'Reaction type',

    'survey-creator.intentDetails.entities.addEntity': 'Add entity',
    'survey-creator.intentDetails.entities.addEntityDescription': 'Select entities which should be picked up in this intent. Save them as a variable so they can be used in next steps of the conversation.',

    'survey-creator.intentDetails.entities.number': 'number (NER)',
    'survey-creator.intentDetails.entities.datetime': 'date and time (NER)',
    'survey-creator.intentDetails.entities.district': 'district (NER)',
    'survey-creator.intentDetails.entities.country': 'country (NER)',
    'survey-creator.intentDetails.entities.person': 'person (NER)',
    'survey-creator.intentDetails.entities.date': 'date (NER)',
    'survey-creator.intentDetails.entities.building_number': 'building number (NER)',
    'survey-creator.intentDetails.entities.street': 'street (NER)',
    'survey-creator.intentDetails.entities.time': 'time (NER)',
    'survey-creator.intentDetails.entities.town': 'town (NER)',
    'survey-creator.intentDetails.entities.company': 'company (NER)',
    'survey-creator.intentDetails.entities.POI': 'POI (NER)',
    'survey-creator.intentDetails.entities.organization': 'organization (NER)',
    'survey-creator.intentDetails.entities.location': 'location (NER)',
    'survey-creator.intentDetails.entities.gpe': 'gpe (NER)',

    'survey-creator.defaultNamePrefix': 'Question',
    'survey-creator.defaultNamePrefix.redirect': 'Redirect',
    'survey-creator.defaultNamePrefix.numerical': 'Numerical question',
    'survey-creator.defaultNamePrefix.word': 'Word question',
    'survey-creator.defaultNamePrefix.conditional': 'Conditional',
    'survey-creator.defaultNamePrefix.datetime': 'Date and hour question',
    'survey-creator.defaultNamePrefix.date': 'Date question',
    'survey-creator.defaultNamePrefix.end': 'End',
    'survey-creator.defaultNamePrefix.go_to_block': 'Go to module',
    'survey-creator.defaultNamePrefix.open': 'Open question',
    'survey-creator.defaultNamePrefix.spelling': 'Spelling',
    'survey-creator.defaultNamePrefix.send_sms': 'SMS',
    'survey-creator.defaultNamePrefix.send_email': 'E-mail',
    'survey-creator.defaultNamePrefix.http': 'HTTP step',
    'survey-creator.defaultNamePrefix.set_variable': 'Set variable',
    'survey-creator.defaultNamePrefix.statement': 'Statement',
    'survey-creator.defaultNamePrefix.help_propose': 'Help',
    'survey-creator.defaultNamePrefix.generic_order': 'Generic order',
    'survey-creator.defaultNamePrefix.city_or_community': 'City or community',
    'survey-creator.defaultNamePrefix.confirmation': 'Yes/No question',
    'survey-creator.defaultNamePrefix.data_collection': 'Report',
    'survey-creator.defaultNamePrefix.function': 'Function',
    'survey-creator.emptyNamePlaceholder': 'No question name',
    'survey-creator.cloneLabel': 'copy',
    'survey-creator.defaultBlockNamePrefix': 'Module',
    'survey-creator.cancel': 'Cancel',
    'survey-creator.save': 'Save changes',
    'survey-creator.blocksLabel': 'Modules',
    'survey-creator.blocksTooltip': `A component of the bot, consisting of any number of steps. You can rename a module by double-clicking on the text. Rearrange steps by dragging them up or down.`,
    'survey-creator.intentsTooltip': 'An element that a user can call up at any time during a conversation with a bot, for example, to ask the question "Where did you get my number?"',
    'survey-creator.callbacksTooltip': 'Step that sends information (SMS or data to the server) after specific events (at the end of the conversation)',
    'survey-creator.addBlockButtonLabel': 'Module or intent',
    'survey-creator.addEntityButtonLabel': 'Entity',
    'survey-creator.addBlockItemBlockLabel': 'Module',
    'survey-creator.addBlockItemIntentLabel': 'Intent',
    'survey-creator.addBlockItemCallbackLabel': 'Callback',
    'survey-creator.addBlockItemDescriptionBlock': 'Section of conversation scenario',
    'survey-creator.addBlockItemDescriptionIntent': 'User\'s statement made at any point of the conversation',
    'survey-creator.addBlockItemDescriptionCallback': 'Sending feedback',
    'survey-creator.addBlockItemDescriptionCallbackDisabled': 'Already added all available',
    'survey-creator.intentsLabel': 'Intents',
    'survey-creator.callbacksLabel': 'Callbacks',
    'survey-creator.callbacks.callFinished': 'Call finished',
    'survey-creator.callbacks.voiceMailDetected': 'Voicemail detected',
    'survey-creator.callbacks.callNotAnswered': 'Call not answered',
    'survey-creator.callback.callFinished.emptyState': 'The "Call finished" callback is a special module that is triggered after the conversation with the bot has ended. It can be used, for example, to asynchronously save data to the client\'s system or to store information for reporting purposes.',
    'survey-creator.callback.voiceMailDetected.emptyState': 'The "Voicemail detected" callback is a special module that is triggered when the bot detects a voicemail while calling the recipient. It can be used, for example, to asynchronously save data to the client\'s system or to store information for reporting purposes. In this callback, you should not use variables defined in the conversation scenario, as they will not contain any values.',
    'survey-creator.callback.callNotAnswered.emptyState': 'The "Call not answered" callback is a special module that is triggered when the bot fails to reach the recipient. It can be used, for example, to asynchronously save data to the client\'s system or to store information for reporting purposes. In this callback, you should not use variables defined in the conversation scenario, as they will not contain any values.',
    'survey-creator.callbacks.addPlaceholder': 'Select from available callbacks',
    'survey-creator.addQuestionButton': 'Add module step',
    'survey-creator.addCallbackStepButton': 'Add step',
    'survey-creator.intent.addNewIntentLabel': 'Create new intent',
    'survey-creator.intent.placeholder': 'Search in available intents or add new',
    'survey-creator.textLabel': 'Text:',
    'survey-creator.audioLabel': 'Audio:',
    'survey-creator.messageLabel': 'Question text',
    'survey-creator.repeatMessageLabel': 'Repeat question text',
    'survey-creator.repeatMessageLabelAlternative': 'Alternative repeat question text',
    'survey-creator.followupMessageLabel': 'Repeat question text',
    'survey-creator.helpNeededMessageLabel': 'Help needed question text',
    'survey-creator.fromIntentMessageLabel': 'Back from intent question text',
    'survey-creator.statementLabel': 'Statement text',
    'survey-creator.addRepeatMessageButton': 'Alternative version',
    'survey-creator.messageAudio.upload': 'Upload file',
    'survey-creator.messageAudio.remove': 'Remove',

    'survey-creator.goTo.titleLabel': 'Response reaction',
    'survey-creator.goTo.falseLabel': 'Rejection',
    'survey-creator.goTo.repeatedLabel': 'Incomprehension',
    'survey-creator.goTo.outOfRangeLabel': 'Out of range',
    'survey-creator.goTo.invalidLengthLabel': 'Invalid length',
    'survey-creator.goTo.falseDescription': 'This reaction will launch when person answers with a rejection.',
    'survey-creator.goTo.repeatedDescription': 'This reaction will launch when bot would not understand any of the defined answers in what person said.',
    'survey-creator.goTo.outOfRangeDescription': 'This reaction will launch when person answers with a number out of defined range.',
    'survey-creator.goTo.invalidLengthDescription': 'This reaction will launch when person answers with a invlid length number.',
    'survey-creator.goTo.continueLabel': 'Continuation',
    'survey-creator.goTo.repeatLabel': 'Repetition',
    'survey-creator.goTo.invalidLabel': 'Non-existing module',

    'survey-creator.possibleQuestionsLabel': 'Intents',
    'survey-creator.possibleQuestionsDescription': 'Select intents to be custom handled in this step.',
    'survey-creator.possibleQuestionsIntent': 'On intent:',
    'survey-creator.possibleQuestionsMessageText': 'Text:',
    'survey-creator.possibleQuestionsMessageAudio': 'Audio:',
    'survey-creator.numericalQuestions.rangesLabel': 'Range',
    'addLabel': 'Add',

    'survey-creator.confirmationStepLabel': 'Yes/No',
    'survey-creator.confirmationStepTooltip': 'Question with yes or no answer',
    'survey-creator.wordStepLabel': 'Word',
    'survey-creator.wordStepTooltip': 'Question with pre-defined answers.',
    'survey-creator.numericalStepLabel': 'Numerical',
    'survey-creator.numericalStepTooltip': 'Question with numeric answer',
    'survey-creator.datetimeStepLabel': 'Date/Hour',
    'survey-creator.datetimeStepTooltip': 'Question for date/time ex. appointment',
    'survey-creator.dateStepLabel': 'Date',
    'survey-creator.dateStepTooltip': 'Question for date ex. date of birth',
    'survey-creator.endStepLabel': 'End',
    'survey-creator.endStepTooltip': 'End conversation',
    'survey-creator.conditionalStepLabel': 'Conditional',
    'survey-creator.conditionalStepTooltip': 'Conditional',
    'survey-creator.openStepLabel': 'Open',
    'survey-creator.openStepTooltip': 'Questions without a defined answer.',
    'survey-creator.spellingStepLabel': 'Spelling',
    'survey-creator.spellingStepTooltip': 'Question when user spells smth',
    'survey-creator.send_smsStepLabel': 'SMS',
    'survey-creator.send_smsStepTooltip': 'Setup and send SMS',
    'survey-creator.send_emailStepLabel': 'E-mail',
    'survey-creator.send_emailStepTooltip': 'Setup and send e-mail message',
    'survey-creator.httpStepLabel': 'HTTP',
    'survey-creator.httpStepTooltip': 'Setup and send HTTP',
    'survey-creator.set_variableStepLabel': 'Set variable',
    'survey-creator.set_variableStepTooltip': 'Define and set a variable',
    'survey-creator.functionStepLabel': 'Function',
    'survey-creator.functionStepTooltip': 'Execute a function',
    'survey-creator.functionStepAddInputArrayButton': 'Add fields',
    'survey-creator.statementStepLabel': 'Statement',
    'survey-creator.statementStepTooltip': 'Statement',
    'survey-creator.redirectStepLabel': 'Redirect',
    'survey-creator.redirectStepTooltip': 'Redirect call to another number ex. consultant',
    'survey-creator.help_proposeStepLabel': 'Help',
    'survey-creator.help_proposeStepTooltip': 'Help',
    'survey-creator.go_to_blockStepLabel': 'Go to module',
    'survey-creator.go_to_blockStepTooltip': 'Go to module',
    'survey-creator.data_collectionStepLabel': 'Collect data',
    'survey-creator.data_collectionStepTooltip': 'Collect data to report',
    'survey-creator.data_collection.column.name': 'Column name',
    'survey-creator.data_collection.column.value': 'Values included in report',
    'survey-creator.data_collection.addColumnButton': 'Add another column',
    'survey-creator.data_collection.phone_number': 'Phone number',
    'survey-creator.data_collection.conversation_details': 'Conversation details',
    'survey-creator.data_collection.error.VALUE_REQUIRED': 'Field can not be empty',
    'survey-creator.data_collection.error.ITEM_DUPLICATED': 'Column names must be unique',
    'survey-creator.system_variable.phoneNumber': 'Phone number',
    'survey-creator.system_variable.botPhoneNumber': 'Bot phone number',
    'survey-creator.system_variable.hangUpCause': 'Hang up cause',
    'survey-creator.system_variable.hangUpCause.clientHungUp': 'Client hung up',
    'survey-creator.system_variable.hangUpCause.botHungUp': 'Bot hung up',
    'survey-creator.system_variable.callDuration': 'Call duration',
    'survey-creator.system_variable.conversationId': 'Conversation ID',
    'survey-creator.system_variable.interactionDate': 'Interaction date',
    'survey-creator.system_variable.followUpInteraction': 'Follow up interaction date',
    'survey-creator.system_variable.lastUserMessage': 'User\'s last message',
    'survey-creator.system_variable.transcript': 'Transcript',
    'survey-creator.system_variable.callDirection': 'Call direction',
    'survey-creator.system_variable.currentHour': 'Current hour',
    'survey-creator.system_variable.currentDay': 'Current day',
    'survey-creator.system_variable.currentDay.Monday': 'Monday',
    'survey-creator.system_variable.currentDay.Tuesday': 'Tuesday',
    'survey-creator.system_variable.currentDay.Wednesday': 'Wednesday',
    'survey-creator.system_variable.currentDay.Thursday': 'Thursday',
    'survey-creator.system_variable.currentDay.Friday': 'Friday',
    'survey-creator.system_variable.currentDay.Saturday': 'Saturday',
    'survey-creator.system_variable.currentDay.Sunday': 'Sunday',
    'survey-creator.system_variable.currentDate': 'Current date',
    'survey-creator.system_variable.callDirection.inbound': 'Inbound call',
    'survey-creator.system_variable.callDirection.outbound': 'Outbound call',
    'survey-creator.system_variable.preferredLanguage': 'Preferred language',
    'survey-creator.system_variable.patientId': 'Patient ID',
    'survey-creator.system_variable.visitId': 'Appointment ID',

    'survey-creator.goToBlock.selectLabel': 'Go to module',
    'survey-creator.goToBlock.blockPreviewPlaceholder': 'Module not selected',
    'survey-creator.goToBlock.notExistingBlockPreviewPlaceholder': 'Not existing module',

    'survey-creator.dictionary.label': 'Possible answers',
    'survey-creator.dictionary.chipsLabel': 'Dictionary',
    'survey-creator.dictionary.chipsGlobalLabel': 'Synonyms from database',
    'survey-creator.dictionary.chipsGlobalLabel.hide': 'Hide',

    'survey-creator.word.answerIdLabel': 'Answer ID',
    'survey-creator.word.readingAnswersToggle': 'Reading answers in order',
    'survey-creator.word.answerMessageLabel': 'Message to read',
    'survey-creator.word.readAnswerType.PERMUTATE': 'random',
    'survey-creator.word.readAnswerType.SEQUENCE': 'sequential',

    'survey-creator.word.addAnswerLabel': 'Add answer',
    'survey-creator.word.addAnswerButton': 'Answer',
    'survey-creator.word.addAnswerDescription': 'Add new answer',

    'survey-creator.word.notPreciseLinkDescription': 'The same synonym was used in different answer, it is required to set clarification.',
    'survey-creator.word.notPreciseLink': 'Go to clarification',

    'survey-creator.word.notPreciseAnswers.answersTitle': 'Answers for clarification',
    'survey-creator.word.notPreciseAnswer.description': 'Clarification means to ask person when it is not clear which answer is correct, because of the same synonyms.',
    'survey-creator.word.notPreciseAnswer.messageLabel': 'Clarification message',
    'survey-creator.word.notPreciseAnswer.repeatMessageLabel': 'Clarification repeat message',
    'survey-creator.word.notPreciseAnswer.messageAlternativeLabel': 'Clarification repeat alternative message',

    'survey-creator.numerical.addRangeButton': 'Numerical range',
    'survey-creator.numerical.addRangeDescription': 'Choose range for numbers to qualify as an answer',
    'survey-creator.numerical.range.label': 'Numerical range',
    'survey-creator.numerical.range.from': 'From',
    'survey-creator.numerical.range.to': 'To',

    'survey-creator.numerical.validation.length': 'Length validation',
    'survey-creator.numerical.validation.min': 'Minimum',
    'survey-creator.numerical.validation.max': 'Maximum',

    'survey-creator.datetime.dateLabel': 'Date question',
    'survey-creator.datetime.timeLabel': 'Time question',

    'survey-creator.date.forbidDatesInTheFuture': 'Forbid dates in the future',
    'survey-creator.date.forbidDatesInThePast': 'Forbid dates in the past',

    'survey-creator.intentDetails.goToModule': 'Go to module',

    'survey-creator.reactionTypeSelect.blockOptionLabel': 'Go to module',
    'survey-creator.reactionTypeSelect.confirmationOptionLabel': 'Question',
    'survey-creator.reactionTypeSelect.wordOptionLabel': 'Question',
    'survey-creator.reactionTypeSelect.statementOptionLabel': 'Statement',
    'survey-creator.reactionTypeSelect.endOptionLabel': 'Ending',

    'survey-creator.intentDetails.localSentences': 'Your sentences',
    'survey-creator.intentDetails.talkieSentences': 'Talkie database sentences',
    'survey-creator.intentDetails.enqueuedSentences': 'Training queue',
    'survey-creator.intentDetails.trainingDataHint': 'Which questions or statements would trigger this reaction? Please enter in separate rows.',
    'survey-creator.intentDetails.addSentences': 'Add sentences',
    'survey-creator.intentDetails.startTraining': 'Train bot',
    'survey-creator.intentDetails.duplicateSentencesHeader': 'Sentences provided for training are already part of the model',
    'survey-creator.intentDetails.duplicateSentencesList': 'Following sentences won\'t be added:',
    'survey-creator.intentDetails.confirmDuplicates': 'OK',
    'survey-creator.intentDetails.removeGlobalSentenceText': 'Do you want to remove global training sentence?',


    'survey-creator.dictionaryPlaceholder': 'Enter synonyms...',
    'survey-creator.dictionary.toggleToLemma': 'Handle all forms of this word',
    'survey-creator.dictionary.toggleToVerbatim': 'Restore word/phrase',
    'survey-creator.dictionary.deleteSynonym': 'Delete',

    'survey-creator.tryId': 'Try it!',

    'survey-creator.condition.eq': 'equals',
    'survey-creator.condition.neq': 'not equals',
    'survey-creator.condition.in': 'is equal to one of',
    'survey-creator.condition.lt': 'less than',
    'survey-creator.condition.gt': 'greater than',
    'survey-creator.condition.truthy': 'confirmed',
    'survey-creator.condition.falsy': 'rejected',
    'survey-creator.condition.exists': 'filled',
    'survey-creator.condition.exists.true': 'yes',
    'survey-creator.condition.exists.false': 'no',
    'survey-creator.condition.includes': 'includes one of',
    'survey-creator.condition.includes.chipsPlaceholder': 'Enter...',
    'survey-creator.condition.invalid': 'has an invalid value',

    'survey-creator.conditionalQuestion.true': 'Yes',
    'survey-creator.conditionalQuestion.false': 'No',
    'survey-creator.conditionalQuestion.default': 'Default',

    'survey-creator.conditional.toggleLabel': 'Run step when condition met',
    'survey-creator.conditional.addButtonLabel': 'Add condition to this group',
    'survey-creator.conditional.conditionLabel': 'Warunek',
    'survey-creator.conditional.addGroupButtonLabel': 'Add condition group',
    'survey-creator.conditional.rootConnectiveLabel': 'Relation type between condition groups',
    'survey-creator.conditional.groupConnectiveLabel': 'Relation type for group',
    'survey-creator.conditional.connectiveOR': 'Or',
    'survey-creator.conditional.connectiveAND': 'And',
    'survey-creator.conditional.addDescription': 'Choose variable which condition needs to be met',
    'survey-creator.conditional.multiSelectPlaceholder': 'Choose or search',
    'survey-creator.conditional.selectConditionOperatorPlaceholder': 'Choose condition type',
    'survey-creator.conditional.selectVariablePlaceholder': 'Choose variable',
    'survey-creator.conditional.selectOperandPlaceholder': 'Value',
    'survey-creator.conditional.messageVariableSelectLabel': 'Select conditional variable',
    'survey-creator.conditional.separatorLabel': 'AND',
    'survey-creator.saveAs.label': 'Save to variable',
    'survey-creator.saveAs.inputLabel': 'Variable name',
    'survey-creator.saveAs.inputDescription': 'Variables allow to collect the information and branching of scenarios by using conditions.',
    'survey-creator.saveAs.changeVariableOptionTooltip': 'Select or create variable',
    'survey-creator.saveAs.renameVariableOptionTooltip': 'Rename',
    'survey-creator.saveAs.renameVariableOptionError': 'Variable with this name already exists',
    'survey-creator.dtmf.label': 'DTMF',
    'survey-creator.dtmf.inputDescription': 'DTMF allows to collect information by pressing keys on the phone.',
    'survey-creator.dtmf.minDigits': 'Minimum number of digits',
    'survey-creator.dtmf.maxDigits': 'Maximum number of digits',
    'survey-creator.dtmf.digitsTimeout': 'Timeout between digits',
    'survey-creator.dtmf.maxTimeout': 'Maximum timeout',
    'survey-creator.createVariableButton': 'Select or add a new variable',
    'survey-creator.createVariableLabel': 'Create',
    'survey-creator.repeatQuestionLimitLabel': 'Repeat question limit',
    'survey-creator.numberOfRepeatQuestionLimitLabel': 'Repeat question limit',
    'survey-creator.conditionalMessageLabel': 'Conditional message?',
    'survey-creator.conditionalMessageButtonLabel': 'Conditional content',
    'survey-creator.dictionaryMessageToggle': 'Readable answer?',
    'survey-creator.readOption': 'Answer order',
    'survey-creator.readOptionFIRST': 'Read as first',
    'survey-creator.readOptionLAST': 'Read as last',
    'survey-creator.readOptionSEQUENCE': 'Read in sequential order',
    'survey-creator.readOptionPERMUTATE': 'Read in random order',
    'survey-creator.readOptionDONT_READ': 'Don\'t read',
    'survey-creator.permuteAnswers': 'Permutate answers?',
    'survey-creator.wordQuestionAnswersCount': 'Number of accepted answers',
    'survey-creator.answersCount.min': 'Min. number of answers',
    'survey-creator.answersCount.max': 'Max. number of answers',
    'survey-creator.ignorableLabel': 'No answer possible?',
    'survey-creator.duplexLabel': 'Listening during question',
    'survey-creator.duplexStopSpeechLabel': 'Stop speaking when the bot is interrupted by the customer',
    'survey-creator.anonymizeAnswerLabel': 'Answer should be anonymized in transcript',
    'survey-creator.waitForRedirectionLabel': 'Wait for redirection',
    'survey-creator.disableInterpretation': 'Disable answer interpretation',
    'survey-creator.isPostponeRequest': 'Should postpone contact?',
    'survey-creator.anyIfMultipleAnswersLabel': 'Accept any answer from multiple answers provided',
    'survey-creator.acceptAnyAnswerLabel': 'Accept non-dictionary answers',
    'survey-creator.useDynamicPhonemizationLabel': 'Accept close-sounding answers',
    'survey-creator.requiredAnswer': 'Required answer?',
    'survey-creator.requiredAnswerDescription': 'Question answer will be required to accept that contect has been called.',
    'survey-creator.joinNumbers': 'Should join number that appears during answer?',
    'survey-creator.customStatus': 'Optional status',
    'survey-creator.customStatus.voicemail': 'Voicemail',
    'survey-creator.customStatus.stop_calling': 'Stop calling',
    'survey-creator.customStatus.all_required_questions_answered': 'Answered to all required questions',
    'survey-creator.changeRecognizer': 'Speech recognition system',
    'survey-creator.recognizer.default': 'Default',
    'survey-creator.recognizer.google_default': 'Google (Default)',
    'survey-creator.recognizer.google_latest_long': 'Google (Latest Long)',
    'survey-creator.recognizer.google_latest_short': 'Google (Latest Short)',
    'survey-creator.recognizer.google_phone_call': 'Google (Phone Call)',
    'survey-creator.recognizer.google_medical_conversation': 'Google (Medical Conversation)',
    'survey-creator.recognizer.google_telephony': 'Google (Telephony)',
    'survey-creator.recognizer.google_telephony_short': 'Google (Telephony Short)',
    'survey-creator.recognizer.techmo_default': 'Techmo',
    'survey-creator.recognizer.techmo_medical': 'Techmo Medical',
    'survey-creator.recognizer.deepgram_nova-2-phonecall': 'Deepgram (Phone Call)',
    'survey-creator.recognizer.deepgram_nova-2-general': 'Deepgram (General)',
    'survey-creator.recognizer.deepgram_nova-2-medical': 'Deepgram (Medical)',
    'survey-creator.changePreSpeechTimeout': 'Time of reaction time before the speaker speaks (prespeech)',
    'survey-creator.resetPreSpeechTimeout': 'Restore the default value',
    'survey-creator.changePostSpeechTimeout': 'End-of-speech timeout',
    'survey-creator.resetPostSpeechTimeout': 'Restore the default value',

    'survey-creator.audio.addRecording': 'Add recording',
    'survey-creator.audio.replaceRecording': 'Replace recording',
    'survey-creator.audio.downloadRecording': 'Download recording',
    'survey-creator.audio.deleteRecording': 'Delete recording',

    'survey-creator.confirmationDictionaryYES': 'yes',
    'survey-creator.confirmationDictionaryNO': 'no',

    'survey-creator.endLabel': 'End',
    'survey-creator.nextBlockLabel': 'Next module',

    'survey-creator.wordAnswerGoToLabel': 'Response reaction',
    'survey-creator.ignorableIntentsLabel': 'Ignorable intents',
    'survey-creator.ignorableIntentsDescription': 'Select intents to be ignored in this step.',

    'survey-creator.smsStep.message': 'SMS text',
    'survey-creator.smsStep.senderNamePlaceholder': 'Enter sender name or leave blank to display phone number',
    'survey-creator.smsStep.fromNumber': 'Displayed sender',
    'survey-creator.smsStep.toNumber': 'Recipient phone number (optional)',

    'survey-creator.emailStep.to': 'Receiver',
    'survey-creator.emailStep.subject': 'Subject',
    'survey-creator.emailStep.message': 'Message',

    'survey-creator.redirectStep.redirectTo': 'Redirect to',
    'survey-creator.redirectStep.redirectFrom': 'Redirect from',
    'survey-creator.redirectStep.redirectFromButton': 'Redirect from',
    'survey-creator.redirectStep.stopRecording': 'Do not record call after transfer',
    'survey-creator.redirectStep.redirectTrunk': 'Trunk',
    'survey-creator.redirectStep.redirectType': 'Type',
    'survey-creator.redirectStep.redirectTypeOptions.dial': 'Dial',
    'survey-creator.redirectStep.redirectTypeOptions.transfer': 'Transfer',
    'survey-creator.redirectStep.redirectTypeOptions.local': 'Local',
    'survey-creator.redirectStep.redirectHeadersLabel': 'SIP Headers',
    'survey-creator.redirectStep.addRedirectHeaderButton': 'Header',
    'survey-creator.redirectStep.redirectHeaderName': 'Header name',
    'survey-creator.redirectStep.redirectHeaderValue': 'Value',
    'survey-creator.redirectStep.destination': 'Destination',

    'survey-creator.functionStep.functions.ConcatArraysDescription': 'Concatenate 2 arrays, returns the resulting array',
    'survey-creator.functionStep.functions.ConcatArraysInputsArrayA': 'Array A',
    'survey-creator.functionStep.functions.ConcatArraysInputsArrayADescription': 'First array to concatenate',
    'survey-creator.functionStep.functions.ConcatArraysInputsArrayB': 'Array B',
    'survey-creator.functionStep.functions.ConcatArraysInputsArrayBDescription': 'Second array to concatenate',
    'survey-creator.functionStep.functions.ConcatArraysOutputsResult': 'Result',
    'survey-creator.functionStep.functions.ConcatArraysOutputsResultDescription': 'Result of concatenation',

    'survey-creator.functionStep.functions.AssignDescription': 'Assigns a variable value to another variable',
    'survey-creator.functionStep.functions.AssignInputsValue': 'Value',
    'survey-creator.functionStep.functions.AssignInputsValueDescription': 'Value to assign',
    'survey-creator.functionStep.functions.AssignOutputsResult': 'Result',
    'survey-creator.functionStep.functions.AssignOutputsResultDescription': 'Result of assignment',

    'survey-creator.functionStep.functions.ArrayFilterDescription': 'Find elements containing the specified parameter, returns an array of matched elements',
    'survey-creator.functionStep.functions.ArrayFilterInputsArray': 'Array',
    'survey-creator.functionStep.functions.ArrayFilterInputsArrayDescription': 'Filtered array content',
    'survey-creator.functionStep.functions.ArrayFilterInputsArrayPlaceholder': '[{contact:{name: "John"}}] or ${variable}',
    'survey-creator.functionStep.functions.ArrayFilterInputsValue': 'Value',
    'survey-creator.functionStep.functions.ArrayFilterInputsValueDescription': 'The value to compare with the array elements',
    'survey-creator.functionStep.functions.ArrayFilterInputsValuePlaceholder': 'John',
    'survey-creator.functionStep.functions.ArrayFilterInputsPath': 'Path',
    'survey-creator.functionStep.functions.ArrayFilterInputsPathDescription': 'Indicates the path to the array element value being compared',
    'survey-creator.functionStep.functions.ArrayFilterInputsPathPlaceholder': 'contact.name',
    'survey-creator.functionStep.functions.ArrayFilterInputsWithRegExp': 'Regular expression',
    'survey-creator.functionStep.functions.ArrayFilterInputsWithRegExpDescription': 'Treat the value as a regular expression',
    'survey-creator.functionStep.functions.ArrayFilterOutputsResult': 'Result',
    'survey-creator.functionStep.functions.ArrayFilterOutputsResultDescription': 'Mapping values from a filtered array',

    'survey-creator.functionStep.functions.ArrayFindDescription': 'Find the first element containing the specified parameter, returns one element from the array',
    'survey-creator.functionStep.functions.ArrayFindInputsArray': 'Array',
    'survey-creator.functionStep.functions.ArrayFindInputsArrayDescription': 'Searched array content',
    'survey-creator.functionStep.functions.ArrayFindInputsArrayPlaceholder': '[{contact:{name: "John"}}] or ${variable}',
    'survey-creator.functionStep.functions.ArrayFindInputsValue': 'Value',
    'survey-creator.functionStep.functions.ArrayFindInputsValueDescription': 'The value to compare with the array elements',
    'survey-creator.functionStep.functions.ArrayFindInputsValuePlaceholder': 'John',
    'survey-creator.functionStep.functions.ArrayFindInputsPath': 'Path',
    'survey-creator.functionStep.functions.ArrayFindInputsPathDescription': 'Indicates the path to the array element value being compared',
    'survey-creator.functionStep.functions.ArrayFindInputsPathPlaceholder': 'contact.name',
    'survey-creator.functionStep.functions.ArrayFindInputsWithInclude': 'Includes',
    'survey-creator.functionStep.functions.ArrayFindInputsWithIncludeDescription': 'Match value when contained in an array element',
    'survey-creator.functionStep.functions.ArrayFindOutputsResult': 'Result',
    'survey-creator.functionStep.functions.ArrayFindOutputsResultDescription': 'Mapping values from a found array element',

    'survey-creator.functionStep.functions.ArrayNthDescription': 'Select the nth element from the array, returns the selected element',
    'survey-creator.functionStep.functions.ArrayNthInputsArray': 'Array',
    'survey-creator.functionStep.functions.ArrayNthInputsArrayDescription': 'Array content',
    'survey-creator.functionStep.functions.ArrayNthInputsArrayPlaceholder': '[{contact:{name: "John"}}] or ${variable}',
    'survey-creator.functionStep.functions.ArrayNthInputsIndex': 'Index',
    'survey-creator.functionStep.functions.ArrayNthInputsIndexDescription': 'Index of the selected element',
    'survey-creator.functionStep.functions.ArrayNthInputsIndexPlaceholder': '0',
    'survey-creator.functionStep.functions.ArrayNthOutputsResult': 'Result',
    'survey-creator.functionStep.functions.ArrayNthOutputsResultDescription': 'Selected array element',

    'survey-creator.functionStep.functions.StringReplaceInputString': 'Input string',
    'survey-creator.functionStep.functions.StringReplaceInputWithRegExp': 'Input with RegExp',
    'survey-creator.functionStep.functions.StringReplaceInputSearchValue': 'Search value',
    'survey-creator.functionStep.functions.StringReplaceInputReplaceValue': 'Replace value',
    'survey-creator.functionStep.functions.StringReplaceOutputsResult': 'Result',
    'survey-creator.functionStep.functions.StringReplaceDescription': 'Function to replace parts of a string with a new value',
    'survey-creator.functionStep.functions.StringReplaceInputStringDescription': 'Enter the original string you want to modify',
    'survey-creator.functionStep.functions.StringReplaceInputWithRegExpDescription': 'Use regular expression for advanced search and replace',
    'survey-creator.functionStep.functions.StringReplaceInputSearchValueDescription': 'The value or pattern you want to search for in the string',
    'survey-creator.functionStep.functions.StringReplaceInputReplaceValueDescription': 'The new value to replace the search value with',
    'survey-creator.functionStep.functions.StringReplaceOutputDescription': 'The resulting string after replacement',
    'survey-creator.functionStep.functions.FuzzyMatcherProcessorType': '(optional) Processor type',
    'survey-creator.functionStep.functions.FuzzyMatcherProcessorTypeDescription': 'AI processor type',
    'survey-creator.functionStep.functions.FuzzyMatcherIdField': '(optional) ID field',
    'survey-creator.functionStep.functions.FuzzyMatcherIdFieldDescription': 'ID field in the array',
    'survey-creator.functionStep.functions.FuzzyMatcherIdFieldPlaceholder': 'id',
    'survey-creator.functionStep.functions.FuzzyMatcherParentIdField': '(optional) Parent ID field',
    'survey-creator.functionStep.functions.FuzzyMatcherParentIdFieldDescription': 'Parent ID field in the array',
    'survey-creator.functionStep.functions.FuzzyMatcherParentIdFieldPlaceholder': 'parent_id',
    'survey-creator.functionStep.functions.FuzzyMatcherCandidatesPreselectionField': '(optional) Preselection field',
    'survey-creator.functionStep.functions.FuzzyMatcherCandidatesPreselectionFieldDescription': 'Path to the field in the data used for preselection',
    'survey-creator.functionStep.functions.FuzzyMatcherCandidatesPreselectionFieldPlaceholder': 'surname',

    'survey-creator.functionStep.functions.FuzzyMatcherDescription': 'Fuzzy search that use our AI service',
    'survey-creator.functionStep.functions.FuzzyMatcherInputsArray': 'Array',
    'survey-creator.functionStep.functions.FuzzyMatcherInputsArrayDescription': 'Array to search',
    'survey-creator.functionStep.functions.FuzzyMatcherInputsArrayPlaceholder': '[{"id":1,"name":"Alice","type":"nurse"},{"id":2,"name":"Bob","type":"doctor"}] or ${variable}',
    'survey-creator.functionStep.functions.FuzzyMatcherMatchingField': 'Matching field',
    'survey-creator.functionStep.functions.FuzzyMatcherMatchingFieldDescription': 'Field to search in the array',
    'survey-creator.functionStep.functions.FuzzyMatcherMatchingFieldPlaceholder': 'name',
    'survey-creator.functionStep.functions.FuzzyMatcherTextToMatch': 'Text to match',
    'survey-creator.functionStep.functions.FuzzyMatcherTextToMatchDescription': 'Text to search for in the array',
    'survey-creator.functionStep.functions.FuzzyMatcherTextToMatchPlaceholder': 'Alice',
    'survey-creator.functionStep.functions.FuzzyMatcherOutputsResult': 'Result',
    'survey-creator.functionStep.functions.FuzzyMatcherOutputsResultDescription': 'Result of the fuzzy search',

    'survey-creator.functionStep.functions.ArrayTransformDescription': 'Transforms an array of objects, returns an array of objects',
    'survey-creator.functionStep.functions.ArrayTransformInputsArray': 'Array to transform',
    'survey-creator.functionStep.functions.ArrayTransformInputsArrayDescription': 'Array of objects to transform',
    'survey-creator.functionStep.functions.ArrayTransformInputsArrayPlaceholder': '[{"id":1,"name":"Alice","type":"nurse"},{"id":2,"name":"Bob","type":"doctor"}] or ${variable}',
    'survey-creator.functionStep.functions.ArrayTransformMappingArray': 'Mapping',
    'survey-creator.functionStep.functions.ArrayTransformMappingArrayDescription': 'Mapping values in the array',
    'survey-creator.functionStep.functions.ArrayTransformFromPath': 'Source path',
    'survey-creator.functionStep.functions.ArrayTransformFromPathDescription': 'Path to the source value',
    'survey-creator.functionStep.functions.ArrayTransformFromPathPlaceholder': 'name',
    'survey-creator.functionStep.functions.ArrayTransformToPath': 'Destination path',
    'survey-creator.functionStep.functions.ArrayTransformToPathDescription': 'Path to the destination value',
    'survey-creator.functionStep.functions.ArrayTransformToPathPlaceholder': 'details.name',
    'survey-creator.functionStep.functions.ArrayTransformFromAsValue': 'Save as value',
    'survey-creator.functionStep.functions.ArrayTransformFromAsValueDescription': 'Saves the value instead of the value under the given path',

    'survey-creator.httpStep.url': 'URL',
    'survey-creator.httpStep.urlPlaceholder': 'API URL address',
    'survey-creator.httpStep.method': 'Method',
    'survey-creator.httpStep.body': 'Body',
    'survey-creator.httpStep.headersLabel': 'HTTP Headers',
    'survey-creator.httpStep.headerName': 'Header name',
    'survey-creator.httpStep.headerValue': 'Value',
    'survey-creator.httpStep.addHeaderButton': 'Header',

    'survey-creator.mapping.mappingsLabel': 'Mappings',
    'survey-creator.mapping.mappingVariable': 'Variable',
    'survey-creator.mapping.mappingPath': 'Path',
    'survey-creator.mapping.mappingPossibleValues': 'Possible values...',
    'survey-creator.mapping.addMappingButton': 'Mapping',
    'survey-creator.mapping.mappingType': 'Type',

    'survey-creator.setVariableStep.variable': 'Variable name',
    'survey-creator.setVariableStep.value': 'Variable value',
    'survey-creator.createVariableDictionaryValue': 'Create new dictionary value',
    'survey-creator.clearVariable': '(Empty value)',
    'survey-creator.setVariable.assignValue.dictionary': 'Assign or create dictionary value',
    'survey-creator.setVariable.assignValue.variable': 'Assign value from variable',
    'survey-creator.setVariable.assignValue.plainText': 'Assign plain text',

    'survey-creator.genericOrder.initialMessage': 'Initial message',
    'survey-creator.genericOrder.productAddedMessage': 'Product added message',
    'survey-creator.genericOrder.resignationMessage': 'Message when product order was canceled',
    'survey-creator.genericOrder.nameLabel': 'Name',
    'survey-creator.genericOrder.typeLabel': 'Type',
    'survey-creator.genericOrder.numericPropertyValue': 'Is the characteristic numerical?',
    'survey-creator.genericOrder.propertyValueMessageText': 'Disambiguate or specify the value of this characteristc',
    'survey-creator.genericOrder.synonyms': 'Synonyms',
    'survey-creator.genericOrder.propertyMessage': 'Values of characteristic',
    'survey-creator.genericOrder.orderLabel': 'Order',
    'survey-creator.genericOrder.positionLabel': 'Position',
    'survey-creator.genericOrder.textFormattingMessageSingularLabel': 'Text format for singular.',
    'survey-creator.genericOrder.textFormattingMessagePluralLabel': 'Text format for plural (2-4).',
    'survey-creator.genericOrder.textFormattingMessagePluralGenitiveLabel': 'Text format for plural (0.5 - 9).',
    'survey-creator.genericOrder.textFormattingMessageLabel': 'Message for this characteristic',
    'survey-creator.genericOrder.propertyLabel': 'Characteristics',
    'survey-creator.genericOrder.propertyValuesLabel': 'Values of characteristic',
    'survey-creator.genericOrder.optionalProperty': 'Optional characteristic',
    'survey-creator.genericOrder.customMessageProperty': 'Specific disambiguation question for this characteristic?',
    'survey-creator.genericOrder.propertiesLabel': 'Characteristics',
    'survey-creator.genericOrder.propertiesValuesLabel': 'Values of characteristic',
    'survey-creator.genericOrder.textFormattingLabel': 'Text formatting when combining characteristics and products.',
    'survey-creator.genericOrder.productsLabel': 'Products',
    'survey-creator.genericOrder.metadataLabel': 'Metadata',

    'survey-creator.tryIt.label': 'Test number:',
    'survey-creator.tryIt.placeholder': 'Enter phone number',

    'survey-creator.addNewGroup': 'Add group',
    'survey-creator.assignToGroupPlaceholder': 'Select existing group or create new',
    'survey-creator.ungroup': 'Do you want to ungroup items?',
    'survey-creator.ungroup.confirm': 'Ungroup',
    'survey-creator.ungroup.cancel': 'Cancel',
    'survey-creator.removeGroup': 'Do you want remove group with all content?',
    'survey-creator.removeGroup.confirm': 'Remove',
    'survey-creator.removeGroup.cancel': 'Cancel',

    'survey-creator.block.description.preview': 'Preview',
    'survey-creator.block.description.edit': 'Edit',
    'survey-creator.addBlockDescription': 'Modules can contain a description that helps to understand its purpose and application. This makes it easier to navigate the structure and operation of the bot before going into the details of individual steps.',
    'survey-creator.addBlockDescriptionButton': 'Add description',

    'survey-creator.primary-answers': 'Primary answers',
    'survey-creator.associated-answers': 'Associated answers',
    'survey-creator.entities': 'Entities',
    'survey-creator.addEntitiesDescription': 'Select associated entities, which can be spoken in the response.',
    'survey-creator.addNewEntityLabel': 'Create new entity',
    'survey-creator.addNewEntityPlaceholderLabel': 'Select an entity',
    'survey-creator.associatedAnswerEntityLabel': 'Entity',
    'survey-creator.associatedAnswerVariable': 'Variable',
    'survey-creator.entities-tabs.answers': 'Answers',
    'survey-creator.entityCreateError': 'Entity with this name already exists',
    'survey-creator.entityAnswerId': 'Answer ID',
    'survey-creator.entitySynonyms': 'Dictionary',
    'survey-creator.entityGlobalSynonyms': 'Synonyms from database',
    'survey-creator.associatedAnswerGoToEntity': 'Go to entity details',
    'survey-creator.associatedAnswerSystemEntity': 'System entity',
    'survey-creator.associatedAnswerReaction': 'Reaction',
    'survey-creator.associatedAnswerReaction.noReaction': 'No reaction',
    'survey-creator.associatedAnswerReaction.continue': 'Continue',
    'survey-creator.fallback': 'Default reaction to errors',
    'survey-creator.fallback.emptyState': 'The fallback is a special module that is triggered when an unexpected error occurs during the conversation flow – for example, when a variable value is missing, an HTTP request fails, or another technical issue arises.',
    'survey-creator.silenceFallback': 'Default reaction to silence',
    'survey-creator.silenceFallback.emptyState': 'After the first silence, the default reaction uses "Repeat question text".{linebreak}If the caller is silent after the "Repeat question text" response, the conversation continues according to the scenario. To set call redirection or to end the call, add a step in this module.',

    'survey-creator.tabs.scenario': 'Scenario',
    'survey-creator.tabs.entities': 'Entities',

    'survey-creator.entities-tabs.answers.addAnswerButton': 'Answer',
    'survey-creator.entities-tabs.answers.addAnswerDescription': 'Add answers, which are part of this entity',

    'survey-creator.specialCharacters.addNewSpecialCharacter.buttonLabel': 'Special character',
    'survey-creator.specialCharacters.addNewSpecialCharacter.description': 'Select special characters to be recognized in spelling step.',
    'survey-creator.specialCharacters.addNewSpecialCharacter.placeholder': 'Select special characters to be recognized in spelling step.',
    'survey-creator.specialCharacters.specialCharacterDefinition': 'Special character {character}',
    'survey-creator.specialCharacters.characters.space': '[ space ]',

    'survey-creator.executableFunctions.functionNames.ConcatArrays': 'Concatenate arrays',
    'survey-creator.executableFunctions.functionNames.Assign': 'Assign',
    'survey-creator.executableFunctions.functionNames.ArrayFilter': 'Filter array',
    'survey-creator.executableFunctions.functionNames.ArrayFind': 'Find in array',
    'survey-creator.executableFunctions.functionNames.StringReplace': 'Replace string',
    'survey-creator.executableFunctions.functionNames.ArrayNth': 'Get nth element from array',
    'survey-creator.executableFunctions.functionNames.FuzzyMatcher': 'Fuzzy Matcher',
    'survey-creator.executableFunctions.functionNames.ArrayTransform': 'Transform array',

    'step.tabs.error': 'The content of the question is missing in the tab - {tab}',
    'step.tabs.conditions.error': 'Conditions configuration incomplete in the tab - Settings',
    'step.tabs.conditions.variableId.error': 'Choose variable',
    'step.tabs.conditions.operator.error': 'Choose condition type',
    'step.tabs.conditions.operand.error': 'Fill condition value',
    'step.tabs.question': 'Question',
    'step.tabs.question.statement': 'Statement',
    'step.tabs.question.redirect': 'Redirect',
    'step.tabs.question.sms': 'SMS',
    'step.tabs.question.email': 'E-mail',
    'step.tabs.question.end': 'Statement',
    'step.tabs.answer': 'Answer',
    'step.tabs.general': 'Settings',
    'step.tabs.intents': 'Intents',
    'step.tabs.specialCharacters': 'Special characters',
    'step.name': 'Step name',
    'step.name.error.VALUE_REQUIRED': 'Step name is required',

    'survey.message.error': 'You must enter the text of the question to proceed',
    'survey.date.message.error': 'You must enter the text of the date question to proceed',
    'survey.hour.message.error': 'You must enter the text of the hour question to proceed',
    'survey.date.repeatMessages.error': 'You must enter the text of the repeat date question to proceed',
    'survey.hour.repeatMessages.error': 'You must enter the text of the repeat hour question to proceed',
    'survey.repeatMessages.error': 'You must enter the text of the repeat question to proceed',
    'survey.followupMessage.error': 'You must enter the text of the repeat question to proceed',
    'survey.helpNeededMessage.error': 'You must enter the text of the help needed question to proceed',
    'survey.repeatMessage': 'Repeat message',
    'survey.saveRepeatMessage': 'Save repeat message',
    'survey.failureMessage': 'Failure message',
    'survey.saveFailureMessage': 'Save failure message',
    'survey.report.download': 'Download',
    'survey.report.generate': 'Generate',
    'survey.reports.title': 'Reports',
    'survey.reportsList.dateLabel': 'Date: ',
    'survey.reportsList.statusLabel': 'Status: ',

    'unsavedChangesModal.text': 'There are unsaved changes. Do you want to discard them?',

    'pillRemoveModal.text': 'Are you sure you want to delete selected item?',

    'questionRemoveModal.text': 'Are you sure tyou want to delete this question?',

    'questionMoveModal.text': 'Select a module to which you want move the step...',

    'confirmationModal.yes': 'Yes',
    'confirmationModal.no': 'No',

    'candidates.zone': 'Drag CSV file with candidates here, or click to select',
    'candidates.zone.withDrag': 'Drop CSV file here',
    'candidates.processing': 'Processing candidates...',
    'candidates.successfulUpload': 'Candidates uploaded successfully.',
    'candidates.noCandidates': 'No Candidates to upload in given file.',
    'candidates.reset': 'Reset candidates',
    'candidates.confirm': 'Upload candidates',
    'candidates.confirmForce': 'Upload and overwrite candidates',
    'candidates.unknownError':
        'Unknown error occurred, please try again or contact us at: support@talkie.ai',
    'candidates.title': 'Upload candidates for scheduling',
    'candidates.errors': 'Validation errors occurred:',
    'candidates.candidates': 'List of candidates to upload:',

    'audioPlayer.noRecording': 'No audio record found',
    'audioPlayer.recordingError': 'Error occurred, audio unavailable',

    'conversations.reports.close': 'Close',
    'conversations.reports.title': 'Reports',
    'conversations.reports.reportTitle': '{reportTitle}',
    'conversations.reports.ready': 'Ready',
    'conversations.reports.pending': 'Pending',
    'conversations.reports.generate': 'Generate new report',
    'conversations.reports.reportRequestDisabled': 'Select a date range to generate a report',
    'conversations.reports.selectAll': 'Select all',
    'conversations.reports.deselectAll': 'Deselect all',
    'conversations.reports.questions': 'Questions:',
    'conversations.reports.intents': 'Intents:',
    'conversations.reports.conversationsLimit': 'Your report has been limited to the top {conversationsLimit} conversations',

    'conversations.filter.allCalls': 'All calls',
    'conversations.filter.startedMinutes': 'Minutes used',
    'conversations.filter.incoming_call': 'Incoming call',
    'conversations.filter.call_answered': 'Outgoing calls: answered',
    'conversations.filter.call_not_answered': 'Outgoing calls: not answered',
    'conversations.filter.voice_mail_detected': 'Voice mail',
    'conversations.filter.with_duplex': 'With duplex',
    'conversations.filter.without_duplex': 'Without duplex',
    'conversations.filter.fatal_error_occurred': 'Error - Unexpected error',
    'conversations.filter.missing_value_of_variable': 'Error - Missing value of variable',
    'conversations.filter.destination_module_is_missing': 'Error - Destination module is missing',
    'conversations.filter.missing_text_or_recording': 'Error - Missing text or recording',
    'conversations.filter.http_step_returned_an_error': 'Error - HTTP step returned an error',
    'conversations.filter.infinite_loop_detected': 'Error - Infinite loop detected',
    'conversations.filter.invalid_condition': 'Error - Invalid condition',
    'conversations.filter.call_redirected': 'Redirections',
    'conversations.filter.startedRedirectMinutes': 'Minutes after redirection',
    'conversations.filter.user_ended_conversation': 'Finished by human',
    'conversations.filter.bot_ended_conversation': 'Finished by bot',
    'conversations.filter.FUNCTION_INPUTS_VALIDATION_ERROR': 'Error - Function inputs validation error',
    'conversations.filter.FUNCTION_DOES_NOT_EXIST': 'Error - Function does not exist',


    'variables-text-area.add-button': 'Variable \$\{ \}',


    'survey-creator.botTester.newChat': 'New text chat',
    'survey-creator.botTester.suggestions.today': 'Today',
    'survey-creator.botTester.suggestions.yesterday': 'Yesterday',
    'survey-creator.botTester.suggestions.tomorrow': 'Tomorrow',
    'survey-creator.botTester.suggestions.tomorrowBetween': 'Tomorrow between',
    'survey-creator.botTester.suggestions.at': 'at',
    'survey-creator.botTester.suggestions.and': 'and',
    'survey-creator.botTester.notRecognized': `Didn't recognize answer`,
    'survey-creator.botTester.intent': 'INTENT',
    'survey-creator.botTester.answer': 'ANSWER',
    'survey-creator.botTester.inputPlaceholder': 'Type an answer or call an intent',
    'survey-creator.botTester.conversationFinished': 'Conversation finished',

    'menuOption.rename.tooltip': 'Edit bot settings',
    'menuOption.duplicate.tooltip': 'Clone',
    'menuOption.delete.tooltip': 'Delete',
    'menuOption.disable.tooltip': 'Deactivate step',
    'menuOption.enable.tooltip': 'Activate step',
    'menuOption.move.tooltip': 'Move to',
    'menuOption.start.tooltip': 'Set as starter',
    'menuOption.group.tooltip': 'Add to group',
    'menuOption.removeFromGroup.tooltip': 'Remove from group',
    'menuOption.removeGroup.tooltip': 'Remove group',
    'menuOption.ungroup.tooltip': 'Ungroup',

    'settings.admin.sipHeaders.mappingsLabel': 'SIP Headers',
    'settings.admin.sipHeaders.addMappingButton': 'Header',
    'settings.admin.sipHeaders.variable': 'Variable',
    'settings.admin.sipHeaders.headerName': 'Header name',
    'settings.admin.sipHeaders.variableType': 'Type',

    'issues.header': 'Add note',
    'issues.title': 'Title',
    'issues.save': 'Add note',
    'issues.placeholder': 'Additional notes',
    'issues.successToast': 'Successfully added note',
    'issues.errorToast': 'Could not add note',

    'reports.table.updatedAt': 'Last update',
    'reports.table.actions': 'Actions',
    'reports.table.phoneNumber': 'Phone number',
    'reports.table.smsText': 'Text SMS',
    'reports.changeStatus.successToast': 'Successfully changed the status of the record',
    'reports.changeStatus.errorToast': 'Could not change the status of the record',
    'reports.emptyState.noData': `We did not collect any reports data yet.

&nbsp;

Go to the [Creator]({creatorLink}) **and make sure you have at least one "Collecting data" step configured.**
Then wait for the voicebot to make or answer some calls and collect data.`,
    'reports.emptyState.noDataNoCreator': 'Reports can make your work much easier. Write to us at: [support@talkie.ai](mailto:support@talkie.ai) and find out what we can offer you.',
    'reports.emptyState.noDataForFilters': 'No results found for the selected filter. **Try changing your filters** for more results.',
    'reports.actions.status.selectPlaceholder': 'Application status',
    'reports.actions.status.DEFAULT': 'New',
    'reports.actions.status.COMPLETED': 'Completed',
    'reports.actions.preview': 'Conversation preview',
    'reports.filters.search': 'Search by typing in, for example, SSN, phone number...',

    'reportConfig.tooltip': 'Automatic report sending',
    'reportConfig.modal.title': '"{report}" report settings',
    'reportConfig.modal.scheduledReports.title': 'Automatic report sending',
    'reportConfig.modal.scheduledReports.text': 'Automatic reports are sent out regularly once a day after midnight and include data gathered during the previous day.<br />The reports will be secured with a password of your choice.',
    'reportConfig.modal.scheduledReports.emailLabel': 'Email addresses to which the reports will be sent:',
    'reportConfig.modal.scheduledReports.passwordLabel': 'Password:',
    'passwordInput.passwordTooltip.show': 'Show password',
    'passwordInput.passwordTooltip.hide': 'Hide password',
    'reportConfig.modal.scheduledReports.passwordValidation': 'Password must contain only latin characters.',
    'reportConfig.modal.scheduledReports.emailValidationMessage': 'Email address is incorrect. Check if it contains the @ character.',
    'reportConfig.modal.notifications.title': 'Notifications',
    'reportConfig.modal.notifications.text': 'The platform can display information about new collected data. The indicator will inform about the number of unconfirmed applications in a specific report.',
    'reportConfig.modal.notifications.toggleText': 'View notifications about new entries',
    'reportConfig.modal.save': 'Save changes',
    'reportConfig.successToast': 'The report configuration has been successfully changed',
    'reportConfig.errorToast': 'The report configuration could not be changed',

    'termsOfServiceModal.title': 'Terms and Conditions of the Platform',
    'termsOfServiceModal.accept': 'I agree',
    'termsOfServiceModal.download': 'Download',
    'termsOfServiceModal.filename': 'Talkie regulations.pdf',
    'termsOfServiceModal.markdownFilename.eu-central-1.dev': 'https://talkie-dev-terms-of-service.s3.eu-central-1.amazonaws.com/terms-of-service-us.md',
    'termsOfServiceModal.markdownFilename.eu-central-1.prd': 'https://talkie-prd-terms-of-service.s3.eu-central-1.amazonaws.com/terms-of-service-us.md',
    'termsOfServiceModal.markdownFilename.us-east-2.us-prd': 'https://talkie-us-prd-terms-of-service.s3.us-east-2.amazonaws.com/terms-of-service-us.md',

    'termsOfServiceModal.filePath.eu-central-1.dev': 'https://talkie-dev-terms-of-service.s3.eu-central-1.amazonaws.com/talkie-terms-of-service.pdf',
    'termsOfServiceModal.filePath.eu-central-1.prd': 'https://talkie-prd-terms-of-service.s3.eu-central-1.amazonaws.com/talkie-terms-of-service.pdf',
    'termsOfServiceModal.filePath.us-east-2.us-prd': 'https://talkie-us-prd-terms-of-service.s3.us-east-2.amazonaws.com/talkie-terms-of-service.pdf',

    'tagFilterModal.title': 'Filtering by tags',
    'tagFilterModal.submitButton': 'Apply',
    'tagFilterModal.cancelButton': 'Cancel',
    'tagFilterModal.description':
      'Select the tags you want to filter out bots by:',
    'tagFilterModal.tagsToSelectEmpty': 'Click tag below to select',
};

const enGbMessages = {
    ...enUsMessages,
    'timestamp.format': 'DD/MM/YYYY h:mm a',
    'date.format': 'DD/MM/YYYY',
    'month.format': 'MM/YYYY',
};

const defaultMessages = {
    ...enUsMessages,
    'timestamp.format': plPlMessages['timestamp.format'],
    'hour.format': plPlMessages['hour.format'],
    'date.format': plPlMessages['date.format'],
    'month.format': plPlMessages['month.format'],
    'datetime.format': plPlMessages['datetime.format'],
}

export const i18nConfig = {
    'pl-PL': plPlMessages,
    'en-US': enUsMessages,
    'en-GB': enGbMessages,
    'default': defaultMessages,
    en: enGbMessages
};
