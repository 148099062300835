import * as React from 'react';
import {useContext, useLayoutEffect, useRef} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {animateScroll} from 'react-scroll';

import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';
import {AddStepButton} from './AddStepButton';
import styles from './BlockDetails.pcss';
import {BlockPreview} from './BlockPreview';
import {VerticalSeparator} from './VerticalSeparator';
import {BlockQuestion, QuestionType} from '../model';
import {addOrdinalNumberToBlockQuestionName} from './utils';
import {useParams} from 'react-router';
import {BotCreatorRouteMatch} from '../useCreatorRouter';

const SCROLL_CONTAINER_ID = 'callback-preview-scroll-container';

const questionTypes = [
    QuestionType.SMS,
    QuestionType.HTTP,
    QuestionType.EMAIL,
    QuestionType.DATA_COLLECTION,
    QuestionType.SET_VARIABLE,
    QuestionType.GO_TO_BLOCK,
];

export const CallbackDetails = () => {
    const {callbacks, questions} = useContext<SurveyContextType>(SurveyContext);
    const newQuestionRef = useRef<string>();
    const params = useParams<BotCreatorRouteMatch>();
    const currentBlock = callbacks.get(params.callbackId);

    useLayoutEffect(() => {
        animateScroll.scrollToTop({
            duration: 0,
            containerId: SCROLL_CONTAINER_ID,
            smooth: true
        });
    }, [currentBlock]);

    if (!currentBlock) {
        return null;
    }

    const handleAdd = (questionToAdd: BlockQuestion) => {
        newQuestionRef.current = questionToAdd.id;

        questions.add(
            addOrdinalNumberToBlockQuestionName(
                questionToAdd, 
                currentBlock.questions.length + 1
            ),
            { type: 'callback', callbackId: params.callbackId, callbackStepId: params.callbackStepId },
        );
    };

    return (
        <div className={styles.container} data-test={'survey-callback-details'}>
            <VerticalSeparator/>
            <Scrollbars renderView={(props: any) => <div {...props} id={SCROLL_CONTAINER_ID}/>}>
                <div className={styles.details}>
                    <BlockPreview
                        blockId={currentBlock.id}
                        currentlySelectedBlock={currentBlock}
                        onBlockUpdated={(callback) => callbacks.update(callback.id, {description: callback.description, steps: callback.questions })}
                    />
                </div>
            </Scrollbars>
            <div className={styles.buttonContainer}>
                <AddStepButton onAdd={handleAdd} disabled={!!questions.new} allowedQuestionTypes={questionTypes}
                               buttonLabel={'survey-creator.addCallbackStepButton'}/>
            </div>
            <VerticalSeparator/>
        </div>
    );
};
