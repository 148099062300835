import React from 'react';
import {
  ExecutableFunctionDefinition,
  ExecutableFunctionOutputDefinition,
  FunctionStep,
} from '../../../model';
import { FunctionStepInputsComponent } from './FunctionStepInputsComponent';
import { InputsArrayElement } from '../../InputsArray/InputsArrayElement';
import { Box } from '../../../../components/Box/Box';
import { AddButton } from '../../AddButton';

type Props = {
  functionInputs: ExecutableFunctionOutputDefinition[];
  executableFunction: ExecutableFunctionDefinition<any, any>;
  functionStep: FunctionStep;
  onChange: (step: FunctionStep) => void;
};

export const FunctionStepInputsWrapperComponent: React.FC<Props> = ({
  functionInputs,
  executableFunction,
  functionStep,
  onChange,
}) => {
  const onAdd = (inputArray: ExecutableFunctionOutputDefinition) => {
    const newInputArray = functionStep.functionInputs[inputArray.name] || [];

    const newDefaultValue = inputArray?.inputs?.map((input) => {
      switch (input.type) {
        case 'string':
          return { [input.name]: '' };
        case 'number':
          return { [input.name]: 0 };
        case 'boolean':
          return { [input.name]: false };
        default:
          return {};
      }
    });

    newInputArray.push(
      newDefaultValue.reduce((acc, curr) => ({ ...acc, ...curr }), {})
    );

    onChange({
      ...functionStep,
      functionInputs: {
        ...functionStep.functionInputs,
        [inputArray.name]: newInputArray,
      },
    });
  };

  const onRemove = (
    inputArray: ExecutableFunctionOutputDefinition,
    idx: number
  ) => {
    const array = [...functionStep.functionInputs[inputArray.name]];
    array.splice(idx, 1);

    const newFunctionInputs = {
      ...functionStep.functionInputs,
      [inputArray.name]: array,
    };

    onChange({
      ...functionStep,
      functionInputs: newFunctionInputs,
    });
  };

  const inputArrays = [];
  const inputs = [];

  functionInputs.forEach((input) => {
    if (input.type === 'inputsArray') {
      inputArrays.push(input);
    } else {
      inputs.push(input);
    }
  });

  return (
    <>
      <FunctionStepInputsComponent
        functionInputs={inputs}
        executableFunction={executableFunction}
        functionStep={functionStep}
        onChange={onChange}
      />
      <Box>
        {inputArrays.map((inputArray, arrayIdx) => {
          return (
            <div key={arrayIdx}>
              {(functionStep.functionInputs[inputArray.name] || []).map(
                (_, idx) => (
                  <div key={idx} data-test={'tmp-test-123'}>
                    <InputsArrayElement
                      key={idx}
                      onRemove={() => onRemove(inputArray, idx)}
                      dataTest={`remove-${inputArray.name}-button`}
                    >
                      <FunctionStepInputsComponent
                        recordKey={idx}
                        functionInputs={inputArray.inputs}
                        executableFunction={executableFunction}
                        functionStep={functionStep}
                        onChange={onChange}
                        parentName={inputArray.name}
                      />
                    </InputsArrayElement>
                  </div>
                )
              )}
              <AddButton
                onClick={() => onAdd(inputArray)}
                dataTest={`add-${inputArray.name}-button`}
                labelId="survey-creator.functionStepAddInputArrayButton"
              />
            </div>
          );
        })}
      </Box>
    </>
  );
};
