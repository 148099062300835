import React from 'react';
import {FormattedMessage} from 'react-intl';
import {InformationIcon} from '../../../icons';
import styles from './BlockDescriptionEmptyState.pcss';
import {Button} from '../../../components/Button/Button';

interface CreateDescriptionProps {
    messageId: string;
    onCreateDescription?: () => void;
}

export const BlockDescriptionEmptyState: React.FC<CreateDescriptionProps> = ({messageId, onCreateDescription}) => {
    return (
        <div className={styles.silenceFallbackEmptyState}>
            <InformationIcon/>

            <span className={styles.message}>
                <FormattedMessage id={messageId} values={{linebreak: <br/>}}/>

                {onCreateDescription && <div className={styles.buttonContainer}>
                  <Button
                      buttonType="normal"
                      onClick={onCreateDescription}
                      data-test="add-block-description-button"
                  >
                    <FormattedMessage id="survey-creator.addBlockDescriptionButton"/>
                  </Button>
                </div>
                }
            </span>
        </div>
    );
};
