import * as React from 'react';
import { useSurveyCreator } from './useSurveyCreator';
import { SurveyCreatorRouter } from './router';
import { Bot } from './model';

export type Intent = { name: string; displayName?: string; group?: string };
export type Group = {id: string; name: string }

export const getIntentDisplayName = (intent: Intent) =>
  intent && (intent.displayName || intent.name);

export const IntentsContext = React.createContext<Intent[]>([]);
export type SurveyContextType = ReturnType<
  typeof useSurveyCreator
>['definition'];
export const SurveyContext = React.createContext<SurveyContextType>(
  {} as SurveyContextType
);

export const SurveyCreatorContext = React.memo(({bot}: {bot: Bot}) => {
  const { definition, allIntents } = useSurveyCreator(bot);

  return <>
    <IntentsContext.Provider value={allIntents}>
      <SurveyContext.Provider value={definition}>
        <SurveyCreatorRouter/>
      </SurveyContext.Provider>
    </IntentsContext.Provider>
  </>
}, (prevProps, nextProps) => {
  return prevProps.bot.id === nextProps.bot.id
});