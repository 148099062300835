import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Modal } from '../../components/Modal/Modal';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { Button } from '../../components/Button/Button';
import { Checkbox } from '../../components/Checkbox';
import styles from './ConversationOptionsModal.pcss';
import {allDebugLineStepTypes, LineStepType} from '../ConversationDetails';

type Props = {
  onClose: () => void;
} & ConversationOptionsProps;

export type ConversationOptionsProps = {
  debugMode: boolean;
  showSSMLTags: boolean;
  selectedDebugSteps: LineStepType[]
  onSave: (props: {
    showSSMLTags: boolean;
    debugSteps: LineStepType[]
  }) => void;
};


export function ConversationOptionsModal({
  onClose,
  onSave,
  showSSMLTags,
  selectedDebugSteps
}: Props) {
  const [SSMLTagsChecked, setSSMLTagsChecked] = useState(showSSMLTags);
  const [selectedDebugStepsChecked, setSelectedDebugStepsChecked] = useState(selectedDebugSteps);

  const selectAll = () => {
    setSSMLTagsChecked(true);
    setSelectedDebugStepsChecked(allDebugLineStepTypes);
  }

  const deselectAll = () => {
    setSSMLTagsChecked(false);
    setSelectedDebugStepsChecked([]);
  }

  const checkedAll = SSMLTagsChecked && selectedDebugStepsChecked.length === allDebugLineStepTypes.length;

  return (
    <Modal
      modalSize="small"
      dataTest="conversation-options-modal"
      noPadding
      noFixedHeight
    >
      <ModalContent
        headerContent={
          <FormattedMessage id="conversations.options.modal.title" />
        }
        footerContent={
          <Button
            className={styles.saveButton}
            buttonType="link"
            dataTest="conversation-options-modal-save"
            onClick={() => {
              onSave({
                showSSMLTags: SSMLTagsChecked,
                debugSteps: selectedDebugStepsChecked
              });
              onClose();
            }}
          >
            <FormattedMessage id="conversations.options.modal.save" />
          </Button>
        }
        onClose={onClose}
        closeButtonDataTest="conversation-options-modal-close"
      >
        <div className={styles.contentContainer}>
          <p className={styles.message}>
            <FormattedMessage id="conversations.options.modal.description" />
          </p>
          <div className={styles.checkboxContainer}>
            <label
              htmlFor="all-checkbox"
              data-test="conversation-options-modal-all-checkbox"
            >
              <Checkbox
                  id="all-checkbox"
                  type="checkbox"
                  checked={checkedAll}
                  onChange={() => checkedAll ? deselectAll() : selectAll()}
              />

              <span className={styles.label}>
                <FormattedMessage id={checkedAll ? 'conversations.options.modal.deselectAll' : 'conversations.options.modal.selectAll'} />
              </span>
            </label>

            <div className={styles.checkboxOptions} >
              <label
                htmlFor="ssml-tags"
                data-test="conversation-options-modal-ssml-tags-checkbox"
              >
                <Checkbox
                  id="ssml-tags"
                  type="checkbox"
                  checked={SSMLTagsChecked}
                  onChange={() => setSSMLTagsChecked((prev) => !prev)}
                />
                <span className={styles.label}>
                  <FormattedMessage id="conversations.options.modal.ssmlTags" />
                </span>
              </label>
              {
                allDebugLineStepTypes.map((stepType) => {
                  return (
                    <label
                      key={stepType}
                      htmlFor={stepType}
                      data-test={`conversation-options-modal-${stepType}-checkbox`}
                    >
                      <Checkbox
                        id={stepType}
                        type="checkbox"
                        checked={selectedDebugStepsChecked.includes(stepType)}
                        onChange={() => {
                          setSelectedDebugStepsChecked(prevSelectedStepTypes => _.xor(prevSelectedStepTypes, [stepType]))
                        }}
                      />
                      <span className={styles.label}>
                        <FormattedMessage id={`conversations.options.modal.${stepType}`} />
                      </span>
                    </label>
                  )
                })
              }
          </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
