import cx from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import ReactTable from 'react-table';

import {
    actionsColumn,
    ActionsColumnProps,
    AfterActionCallback
} from '../components/callsList/columns/actions/ActionsColumn';
import { ColumnFactory } from '../components/callsList/columns/ColumnFactory';
import { CheckboxComponent } from '../components/CheckBox/CheckBoxComponent';
import { SelectComponent, Value } from '../components/Select/Select';
import { TooltipComponent } from '../components/Tooltip/Tooltip';
import styles from '../contacts/ContactsFilter.pcss';
import { PhraseFilter } from '../filters/PhraseFilter/PhraseFilter';
import { interpretationApiLocale } from '../language/botLanguage';
import { Header } from '../layout/Header';
import { MainPage } from '../layout/MainPage';
import { BlockQuestion, Bot, ConditionalQuestion, QuestionType, WordQuestion } from '../surveyCreator/model';
import { Intent } from '../surveyCreator/SurveyCreatorContext';
import { withCurrentBot } from '../utils/CurrentBot';
import { DataFetchProps, withDataFetch } from '../utils/DataFetch';
import { HttpClient } from '../utils/HttpClient';
import { NamesDictionaryProps, withNamesDictionary } from '../utils/NamesDictionary';
import { processUserInputForIntent, processUserInputForStep } from '../utils/userInput';

import {
    withSelectableRows,
    WithSelectableRowsProps,
    withTrainingModal,
    WithTrainingModalProps
} from './Inspector.hoc';
import inspectorStyles from './Inspector.pcss';
import { IntentTrainingBarContainer } from './IntentTrainingBarContainer/IntentTrainingBarContainer';
import { GetQuestionName, questionNameColumn } from './QuestionNameColumn';
import { TableControls } from './TableControls/TableControls';
import { TrainingModal, TrainingScope } from './TrainingModal';
import { sortByNameComparator } from '../utils/SortUtils';

type SelectableColumnFactory = (
    selectedRows: WithSelectableRowsProps['selectedRows'],
    onSelectRow: WithSelectableRowsProps['onSelectRow']
) => ColumnFactory;

export const selectedStyles = (selectedRows: number[], index: number) =>
    selectedRows.includes(index) ? inspectorStyles.selected : undefined

export function TableHeader(props: { labelKey: string }) {
    return (
        <div className={inspectorStyles.header}>
            <FormattedMessage id={props.labelKey} />
        </div>
    );
}

export function TableCell(props: {
    dataTest?: string,
    value?: string,
    className?: string,
    children?: React.ReactChild
}) {
    return (
        <div
            data-test={props.dataTest ?? 'table-cell'}
            data-sensitive="true"
            className={cx(inspectorStyles.cell, props.className)}
        >
            {
                props.children ??
                <div>{props.value}</div>
            }
        </div>
    );
}

const selectedRowsColumn: SelectableColumnFactory = (
    selectedRows,
    onSelectRow
) => () => ({
    Header: null,
    Cell: (row) => <TableCell
        className={selectedStyles(selectedRows, row.index)}
    >
        <CheckboxComponent
            dataTest="select-checkbox"
            onChange={() => onSelectRow(row.index)}
            value={selectedRows.includes(row.index)}
        />
    </TableCell>,
    maxWidth: 48,
    id: 'selectedRows',
    resizable: false
})

const textColumn: SelectableColumnFactory = (selectedRows) => () => ({
    Header: <TableHeader labelKey="inspector.text" />,
    Cell: (row: any) => (<TableCell
        dataTest={'text-cell'}
        className={selectedStyles(selectedRows, row.index)}
        value={row.original.text}
    />),
    minWidth: 250,
    id: 'text'
});

const intentValueAndProbabilityOrDash = (row: any, intentDisplayName: (intent: any) => string) => {
    const intentValue = row.original?.interpretation?.entities?.intent?.[0]?.value;
    const displayName = intentDisplayName(intentValue);
    if (!!intentValue) {
        return `${displayName} | ${_.ceil(_.get(row.original, 'interpretation.entities.intent[0].confidence', '1'), 2)}`
    }
    return '-';
}


const intentColumn: (
    intentDisplayName: GetIntentDisplayName,
    selectedRows: WithSelectableRowsProps['selectedRows']
) => ColumnFactory = (intentDisplayName, selectedRows) => () => ({
    Header: <TableHeader labelKey="inspector.recognizedIntent" />,
    id: 'recognizedIntent',
    Cell: (row: any) => (<TableCell
        dataTest={'intent-cell'}
        className={selectedStyles(selectedRows, row.index)}
        value={intentValueAndProbabilityOrDash(row, intentDisplayName)}
    />),
    maxWidth: 300,
    minWidth: 200
});

function synonymAndEntityValues(row: any) {
    const dictionaryValue = _.get(row.original, 'interpretation.dictionary[0].key');
    const dictionary =  _.get(row.original, 'interpretation.dictionary[0].distance') ? `${dictionaryValue} | p` : dictionaryValue;

    const datetime = _.get(row.original, 'interpretation.entities.datetime[0].body')
    const numberEntity = _.get(row.original, 'interpretation.entities.number[0].body')

    const allEntitiesKeys = Object.keys(_.get(row.original, 'interpretation.entities', {}));
    const nonSystemEntitiesValues = allEntitiesKeys
        .filter(key => !['number', 'datetime', 'intent'].includes(key))
        .map(e => _.get(row.original, `interpretation.entities.${e}[0].value`))

    const strings = [dictionary, datetime, numberEntity, ...nonSystemEntitiesValues].filter(e => !_.isNil(e));
    return _.isEmpty(strings) ? '-' : strings.join(' || ');
}

const synonymEntityColumn: SelectableColumnFactory = (selectedRows) => () => ({
    Header: <TableHeader labelKey="inspector.recognizedAnswer" />,
    id: 'recognizedAnswer',
    Cell: (row: any) => (<TableCell
        dataTest={'synonym-entity-cell'}
        className={selectedStyles(selectedRows, row.index)}
        value={synonymAndEntityValues(row)}
    />),
    maxWidth: 300,
    minWidth: 200
});

const occurrencesColumn: SelectableColumnFactory = (selectedRows) => () => ({
    Header: <TableHeader labelKey="inspector.occurrences" />,
    id: 'occurrences',
    Cell: (row: any) => (<TableCell
        className={selectedStyles(selectedRows, row.index)}
        value={`${_.get(row.original, 'occurrences', '1')}`}
    />),
    maxWidth: 100,
    minWidth: 100
});

type GetIntentDisplayName = (intent: string) => string;

type VoiceColumnsProps = {
    currentBot: Bot;
    afterAction: AfterActionCallback;
    questionName: GetQuestionName;
    intentDisplayName: GetIntentDisplayName;
    questions: BlockQuestion[];
    selectedRows: WithSelectableRowsProps['selectedRows'];
    onSelectRow: WithSelectableRowsProps['onSelectRow'];
    onTrainClick: ActionsColumnProps['onTrainClick'];
};

const voiceColumns = ({
    currentBot,
    afterAction,
    questionName,
    intentDisplayName,
    questions,
    selectedRows,
    onSelectRow,
    onTrainClick
}: VoiceColumnsProps) => [
        selectedRowsColumn(
            selectedRows,
            onSelectRow
        ),
        textColumn(selectedRows, onSelectRow),
        synonymEntityColumn(selectedRows, onSelectRow),
        intentColumn(intentDisplayName, selectedRows),
        questionNameColumn(questionName, selectedRows),
        occurrencesColumn(selectedRows, onSelectRow),
        actionsColumn({
            currentBot,
            callback: afterAction,
            questions,
            selectedRows,
            onTrainClick
        })
    ];

export type InspectorFilter = {
    page: number;
    pageSize: number;
    phrase: string;
    withoutAnyAnswers: boolean;
    withoutAnyIntents: boolean;
    questionId: Value;
    intent: Value;
};

export interface Entity {
    value: string;
    values: any[];
    type: string;
    body?: string;
}

export interface Token {
    raw: string;
    start_char: number;
    end_char: number;
    labels: {
        lemma: string;
        upos: string;
        xpos: string;
        morphological_feats?: string;
    };
}

export interface DictionaryEntry {
    key: string;
    usedSynonym: string;
    distance?: number;
}

export interface Interpretation {
    entities?: { [key: string]: Entity[] };
    tagger?: { tokens: Token[] };
    dictionary?: DictionaryEntry[];
}

export interface ToLearnMessage {
    id: string,
    timestamp: number;
    callId: string;
    questionId: string;
    text: string;
    interpretation: Interpretation;
    occurrences: number;
}

interface Messages {
    items: ToLearnMessage[];
    size: number;
}

type DataType = {
    messages: Messages;
};

type State = {
    messages: Messages;
    selectedQuestions: WordQuestion[];
    selectedMessages: ToLearnMessage[];
    questions: BlockQuestion[];
    filter: InspectorFilter;
    allIntents: Intent[];
    globalIntents: Intent[];
    check: boolean;
};

type ReduxProps = { currentBot: Bot };

type Props =
    DataFetchProps<DataType> &
    NamesDictionaryProps &
    ReduxProps &
    WrappedComponentProps &
    WithSelectableRowsProps &
    WithTrainingModalProps;

class InspectorComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            check: false,
            messages: {
                items: [],
                size: 0
            },
            questions: [],
            selectedQuestions: [],
            selectedMessages: [],
            filter: {
                page: 0,
                pageSize: 100,
                phrase: '',
                withoutAnyAnswers: false,
                withoutAnyIntents: false,
                questionId: undefined,
                intent: undefined,
            },
            allIntents: [],
            globalIntents: [],
        };
    }

    componentDidMount() {
        this.loadConversations();
        this.loadBotDefinition();
        this.fetchIntents();
        this.fetchGlobalIntents();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.currentBot?.id !== this.props.currentBot?.id) {
            this.loadConversations();
        }

        if (prevProps.data.messages !== this.props.data.messages) {
            this.setState(() => ({
                messages: this.props.data.messages
            }));
        }
    }

    removeConversation = async (messageId?: string) => {
        await this.loadConversations();
        if (!messageId) {
            this.props.onDeselectAll();
        } else {
            const index = this.props.data.messages.items.findIndex(message => message.id === messageId);
            this.props.onDeselectRow(index);
        }
    };

    questionName = (questionId: string) => {
        const currentQuestion = this.state.questions.find(question => question.id === questionId);
        return !!currentQuestion ? currentQuestion.name : '-';
    };

    intentDisplayName = (intentName: string) => {
        const intents = this.getIntents()
        const foundIntent = intents.find(intent => intent.name === intentName);
        return foundIntent ? foundIntent.displayName ? foundIntent.displayName : foundIntent.name : intentName;
    };

    getSelectedIds = () => {
        return this.state.selectedMessages.map(message => message.id);
    }

    markSelectedAsDone = async () => {
        const messageIds = this.getSelectedIds();
        await HttpClient.post({
            path: `/bots/${this.props.currentBot.id}/to-learn-messages/done`,
            body: { messageIds }
        });
        await this.removeConversation();
    }

    trainQuestionWithSelected = async (questionId: string, word: string, trainingInput: string, scope: TrainingScope) => {
        const messageIds = this.getSelectedIds();
        const values = processUserInputForStep(trainingInput);
        if (scope === 'local') {
            await HttpClient.post({
                path: `/bots/${this.props.currentBot.id}/to-learn-messages/train-answers`,
                body: {
                    questionId: questionId,
                    word,
                    values,
                    messageIds
                }
            });
        } else {
            await HttpClient.post({
                path: `/admin/global-synonyms`,
                body: {
                    word,
                    synonyms: values,
                    language: interpretationApiLocale[this.props.currentBot.languageCode]
                }
            });
        }
        await this.markSelectedAsDone();
        this.props.onCloseTrainingModal();
    }

    addSelectedToIntent = async (intent: string, trainingInput: string, scope: TrainingScope) => {
        const trainingData = processUserInputForIntent(trainingInput)
            .map(item => ({
                text: item,
                intent,
                language: interpretationApiLocale[this.props.currentBot.languageCode]
            }))
        if (scope === 'local') {
            await HttpClient.post({
                path: `/bots/${this.props.currentBot.id}/rasa-sentences`,
                body: { trainData: trainingData }
            });
        } else {
            await HttpClient.post({
                path: `/admin/global-train-data`,
                body: { trainData: trainingData }
            });
        }
        await this.markSelectedAsDone();
        this.props.onCloseTrainingModal();
    }

    getMessagesFromSelected = () => {
        const { selectedRows } = this.props;
        return selectedRows.map(index => this.state.messages.items[index]);
    }

    getQuestionsFromSelected = () => {
        const messages = this.getMessagesFromSelected();
        const questionIds = _.uniq(messages.map(message => message.questionId));
        return this.state.questions?.filter(question => questionIds.includes(question.id) && question.type === QuestionType.WORD) as WordQuestion[];
    }

    getIntents() {
        const allIntents = _.uniqBy([...this.state.allIntents, ...this.state.globalIntents], 'name');
        return _.sortBy(
            allIntents.map(i => ({id: i.name, ...i})),
            item => {
                return _.toLower(item.name);
            },
            'asc'
        );
    }

    intentsForOptions() {
        const intents = this.getIntents();
        const mappedIntents = intents.map(intent => ({
            ...intent,
            name: intent.displayName ? intent.displayName : intent.name
        }));
        return mappedIntents.sort(sortByNameComparator);
    }

    render() {
        const questionsOptions = this.state.questions
            .filter(this.isQuestion)
            .map(q => ({ id: q.id, name: q.name }))
            .sort(sortByNameComparator);
        return (
            <MainPage>
                <Header />
                <div className={inspectorStyles.container}>
                    <TrainingModal
                        data-test="training-modal"
                        isOpen={this.props.isTrainingModalOpen}
                        onCancel={this.props.onCloseTrainingModal}
                        onTrainQuestion={this.trainQuestionWithSelected}
                        onTrainIntent={this.addSelectedToIntent}
                        questions={this.state.selectedQuestions}
                        messages={this.state.selectedMessages}
                        localIntents={this.state.allIntents}
                        globalIntents={this.state.globalIntents}
                    />
                    <IntentTrainingBarContainer />
                    <div className={inspectorStyles.navbar}>
                        <TableControls
                            onSelectAll={() => this.props.onSelectAllRows(this.state.messages.items.length)}
                            isAllSelected={this.props.getAllSelected(this.state.messages.items.length)}
                            isAnySelected={this.props.selectedRows.length > 0}
                            onDelete={() => {
                                this.setState({
                                    ...this.state,
                                    selectedQuestions: this.getQuestionsFromSelected(),
                                    selectedMessages: this.getMessagesFromSelected()
                                }, () => {
                                    this.markSelectedAsDone();
                                });
                            }}
                            onTrain={() =>
                                this.setState({
                                    ...this.state,
                                    selectedQuestions: this.getQuestionsFromSelected(),
                                    selectedMessages: this.getMessagesFromSelected()
                                }, () => {
                                    this.props.onOpenTrainingModal();
                                })
                            }
                        />
                        <div data-test={'filters-container'} className={inspectorStyles.filtersContainer}>
                            <div className={inspectorStyles.toggle}>
                                <CheckboxComponent dataTest={'inspector-without-intents-checkbox'}
                                    labelId={'inspector.filters.withoutIntents'}
                                    value={this.state.filter.withoutAnyIntents}
                                    onChange={this.updateWithoutIntentsToggle} />
                            </div>
                            <div className={inspectorStyles.toggle}>
                                <CheckboxComponent dataTest={'inspector-without-answers-checkbox'}
                                    labelId={'inspector.filters.withoutAnswers'}
                                    value={this.state.filter.withoutAnyAnswers}
                                    onChange={this.updateWithoutAnswersToggle} />
                            </div>
                            <div className={inspectorStyles.questionSelect}>
                                <TooltipComponent
                                    tooltipText={this.props.intl.messages['inspector.filters.question.tooltip'] as string}
                                >
                                    <SelectComponent
                                        data-test={'inspector-question-filter'}
                                        options={questionsOptions}
                                        onChange={this.onQuestionFilterChange}
                                        value={this.state.filter.questionId}
                                        isClearable={true}
                                        isSearchable
                                        components={{
                                            Placeholder: () => (
                                                <div className={styles.placeholder}>
                                                    <span>
                                                        <FormattedMessage id="inspector.filters.question" />
                                                    </span>
                                                </div>
                                            )
                                        }}
                                    />
                                </TooltipComponent>
                            </div>
                            <div className={inspectorStyles.questionSelect}>
                                <TooltipComponent
                                    tooltipText={this.props.intl.messages['inspector.filters.intent.tooltip'] as string}
                                >
                                    <SelectComponent
                                        data-test={'inspector-intent-filter'}
                                        options={this.intentsForOptions()}
                                        onChange={this.updateIntent}
                                        value={this.state.filter.intent}
                                        isClearable={true}
                                        isSearchable
                                        components={{
                                            Placeholder: () => (
                                                <div className={styles.placeholder}>
                                                    <span>
                                                        <FormattedMessage id="inspector.filters.intent" />
                                                    </span>
                                                </div>
                                            )
                                        }}
                                    />
                                </TooltipComponent>
                            </div>
                            <PhraseFilter onChange={this.updatePhraseFilter} withDebounce={true}
                                phrase={this.state.filter.phrase} />

                        </div>
                    </div>
                    <div className={inspectorStyles.sentencesList} data-test={'sentences-list'}>
                        <ReactTable
                            data={this.state.messages.items}
                            manual={true}
                            columns={
                                voiceColumns({
                                    currentBot: this.props.currentBot,
                                    afterAction: this.removeConversation,
                                    intentDisplayName: this.intentDisplayName,
                                    questionName: this.questionName,
                                    questions: this.state.questions,
                                    selectedRows: this.props.selectedRows,
                                    onSelectRow: this.props.onSelectRow,
                                    onTrainClick: (question: WordQuestion, row: number) =>
                                        this.setState({
                                            ...this.state,
                                            selectedQuestions: [question],
                                            selectedMessages: [this.state.messages.items[row]]
                                        }, () => {
                                            this.props.onOpenTrainingModal();
                                        })
                                })
                                    .map((columnFactory: ColumnFactory) => columnFactory(this.props))
                            }
                            showPagination={this.state.messages.size >= 25}
                            pageSize={this.state.filter.pageSize}
                            pageSizeOptions={[25, 50, 100]}
                            page={this.state.filter.page}
                            pages={_.ceil(this.state.messages.size / this.state.filter.pageSize)}
                            onPageChange={page => {
                                this.onPageChange(page);
                            }}
                            onPageSizeChange={pageSize => {
                                this.onPageSizeChange(pageSize);
                            }}
                            minRows={1}
                            sortable={false}
                            noDataText=""
                        />
                    </div>

                </div>
            </MainPage>
        );
    }

    private isQuestion(question: BlockQuestion) {
        const q = question.type === 'conditional' ? (question as ConditionalQuestion).question : question;
        return q.type !== 'end'
            && q.type !== 'statement'
            && q.type !== 'go_to_block'
            && q.type !== 'http'
            && q.type !== 'set_variable'
            && q.type !== 'redirect'
            && q.type !== 'send_sms';
    }

    private setFilterState = (updatedFilter: Partial<InspectorFilter>) => {
        this.props.onDeselectAll();
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                ...updatedFilter,
                page: updatedFilter.page ? updatedFilter.page : updatedFilter.pageSize ? prevState.filter.page : 0,
                withoutAnyAnswers: updatedFilter.withoutAnyAnswers ? !prevState.filter.withoutAnyAnswers : prevState.filter.withoutAnyAnswers,
                withoutAnyIntents: updatedFilter.withoutAnyIntents ? !prevState.filter.withoutAnyIntents : prevState.filter.withoutAnyIntents,
            }
        }), this.loadConversations);
    }

    private updatePhraseFilter = (phrase: string) => this.setFilterState({ phrase });

    private updateWithoutAnswersToggle = () => this.setFilterState({ withoutAnyAnswers: true });

    private onQuestionFilterChange = (event?: any) => {
        const questionId = !!event ? event : null;
        this.setFilterState({ questionId });
    };

    private updateIntent = (event?: any) => {
        const intent = !!event ? event : null;
        this.setFilterState({ intent });
    }


    private updateWithoutIntentsToggle = () => this.setFilterState({ withoutAnyIntents: true });

    private loadConversations = () =>
        this.props.fetchData({
            path: `/bots/${this.props.currentBot?.id}/to-learn-messages`,
            dataKey: 'messages',
            body: {
                page: this.state.filter.page,
                pageSize: this.state.filter.pageSize,
                phrase: this.state.filter.phrase,
                onlyWithoutAnswers: this.state.filter.withoutAnyAnswers,
                onlyWithoutIntents: this.state.filter.withoutAnyIntents,
                questionId: this.state.filter?.questionId?.id,
                intent: this.state?.filter?.intent?.id,
            },
            type: 'POST'
        });

    private loadBotDefinition = async () => {
        const questions = await HttpClient.get({ path: `/bots/${this.props.currentBot.id}/creator` }).then(definitionResult =>
            definitionResult.data.blocks
                .flatMap(block => block.questions));
        this.setState(() => ({ questions }))
    };

    private fetchIntents() {
        return HttpClient.get({ path: `/bots/${this.props.currentBot.id}/intents` })
            .then(response => response.data)
            .then(intents => this.setState({ allIntents: intents }));
    }

    private fetchGlobalIntents() {
        return HttpClient.get({ path: `/admin/global-intents`, params: { language: this.props.currentBot.languageCode } })
            .then(response => response.data)
            .then(intents => this.setState({ globalIntents: intents }));
    }

    private onPageChange = (page: number) => this.setFilterState({ page });

    private onPageSizeChange = (pageSize: number) => this.setFilterState({ pageSize });

}
export const Inspector = _.flow(
    withDataFetch<DataType>(['messages']),
    withCurrentBot,
    withNamesDictionary,
    withSelectableRows,
    withTrainingModal,
    injectIntl
)(InspectorComponent);
