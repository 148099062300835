import { orderBy } from 'lodash';
import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import uuid from 'uuid';

import addIcon from '../../images/add-icon.svg';
import {callbackTypes} from '../model';
import {IntentsContext, SurveyContext, SurveyContextType} from '../SurveyCreatorContext';

import styles from './AddBlockButton.pcss';
import {AddBlockMenu, BlockType} from './AddBlockMenu';
import {AddItemModal} from './AddItemModal/AddItemModal';
import {useOutsideClick} from './useOutsideClick';
import { sortByNameComparator } from '../../utils/SortUtils';
import { useLocation, useParams } from 'react-router';
import { BotCreatorRouteMatch, getScopeForCreatorParams } from '../useCreatorRouter';

type Props = {
  dataTest?: string;
  disabled?: boolean;
  questionTypes?: string[];
  buttonLabel?: string;
}

const preventDefault = (e: any) => {
  e.stopPropagation();
  e.preventDefault();
};

export const AddBlockButton = (props: Props) => {
  const intl = useIntl();
  const {blocks, intents, callbacks} = useContext<SurveyContextType>(SurveyContext);
  const allIntents = useContext(IntentsContext);
  const [isOpened, changeOpen] = useState<boolean>(false);
  const [currentlyAddingType, setCurrentlyAddingType] = useState<BlockType>(undefined);
  const [itemsToShow, setItemsToShow] = useState<{ key: string, name: string }[]>(undefined);
  const optionsRef = useRef(null);
  const optionsOutsideClick = useOutsideClick(optionsRef, () => closeDropdown());
  const params = useParams<BotCreatorRouteMatch>();
  const { pathname } = useLocation()

  const handleClick = () => {
    if (!props.disabled && !isOpened) {
      changeOpen(true);
      optionsOutsideClick.addHandler();
    }
  };

  const closeDropdown = () => {
    changeOpen(false);
  };

  const handleAddBlock = (e: any) => {
    preventDefault(e);
    closeDropdown();
    addBlock();
  };
  const afterClickedAddOption = (e: MouseEvent, open: any) => {
    preventDefault(e);
    optionsOutsideClick.removeHandler();
    open(e);
  };
  const handleAddIntent = (open: any) => (e: MouseEvent) => {
    setCurrentlyAddingType(BlockType.Intent);
    setItemsToShow(
      allIntents
        .filter(intent => !intents.get().map(botIntent => botIntent.name).includes(intent.name))
        .map(intent => ({key: intent.name, name: intent.displayName || intent.name}))
        .sort(sortByNameComparator)
    );
    afterClickedAddOption(e, open);
  };
  const handleAddCallback = (open: any) => (e: MouseEvent) => {
    setCurrentlyAddingType(BlockType.Callback);
    const existingCallbacks = Object.keys(callbacks.all);
    setItemsToShow(
      orderBy(
        callbackTypes
          .filter(el => !existingCallbacks.includes(el))
          .map(el => ({key: el, name: intl.messages[`survey-creator.callbacks.${el}`] as string})),
        'name',
        'asc'
      )
    );
    afterClickedAddOption(e, open);
  };
  const addBlock = async () => {
    const newBlock = {
      id: uuid.v4(),
      name: `${intl.messages['survey-creator.defaultBlockNamePrefix']} ${blocks.get().length + 1}`,
      groupId: blocks.getCurrentStepsOrBlockForScope(getScopeForCreatorParams(params, pathname))?.groupId,
      questions: [],
    };
    await blocks.add(newBlock, params.blockId);
  };

  const handleConfirm = value => {
    closeDropdown();
    optionsOutsideClick.removeHandler();
    if (currentlyAddingType === BlockType.Callback) {
      callbacks.add(value);
    } else {
      intents.add(value.trim(), intl.messages);
    }
  };

  const currentlyAddingContext = currentlyAddingType === BlockType.Intent
    ? {
      addLabelId: 'survey-creator.intent.addNewIntentLabel',
      placeholderLabelId: 'survey-creator.intent.placeholder',
      dataTest: 'new-survey-intent-popup',
      forbidAddingItems: false
    }
    : {
      placeholderLabelId: 'survey-creator.callbacks.addPlaceholder',
      dataTest: 'add-callbacks-modal',
      forbidAddingItems: true
    };

  return (
    <div className={styles.addBlockContainer}>
      <div tabIndex={0} data-test="add-block-button" className={styles.addBlockButton} onClick={handleClick}>
        <img src={addIcon} alt="add" className={styles.plusIcon} />
        <FormattedMessage id={props.buttonLabel ? props.buttonLabel : `survey-creator.addBlockButtonLabel`} />

        {isOpened && (
          <div data-test={props.dataTest} className={styles.options} ref={optionsRef}>
            <AddItemModal
              addLabelId={currentlyAddingContext.addLabelId}
              placeholderLabelId={currentlyAddingContext.placeholderLabelId}
              dataTest={currentlyAddingContext.dataTest}
              forbidAddingItems={currentlyAddingContext.forbidAddingItems}
              onCreate={handleConfirm}
              onSelect={handleConfirm}
              onClose={() => {
                closeDropdown();
                optionsOutsideClick.removeHandler();
              }}
              items={itemsToShow}
              renderTrigger={({open}) => (
                <AddBlockMenu
                  onAddBlockClick={handleAddBlock}
                  onAddIntentClick={handleAddIntent(open)}
                  onAddCallbackClick={handleAddCallback(open)}
                />
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};
