import React from 'react';

import {
    BlockQuestion,
    CityOrCommunityQuestion,
    ConditionalQuestion,
    GenericOrderQuestion,
    GoToBlockQuestion,
    HelpProposeQuestion,
    RedirectStep,
    SetVariableStep,
    SmsStep,
    SpellingQuestion,
    StatementStep,
    QuestionType,
    DataCollectionStep,
    FunctionStep
} from '../../model';
import { AnonymizeAnswerToggle } from '../AnonymizeAnswerToggle';
import { CityOrCommunityStep } from '../CityOrCommunityStep';
import { ConfirmationDictionaryComponent } from '../ConfirmationDictionaryComponent';
import { DuplexToggle } from '../DuplexToggle';
import { GenericOrderStep } from '../GenericOrderStep';
import { GoToBlockSelect } from '../GoToBlockSelect';
import { LocalIntentsContainer } from '../IntentsTab/LocalIntents/LocalIntentsContainer';
import { BasicMessages } from '../messages/BasicMessages';
import { HelpProposeMessages } from '../messages/HelpProposeMessages';
import { RedirectMessage } from '../RedirectMessage';
import { RedirectToInput } from '../RedirectToInput';
import { SaveAs } from '../SaveAs';
import { SetVariable } from '../SetVariable';
import { SmsConfigTab } from '../SmsConfigTab';
import { SpecialCharactersTab } from '../SpecialCharactersTab/SpecialCharactersTab';
import { PostSpeechTimeoutField } from '../PostSpeechTimeoutField/PostSpeechTimeoutField';
import { PreSpeechTimeoutField } from '../PreSpeechTimeoutField/PreSpeechTimeoutField';
import { RecognizerField } from '../RecognizerField/RecognizerField';
import { StatementComponent } from '../StatementComponent';
import { StopRecordingToggle } from '../RedirectStep/StopRecordingToggle';

import { dateQuestionFactory } from './date-question-factory';
import { datetimeQuestionFactory } from './datetime-question-factory';
import { sendEmailFactory } from './email-step-component-factory';
import { endQuestionFactory } from './end-question-factory';
import { httpStepFactory } from './http-step-factory';
import { numericalQuestionFactory } from './numerical-question-factory';
import { openQuestionFactory } from './open-question-factory';
import { TabFactory } from './question-component-factory-model';
import { StepTabType } from './types';
import { wordQuestionFactory } from './word-question-factory';
import { RedirectConfig } from '../RedirectStep/RedirectConfig';
import { DataCollectionStepComponent } from '../DataCollectionStepComponent';
import { RedirectHeaders } from '../RedirectHeadersComponent';
import { FunctionStepComponent } from '../FunctionStep/FunctionStepComponent';

export const questionComponentsFactory = (type: string): TabFactory => questionPartsComponents[type] || {};

const conditionalQuestionHandlerFor = (tab: string) => ({ question, onChange }) => {
    const component = questionComponentsFactory(question.question.type);
    const conditionalChange = (questionState: Partial<BlockQuestion>) => {
        onChange((q) => ({
            ...q,
            question: {
                ...q.question,
                ...questionState,
            },
        }));
    };
    return component[tab] ? component[tab]({ question: question.question, onChange: conditionalChange, isConditional: true }) : null;
};
const goToBlockFactory: TabFactory<GoToBlockQuestion> = {
    [StepTabType.General]: ({ question, onChange }) => {
        return (
            <>
                <GoToBlockSelect question={question} onChange={onChange} />
            </>
        );
    }
};
const conditionalFactory: TabFactory<ConditionalQuestion> = {
    [StepTabType.Question]: conditionalQuestionHandlerFor(StepTabType.Question),
    [StepTabType.General]: conditionalQuestionHandlerFor(StepTabType.General),
    [StepTabType.SpecialCharacters]: conditionalQuestionHandlerFor(StepTabType.SpecialCharacters),
    [StepTabType.Answer]: conditionalQuestionHandlerFor(StepTabType.Answer),
    [StepTabType.Intents]: conditionalQuestionHandlerFor(StepTabType.Intents),
    questionLabelId: (type) => {
        const questionLabelId = questionComponentsFactory(type).questionLabelId;
        return questionLabelId && questionLabelId(type)
    }
};

const spellingQuestionFactory: TabFactory<SpellingQuestion> = {
    [StepTabType.Question]: ({ question, onChange, isConditional }) => {
        return (
            <>
                <BasicMessages question={question} onChange={onChange} isConditional={isConditional} />
            </>
        );
    },
    [StepTabType.General]: ({ question, onChange }) => {
        return (
            <>
                <RecognizerField question={question} onChange={onChange} />
                <SaveAs question={question} onChange={onChange} />
                <DuplexToggle question={question} onChange={onChange} />
                <AnonymizeAnswerToggle question={question} onChange={onChange} />
                <PostSpeechTimeoutField question={question} onChange={onChange} />
                <PreSpeechTimeoutField question={question} onChange={onChange} />
            </>
        );
    },
    [StepTabType.Intents]: ({ question, onChange }) => <LocalIntentsContainer question={question} onChange={onChange} />,
    [StepTabType.SpecialCharacters]: (props) => <SpecialCharactersTab {...props} />
};

const sendSmsFactory: TabFactory<SmsStep> = {
    [StepTabType.Question]: ({ question, onChange }) => {
        return (
            <SmsConfigTab question={question} onChange={onChange} />
        );
    },
    questionLabelId: () => 'step.tabs.question.sms'
};

const setVariableStepFactory: TabFactory<SetVariableStep> = {
    [StepTabType.General]: ({ question, onChange }) => {
        return (
            <>
                <SetVariable question={question} onChange={onChange} />
            </>
        );
    }
};
const setDataCollectionStepFactory: TabFactory<DataCollectionStep> = {
    [StepTabType.General]: ({ question, onChange }) => {
        return (
            <>
                <DataCollectionStepComponent question={question} onChange={onChange} />
            </>
        );
    }
};
const statementFactory: TabFactory<StatementStep> = {
    [StepTabType.Question]: ({ question, onChange }) => {
        return (
            <>
                <StatementComponent question={question} onChange={onChange} />
            </>
        );
    },
    [StepTabType.General]: (props) => {
        return (
            <>
                <DuplexToggle {...props} labelId={'survey-creator.duplexStopSpeechLabel'} />
            </>
        );
    },
    questionLabelId: () => 'step.tabs.question.statement'
};
const helpProposeQuestionFactory: TabFactory<HelpProposeQuestion> = {
    [StepTabType.Question]: ({ question, onChange }) => {
        return (
            <>
                <HelpProposeMessages question={question} onChange={onChange} />
            </>
        );
    },
    [StepTabType.General]: ({ question, onChange }) => {
        return (
            <>
                <RecognizerField question={question} onChange={onChange} />
                <SaveAs question={question} onChange={onChange} />
                <AnonymizeAnswerToggle question={question} onChange={onChange} />
                <PostSpeechTimeoutField question={question} onChange={onChange} />
                <PreSpeechTimeoutField question={question} onChange={onChange} />
            </>
        )
    },
    [StepTabType.Answer]: ({ question, onChange }) => {
        return (
            <>
                <ConfirmationDictionaryComponent question={question} onChange={onChange} noReactionGoTo />
            </>
        );
    }
};
const redirectFactory: TabFactory<RedirectStep> = {
    [StepTabType.Question]: ({ question, onChange }) => {
        return (
            <>
                <RedirectToInput question={question} onChange={onChange} />
                <RedirectMessage question={question} onChange={onChange} />
            </>
        );
    },
    [StepTabType.General]: ({ question, onChange }) => {
        return (
            <>
                <StopRecordingToggle question={question} onChange={onChange} />
                <RedirectConfig question={question} onChange={onChange} />
                <RedirectHeaders question={question} onChange={onChange} />
            </>
        )
    },
    questionLabelId: () => 'step.tabs.question.redirect'
};

const genericOrderFactory: TabFactory<GenericOrderQuestion> = {
    [StepTabType.General]: ({ question, onChange }) => {
        return (
            <>
                <GenericOrderStep question={question} onChange={onChange} />
            </>
        );
    }
};

const cityOrCommunityFactory: TabFactory<CityOrCommunityQuestion> = {
    [StepTabType.General]: ({ question, onChange }) => {
        return (
            <>
                <CityOrCommunityStep question={question} onChange={onChange} />
            </>
        );
    }
};

const functionStepFactory: TabFactory<FunctionStep> = {
    [StepTabType.General]: ({ question, onChange }) => {
        return <FunctionStepComponent functionStep={question} onChange={onChange} />
    }
}

const questionPartsComponents: Record<QuestionType, TabFactory<any>> = {
    'word': wordQuestionFactory,
    'numerical': numericalQuestionFactory,
    'end': endQuestionFactory,
    'go_to_block': goToBlockFactory,
    'datetime': datetimeQuestionFactory,
    'date': dateQuestionFactory,
    'conditional': conditionalFactory,
    'open': openQuestionFactory,
    'spelling': spellingQuestionFactory,
    'send_sms': sendSmsFactory,
    'send_email': sendEmailFactory,
    'http': httpStepFactory,
    'set_variable': setVariableStepFactory,
    'statement': statementFactory,
    'help_propose': helpProposeQuestionFactory,
    'redirect': redirectFactory,
    'generic_order': genericOrderFactory,
    'city_or_community': cityOrCommunityFactory,
    'data_collection': setDataCollectionStepFactory,
    'function': functionStepFactory
};
