import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {ExecutableFunctionDefinition, ExecutableFunctionOutputDefinition, FunctionStep} from '../../../model';
import { renderInputTypeToWidget } from '../FunctionStepInputWidgets';
import { Box } from '../../../../components/Box/Box';
import styles from '../FunctionStepComponent.pcss';

export function isLLMFunction(fn: ExecutableFunctionDefinition<any, any>) {
  return fn?.type === 'llm';
}

type FunctionStepProps = {
  functionInputs: ExecutableFunctionOutputDefinition[];
  executableFunction: ExecutableFunctionDefinition<any, any>;
  functionStep: FunctionStep;
  onChange: (step: FunctionStep) => void;
  recordKey?: string;
  parentName?: string;
};

export const FunctionStepInputsComponent: React.FC<FunctionStepProps> = ({
  functionInputs,
  executableFunction,
  functionStep,
  onChange,
  recordKey,
  parentName,
}) => {
  const intl = useIntl();

  const isPartOfArray = Boolean(recordKey !== undefined && parentName);

  return (
    <>
      {functionInputs.map(
        ({ name, description, placeholder, render, options }) => {
          const Widget = renderInputTypeToWidget(render);
          const displayedDescription = isLLMFunction(executableFunction)
            ? description
            : intl.formatMessage({
                id: `survey-creator.functionStep.functions.${description}`,
              });

          return (
            <Box
              dataTest={`function-input-${name}`}
              key={name}
              grey={isPartOfArray}
            >
              <div
                className={styles.label}
                data-test={`function-input-${name}-label`}
              >
                {isLLMFunction(executableFunction) ? (
                  name
                ) : (
                  <FormattedMessage
                    id={`survey-creator.functionStep.functions.${name}`}
                  />
                )}
              </div>
              {displayedDescription && (
                <div
                  className={styles.description}
                  data-test={`function-input-${name}-description`}
                >
                  {displayedDescription}
                </div>
              )}
              <Widget
                value={
                  isPartOfArray
                    ? functionStep.functionInputs[parentName] &&
                      functionStep.functionInputs[parentName][recordKey] &&
                      functionStep.functionInputs[parentName][recordKey][name]
                    : functionStep.functionInputs[name]
                }
                onChange={(value) => {
                  if (isPartOfArray) {
                    const newValue = {
                      ...functionStep.functionInputs[parentName][recordKey],
                      [name]: value,
                    };

                    const newArray = [...functionStep.functionInputs[parentName]];
                    newArray[recordKey] = newValue;

                    onChange({
                      ...functionStep,
                      functionInputs: {
                        ...functionStep.functionInputs,
                        [parentName]: newArray,
                      },
                    });
                  } else {
                    onChange({
                      ...functionStep,
                      functionInputs: {
                        ...functionStep.functionInputs,
                        [name]: value,
                      },
                    });
                  }
                }}
                {...(options && { options })}
                {...(placeholder && {
                  placeholder: intl.formatMessage({
                    id: `survey-creator.functionStep.functions.${placeholder}`,
                  }),
                })}
              />
            </Box>
          );
        }
      )}
    </>
  );
};
