import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Box } from '../../../components/Box/Box';
import { InputWithLabel } from '../../../components/InputWithLabel/InputWithLabel';
import { SelectWithLabel } from '../../../components/SelectWithLabel/SelectWithLabel';
import { RedirectStep } from '../../model';
import { StepElementProps } from '../StepElementProps';

import styles from './RedirectConfig.pcss';
import { TextAreaWithVariables } from '../../../components/TextArea/TextAreaWithVariables';
import { SurveyContext } from '../../SurveyCreatorContext';
import { ElementWithLabel } from '../../../components/ElementWithLabel/ElementWithLabel';

export const RedirectConfig = ({
  question,
  onChange,
}: StepElementProps<RedirectStep>) => {
  const intl = useIntl();
  const redirectTypeOptions = [
    {
      id: 'dial',
      name: intl.messages[
        'survey-creator.redirectStep.redirectTypeOptions.dial'
      ] as string,
    },
    {
      id: 'transfer',
      name: intl.messages[
        'survey-creator.redirectStep.redirectTypeOptions.transfer'
      ] as string,
    },
    {
      id: 'local',
      name: intl.messages[
        'survey-creator.redirectStep.redirectTypeOptions.local'
      ] as string,
    },
  ];
  const { variables } = useContext(SurveyContext);
  const availableVariables = variables.getVariablesForCreator();

  return (
    <Box border>
      <div className={styles.redirectConfig}>
        <SelectWithLabel
          dataTest="redirect-type"
          labelId={'survey-creator.redirectStep.redirectType'}
          value={redirectTypeOptions.find(
            (option) => option.id === question.redirectType
          )}
          options={redirectTypeOptions}
          onChange={(value) =>
            onChange({
              ...question,
              redirectType: value.id,
              redirectTrunk: '',
              redirectDestination: value.id === 'local'
                ? ''
                : question.redirectDestination,
            })
          }
          noPadding
        />
        <InputWithLabel
          dataTest="redirect-trunk"
          labelId={'survey-creator.redirectStep.redirectTrunk'}
          value={question.redirectTrunk}
          onChange={(value) =>
            onChange({ ...question, redirectTrunk: value as string })
          }
          noPadding
          disabled={question.redirectType !== 'dial'}
        />
      </div>
      <ElementWithLabel
        inner
        noPadding
        labelId={'survey-creator.redirectStep.destination'}
        className={styles.redirectDestination}
      >
        <TextAreaWithVariables
          dataTest="redirect-destination"
          value={question.redirectDestination}
          onChange={(value) =>
            onChange({ ...question, redirectDestination: value as string })
          }
          asInput
          availableVariables={availableVariables}
          disabled={question.redirectType === 'local'}
        />
      </ElementWithLabel>
    </Box>
  );
};
