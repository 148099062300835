import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router';
import moment from 'moment';

import { Button } from '../components/Button/Button';
import { createUrlOrderingState } from '../components/urlOrderingState';
import { Header } from '../layout/Header';
import { MainPage } from '../layout/MainPage';
import { useAsyncFn } from '../utils/asyncUtils';
import { getBotUrlId, useCurrentBot } from '../utils/CurrentBot';
import { HttpClient } from '../utils/HttpClient';
import { useSurveyConfig } from '../views/Settings/Settings.hooks';

import { ConversationDetails } from './ConversationDetails';
import styles from './Conversations.pcss';
import { ConversationViews } from './ConversationViews';
import { AllFiltersPage, Events } from './filters';
import { ConversationsFiltersView } from './filters/ConversationsFiltersView';
import { ConversationsList } from './list';
import { Call } from './models/Call';
import { useReports } from './reports/useReports';

export const {
    parse: parseConversationsOrdering,
    toggleOrdering: toggleConversationOrdering
} = createUrlOrderingState({
    allowedProperties: ['duration', 'startedAt'],
    defaultSort: 'startedAt'
});

export const Conversations = withRouter((props: RouteComponentProps<{ callId: string }>) => {
    const currentBot = useCurrentBot();
    const [reports, loadingReports, generateReport, downloadReport, filters, setFilters] = useReports(currentBot.id);
    const surveyConfig = useSurveyConfig();
    const { callId } = useParams<{ callId: string }>();
    const ordering = useMemo(() => parseConversationsOrdering(props.location.search), [props.location.search]);
    const [showFilters, setShowFilters] = useState<boolean>();
    const [showReportsDropdown, setShowReportsDropdown] = useState(false);
    const [items, setItems] = useState<Call[]>([]);
    const [itemsCount, setItemsCount] = useState();
    const [hasMore, setHasMore] = useState<boolean>();

    const onSelectCall = useCallback(
        (call: Call) => {
            props.history.push({
                ...props.location,
                pathname: `/${getBotUrlId(currentBot)}/${call.id}`
            });
        },
        [props.history, props.location, currentBot]
    );

    const setConversationFilters = (conversationFilters: Record<string, string>) => {
        props.history.replace({
            ...props.location,
            pathname: `/${getBotUrlId(currentBot)}`
        });
        setFilters(conversationFilters)
    }

    const {
        state: { loading },
        callback: loadItems,
        cancelPendingTasks
    } = useAsyncFn(
        (lastItemId?: string) => {
            const {
                startDate,
                endDate,
                callDurationMin,
                callDurationMax,
                information,
                recognizedIntents,
                results,
                surveyQuestions,
                technicalInformation,
                ...rest
            } = filters;
            return HttpClient.post({
                path: `/bots/${currentBot.id}/calls/filter`,
                body: {
                    ...rest,
                    startDate: moment(startDate).valueOf() || null,
                    endDate: moment(endDate).valueOf() || null,
                    callDuration: {
                        min: parseInt(callDurationMin, 10) || undefined,
                        max: parseInt(callDurationMax, 10) || undefined,
                    },
                    filters: {
                        information,
                        recognizedIntents,
                        results,
                        surveyQuestions,
                        technicalInformation,
                    },
                    pageSize: 50,
                    orderBy: [ordering],
                    ...(lastItemId ? { after: lastItemId } : {})
                }
            });
        },
        [currentBot.id, filters, ordering],
        {
            minimumQueryLoadingTime: 150
        }
    );

    const fetchPage = useCallback(
        (lastItemId?: string) => {
            return loadItems(lastItemId)
                .then(({ data }) => ({ items: data.items, itemsCount: data.size, hasMore: data.hasMore }))
                .then(data => {
                    setItems(currentItems => [...currentItems, ...data.items]);
                    setItemsCount(data.itemsCount);
                    setHasMore(data.hasMore);
                });
        },
        [loadItems, setItems, setItemsCount, setHasMore]
    );


    const refreshList = useCallback(() => {
        setHasMore(false);
        setItemsCount(undefined);
        setItems([]);
        fetchPage();
    }, [fetchPage, setHasMore, setItemsCount, setItems]);

    useEffect(() => {
        setHasMore(false);
        setItemsCount(undefined);
        setItems([]);
        fetchPage();
        return cancelPendingTasks;
    }, [filters, fetchPage, currentBot.id, cancelPendingTasks, setHasMore, setItemsCount, setItems]);

    if (showFilters) {
        return <AllFiltersPage
          filters={filters}
          setFilters={setConversationFilters}
          onClose={() => setShowFilters(false)}
          timezone={surveyConfig?.displayedConfig?.timeZone}
        />;
    }

    return (
        <MainPage>
            <Header />
            <div className={styles.page} data-test="conversations">
                <div>
                    <div className={styles.section}>
                        <ConversationViews />
                        <ConversationsFiltersView
                            onOpenReportsDropdown={() => setShowReportsDropdown(true)}
                            onCloseReportsDropdown={() => setShowReportsDropdown(false)}
                            showReportsDropdown={showReportsDropdown}
                            timezone={surveyConfig?.displayedConfig?.timeZone}
                            reports={reports}
                            loadingReports={loadingReports}
                            generateReport={generateReport}
                            downloadReport={downloadReport}
                            filters={filters}
                            setFilters={setConversationFilters}
                        />
                    </div>
                </div>
                <div className={styles.filterBar}>
                    <div>
                        <Button
                            onClick={() => setShowFilters(true)}
                            dataTest="conversations-all-filters-button"
                            translateText="conversations.filters.showAll"
                        />
                    </div>
                    <div>
                        <Events filters={filters} setFilters={setConversationFilters} />
                    </div>
                </div>
                <div className={styles.callSection}>
                    <ConversationsList
                        hasMore={hasMore}
                        conversations={items}
                        fetchNextPage={fetchPage}
                        itemsCount={itemsCount}
                        onSelect={onSelectCall}
                        loading={loading}
                        refresh={refreshList}
                        isSurveyConfigLoading={surveyConfig.isLoading}
                        timezone={surveyConfig?.displayedConfig?.timeZone}
                    />
                    <div className={styles.conversationDetailsContainer}>
                        <ConversationDetails
                          callId={callId}
                          botId={currentBot.id}
                          timezone={surveyConfig?.displayedConfig?.timeZone}
                        />
                    </div>
                </div>
            </div>
        </MainPage>
    );
});
