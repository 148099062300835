import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { isEqual } from 'lodash';

import { useCurrentBot } from '../../../utils/CurrentBot';

import { SettingsVariablesProps } from '../../../survey/ContactVariablesComponent';
import { SurveyDefinitionVariable } from '../../../surveyCreator/model';
import { SavableProps, VariablesState } from '../types';
import { VariablesService } from './VariablesService';

type VariablesHook = SettingsVariablesProps & SavableProps
type ResettableState = {
  resetChanges: () => void
};

// consider using useVariablesWithSipHeaderMappings instead
export function useVariables(): VariablesHook {
  const currentBot = useCurrentBot();
  const queryClient = useQueryClient();
  const variablesQueryKey = ['bots', currentBot.id, 'variables'];

  const {
    isSuccess: isFetchingReady,
    data: fetchingData,
  } = useQuery(variablesQueryKey, () => VariablesService.fetch(currentBot.id));
  const { mutate: updateVariables } = useMutation((variables: SurveyDefinitionVariable[]) => {
    return VariablesService.update(currentBot.id, variables.filter(v => v.origin !== 'system'));
  }, {
    onMutate: async newVariables => {
      await queryClient.cancelQueries(variablesQueryKey);
      const previousVariables = queryClient.getQueryData(variablesQueryKey);
      queryClient.setQueryData(variablesQueryKey, () => newVariables);
      return {previousVariables};
    },
    onError: (err, newVariables, context: { previousVariables: SurveyDefinitionVariable[] }) => {
      queryClient.setQueryData(variablesQueryKey, context.previousVariables);
    },
  });

  const stateVariables = useStateVariables({ data: fetchingData, isReady: isFetchingReady });

  return {
    ...stateVariables,
    onSave: async () => updateVariables(stateVariables.variables),
    onCancel: () => stateVariables.resetChanges(),
  };
}

function useStateVariables(initialVariables: { data: SurveyDefinitionVariable[], isReady: boolean }): SettingsVariablesProps & ResettableState {
  const [variables, setVariables] = useState<VariablesState>(initialVariables.data);
  const [isReady, setIsReady] = useState<boolean>(initialVariables.isReady);

  useEffect(() => {
    setVariables(initialVariables.data);
    setIsReady(initialVariables.isReady);
  }, [initialVariables.data, initialVariables.isReady]);

  const hasChanges = useMemo(() => !isEqual(variables, initialVariables.data), [variables, initialVariables.data]);

  function createVariable(variable: SurveyDefinitionVariable) {
    setVariables(oldVariables => [
      ...oldVariables,
      variable,
    ]);
  }

  function editVariable(id: string) {
    return (variable: Omit<SurveyDefinitionVariable, 'id'>) => {
      setVariables((oldVariables) => {
        return oldVariables.map(oldStateVariable => {
          if (oldStateVariable.id !== id) {
            return oldStateVariable;
          }
          return {
            ...oldStateVariable,
            ...variable,
            id,
          } as SurveyDefinitionVariable;
        });
      });
    };
  }

  function removeVariable(id: string) {
    setVariables(oldStateVariables => oldStateVariables.filter(v => v.id !== id))
  }

  function resetChanges() {
    setVariables(initialVariables.data);
  }

  return {
    variables,
    isReady,
    hasChanges,
    createVariable,
    editVariable,
    removeVariable,
    resetChanges,
  };
}
