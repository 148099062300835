import React, {useState} from 'react';
import {InterpretEntityValue, TextInterpretation} from '../models/Transcript';
import styles from './StepLine.pcss';
import {DetailsLineBody, InterpretationStepLineDetails} from './InterpretationStepLineDetails';
import {EntityButtonIcon, IntentIcon} from '../../icons';
import {useIntl} from 'react-intl';
import {LanguagesIcon} from '../../icons/LanguagesIcon';
import {InterpretationLineElement} from './InterpretationLineElement';

type Props = {
  interpretation?: TextInterpretation;
};

export function InterpretationStepLine({ interpretation }: Props) {
  const intl = useIntl();

  const [lineDetails, setLineDetails] = useState<null | {
    title: string;
    details: DetailsLineBody;
  }>(null);


  // todo: write test for !interpretation.entities
  if (!interpretation) {
    return null;
  }

  return (
    <>
      {interpretation?.translation && (
          <InterpretationLineElement onClick={() => setLineDetails({
              title: intl.formatMessage({id: `conversations.interpretation.translation`}), details: interpretation.translation })}>
            <LanguagesIcon />
            <span className={styles.text}> {interpretation.translation.originalText} </span>
          </InterpretationLineElement>
      )}
      {Object.entries(interpretation?.entities || {}).map(([key, value]) => {
        const isIntent = key === 'intent';
        const title =
          `${isIntent
              ? intl.messages['conversations.interpretation.intent']
              : `${intl.messages['conversations.interpretation.entity']} ${key}`} ${(value[0] as InterpretEntityValue).value}`;
        // todo: test for no intent
        if (
          isIntent &&
          (value[0] as InterpretEntityValue)?.value === 'No intent'
        ) {
          return null;
        }

        return <InterpretationLineElement key={key} onClick={() => setLineDetails({title, details: value})}>
          {isIntent ? <IntentIcon animationClass={styles.icon} /> : <EntityButtonIcon animationClass={styles.icon} />}
          <span className={styles.text}>{title}</span>
        </InterpretationLineElement>

      })}
      {lineDetails && (
        <InterpretationStepLineDetails
          title={lineDetails.title}
          details={lineDetails.details}
          onClose={() => setLineDetails(null)}
        />
      )}
    </>
  );
}
