import classNames from 'classnames';
import * as React from 'react';
import {FormattedMessage} from 'react-intl';

import {EditButtons} from './EditButtons';
import {BlockDescriptionTabType, StepTabType} from './factory/types';
import styles from './StepDetail.pcss';

type StepHeaderProps = {
    activeTab: StepTabType | BlockDescriptionTabType,
    changeTab: (tab: StepTabType | BlockDescriptionTabType) => void,
    onClose: () => void,
    onSave: () => Promise<void> | void,
    disabledSave: boolean,
    availableTabs: string[],
    questionLabelId?: string,
    errors?: Partial<Record<StepTabType | BlockDescriptionTabType, string>>,
};

type TabHeaderButtonProps = {
    warning?: boolean;
    active: boolean;
    onClick: (e: React.MouseEvent) => void;
    dataTest?: string;
    display: boolean;
    messageId: string;
}

export const StepHeader: React.FC<StepHeaderProps> = ({
    errors= {},
    activeTab,
    changeTab,
    availableTabs,
    questionLabelId,
    onClose,
    onSave,
    disabledSave
}) => {
    const errorTooltipContent = Object.entries(errors)
      .filter(([tab], i, array) => array.length > 1 || tab !== activeTab)
      .map(([tab, message]) => message);
    const resolveTestId = (tabName: string, warning: boolean) => warning ? `${tabName}-warning` : tabName
    return (
        <div className={styles.header}>
            {errorTooltipContent.length > 0 && <div className={styles.header__tooltip} data-test="tooltip">
                {errorTooltipContent.map((span, index) => {
                    if (index < errorTooltipContent.length - 1) {
                        return <>{span}<br/></>;
                    }
                    return span;
                })}
            </div>}
            <div className={styles.tabs} data-test={'step-tabs'} data-active-tab={activeTab}>
                <TabHeaderButton
                    warning={!!errors[StepTabType.Question]}
                    active={activeTab === StepTabType.Question}
                    onClick={() => changeTab(StepTabType.Question)}
                    data-test={'question-tab-wrapper'}
                    dataTest={resolveTestId('question-tab', !!errors[StepTabType.Question] && activeTab !== StepTabType.Question)}
                    display={availableTabs.includes(StepTabType.Question)}
                    messageId={questionLabelId}
                />
                <TabHeaderButton
                    warning={!!errors[StepTabType.Answer]}
                    active={activeTab === StepTabType.Answer}
                    onClick={() => changeTab(StepTabType.Answer)}
                    data-test={'answer-tab-wrapper'}
                    dataTest={resolveTestId('answer-tab', !!errors[StepTabType.Answer] && activeTab !== StepTabType.Answer)}
                    display={availableTabs.includes(StepTabType.Answer)}
                    messageId={'step.tabs.answer'}
                />
                <TabHeaderButton
                    warning={!!errors[StepTabType.SpecialCharacters]}
                    active={activeTab === StepTabType.SpecialCharacters}
                    onClick={() => changeTab(StepTabType.SpecialCharacters)}
                    data-test={'special-characters-tab-wrapper'}
                    dataTest={resolveTestId('special-characters-tab', !!errors[StepTabType.SpecialCharacters] && activeTab !== StepTabType.SpecialCharacters)}
                    display={availableTabs.includes(StepTabType.SpecialCharacters)}
                    messageId={'step.tabs.specialCharacters'}
                />
                <TabHeaderButton
                    active={activeTab === BlockDescriptionTabType.Preview}
                    onClick={() => changeTab(BlockDescriptionTabType.Preview)}
                    data-test="preview-block-description-tab"
                    dataTest="preview-block-description-tab"
                    display={availableTabs.includes(BlockDescriptionTabType.Preview)}
                    messageId="survey-creator.block.description.preview"
                />
                <TabHeaderButton
                    warning={!!errors[StepTabType.General]}
                    active={activeTab === StepTabType.General}
                    onClick={() => changeTab(StepTabType.General)}
                    data-test={'general-tab-wrapper'}
                    dataTest={resolveTestId('general-tab', !!errors[StepTabType.General] && activeTab !== StepTabType.General)}
                    display={true}
                    messageId={'step.tabs.general'}
                />
                <TabHeaderButton
                    warning={!!errors[StepTabType.Intents]}
                    active={activeTab === StepTabType.Intents}
                    onClick={() => changeTab(StepTabType.Intents)}
                    data-test={'intents-tab-wrapper'}
                    dataTest={resolveTestId('intents-tab', !!errors[StepTabType.Intents] && activeTab !== StepTabType.Intents)}
                    display={availableTabs.includes(StepTabType.Intents)}
                    messageId={'step.tabs.intents'}
                />
            </div>
            <div className={styles.buttons}>
                <EditButtons onClose={onClose} onSave={onSave} disabled={disabledSave} />
            </div>
        </div>
    );

}

export const TabHeaderButton: React.FC<TabHeaderButtonProps> = ({ children, active, warning, onClick, dataTest, display, messageId }) => {
    const classes = classNames(styles.tabHeaderButton, active && styles.active, (warning && !active) && styles.warning);
    return display && (
        <button className={classes} onClick={onClick} data-test={dataTest}>
            <FormattedMessage id={messageId} />
            {children}
        </button>
    );
};
