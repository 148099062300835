import * as React from 'react';
import {useContext} from 'react';
import {Draggable} from 'react-beautiful-dnd';

import {Block} from '../model';
import {useCreatorRouter} from '../useCreatorRouter';

import styles from './Block.pcss';
import {BlockOptionsComponent} from './BlockOptions';
import {PillComponent} from './pill/Pill';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';

type Props = {
    block: Block;
    index: number;
    someBlockEdit?: boolean;
    onEditToggle?: (value: boolean) => void;
    onBlockNameUpdate?: (name: string, block: Block) => void;
    groupRef?: React.RefObject<HTMLDivElement>;
    isSelected?: boolean;
}

export const BlockComponent = React.memo((props: Props) => {
    const {blocks} = useContext<SurveyContextType>(SurveyContext);

    const {block, index} = props;

    const {navigateToBlock} = useCreatorRouter();

    return (
      <Draggable draggableId={block.id} index={index}>
        {provided => (
          <PillComponent
            draggable={provided}
            key={block.id}
            isFirst={blocks.isFirst(block.id)}
            onSelect={() => navigateToBlock(block.id)}
            item={block}
            itemName={block.name}
            selected={props.isSelected}
            dataTest={'survey-creator-block'}
            onItemNameChange={props.onBlockNameUpdate}
            isEditable
            onEditToggle={props.onEditToggle}
            someBlockEdit={props.someBlockEdit}
            renderOptions={params => (
              <BlockOptionsComponent
                {...params}
                block={block}
              />
            )}
            classes={{ editableContainer: styles.editableContainer }}
            groupRef={props.groupRef}
          />
        )}
      </Draggable>
    );
});

