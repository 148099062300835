import * as React from 'react';
import {useState, useCallback, useEffect} from 'react';
import {isEqual} from 'lodash';

import { Message, MultipleMessages, Question } from '../../model';

import {MessageComponent} from './Message';
import {MultipleMessagesComponent} from './MultipleMessages';
import {useContext} from 'react';
import {SurveyContext, SurveyContextType} from '../../SurveyCreatorContext';

type Props = { question: Question, onChange: (question: Partial<Question>) => void, isConditional?: boolean };

export const BasicMessages = ({question, onChange, isConditional = false}: Props) => {
    const [messageErrors, setMessageErrors] = useState(null);
    const [repeatMessagesErrors, setRepeatMessagesErrors] = useState(null);

    const { questions: { withError } } = useContext<SurveyContextType>(SurveyContext);

    const setMessage = useCallback((message: Message) => {
        onChange({
            ...(isConditional ? {} : question),
            message,
        });
    }, isConditional ? [question.id] : undefined);

    const setRepeatMessages = useCallback((repeatMessages: MultipleMessages) => {
        onChange({
            ...(isConditional ? {} : question),
            repeatMessages,
        });
    }, isConditional ? [question.id] : undefined);

    const setFromIntentMessage = useCallback((fromIntentMessage: Message) => {
        onChange({
            ...(isConditional ? {} : question),
            fromIntentMessage,
        });
    }, isConditional ? [question.id] : undefined);

    const setMaxRepeatQuestionCount = useCallback((value: number) => {
        onChange({
            ...(isConditional ? {} : question),
            maxRepeatQuestionCount: value,
        });
    }, isConditional ? [question.id] : undefined);

    useEffect(() => {
        const errors = withError(question.id, 'message');
        if (!isEqual(errors, messageErrors)) {
            setMessageErrors(errors);
        }
    }, [withError]);

    useEffect(() => {
        const errors = withError(question.id, 'repeatMessages');
        if (!isEqual(errors, repeatMessagesErrors)) {
            setRepeatMessagesErrors(errors);
        }
    }, [withError]);

    return (
        <>
            <MessageComponent message={question.message} onChange={setMessage} dataTestPrefix={'question'} labelId={'survey-creator.messageLabel'} errors={messageErrors} />
            <MultipleMessagesComponent messages={question.repeatMessages} maxRepeatQuestionCount={question.maxRepeatQuestionCount} onChange={setRepeatMessages} onMaxRepeatQuestionCountChange={setMaxRepeatQuestionCount} dataTestPrefix={'question-repeat'} errors={repeatMessagesErrors}/>
            <MessageComponent message={question.fromIntentMessage || {text: ''}} path="fromIntentMessage" onChange={setFromIntentMessage} dataTestPrefix={'question-from-intent'} labelId={'survey-creator.fromIntentMessageLabel'} />
        </>
    );
};
