import {useContext, useEffect, useState} from 'react';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';
import {useLocation, useParams} from 'react-router';
import {BotCreatorRouteMatch, getScopeForCreatorParams} from '../useCreatorRouter';
import {BlockDescriptionTabType, StepTabType} from './factory/types';
import {ViewState} from './StepComponent';

export const useBlockDescription = () => {
    const {blocks} = useContext<SurveyContextType>(SurveyContext);
    const params = useParams<BotCreatorRouteMatch>();
    const {pathname} = useLocation();

    const scope = getScopeForCreatorParams(params, pathname)
    const currentBlock = blocks?.getCurrentForScope(scope);

    const [viewState, setViewState] = useState(ViewState.Blank);
    const [blockDescriptionTabType, setBlockDescriptionTabType] = useState<BlockDescriptionTabType.Preview | StepTabType.General>(BlockDescriptionTabType.Preview);
    const [markdown, setMarkdown] = useState('');
    const [hasChanges, setHasChanges] = useState(false);

    const setStateBasedOnDescription = (desc = '') => {
        setMarkdown(desc);
        setViewState(desc ? ViewState.Description : ViewState.EmptyStateDescription);
    };

    function displayEmptyStateDescription() {
        setViewState(ViewState.EmptyStateDescription);
    }

    function resetViewState() {
        setBlockDescriptionTabType(BlockDescriptionTabType.Preview);
        setHasChanges(false);
    }

    const handleChangeTab = (tab: BlockDescriptionTabType) => {
        setBlockDescriptionTabType(tab);
    }


    useEffect(() => {
        if (!currentBlock) {
            setViewState(ViewState.Blank);
            return;
        }
        setHasChanges(false);
        setBlockDescriptionTabType(BlockDescriptionTabType.Preview);
        setStateBasedOnDescription(currentBlock.description);
    }, [currentBlock?.id, scope.stepId, scope.callbackStepId, scope.type, pathname]);

    return {
        viewState,
        setViewState,
        setBlockDescriptionTabType,
        setMarkdown,
        markdown,
        hasChanges,
        setHasChanges,
        resetViewState,
        handleChangeTab,
        blockDescriptionTabType,
        displayEmptyStateDescription,
    }
}