import * as React from 'react';
import {useContext, useMemo, useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import _ from 'lodash';

import {SelectComponent} from '../../../components/Select/Select';
import {TextAreaWithVariables} from '../../../components/TextArea/TextAreaWithVariables';
import {
    handleNestedVariableId,
    handleNestedVariableName,
    includesAnotherVariable,
    isVariableDictionaryType,
    ResolvedVariableDictionaryType,
    WordQuestionResult
} from './variableResolver/variableResolver';
import {useVariableResolver} from './variableResolver/useVariableResolver';
import {SurveyContext, SurveyContextType} from '../../SurveyCreatorContext';
import {SurveyDefinitionVariable} from '../../model';
import {sortByNameComparator} from '../../../utils/SortUtils';
import {PillComponent} from '../pill/Pill';
import {OptionsComponent} from '../optionsMenu/Options';
import {MenuOption} from '../optionsMenu/OptionsMenu';
import {DictStepIcon, OpenStepIcon, SetVariableStepIcon} from '../../../icons';
import {originToBadgeMap, VariablesIconWrapper} from './Variables';
import styles from './VariableValue.pcss'
import {VariableType} from './Variables.utils';
import { useParams } from 'react-router';
import { BotCreatorRouteMatch, getScopeForVariables } from '../../useCreatorRouter';


interface VariableValueProps {
    value: string | WordQuestionResult;
    onChange: (value: string | WordQuestionResult) => void;
    selectedOption: SurveyDefinitionVariable
}

export const VariableValue = ({value, onChange, selectedOption}: VariableValueProps) => {
    const {variables} = React.useContext<SurveyContextType>(SurveyContext);
    const params = useParams<BotCreatorRouteMatch>();
    const availableVariables = variables.getForScope(getScopeForVariables(params));
    const {resolvedVariablesTypes} = useVariableResolver();
    const variableType = useMemo(() => resolvedVariablesTypes[selectedOption?.id], [selectedOption, resolvedVariablesTypes]);

    if (isVariableDictionaryType(variableType)) {
        return <VariableDictionaryValueContainer
            value={value}
            onChange={onChange}
            variableDictionaryType={variableType}
            availableVariables={availableVariables}
        />
    }
    return <TextAreaWithVariables
        dataTest="set-variable-value"
        value={handleNestedVariableId(value)}
        availableVariables={availableVariables}
        onChange={onChange as (value: string) => void}
    />
}


enum VariableValueViewOptions {
    dictionary = 'dictionary',
    variable = 'variable',
    plainText = 'plainText',
    default = 'default'
}

const dictionaryVariableValueViewOptions = [
    {key: VariableValueViewOptions.dictionary, icon: DictStepIcon},
    {key: VariableValueViewOptions.variable, icon: SetVariableStepIcon},
    {key: VariableValueViewOptions.plainText, icon: OpenStepIcon}
] as const;

export const VariableDictionaryValueContainer = ({value, onChange, variableDictionaryType, availableVariables}: {
    value: string | WordQuestionResult;
    onChange: (value: string | WordQuestionResult) => void;
    variableDictionaryType: ResolvedVariableDictionaryType;
    availableVariables: SurveyDefinitionVariable[];
}) => {
    const [viewOption, setViewOption] = useState(VariableValueViewOptions.default);

    const resetViewState = () => setViewOption(VariableValueViewOptions.default);

    switch (viewOption) {

        case VariableValueViewOptions.dictionary:
            return (
                <DictionarySelect
                    onChange={onChange}
                    variableDictionaryType={variableDictionaryType}
                    resetViewState={resetViewState}
                />
            );

        case VariableValueViewOptions.variable:
            return (
                <VariableSelect 
                    onChange={onChange} 
                    resetViewState={resetViewState}
                    availableVariables={availableVariables}
                />
            );

        case VariableValueViewOptions.plainText:
            return (
                <PlainTextInput
                    value={handleNestedVariableId(value)}
                    onChange={onChange}
                    resetViewState={resetViewState}
                    availableVariables={availableVariables}
                />
            );

        default:
            return (
                <DefaultView 
                    value={value}
                    setViewOption={setViewOption}
                    availableVariables={availableVariables}
                />
            );
    }
};

const PlainTextInput = ({value, onChange, resetViewState, availableVariables}: {
    value: string;
    onChange: (value: string) => void;
    resetViewState: () => void;
    availableVariables: SurveyDefinitionVariable[];
}) => {
    return (
        <OutsideClickHandler onOutsideClick={resetViewState}>
            <TextAreaWithVariables
                autoFocus
                dataTest="set-variable-value"
                value={value}
                availableVariables={availableVariables}
                onChange={onChange}
            />
        </OutsideClickHandler>
    );
};

const DictionarySelect = ({variableDictionaryType, onChange, resetViewState}: {
    variableDictionaryType: ResolvedVariableDictionaryType;
    onChange: (value: string | WordQuestionResult) => void;
    resetViewState: () => void;
}) => {
    const variableValueDictionaryOptions = useMemo(() => {
        const clearVariableOption = { id: '', name: '', badge: 'clear' };

        const baseOptions = variableDictionaryType
            .map(variable => {
                if (variable.key === '') {
                    return clearVariableOption;
                }
                return ({
                    id: variable.id,
                    name: variable.key,
                    ...(shouldDisplayWordQuestionResultLabel(variableDictionaryType, variable) && {badge: 'dict'})
                });
            })
            .sort(sortByNameComparator);

        return shouldAddClearVariableValue(variableDictionaryType)
            ? [...baseOptions, clearVariableOption]
            : baseOptions;
    }, [variableDictionaryType]);



    return (
        <OutsideClickHandler onOutsideClick={resetViewState}>
            <SelectComponent
                createOptionMessageId="survey-creator.createVariableDictionaryValue"
                options={variableValueDictionaryOptions}
                className={styles.select}
                isCreatable
                isOpen
                autoFocus
                onChange={variable => {
                    onChange(includesWordQuestionResultDictionary(variableDictionaryType) ? {id: variable.id, key: variable.name} : variable.id);
                    resetViewState();
                }}
                onCreate={name => {
                    onChange(name);
                    resetViewState();
                }}
            />
        </OutsideClickHandler>
    );
};

const VariableSelect = ({onChange, resetViewState, availableVariables}) => {
    const {variables} = useContext(SurveyContext);
    const options = useMemo(() => availableVariables
        .map(variable => ({
            id: variable.id,
            name: variable.name,
            badge: originToBadgeMap[variable.origin]
        }))
        .sort(sortByNameComparator), [variables]);


    return (
        <OutsideClickHandler onOutsideClick={resetViewState}>
            <SelectComponent
                options={options}
                onChange={variable => {
                    onChange(asVariableTag(variable.id));
                    resetViewState();
                }}
                createOptionMessageId="survey-creator.createVariableDictionaryValue"
                className={styles.select}
                isOpen
                autoFocus
                isSearchable
            />
        </OutsideClickHandler>
    );
};

const DefaultView = ({value, setViewOption, availableVariables}) => {
    const isAssigningVariable = includesAnotherVariable(handleNestedVariableId(value));
    const assigningVariable = availableVariables.find(v => v.id === withoutVariableTag(handleNestedVariableId(value)));

    return (
        <PillComponent
            itemName={isAssigningVariable ? assigningVariable?.name : handleNestedVariableName(value)}
            itemBadge={isAssigningVariable && originToBadgeMap[assigningVariable?.origin]}
            item={null}
            onSelect={_.noop}
            dataTest="variable-value"
            classes={{root: styles.pill}}
            PrefixIcon={isAssigningVariable && VariablesIconWrapper}
            renderOptions={() => (
                <OptionsComponent
                    containerPositionClassName={styles.optionsContainer}
                    options={dictionaryVariableValueViewOptions.map(({key, icon}) => (
                        <MenuOption
                            key={key}
                            icon={icon}
                            dataTest={`survey-creator.setVariable.assignValue.${key}`}
                            tooltipLabelId={`survey-creator.setVariable.assignValue.${key}`}
                            onClick={() => setViewOption(key)}
                        />
                    ))}
                />
            )}
        />
    );
};


function includesWordQuestionResultDictionary(dictionaries: ResolvedVariableDictionaryType) {
    return !dictionaries.every(dictionary => dictionary.id === dictionary.key);
}

function asVariableTag(variableId: string) {
    return '${' + variableId + '}';
}

function withoutVariableTag(variable: string) {
    return variable.replace('${', '').replace('}', '');
}

function isDuplicated(variableDictionaryType: ResolvedVariableDictionaryType, variable: ResolvedVariableDictionaryType[0]) {
    return (_.countBy(variableDictionaryType, 'key'))[variable.key] > 1;
}

function isVariableFromWordQuestion(variable: { type: VariableType.DICTIONARY; id: string; key: string }) {
    // variable from word question has uuid as id
    return variable.key !== variable.id;
}

function shouldDisplayWordQuestionResultLabel(variableDictionaryType: ResolvedVariableDictionaryType, variable: ResolvedVariableDictionaryType[0]) {
    return includesWordQuestionResultDictionary(variableDictionaryType) && isDuplicated(variableDictionaryType, variable) && isVariableFromWordQuestion(variable);
}

function shouldAddClearVariableValue(variableDictionaryType: ResolvedVariableDictionaryType) {
    return !variableDictionaryType.some(variable => variable.key === '');
}
