import { useHistory, useLocation, useParams } from 'react-router';
import { BotRouteMatch, CallbackTypeId } from '../utils/CurrentBot';
import { slugifyIntentName } from './model';
import slugify from 'slugify';

export type BotCreatorRouteMatch = BotRouteMatch & {
    tabName?: string;
    blockId?: string;
    stepId?: string;
    fallbackStepId?: string;
    silenceFallbackStepId?: string;
    callbackId?: CallbackTypeId;
    callbackStepId?: string;
    intentId?: string;
}

export function useCreatorRouter() {
    const params = useParams<BotCreatorRouteMatch>();
    const { pathname, search } = useLocation();
    const history = useHistory();

    const baseCreatorPath = `/${params.orgId}/${params.botId}/survey-creator/${params.tabName}`

    function getUrlForBlock(blockId: string) {
        return `${baseCreatorPath}/modules/${blockId}`;
    }

    function getUrlForStep(blockId: string, stepId: string) {
        return `${getUrlForBlock(blockId)}/${stepId}`;
    }

    function getUrlForFallback() {
        return `${baseCreatorPath}/fallback`;
    }

    function getUrlForFallbackStep(stepId: string) {
        return `${getUrlForFallback()}/${stepId}`;
    }

    function getUrlForSilenceFallback() {
        return `${baseCreatorPath}/silence-fallback`;
    }

    function getUrlForSilenceFallbackStep(stepId: string) {
        return `${getUrlForSilenceFallback()}/${stepId}`;
    }

    function getUrlForCallback(callbackId: CallbackTypeId) {
        return `${baseCreatorPath}/callbacks/${callbackId}`;
    }

    function getUrlForCallbackStep(callbackId: CallbackTypeId, stepId: string) {
        return `${getUrlForCallback(callbackId)}/${stepId}`;
    }

    function getUrlForIntent(intentId: string) {
        return `${baseCreatorPath}/intents/${intentId}`;
    }

    function getUrlForCurrentStep(stepId: string) {
        if (params.blockId) {
            return getUrlForStep(params.blockId, stepId);
        }
        if (pathname.startsWith(getUrlForFallback())) {
            return getUrlForFallbackStep(stepId);
        }
        if (pathname.startsWith(getUrlForSilenceFallback())) {
            return getUrlForSilenceFallbackStep(stepId);
        }
        if (params.callbackId) {
            return getUrlForCallbackStep(params.callbackId as CallbackTypeId, stepId);
        }
        return baseCreatorPath;
    }

    function getUrlForEntity(entityId: string) {
        return `/${params.orgId}/${params.botId}/survey-creator/entities/${entityId}`;
    }

    function setActiveTab(tabName: string) {
        const searchParams = new URLSearchParams(search);
        searchParams.set('tab', tabName);
        history.push(`${pathname}?${searchParams.toString()}`);
    }

    return {
        getUrlForBlock,
        getUrlForFallback,
        getUrlForFallbackStep,
        getUrlForCallback,
        getUrlForSilenceFallback,
        getUrlForSilenceFallbackStep,
        getUrlForCallbackStep,
        getUrlForIntent,
        getUrlForEntity,
        navigateToNewQuestion: (questionId: string) => history.push(getUrlForCurrentStep(questionId)),
        navigateToIntent: (intentId: string) => history.push(getUrlForIntent(slugifyIntentName(intentId))),
        navigateToBlock: (blockId: string, method: 'push' | 'replace' = 'push') => history[method](getUrlForBlock(blockId)),
        navigateToBlockQuestion: (blockId: string, questionId: string, method: 'push' | 'replace' = 'push') => history[method](getUrlForStep(blockId, questionId)),
        navigateToFallback: () => history.push(getUrlForFallback()),
        navigateToFallbackQuestion: (questionId: string, method: 'push' | 'replace' = 'push') => history[method](getUrlForFallbackStep(questionId)),
        navigateToSilenceFallbackQuestion: (questionId: string, method: 'push' | 'replace' = 'push') => history[method](getUrlForSilenceFallbackStep(questionId)),
        navigateToSilenceFallback: () => history.push(getUrlForSilenceFallback()),
        navigateToCallbackQuestion: (callbackId: CallbackTypeId, questionId: string, method: 'push' | 'replace' = 'push') => history[method](getUrlForCallbackStep(callbackId, questionId)),
        navigateToCallback: (callbackId: CallbackTypeId) => history.push(getUrlForCallback(callbackId)),
        activeTab: {
            value: new URLSearchParams(search).get('tab'),
            set: setActiveTab,
        },
        current: {
            blockId: params.blockId,
            stepId: params.stepId,
            fallback: pathname.startsWith(getUrlForFallback()),
            fallbackStepId: params.fallbackStepId,
            silenceFallback: pathname.startsWith(getUrlForSilenceFallback()),
            silenceFallbackStepId: params.silenceFallbackStepId,
            callbackId: params.callbackId,
            callbackStepId: params.callbackStepId,
            intentId: params.intentId,
        }
    };
}

// pure functions below -  not relying on path state from hooks

const slugifyParam = (param: string) => slugify(param, { strict: true })

export function intentUrl(organizationName: string, botName: string, intentId: string) {
    return (`/${slugifyParam(organizationName)}/${slugifyParam(botName)}/survey-creator/scenario/intents/${intentId}`)
}

export function blockUrl(organizationName: string, botName: string, blockId: string) {
    return (`/${slugifyParam(organizationName)}/${slugifyParam(botName)}/survey-creator/scenario/modules/${blockId}`)
}

export function blockQuestionUrl(organizationName: string, botName: string, blockId: string, stepId: string) {
    return (`/${slugifyParam(organizationName)}/${slugifyParam(botName)}/survey-creator/scenario/modules/${blockId}/${stepId}`)
}

export function fallbackQuestionUrl(organizationName: string, botName: string, stepId: string) {
    return (`/${slugifyParam(organizationName)}/${slugifyParam(botName)}/survey-creator/scenario/fallback/${stepId}`)
}

export function silenceFallbackQuestionUrl(organizationName: string, botName: string, stepId: string) {
    return (`/${slugifyParam(organizationName)}/${slugifyParam(botName)}/survey-creator/scenario/silence-fallback/${stepId}`)
}

export function callbackQuestionUrl(organizationName: string, botName: string, callbackId: string, stepId: string) {
    return (`/${slugifyParam(organizationName)}/${slugifyParam(botName)}/survey-creator/scenario/callbacks/${callbackId}/${stepId}`)
}

export function getScopeForCreatorParams(params: BotCreatorRouteMatch, pathname: string) {
    if (params.fallbackStepId || pathname.includes('/scenario/fallback')) {
        return { type: 'fallback' as const, fallbackStepId: params.fallbackStepId };
    } else if (params.silenceFallbackStepId || pathname.includes('/scenario/silence-fallback')) {
        return { type: 'silenceFallback' as const, silenceFallbackStepId: params.silenceFallbackStepId };
    } else if (params.callbackId) {
        return { type: 'callback' as const, callbackId: params.callbackId, callbackStepId: params.callbackStepId };
    } else {
        return { type: 'block' as const, blockId: params.blockId, stepId: params.stepId };
    }
}

export function getBlockIdForScope(scope: ReturnType<typeof getScopeForCreatorParams>) {
    if (scope.type === 'fallback') {
        return 'fallback';
    }
    if (scope.type === 'silenceFallback') {
        return 'silenceFallback';
    }
    return scope.blockId;
}

export function getScopeForVariables(params: BotCreatorRouteMatch) {
    if (params.callbackId === 'callNotAnswered') {
        return 'callbackCallNotAnswered'
    } 
    if (params.callbackId === 'callFinished') {
        return 'callbackCallFinished'
    }  
    if (params.callbackId === 'voiceMailDetected') {
        return 'callbackVoicemailDetected'
    } 
    return 'creator'
}