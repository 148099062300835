import cx from 'classnames';
import _ from 'lodash';
import React, {useCallback, useState} from 'react';
import {generatePath, Redirect, useHistory, useRouteMatch} from 'react-router';

import {ErrorPopup} from '../../components/ErrorPopup/ErrorPopup';
import {Loader} from '../../components/Loader';
import {getBotUrlId, useCurrentBot} from '../../utils/CurrentBot';

import styles from './EntitiesMenu.pcss';
import {EntityDetailsTabs} from './EntityDetails/EntityDetails';
import {useEntities} from './EntityDetails/hooks/useEntities';
import {usePendingEntity} from './EntityDetails/hooks/usePendingEntity';
import {PillComponent} from './pill/Pill';

type EntitiesMenuProps = {};

export const EntitiesMenu: React.FC<EntitiesMenuProps> = () => {
  const currentBot = useCurrentBot();
  const match = useRouteMatch<{
    entity?: string;
    entityDetailsTab?: string;
  }>();
  const history = useHistory();
  const {
    pendingEntity,
    cancelPendingEntity,
    confirmPendingEntity
  } = usePendingEntity();
  const {
    isLoading,
    isReady,
    entities,
  } = useEntities(currentBot.id);
  const [hasError, setError] = useState<boolean>(false);

  const generateEntityTabPath = (entity: string, tab?: EntityDetailsTabs) => generatePath(
    `/${getBotUrlId(currentBot)}/survey-creator/entities/:entity/:entityDetailsTab?`,
    {
      entityDetailsTab: tab ?? match.params.entityDetailsTab,
      entity
    }
  );

  const onSelect = useCallback((entity: string) => {
    const newPath = generateEntityTabPath(entity);
    history.push(newPath);
  }, [history]);

  const onConfirm = useCallback((entity: string) => {
    confirmPendingEntity(entity);
    onSelect(entity);
  }, [onSelect]);

  const handleEntityUpdate = async (entity: string) => {
    setError(entities.includes(entity));
  }

  if ((!match.params.entity && Boolean(entities) && entities.length > 0) || (Boolean(entities) && entities.length > 0 && !entities.includes(match.params.entity))) {
    return <Redirect to={generateEntityTabPath(entities[0], EntityDetailsTabs.Answers)}/>;
  }

  if (Boolean(match.params.entity) && !match.params.entityDetailsTab) {
    return <Redirect to={`${match.url}/${EntityDetailsTabs.Answers}`}/>;
  }

  return <div
    data-test="survey-creator-entities"
    className={styles.entitiesList}
  >
    {isLoading && <Loader/>}
    {isReady && <>
      {entities.map(entity => {
        const isSelected = match.params.entity === entity && !Boolean(pendingEntity);
        return <PillComponent
          key={entity}
          dataTest={`${entity}-pill`}
          item={entity}
          itemName={entity}
          onSelect={() => onSelect(entity)}
          selected={isSelected}
          classes={{
            root: cx(
              styles.entity,
              {[styles.selected]: isSelected}
            )
          }}/>;
      })}

      {Boolean(pendingEntity) &&
      <div className={styles.pendingEntity}>
          <PillComponent
              hasError={hasError}
              dataTest={`pending-entity`}
              item={pendingEntity}
              itemName={pendingEntity}
              onSelect={_.noop}
              onCancel={cancelPendingEntity}
              onItemNameChange={onConfirm}
              onItemNameUpdate={handleEntityUpdate}
              selected={Boolean(pendingEntity)}
              isEditing={Boolean(pendingEntity)}
              classes={{
                root: cx(
                  styles.entity,
                  {[styles.selected]: Boolean(pendingEntity)}
                )
              }}
          />

        {hasError && <ErrorPopup
            data-test="error"
            messageId="survey-creator.entityCreateError"
        />}
      </div>
      }
    </>

    }
  </div>;
};