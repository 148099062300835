import {ToggleWithLabel} from './ToggleWithLabel';
import * as React from 'react';

type QuestionProps =  { disableInterpretation?: boolean }
type Props = { question: QuestionProps, onChange: (question: QuestionProps) => void };

export const DisableInterpretationToggle = ({question, onChange}: Props) => {
    const setDisableRecognition = () => onChange({
        ...question,
        disableInterpretation: question.disableInterpretation ? undefined : true
    });
    return (
        <ToggleWithLabel dataTest="question-config-interpretation-toggle" labelId="survey-creator.disableInterpretation" value={!!question.disableInterpretation} onToggle={setDisableRecognition}/>
    );
};