import _ from 'lodash';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {allEntityAnswersSelector} from '../../../../selectors/entityDetailsSelectors';
import {fetchEntitiesDetails} from '../../../store/entityDetails/actions';

import {useEntities} from './useEntities';

export const useAllEntitiesValues = (entities: string[], currentBotId: string) => {
    const dispatch = useDispatch()
    const entityDetails = useSelector(allEntityAnswersSelector)
    const { isReady } = useEntities(currentBotId)
    useEffect(() => {
        if (isReady && entities.length !== Object.keys(_.pick(entityDetails ?? {}, entities)).length) {
            dispatch(fetchEntitiesDetails.request({entities, id: currentBotId}));
        }
    }, [currentBotId, isReady, entities, entityDetails])

    const memoizedEntityDetails = useMemo(() => {
            return _.pick(entityDetails, entities)
        },
        // optimisation as entryDetails obtained from redux store is not stable and returns different object reference on each call
        [JSON.stringify(_.pick(entityDetails, entities))]
    )

    return memoizedEntityDetails
}