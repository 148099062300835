import React from 'react';

import { DeleteIcon } from '../../../icons';
import { VerticalSeparator } from '../VerticalSeparator';

import styles from './InputsArrayElement.pcss';

type InputsArrayElementProps = {
  children: React.ReactNode;
  onRemove: () => void;
  dataTest: string;
};

export const InputsArrayElement = ({
  children,
  onRemove,
  dataTest,
}: InputsArrayElementProps) => {
  return (
    <div data-test="inputs-array-container" className={styles.mappingContainer}>
      <div data-test="inputs-array" className={styles.groupContainer}>
        <div className={styles.inputsWithActionsGroup}>
          <div className={styles.inputsGroup}>{children}</div>
          <VerticalSeparator noMargin />
          <div
            data-test={dataTest}
            onClick={onRemove}
            className={styles.remove}
          >
            <div className={styles.icon}>
              <DeleteIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
