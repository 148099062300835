import _ from 'lodash';
import * as React from 'react';
import {useIntl} from 'react-intl';

import {SelectComponent, Value} from '../../components/Select/Select';
import {Block} from '../model';
import { sortByNameComparator } from '../../utils/SortUtils';

const sortOptions = (currentBlock: Block) => (block: Block) => {
    if (!currentBlock && block.id === 'continue') {
        return 0;
    }
    if (currentBlock && block.id === currentBlock.id) {
        return 0;
    }
    return 1;
};

enum SpecialOption {
    CONTINUE = 'continue',
    REPEAT = 'repeat',
    UNKNOWN = 'unknown',
}

type GoToProps = {
    currentBlockId?: string;
    onChange: (blockId?: string) => void;
    blocks: Value[];
    includeRepeat?: boolean;
    includeContinue?: boolean;
    specialOptionsIds?: {
        continue?: string;
        repeat?: string;
        unknown?: string;
    }
};

export const SelectGoToBlock = ({ currentBlockId, onChange, blocks, includeRepeat, includeContinue, specialOptionsIds }: GoToProps) => {
    const intl = useIntl();
    const handleChange = (selected: any) => onChange(selected.id);

    const continueOption = {
        id: specialOptionsIds?.continue || SpecialOption.CONTINUE,
        name: intl.messages['survey-creator.goTo.continueLabel'] as string,
    };
    const repeatOption = {
        id: specialOptionsIds?.repeat || SpecialOption.REPEAT,
        name: intl.messages['survey-creator.goTo.repeatLabel'] as string,
    };
    const unknownOption = {
        id: specialOptionsIds?.unknown || SpecialOption.UNKNOWN,
        name: intl.messages['survey-creator.goTo.invalidLabel'] as string,
    };

    const sortedBlocks = [...blocks].sort(sortByNameComparator);
    const rawOptions = [
        ...(includeRepeat ? [repeatOption] : []),
        ...(includeContinue ? [continueOption] : []),
        ...sortedBlocks
    ];

    const currentBlock = rawOptions.find(block => block.id === currentBlockId) as Block;
    const options = _.orderBy(rawOptions, sortOptions(currentBlock));
    const hasInvalidModule = currentBlockId && !currentBlock;
    const getValue = () => {
        if (hasInvalidModule) {
            return unknownOption
        }
        if (currentBlock) {
            return currentBlock;
        }
        if (includeRepeat) {
            return repeatOption;
        }
        if (includeContinue) {
            return continueOption;
        }
        return undefined;
    };
    return <SelectComponent value={getValue()} invalid={hasInvalidModule} options={options} onChange={handleChange} isSearchable />;
};
