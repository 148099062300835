import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import {useContext, useRef} from 'react';
import {useIntl} from 'react-intl';
import {scroller} from 'react-scroll';

import { SynonymsChipsComponent } from '../../components/chips/SynonymsChipsComponent';
import {ElementWithLabel} from '../../components/ElementWithLabel/ElementWithLabel';
import {Input} from '../../components/Input';
import {DeleteIcon} from '../../icons';
import {DictionaryWordAnswer, PermutableBasicMessage, Synonym, WordsDictionaryItem} from '../model';
import {SurveyContext} from '../SurveyCreatorContext';

import styles from './DictionaryAnswers.pcss';
import {SingleMessageComponent} from './messages/SingleMessage';
import {ReadOption} from './ReadOption';
import {SelectGoToBlock} from './SelectGoTo';

type Props = {
    onRemove?: (id: string) => void,
    onChange: (entry: WordsDictionaryItem) => void,
    answer: DictionaryWordAnswer,
    answerText?: string,
    readOnly?: boolean,
    defaultReadType?: 'SEQUENCE' | 'PERMUTATE',
    noReactionGoTo?: boolean,
    notPreciseAnswerId?: string
};

export const DictionaryAnswer = (props: Props) => {
    const {blocks} = useContext(SurveyContext);
    const {messages} = useIntl();
    const containerRef = useRef<HTMLDivElement>();
    const changeSynonyms = (synonyms: Synonym[]) => props.onChange({...props.answer, synonyms});
    const changeAnswerText = (newText: string) => !props.readOnly && props.onChange({...props.answer, key: newText});
    const changeAnswerGoTo = (goTo: string) => props.onChange({...props.answer, goTo});
    const changeAnswerMessage = (message: PermutableBasicMessage) => !!props.answer.message && props.onChange({
        ...props.answer,
        message
    });
    const changeReadType = (readOption: any) => {
        if (!props.defaultReadType) {
            return;
        }
        const message = readOption.id !== 'DONT_READ'
            ? {...props.answer.message, read: readOption.id}
            : undefined;
        props.onChange({
            ...props.answer,
            message: message
        });
    };

    const scrollToNotPreciseAnswer = () => {
        const closestScroll = containerRef.current.closest('div[style*="overflow: scroll"]')
        scroller.scrollTo(props.notPreciseAnswerId, {
            duration: 600,
            containerId: closestScroll && closestScroll.id,
            smooth: true
        });
    }

    const value = props.answerText || props.answer.key;

    return (
        <div className={styles.answer} ref={containerRef}>
            {!props.readOnly && (
                <div className={styles.controls}>
                    {props.defaultReadType && (
                        <ReadOption
                            onChange={changeReadType}
                            value={_.get(props, 'answer.message.read')}
                            defaultReadType={props.defaultReadType}
                        />
                    )}
                    <div className={styles.buttons}>
                        <div data-test="remove-dictionary-entry-button"
                             onClick={() => props.onRemove && props.onRemove(props.answer.id)}
                             className={styles.remove}>
                            <DeleteIcon animationClass={classNames(styles.removeIcon)}/>
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.content}>
                <div className={styles.idRow}>
                    <ElementWithLabel labelId={'survey-creator.word.answerIdLabel'} noPadding
                                      className={styles.answerTextInput}>
                        <Input type="text" dataTest="answer-text" disabled={props.readOnly} value={value}
                               onChange={changeAnswerText} width={'100%'}/>
                    </ElementWithLabel>
                    {!props.noReactionGoTo && (
                        <ElementWithLabel labelId={'survey-creator.wordAnswerGoToLabel'} noPadding>
                            <div data-test="answer-go-to">
                                <SelectGoToBlock onChange={changeAnswerGoTo} currentBlockId={props.answer.goTo}
                                                 blocks={blocks.available()} includeContinue/>
                            </div>
                        </ElementWithLabel>
                    )}
                </div>
                {
                    props.answer.message &&
                    (
                        <div className={styles.messageRow}>
                            <SingleMessageComponent
                                dataTestPrefix={`answer`}
                                labelId={'survey-creator.word.answerMessageLabel'}
                                message={props.answer.message}
                                onChange={changeAnswerMessage}
                            />
                        </div>
                    )
                }
                <ElementWithLabel labelId={'survey-creator.dictionary.chipsLabel'} noPadding>
                    <SynonymsChipsComponent
                        items={props.answer.synonyms}
                        onChange={changeSynonyms}
                        i18nKey={'survey-creator.dictionaryPlaceholder'}/>
                </ElementWithLabel>
                {props.notPreciseAnswerId && (
                    <div className={styles.notPreciseDescription}>
                        <span>
                          {messages['survey-creator.word.notPreciseLinkDescription']}
                            <button data-test={'not-precise-answer-link'} className={styles.notPreciseLink}
                               onClick={scrollToNotPreciseAnswer}>
                            {messages['survey-creator.word.notPreciseLink']}
                          </button>
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};
