import * as React from 'react';
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  Node,
  Position,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import {
  Block,
  BlockQuestion,
  GoToBlockQuestion,
  QuestionType,
} from '../model';
import { getLayoutedElements } from './autolayout';
import {
  ModuleNode,
  moduleTitleHeight,
  visualEditorModuleWidth,
  visualEditorQuestionHeight,
} from './ModuleNode';
import { SelectedQuestionOverlay } from './SelectedQuestionOverlay';
import 'reactflow/dist/style.css';
import { useParams } from 'react-router';
import { BotCreatorRouteMatch } from '../useCreatorRouter';

type VisualEditorNodeData = {
  id: string;
  label: string;
  questions: BlockQuestion[];
  position?: {
    x: number;
    y: number;
  };
};
export type VisualEditorNode = Node<VisualEditorNodeData, string>[];

const customNodesConfig = { ModuleNode: ModuleNode };

type VisualEditorProps = {
  modules: Block[];
  savePosition: (id: string, position: { x: number; y: number }) => void;
};

export const VisualEditor = ({ modules, savePosition }: VisualEditorProps) => {
  const [selectedQuestionId, setSelectedQuestionId] = React.useState<
    string | null
  >(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(
    modules.map((mod) => {
      return {
        id: mod.id,
        sourcePosition: 'left' as Position,
        data: {
          id: mod.id,
          label: mod.name,
          questions: mod.questions,
          ...(mod.position && { position: mod.position }),
          setSelectedQuestionId: (questionId: string) =>
            setSelectedQuestionId(questionId),
        } as unknown as VisualEditorNodeData,
        position: { x: 0, y: 0 },
        type: 'ModuleNode',
      };
    })
  );
  const [edges, _setEdges, onEdgesChange] = useEdgesState(
    modules.flatMap((mod) => {
      return mod.questions
        .filter((step) => step.type === QuestionType.GO_TO_BLOCK)
        .map((step) => {
          return {
            id: `${step.id}-${(step as GoToBlockQuestion).nextBlock}`,
            source: mod.id,
            sourceHandle: step.id,
            target: (step as GoToBlockQuestion).nextBlock,
            markerEnd: { type: MarkerType.ArrowClosed },
          };
        });
    })
  );

  // Use autolayout for initial visual editor layout
  React.useEffect(() => {
    const { nodes: mappedNodes } = getLayoutedElements(nodes, edges, 'LR'); // left to right = 'LR' or top to bottom = 'TB'
    setNodes(mappedNodes);
  }, []);

  // sync changes from SurveyContext to reactflow nodes
  React.useEffect(() => {
    setNodes((prevNodes) => {
      return prevNodes.map((node) => {
        const module = modules.find((mod) => mod.id === node.data.id);
        // deleting modules is not yet implemented in that view, but it may require some tweaks in state syncing logic here
        // to properly update/remove nodes and edges handled by reactflow
        if (!module) {
          return node;
        }

        return {
          ...node,
          data: {
            ...node.data,
            questions: module.questions,
          },
        };
      });
    });
  }, [modules]);

  const { setCenter, getZoom } = useReactFlow();

  const [viewInitialized, setViewInitialized] = React.useState(false);
  const [scrolledOnMount, setScrolledOnMount] = React.useState(false);

  const params = useParams<BotCreatorRouteMatch>();
  const currentQuestionId = params.blockId || params.callbackStepId || params.fallbackStepId || params.silenceFallbackStepId;

  React.useEffect(() => {
    if (viewInitialized && !scrolledOnMount) {
      const node = nodes.find((n) =>
        n.data.questions.some((q) => q.id === currentQuestionId)
      );
      if (node) {
        const currentModule = modules.find((mod) => mod.id === node.data.id);
        const questionWithinModuleIndex = currentModule?.questions.findIndex(
          (q) => q.id === currentQuestionId
        );

        setCenter(
          node.position.x + visualEditorModuleWidth / 2,
          node.position.y +
            moduleTitleHeight +
            visualEditorQuestionHeight * questionWithinModuleIndex,
          { zoom: getZoom() }
        );
        setScrolledOnMount(true);
      }
    }
  }, [scrolledOnMount, viewInitialized, currentQuestionId]);

  return (
    <>
      <ReactFlow
        onInit={() => {
          setViewInitialized(true);
        }}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        attributionPosition="bottom-left"
        nodeTypes={customNodesConfig}
        maxZoom={2}
        minZoom={0.05}
        onNodeDragStop={(_event, node) => {
          savePosition(node.data.id, node.position);
        }}
      >
        <Controls />
        <Background />
      </ReactFlow>
      {selectedQuestionId && (
        <SelectedQuestionOverlay
          selectedQuestionId={selectedQuestionId}
          close={() => setSelectedQuestionId(null)}
        />
      )}
    </>
  );
};
