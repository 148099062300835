import * as React from 'react';
import {useContext} from 'react';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';
import {Draggable, DraggableProvided, Droppable} from 'react-beautiful-dnd';
import styles from './GroupedBlocksContent.pcss'
import {GroupComponent} from './GroupComponent';
import {BlockComponent} from './Block';
import {Block} from '../model';
import {BlockGroup} from '../useSurveyCreator';
import { useCreatorRouter } from '../useCreatorRouter';

type GroupedBlocksComponentProps = {
    index: number;
    onEditToggle?: (value: boolean) => void;
    onBlockNameUpdate?: (name: string, block: Block) => void;
    group: BlockGroup,
    dataTest?: string;
    updateBlockName: (name: string, block: Block) => void;

}

export const GroupedBlocksComponent = ({group, index, onEditToggle, updateBlockName}: GroupedBlocksComponentProps) => {
    const { groups } = useContext<SurveyContextType>(SurveyContext);
    const { current } = useCreatorRouter()

    const onEditName = (newGroupName: string) => {
        groups.update(group.id, { name: newGroupName });
    };

    const groupRef = React.useRef<HTMLDivElement>(null);
    return ( <Draggable draggableId={group.id} index={index} key={group.id}>
        {(provided: DraggableProvided) => {
            return (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={styles.groupWrapper}>
                    <GroupComponent groupId={group.id} groupName={group.name} dataTest="block-group"
                                    itemsLength={group.blocks.length}
                                    onUngroup={() => groups.ungroup(group.id)}
                                    onRemoveGroup={() => groups.remove(group.id)}
                                    onEditToggle={onEditToggle}
                                    onEditName={onEditName}
                                    className={styles.group}
                                    groupRef={groupRef}
                    >
                        <Droppable droppableId={group.id} key={group.id} type={group.id}>
                            {(nestedDroppable) => (
                                <div {...nestedDroppable.droppableProps} ref={nestedDroppable.innerRef} className={styles.groupContent}>
                                    <div className={styles.fixMargin}>
                                        {
                                            group.blocks.map((block, idx) => (
                                                <BlockComponent
                                                    key={block.id}
                                                    block={block}
                                                    index={idx}
                                                    onEditToggle={onEditToggle}
                                                    onBlockNameUpdate={updateBlockName}
                                                    groupRef={groupRef}
                                                    isSelected={current.blockId === block.id}
                                                />
                                            ))
                                        }
                                    </div>
                                    {nestedDroppable.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </GroupComponent>
                </div>
            );
        }}
    </Draggable>)
}


