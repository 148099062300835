import * as React from 'react';
import {useContext} from 'react';
import {useIntl} from 'react-intl';
import {useLocation, useParams} from 'react-router';
import DOMPurify from 'dompurify';
import {BotCreatorRouteMatch, getScopeForCreatorParams} from '../useCreatorRouter';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';
import {useUnsavedChanges} from './useUnsavedChanges';
import styles from './BlockDescriptionComponent.pcss';
import {StepHeader} from './StepHeader';
import {BlockDescriptionTabType, StepTabType} from './factory/types';
import {MarkdownContent} from '../../components/MarkdownContent/MarkdownContent';
import {TextArea} from '../../components/TextArea/TextArea';

interface BlockDescriptionComponentProps {
    hasChanges: boolean;
    setHasChanges: (value: boolean) => void;
    markdown: string;
    setMarkdown: (value: string) => void;
    resetViewState: () => void;
    handleChangeTab: (tab: BlockDescriptionTabType) => void;
    activeTab: BlockDescriptionTabType.Preview | StepTabType.General;
    displayEmptyStateDescription: () => void;
}

export const BlockDescriptionComponent: React.FC<BlockDescriptionComponentProps> = ({
    hasChanges,
    markdown,
    setMarkdown,
    setHasChanges,
    resetViewState,
    handleChangeTab,
    activeTab,
    displayEmptyStateDescription
}) => {
    const intl = useIntl();
    const params = useParams<BotCreatorRouteMatch>();
    const {pathname} = useLocation();
    const {blocks, callbacks, fallback, silenceFallback} = useContext<SurveyContextType>(SurveyContext);
    const scope = getScopeForCreatorParams(params, pathname);
    const currentBlock = blocks?.getCurrentStepsOrBlockForScope(scope);
    const {
        containerRef,
        renderUnsavedChangesComponents,
        showModal
    } = useUnsavedChanges(hasChanges, intl.messages['unsavedChangesModal.text'] as string);


    const onMarkdownChange = (value: string) => {
        setMarkdown(value);
        setHasChanges(value !== currentBlock?.description);
    };


    const handleSaveDescription = async () => {
        const description = DOMPurify.sanitize(markdown);
        if (scope.type === 'block') {
            await blocks.update({...currentBlock, description});
        } else if (scope.type === 'callback') {
            await callbacks.update(scope.callbackId, {steps: callbacks.get(scope.callbackId).questions, description});
        } else if (scope.type === 'fallback') {
            await fallback.update({steps: fallback.get()?.steps, description});
        } else if (scope.type === 'silenceFallback') {
            await silenceFallback.update({steps: silenceFallback.get()?.steps, description});
        }
        resetViewState();
    };


    const resetDescription = () => {
        setMarkdown( currentBlock.description || '');
        if (!currentBlock.description) {
            displayEmptyStateDescription();
        }
        resetViewState();

    };

    const handleDiscardChanges = () => hasChanges ? showModal() : resetDescription();


    return <div className={styles.container} ref={containerRef}>
        <StepHeader activeTab={activeTab} availableTabs={[BlockDescriptionTabType.Preview]}
                    onClose={handleDiscardChanges} onSave={handleSaveDescription} changeTab={handleChangeTab}
                    disabledSave={!hasChanges} />

        <div className={styles.moduleDescription} data-test="block-description">
            {activeTab === BlockDescriptionTabType.Preview ?
                <MarkdownContent content={DOMPurify.sanitize(markdown)}/> : <TextArea
                    autoFocus
                    dataTest="block-description-textarea"
                    value={markdown}
                    onChange={onMarkdownChange}
                />
            }
        </div>

        {renderUnsavedChangesComponents(resetDescription)}
    </div>
}