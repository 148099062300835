import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import {useCallback, useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {SurveyIntent} from '../model';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';

import {IntentComponent} from './Intent';
import styles from './Intents.pcss';
import {TooltipComponent} from '../../components/Tooltip/Tooltip';
import {InformationIcon} from '../../icons';
import {GroupComponent} from './GroupComponent';
import { useParams } from 'react-router';

export type IntentsProps = {
  onEditToggle?: (value: boolean) => void;
}

export const Intents: React.FC<IntentsProps> = React.memo(({ onEditToggle }) => {
  const {intents} = useContext<SurveyContextType>(SurveyContext);
  const {updateName, removeFromGroup, assignGroup} = intents;
  const params = useParams<{intentId: string}>();
  const currentIntent = intents.getBySlugifiedName(params.intentId);
  const updateIntentName = useCallback(
    (newIntentName: string, intent: SurveyIntent) => updateName(intent.name, newIntentName.trim()),
    [updateName]
  );
  const intl = useIntl();


  const handleRemoveFromGroup = useCallback((intentName: string) => {
    removeFromGroup(intentName);
  }, [removeFromGroup]);

  const removeIntentFromGroup = useCallback((name) => () => {
    handleRemoveFromGroup(name);
  }, [handleRemoveFromGroup]);

  const removeGroup = (group: string) => {
    intents.removeGroup(group);
  };

  const handleAssignToGroup = useCallback((name) => (groupToAssign) => assignGroup(name, groupToAssign), [assignGroup]);

  const [assigned, unassigned] = _.partition(intents.get(), element => element.group !== undefined);
  const groupedIntents = _.orderBy(Object.entries(_.groupBy(assigned, 'group')), ([group]) => group);

  const hasSomeGroups = groupedIntents.length > 0;
  return (
    <div className={styles.intents} data-test={'intents-list'}>
      <div className={styles.titleWithIcon}>
          <FormattedMessage id={'survey-creator.intentsLabel'}>
            {(value) => <span className={styles.title}>{value}</span>}
          </FormattedMessage>

          <TooltipComponent tooltipText={intl.messages['survey-creator.intentsTooltip'] as string}>
            <div style={{padding: '5px'}}>
              <InformationIcon/>
            </div>
          </TooltipComponent>
      </div>

      <div className={styles.list}>
        {
          groupedIntents.map(([group, intentsOfGroup]) => {

            return (
                <GroupComponent key={group} groupId={group} groupName={`#${group}`} itemsLength={intentsOfGroup.length} onRemoveGroup={() => removeGroup(group)} dataTest="intent-group">
                  {
                    intentsOfGroup.map(intent => (
                      <IntentComponent
                        intent={intent}
                        key={intent.name}
                        onRemove={intents.remove}
                        selected={currentIntent && currentIntent.name === intent.name}
                        onIntentNameUpdate={updateIntentName}
                        onRemoveGroup={removeIntentFromGroup(intent.name)}
                        onAssignGroup={handleAssignToGroup(intent.name)}
                        onEditToggle={onEditToggle}
                      />
                    ))
                }
                </GroupComponent>
            );
          })
        }
        <div data-test={'unassigned-intents'} className={classNames({ [styles.unassigned]: hasSomeGroups })}>
          {unassigned.map(intent => (
            <IntentComponent
              intent={intent}
              key={intent.name}
              onRemove={intents.remove}
              selected={currentIntent && currentIntent.name === intent.name}
              onIntentNameUpdate={updateIntentName}
              onAssignGroup={(groupToAssign) => intents.assignGroup(intent.name, groupToAssign)}
              onEditToggle={onEditToggle}
            />
          ))}
        </div>
      </div>
    </div>
  );
});


