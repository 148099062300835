import * as React from 'react';
import {useContext} from 'react';
import {useLocation, useParams} from 'react-router';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';
import {StepDetail} from './StepDetail';
import {BotCreatorRouteMatch, getScopeForCreatorParams} from '../useCreatorRouter';
import {StepTabType} from './factory/types';
import {BlockDescriptionComponent} from './BlockDescriptionComponent';
import {useBlockDescription} from './useBlockDescription';

type StepComponentProps = {
    descriptionElement?: ({onCreateDescription}: { onCreateDescription: () => void }) => JSX.Element
}

export enum ViewState {
    Blank = 'blank',
    Description = 'description',
    EmptyStateDescription = 'emptyStateDescription'
}

export const StepComponent = (({descriptionElement = null}: StepComponentProps = {}) => {
    const { questions} = useContext<SurveyContextType>(SurveyContext);
    const params = useParams<BotCreatorRouteMatch>();
    const {pathname} = useLocation();

    const scope = getScopeForCreatorParams(params, pathname)
    const questionsToDisplay = [...questions.getForScope(scope), questions.new].filter(Boolean)
    const selectedQuestionId = scope.stepId || scope.callbackStepId || scope.fallbackStepId || scope.silenceFallbackStepId;
    const selectedQuestion = questionsToDisplay.find(question => question.id === selectedQuestionId);
    const {viewState,
        setViewState,
        setBlockDescriptionTabType,
        setMarkdown,
        markdown,
        hasChanges,
        setHasChanges,
        resetViewState,
        handleChangeTab,
        blockDescriptionTabType,
        displayEmptyStateDescription,
    } = useBlockDescription();

    if (!!selectedQuestion) {
        return <StepDetail
            key={selectedQuestion?.id}
            question={selectedQuestion}
            close={questions.close}
            onQuestionUpdated={question => questions.save(question, scope)}
        />
    }
    switch (viewState) {
        case ViewState.EmptyStateDescription:
            return descriptionElement ? descriptionElement({
                onCreateDescription: () => {
                    setViewState(ViewState.Description);
                    setBlockDescriptionTabType(StepTabType.General);
                    setMarkdown('')
                }
            }) : <div/>
        case ViewState.Description:

            return <BlockDescriptionComponent
                markdown={markdown}
                setMarkdown={setMarkdown}
                hasChanges={hasChanges}
                setHasChanges={setHasChanges}
                resetViewState={resetViewState}
                handleChangeTab={handleChangeTab}
                activeTab={blockDescriptionTabType}
                displayEmptyStateDescription={displayEmptyStateDescription}
            />
        case ViewState.Blank:
            return <div/>
        default:
            return <div/>
    }
});


