import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { SurveyCreator } from './SurveyCreator';

export const SurveyCreatorRouter: React.FC = () => {
  return (
    <Switch>
      <Route
        render={({ match }) => {
          const { path, url } = match;

          return <Switch>
            <Route 
              path={[
                `${path}/:tabName/modules/:blockId?/:stepId?`,
                `${path}/:tabName/fallback/:fallbackStepId?`,
                `${path}/:tabName/silence-fallback/:silenceFallbackStepId?`,
                `${path}/:tabName/callbacks/:callbackId?/:callbackStepId?`,
                `${path}/:tabName/intents/:intentId?`,
                `${path}/:tabName/:entity?/:entityDetailsTab?`,
              ]} 
              component={SurveyCreator} 
            />
            <Route path={path}>
              <Redirect to={`${url}/scenario`} />
            </Route>
          </Switch>
        }}
      />
    </Switch>
  );
};


