import React from 'react';

import { OpenQuestion } from '../../model';
import {AnonymizeAnswerToggle} from '../AnonymizeAnswerToggle';
import {DuplexToggle} from '../DuplexToggle';
import { IntentsTab } from '../IntentsTab/IntentsTab';
import {BasicMessages} from '../messages/BasicMessages';
import {RequiredAnswer} from '../RequiredAnswer';
import {SaveAs} from '../SaveAs';
import {PostSpeechTimeoutField} from '../PostSpeechTimeoutField/PostSpeechTimeoutField';
import {WaitForRedirectionToggle} from '../WaitForRedirectionToggle';
import {DisableInterpretationToggle} from '../DisableInterpretationToggle';
import { PreSpeechTimeoutField } from '../PreSpeechTimeoutField/PreSpeechTimeoutField';
import { RecognizerField } from '../RecognizerField/RecognizerField';

import {TabFactory} from './question-component-factory-model';
import { StepTabType } from './types';

export const openQuestionFactory: TabFactory<OpenQuestion> = {
  [StepTabType.Question]: (props) => {
    return (
      <>
        <BasicMessages {...props} />
      </>
    );
  },
  [StepTabType.General]: (props) => {
    return (
      <>
        <RecognizerField question={props.question} onChange={props.onChange} />
        <SaveAs {...props} />
        <RequiredAnswer {...props} />
        <DuplexToggle {...props} />
        <AnonymizeAnswerToggle {...props} />
        <PostSpeechTimeoutField question={props.question} onChange={props.onChange} />
        <PreSpeechTimeoutField question={props.question} onChange={props.onChange} />
        <DisableInterpretationToggle {...props} />
        <WaitForRedirectionToggle {...props} />
      </>
    );
  },
  [StepTabType.Intents]: (props) => <IntentsTab {...props} />
};
