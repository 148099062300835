import * as React from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router';

import { StepDetail } from '../components/StepDetail';
import { SurveyContext, SurveyContextType } from '../SurveyCreatorContext';
import { BotCreatorRouteMatch, getScopeForCreatorParams } from '../useCreatorRouter';

import styles from './SelectedQuestionOverlay.pcss';

type SelectedQuestionOverlayProps = {
  selectedQuestionId: string;
  close: () => void;
};

export function SelectedQuestionOverlay({
  selectedQuestionId,
  close,
}: SelectedQuestionOverlayProps) {
  const intl = useIntl();
  const { questions } = React.useContext<SurveyContextType>(SurveyContext);
  const params = useParams<BotCreatorRouteMatch>();
  const { pathname } = useLocation();

  const scope = getScopeForCreatorParams(params, pathname);
  const selectedQuestion = questions
    .getForScope(scope)
    .find((q) => q.id === selectedQuestionId);

  return (
    <div className={styles.overlayContainer}>
      <div className={styles.overlay} onClick={close} />
      <div className={styles.questionDetailsContainer}>
        <div>
          <div className={styles.questionDetailsHeader}>
            <span className={styles.questionName}>{selectedQuestion.name}</span>
            <span className={styles.questionType}>
              {
                intl.messages[
                  `survey-creator.${selectedQuestion.type}StepLabel`
                ]
              }
            </span>
          </div>
          <div className={styles.questionDetailsContentWrapper}>
            <StepDetail
              key={selectedQuestion.id}
              question={selectedQuestion}
              close={_.noop}
              onQuestionUpdated={(updatedQuestion) => {
                questions.save(updatedQuestion, scope);
                close();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
