import _ from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { FormattedMessage } from 'react-intl';

import { Modal } from '../components/Modal/Modal';
import { TextArea } from '../components/TextArea/TextArea';
import { Intent } from '../surveyCreator/SurveyCreatorContext';

import styles from './learning.pcss';

type TrainData = { text: string; intent: string };

type Props = {
    intentSuggestions: Intent[];
    filterSuggestions: (value: { value: string }) => void;
    onAdd: (trainData: TrainData[]) => Promise<void>;

    isOpen: boolean;
    onClose: () => void;
};

const renderSuggestion = (intent: Intent) => <div
    data-test="suggested-intent">{intent.displayName ? intent.displayName : intent.name}</div>;

export const MultipleIntents = ({ intentSuggestions, filterSuggestions, onAdd, isOpen, onClose }: Props) => {
    const [sentencesText, setSentencesText] = useState('');
    const [intent, setIntent] = useState('');
    const closeModal = () => {
        setIntent('');
        setSentencesText('');
        onClose();
    };
    const addSentences = async () => {
        const data = sentencesText
            .split('\n')
            .filter(text => text.trim() !== '')
            .map(text => ({ text: text.trim(), intent: intent }));
        if (data.length > 0 && !!intent) {
            await onAdd(data);
            closeModal();
        }
    };

    const intentInputProps = {
        value: intent,
        onChange: (event: any, { newValue }: any) => setIntent(newValue),
        'data-test': 'intent-input'
    };

    return (
        <div data-test={'multiple-intents'}>
            {isOpen && (
                <Modal dataTest={'multiple-intents-modal'}>
                    <div className={styles.multiIntentsForm}>
                        <Autosuggest
                            suggestions={intentSuggestions}
                            onSuggestionsFetchRequested={filterSuggestions}
                            onSuggestionsClearRequested={_.noop}
                            shouldRenderSuggestions={() => true}
                            getSuggestionValue={suggestion => suggestion.name}
                            renderSuggestion={renderSuggestion}
                            inputProps={intentInputProps}
                        />
                        <TextArea
                            value={sentencesText}
                            onChange={text => setSentencesText(text)}
                            dataTest="sentences-text-area"
                        />
                        <div className={styles.multiIntentsButtons}>
                            <button className={styles.button} data-test={'add-sentences'} onClick={addSentences}>
                                <FormattedMessage id={'learning.addIntent'} />
                            </button>
                            <button className={styles.button} data-test={'cancel'} onClick={closeModal}>
                                <FormattedMessage id={'learning.cancel'} />
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};
