import * as React from 'react';

import {Message, StatementStep} from '../model';

import {MessageComponent} from './messages/Message';
import {useContext} from 'react';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';

type Props = {
    question: StatementStep,
    onChange: (question: StatementStep) => void,
};

export const StatementComponent = (props: Props) => {
    const { questions: { withError } } = useContext<SurveyContextType>(SurveyContext);

    const setMessage = (message: Message) => {
        props.onChange({
            ...props.question,
            message
        });
    };

    return (
        <div data-test={'statement-reaction'}>
            <MessageComponent
               message={props.question.message}
               dataTestPrefix={'question'}
               labelId={'survey-creator.statementLabel'}
               onChange={setMessage}
               errors={withError(props.question.id, 'message')}
            />
        </div>
    );
};
