import React, { useContext } from 'react';
import {
  ExecutableFunctionInputDefinition,
  ExecutableFunctionWidget,
} from '../../model';
import { TextAreaWithVariables } from '../../../components/TextArea/TextAreaWithVariables';
import { SurveyContext, SurveyContextType } from '../../SurveyCreatorContext';
import { Toggle } from '../../../components/Toggle/Toggle';
import {SelectComponent} from '../../../components/Select/Select';
import { useParams } from 'react-router';
import { BotCreatorRouteMatch, getScopeForVariables } from '../../useCreatorRouter';

type TextInputInputWidgetProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};
export const TextInputInputWidget = ({
  value,
  placeholder,
  onChange,
}: TextInputInputWidgetProps) => {
  const { variables } = useContext<SurveyContextType>(SurveyContext);
  const params = useParams<BotCreatorRouteMatch>();
  return (
    <TextAreaWithVariables
      value={value}
      asInput
      availableVariables={variables.getForScope(getScopeForVariables(params))}
      onChange={onChange}
      {...(placeholder && { placeholder })}
    />
  );
};

type TextAreaInputWidgetProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};
export const TextAreaInputWidget = ({
  value,
  placeholder,
  onChange,
}: TextAreaInputWidgetProps) => {
  const { variables } = useContext<SurveyContextType>(SurveyContext);
  const params = useParams<BotCreatorRouteMatch>();
  return (
    <TextAreaWithVariables
      value={value}
      availableVariables={variables.getForScope(getScopeForVariables(params))}
      onChange={onChange}
      {...(placeholder && { placeholder })}
    />
  );
};

type CheckboxInputWidgetProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const CheckboxInputWidget = ({
  value,
  onChange,
}: CheckboxInputWidgetProps) => {
  return <Toggle on={value} onToggle={() => onChange(!value)} />;
};

type DropdownInputWidgetProps = {
  value: string;
  options: string[];
  onChange: (value: boolean) => void;
};

export const DropdownInputWidget = ({
  value,
  options,
  onChange,
}: DropdownInputWidgetProps) => {
  const o = options.map(option => ({ id: option, name: option }))
  const v = o.find(option => option.id === value)

  return <SelectComponent
    onChange={e => onChange(e.id)}
    options={o}
    value={v}
  />
};

type FunctionInputWidgetProps = {
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
};

export const renderInputTypeToWidget = (
  widgetType: ExecutableFunctionInputDefinition['render']
): React.FC<FunctionInputWidgetProps> => {
  switch (widgetType) {
    case ExecutableFunctionWidget.TextInput:
      return TextInputInputWidget;
    case ExecutableFunctionWidget.TextArea:
      return TextAreaInputWidget;
    case ExecutableFunctionWidget.Checkbox:
      return CheckboxInputWidget;
    case ExecutableFunctionWidget.Dropdown:
      return DropdownInputWidget;
    default:
      return null;
  }
};
