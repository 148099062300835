import classNames from 'classnames';
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';

import {GoToModuleIcon, PowerIcon, ConditionalQuestionIcon} from '../../icons';
import { TooltipComponent } from '../../components/Tooltip/Tooltip';
import { IconButton } from '../../components/IconButton/IconButton';
import {CallbackTypeId} from '../../utils/CurrentBot';
import { BlockQuestion, ConditionalQuestion, GoToBlockQuestion, QuestionType } from '../model';
import { SurveyContext, SurveyContextType } from '../SurveyCreatorContext';
import { BotCreatorRouteMatch, getBlockIdForScope, getScopeForCreatorParams, useCreatorRouter } from '../useCreatorRouter';

import styles from './BlockQuestionComponent.pcss';
import { BlockQuestionOptionsComponent } from './BlockQuestionOptions';
import { getSurveyCreatorView } from '../useSurveyCreatorView';
import { useLocation, useParams } from 'react-router';
import { LiveStatusContext } from '../../liveStatus/LiveStatus';

export type QuestionScopeType = 'block' | 'fallback' | 'callback' | 'silenceFallback';

type Props = {
    question: BlockQuestion,
    index: number,
    selected?: boolean,
    disabledDrag?: boolean,
    blockId: string,
}

export const BlockQuestionComponent = (props: Props) => {
    const intl = useIntl();
    const liveStatusContext = useContext(LiveStatusContext);
    const [visitors, setVisitors] = useState([]);
    const { question, index, selected, disabledDrag } = props;
    const { questions, blocks, fallback, silenceFallback } = useContext<SurveyContextType>(SurveyContext);
    const {navigateToBlock} = useCreatorRouter();
    const params = useParams<BotCreatorRouteMatch>();
    const { pathname } = useLocation();
    const scope = getScopeForCreatorParams(params, pathname);
    const currentBlockId = getBlockIdForScope(scope) ?? blocks.get()?.[0]?.id;

    const { navigateToBlockQuestion, navigateToFallbackQuestion, navigateToCallbackQuestion, navigateToSilenceFallbackQuestion} = useCreatorRouter()
    const questionType = _.flow(
        (type: QuestionType) => type === QuestionType.CONDITIONAL ? (question as ConditionalQuestion).question.type : type
    )(question.type);

    useEffect(() => {
        setVisitors(liveStatusContext?.visitors?.[question?.id] || []);
    }, [liveStatusContext]);

    const handleClick = (action: any) => (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        action(question, scope);
    };

    const handleRemove = handleClick(questions.remove);
    const handleClone = handleClick(questions.clone(question.name && `${question.name} (${intl.messages['survey-creator.cloneLabel']})`));
    const handleToggleDisabled = handleClick(async () => await questions.save(
        { ...question, isDisabled: !question.isDisabled },
        scope
    ));
    const isNewQuestion = _.get(questions.new, 'id') === question.id;
    const handleMove = (blockId: string) => handleClick(() => {
        questions.move(question, currentBlockId, blockId);
    });

    const containerRef = useRef(null);
    useEffect(() => {
        const view = getSurveyCreatorView();
        if (selected && view === 'list') {
            containerRef.current?.scrollIntoView?.();   
        }
    }, [])

    function getQuestionPreviewName() {
        return (
            <div
                data-test="question-name"
                className={classNames(styles.questionPreviewName, {
                    [styles.empty]: !question.name,
                    [styles.disabled]: question.isDisabled,
                })}
            >
                {questionType === QuestionType.GO_TO_BLOCK
                    ? intl.messages['survey-creator.go_to_blockStepLabel']
                    : (question.name || intl.messages['survey-creator.emptyNamePlaceholder'])
                }
            </div>
        );
    }

    function goToBlockNextBlock() {
        return question.type === QuestionType.CONDITIONAL ? ((question as ConditionalQuestion).question as GoToBlockQuestion).nextBlock : (question as GoToBlockQuestion).nextBlock;
    }

    function navigateToStep() {
        switch (scope.type) {
            case 'fallback':
                navigateToFallbackQuestion(question.id)
                break;
            case 'silenceFallback':
                navigateToSilenceFallbackQuestion(question.id)
                break;
            case 'callback':
                navigateToCallbackQuestion(props.blockId as CallbackTypeId, question.id)
                break;
            case 'block':
            default:
                navigateToBlockQuestion(props.blockId, question.id)
        }
    }

    return (
        <>
            <Draggable key={question.id} draggableId={question.id} index={index} isDragDisabled={disabledDrag}>
                {(provided) => <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    data-test="block-question"
                    className={classNames(styles.questionPreview, { [styles.selected]: selected, [styles.disabled]: question.isDisabled})}
                    data-question-type={questionType}
                    onClick={navigateToStep}
                    data-is-placeholder={isNewQuestion}
                    id={question.id}
                >
                    <div className={styles.questionPreviewContainer} ref={containerRef}>
                        <div className={styles.questionPreviewContent}>
                            <div className={styles.questionPreviewActions}>
                                <TooltipComponent
                                    tooltipText={intl.formatMessage({id: question.isDisabled ? 'menuOption.enable.tooltip' : 'menuOption.disable.tooltip'})}>
                                    <IconButton
                                        className={question.isDisabled ? styles.disabledIcon : ''}
                                        Icon={PowerIcon}
                                        onClick={handleToggleDisabled}
                                    />
                                </TooltipComponent>
                            </div>

                            <div className={styles.previewHeader}>
                                {getQuestionPreviewName()}
                                {question.type === 'conditional' &&
                                    <ConditionalQuestionIcon data-test="conditional-question-icon" animationClass={styles.conditionalQuestionIcon} />}
                            </div>
                            <div className={styles.typeAndOptionsContainer}>
                                {questionType !== QuestionType.GO_TO_BLOCK
                                    ? (
                                        <div
                                            className={classNames(styles.questionTypeName, { [styles.disabled]: question.isDisabled })}
                                        >{intl.messages[`survey-creator.${questionType}StepLabel`]}</div>
                                    )
                                    : <div className={styles.goToBlockPreview}>
                                        <GoToModuleIcon animationClass={styles.goToModuleIcon} />
                                        {blocks.get().some(q => q.id === goToBlockNextBlock())
                                            ? (
                                                <div
                                                    data-test="go-to-block-question-preview"
                                                    className={classNames(styles.questionTypeName, { [styles.disabled]: question.isDisabled })}
                                                >
                                                    <div data-testid="go-to-block-question-text" className={styles.goToBlockPreviewText} onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigateToBlock(goToBlockNextBlock());
                                                    }}>{`${blocks.get().find(q => q.id === goToBlockNextBlock()).name}`}</div>
                                                </div>
                                            ) : (
                                                <div
                                                    data-test="go-to-block-question-preview"
                                                    className={classNames(styles.goToBlockPreviewPlaceholder, { [styles.disabled]: question.isDisabled })}
                                                >
                                                    {!!(question as GoToBlockQuestion).nextBlock
                                                        ? intl.messages['survey-creator.goToBlock.notExistingBlockPreviewPlaceholder']
                                                        : intl.messages['survey-creator.goToBlock.blockPreviewPlaceholder']}
                                                </div>
                                            )
                                        }
                                    </div>}


                                <BlockQuestionOptionsComponent
                                    scope={scope.type}
                                    isStepDisabled={question.isDisabled}
                                    onDuplicate={handleClone}
                                    onDelete={handleRemove}
                                    onMove={(event, blockId) => handleMove(blockId)(event)}
                                    blocks={[...blocks.get(), fallback.getAsBlock(), silenceFallback.getAsBlock()]}
                                    currentBlockId={currentBlockId}
                                />
                            </div>
                        </div>
                    </div>
                    {visitors.length > 0 && (
                      <div className={styles.visitors}>
                          {visitors.map((visitor, i) => (
                            <div key={i}>{visitor.email.charAt(0)}</div>
                          ))}
                      </div>
                    )}
                </div>}
            </Draggable>

        </>
    );
};
