import * as React from 'react';

import { ElementContainer } from '../../components/ElementContainer/ElementContainer';
import { Label } from '../../components/Label';
import { HttpMapping } from '../model';

import { StepElementProps } from './StepElementProps';
import { Mappings } from './mapping/Mapping';

import styles from './HttpMappingsComponent.pcss';

type HttpMappingsComponentProps = { mappings?: HttpMapping[] };

export const HttpMappingsComponent = ({
  question,
  onChange,
}: StepElementProps<HttpMappingsComponentProps>) => {
  const mappings = question.mappings || [];

  const handleChange = (updatedMappings: HttpMapping[]) => {
    onChange({ ...question, mappings: updatedMappings });
  };

  return (
    <ElementContainer>
      <Label className={styles.label} labelId={'survey-creator.mapping.mappingsLabel'} />
      <Mappings mapping={mappings} onChange={handleChange} />
    </ElementContainer>
  );
};
