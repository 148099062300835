import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { entitiesSelectors as entitiesSelector } from '../../../../selectors/entitiesSelectors';
import { fetchEntities } from '../../../store/entities/actions';

export const useEntities = (currentBotId: string) => {
  const dispatch = useDispatch();
  const { isLoading, isReady, data } = useSelector(entitiesSelector);

  useEffect(() => {
    if (!isReady) {
      dispatch(fetchEntities.request({ id: currentBotId }));
    }
  }, [currentBotId, isReady]);

  return {
    isLoading: isLoading,
    isReady: isReady,
    entities: data,
  };
};
