import * as React from 'react';
import { useContext } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { reorder } from '../../utils/drag-drop';
import { Block } from '../model';
import { SurveyContext, SurveyContextType } from '../SurveyCreatorContext';

import styles from './BlockPreview.pcss';
import {BlockQuestionComponent} from './BlockQuestionComponent';
import { useLocation, useParams } from 'react-router';
import { BotCreatorRouteMatch, getScopeForCreatorParams } from '../useCreatorRouter';

type Props = {
    currentlySelectedBlock: Block,
    onBlockUpdated: (block: Block) => any
    blockId: string;
};

export const BlockPreview = (props: Props) => {
    const { questions } = useContext<SurveyContextType>(SurveyContext);
    const params = useParams<BotCreatorRouteMatch>();
    const { pathname } = useLocation();

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        props.onBlockUpdated({
            ...props.currentlySelectedBlock,
            questions: reorder(props.currentlySelectedBlock.questions, result.source.index, result.destination.index)
        });
    };

    const scope = getScopeForCreatorParams(params, pathname);
    const questionsToDisplay = [...questions.getForScope(scope), questions.new].filter(Boolean)
    const selectedQuestionId = scope.stepId || scope.callbackStepId || scope.fallbackStepId || scope.silenceFallbackStepId;

    return (
        <>
            <div data-test="details-name" className={styles.blockName}>{props.currentlySelectedBlock.name}</div>
            <div className={styles.questions}>
                <DragDropContext data-test={'drag-drop-container'} onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {
                                    
                                    questionsToDisplay.map((question, index) => (
                                            <BlockQuestionComponent
                                                blockId={props.blockId}
                                                question={question}
                                                key={question.id}
                                                index={index}
                                                selected={selectedQuestionId === question.id}
                                                disabledDrag={!!questions.new || questions.getForScope(scope).length < 2}
                                            />
                                        ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
    );
};
