import styles from './StepLine.pcss';
import React from 'react';

type InterpretationLineElementProps = {
    onClick: () => void;
    children?: React.ReactNode;
};

export const InterpretationLineElement = ({onClick, children}: InterpretationLineElementProps) => {
    return (
        <div className={styles.wrapper}>
            <button
                data-test="interpretation-line"
                className={styles.button}
                onClick={onClick}
            >
                {children}

            </button>
        </div>
    )
}