import { Dictionary, has } from 'lodash';
import slugify from 'slugify';

import { LanguageCode } from '../language/botLanguage';
import { Schedule } from '../scheduler/schedule-model';

import { VariableType } from './components/variables/Variables.utils';
import { Group } from './SurveyCreatorContext';
import { VoiceConfigType } from '../views/Settings/store/types';

export enum QuestionType {
  REDIRECT = 'redirect',
  NUMERICAL = 'numerical',
  WORD = 'word',
  CONDITIONAL = 'conditional',
  DATETIME = 'datetime',
  DATE = 'date',
  END = 'end',
  GO_TO_BLOCK = 'go_to_block',
  OPEN = 'open',
  SPELLING = 'spelling',
  SMS = 'send_sms',
  EMAIL = 'send_email',
  HTTP = 'http',
  SET_VARIABLE = 'set_variable',
  STATEMENT = 'statement',
  HELP_PROPOSE = 'help_propose',
  GENERIC_ORDER = 'generic_order',
  CITY_OR_COMMUNITY = 'city_or_community',
  DATA_COLLECTION = 'data_collection',
  FUNCTION = 'function',
}

export type BasicMessage = {
  text: string;
  audio?: string[];
};

export type PermutableBasicMessage = BasicMessage & { read: ReadOption };

export type ReadOption = 'PERMUTATE' | 'SEQUENCE' | 'LAST' | 'FIRST';

export type QuestionCustomStatus = 'voicemail' | 'stop_calling' | 'all_required_questions_answered';

export type ConditionalMessage = {
  name: string;
  values: Dictionary<BasicMessage>;
};

export function isConditionalMessage(message: Message): message is ConditionalMessage {
  return (message as ConditionalMessage).values !== undefined;
}

export type MultipleConditionalMessages = {
  name: string;
  values: Dictionary<BasicMessage[]>;
};

export type Message = BasicMessage | ConditionalMessage;
export type MultipleMessages = BasicMessage[] | MultipleConditionalMessages;

export type PossibleQuestion = {
  id: string;
  intent: string;
  message: Message;
};

export type NotPreciseAnswerDictionary = { id: string; type: WordAnswerType.DICTIONARY };
export type NotPreciseAnswerEntity = { id: string; key: string; type: WordAnswerType.ENTITY };
export type NotPreciseAnswerAnswer = NotPreciseAnswerEntity | NotPreciseAnswerDictionary;
export type NotPreciseAnswer = {
  // note1 - we're migrating elements into answers. temporarily we will keep both keys in types (save only to answers. see note2)
  // note2 - during model->view elements will get translated into answers
  // note3 - because of note1, we can't require to provide elements during the view->model mapping
  elements?: string[];
  answers?: NotPreciseAnswerAnswer[];
  message: Message;
  repeatMessages: MultipleMessages;
};

export interface Question {
  id: string;
  type: QuestionType;
  isDisabled?: boolean;
  ignorable?: boolean;
  name?: string;
  message: Message;
  repeatMessages: MultipleMessages;
  fromIntentMessage?: Message;
  possibleQuestions?: PossibleQuestion[];
  saveTo?: string;
  ignorableIntents?: string[];
  duplex?: boolean;
  requiredAnswer?: boolean;
  maxRepeatQuestionCount?: number;
  changeStatusTo?: QuestionCustomStatus;
  anonymizeAnswer?: boolean;
  postSpeechSilenceTimeout?: number,
  preSpeechSilenceTimeout?: number,
  recognizerOptions?: {
    type: Recognizer['type'],
    model: Recognizer['model'],
  }
}

export interface Synonym {
  verbatim: string;
  lemma?: string;
}

export type DictionaryWordAnswer = {
  id: string;
  key: string;
  synonyms: Synonym[];
  message?: PermutableBasicMessage;
  goTo?: string;
  type: WordAnswerType.DICTIONARY;
}

export type EntityWordAnswerPointer = {
  // watch out, id refers to entity name - not uuid
  id: string;
  // key refers to entity name (same as id)
  key: string;
  type: WordAnswerType.ENTITY;
}

export type WordsDictionaryItem = DictionaryWordAnswer | EntityWordAnswerPointer;

export type WordsDictionary = WordsDictionaryItem[];

export enum WordAnswerType {
  DICTIONARY = 'DICTIONARY',
  ENTITY = 'ENTITY'
}

export type DefaultReadAnswerType = 'SEQUENCE' | 'PERMUTATE'

export interface WordQuestion extends Question {
  type: QuestionType.WORD,
  dictionary: WordsDictionary;
  reactions: ReactionItem[];
  minAnswers?: number;
  maxAnswers?: number;
  notPreciseAnswer: NotPreciseAnswer[];
  goTo: GoTo;
  acceptAnyAnswer?: boolean;
  anyIfMultipleAnswers?: boolean;
  useDynamicPhonemization?: boolean
  defaultReadAnswerType?: DefaultReadAnswerType
  associatedAnswers?: AssociatedAnswer[];
}

export interface ReactionItem {
  id: string;
  key?: string;
  goTo: string;
  type: WordAnswerType,
}

export type GoTo = {
  false?: string;
  repeated?: string;
  outOfRange?: string;
  invalidLength?: string
};

interface PropertyDefinitionValue {
  id: string,
  name: string,
  synonyms: Synonym[],
  type?: string,
  messageText?: string
}

export interface Property {
  id: string,
  name: string,
  values: PropertyDefinitionValue[],
  message: Message,
  type?: 'values' | 'count',
  textFormatting: {
    message?: string,
    messageSingular?: string,
    messagePlural?: string,
    messagePluralGenitive?: string,
    position: 'after' | 'before',
    order: number
  }
}

interface ProductProperty {
  property: string,
  values?: string[],
  message?: Message,
  optionalProperty?: boolean
}

type Metadata = { [key: string]: any };

export interface Product {
  name: string,
  synonyms: Synonym[],
  properties?: ProductProperty[],
  metadata: Metadata
}

export interface GenericOrderQuestion extends Question {
  products: Product[];
  properties: Property[];
  initialMessage: Message;
  productAddedMessage: Message;
  resignationMessage: Message;
  repeatMessages: MultipleMessages;
  id: string;
  type: QuestionType;
  name?: string;
  associatedAnswers?: AssociatedAnswer[];
}

type QuestionMessages = {
  message: Message;
  repeatMessage: Message;
}

export interface CityOrCommunityQuestion {
  miastoWies: QuestionMessages;
  wojewodztwo: QuestionMessages;
  miasto: QuestionMessages;
  gmina: QuestionMessages;
  powiat: QuestionMessages;
}

export interface OpenQuestion extends Question {
  type: QuestionType.OPEN;
  associatedAnswers?: AssociatedAnswer[];
  disableInterpretation?: boolean;
}

export interface SpellingQuestion extends Question {
  type: QuestionType.SPELLING;
  associatedAnswers?: AssociatedAnswer[];
  dictionary?: WordsDictionary;
}

export interface DateQuestion extends Question {
  type: QuestionType.DATE;
  validations?: DateQuestionValidations;
  goTo: { repeated?: string; }
  associatedAnswers?: AssociatedAnswer[];
}

export interface DateQuestionValidations {
  forbidDatesInTheFuture?: boolean;
  forbidDatesInThePast?: boolean;
}

export interface SmsStep {
  id: string;
  isDisabled?: boolean;
  name?: string;
  message: string;
  from: string;
  to?: string;
  operator: string;
  type: QuestionType.SMS;
  associatedAnswers?: AssociatedAnswer[];
}

export interface EmailStep {
  id: string;
  isDisabled?: boolean;
  name?: string;
  message: string;
  to: string;
  subject: string;
  type: QuestionType.EMAIL;
  associatedAnswers?: AssociatedAnswer[];
}

export interface DataCollectionStep {
  id: string;
  isDisabled?: boolean;
  name?: string;
  type: QuestionType.DATA_COLLECTION;
  collectedData: CollectedDataItem[],
  associatedAnswers?: AssociatedAnswer[];
}

export enum ExecutableFunctionWidget {
  TextInput = 'TextInput',
  TextArea = 'TextArea',
  NumberInput = 'NumberInput',
  Checkbox = 'Checkbox',
  Dropdown = 'Dropdown',
}

interface ExecutableFunctionBaseInput {
  name: string;
  description?: string;
  type: 'string' | 'number' | 'boolean';
  render: ExecutableFunctionWidget;
}

export interface ExecutableFunctionStringInput extends ExecutableFunctionBaseInput {
  type: 'string';
  render: ExecutableFunctionWidget.TextInput | ExecutableFunctionWidget.TextArea;
}

export interface ExecutableFunctionNumberInput extends ExecutableFunctionBaseInput {
  type: 'number';
  render: ExecutableFunctionWidget.NumberInput;
}

export interface ExecutableFunctionBooleanInput extends ExecutableFunctionBaseInput {
  type: 'boolean';
  render: ExecutableFunctionWidget.Checkbox;
}

export interface ExecutableFunctionDropdownInput extends ExecutableFunctionBaseInput {
  type: 'string';
  render: ExecutableFunctionWidget.Dropdown;
  options: string[];
}

export type ExecutableFunctionInputDefinition =
  | ExecutableFunctionStringInput
  | ExecutableFunctionNumberInput
  | ExecutableFunctionBooleanInput
  | ExecutableFunctionDropdownInput;

export interface ExecutableFunctionOutputDefinition {
  name: string;
  description?: string;
  type: 'string' | 'number' | 'boolean' | 'mapping' | 'inputsArray';
  placeholder?: string;
  render?: ExecutableFunctionWidget;
  options?: string[];
  inputs?: ExecutableFunctionInputDefinition[];
}

export enum ExecutableFunctionType {
  Creator = 'creator',
  LLM = 'llm',
}

export type ExecutableFunctionDefinition<
  I extends ExecutableFunctionInputDefinition[],
  O extends ExecutableFunctionOutputDefinition[]
> = {
  name: string;
  description: string;
  type: ExecutableFunctionType;
  inputs: I;
  outputs: O;
};

export interface FunctionStep {
  id: string;
  isDisabled?: boolean;
  name?: string;
  type: QuestionType.FUNCTION;

  functionName: string;
  functionInputs: Record<string, any>;
  functionOutputs: Record<string, any>;

  associatedAnswers?: AssociatedAnswer[];
}

export type Recognizer = {
  name: string;
  type: string;
  model: string;
  languages: string[];
}

const HttpMappingConditionalQuestionTypes = Object.values(VariableType);
type HttpMappingConditionalQuestionType = typeof HttpMappingConditionalQuestionTypes[number]

export interface HttpMapping {
  variable: string;
  path: string;
  type: HttpMappingConditionalQuestionType;
  possibleValues?: string[];
}

export interface HttpHeader {
  name: string;
  value: string;
}

export interface HttpStep {
  id: string;
  isDisabled?: boolean;
  name?: string;
  url: string;
  method: 'get' | 'post';
  body?: string;
  mappings: HttpMapping[];
  headers: HttpHeader[];
  type: QuestionType.HTTP;
  associatedAnswers?: AssociatedAnswer[];
  timeout?: number;
}

export interface SetVariableStep {
  id: string;
  isDisabled?: boolean;
  name?: string;
  variable: string;
  value: string;
  type: QuestionType.SET_VARIABLE;
  associatedAnswers?: AssociatedAnswer[];
}

export interface CollectedDataItem {
  name: string,
  value: string,
}

export const isHttpStep = (question: BlockQuestion): question is HttpStep => question.type === QuestionType.HTTP;

export const isSetVariableStep = (question: BlockQuestion): question is SetVariableStep => question.type === QuestionType.SET_VARIABLE;

export const isConditionalStep = (question: BlockQuestion): question is ConditionalQuestion => question.type === QuestionType.CONDITIONAL;

export const isQuestionWithAnswer = (question: BlockQuestion): question is QuestionWithAnswer =>
  [QuestionType.NUMERICAL, QuestionType.DATETIME, QuestionType.DATE, QuestionType.WORD, QuestionType.OPEN, QuestionType.SPELLING].includes(question.type);

export const isQuestionWithAssociatedAnswer = (question: BlockQuestion): boolean => has(question, 'associatedAnswers')

export const isWordStep = (question: BlockQuestion): question is WordQuestion => question.type === QuestionType.WORD;
export const isGenericOrderStep = (question: BlockQuestion): question is GenericOrderQuestion => question.type === QuestionType.GENERIC_ORDER;

export const isBlockSurveyIntent = (intent: SurveyIntent): intent is BlockSurveyIntent => has(intent, 'block');
export const isWordSurveyIntent = (intent: SurveyIntent): intent is WordSurveyIntent => !isBlockSurveyIntent(intent) && isWordStep(intent.question);

export const isSynonymsArray = (synonyms: Synonym[] | string[]): synonyms is Synonym[] => {
  return synonyms.length > 0 && typeof synonyms[0] === 'object';
}

export const isFunctionStep = (question: BlockQuestion): question is FunctionStep => question.type === QuestionType.FUNCTION;

export type ConfirmationDictionary = [
  { id: 'true'; key: 'YES'; synonyms: Synonym[], goTo?: string, type: WordAnswerType.DICTIONARY },
  { id: 'false'; key: 'NO'; synonyms: Synonym[], goTo?: string, type: WordAnswerType.DICTIONARY }
];

export type Range = { id: string; min: number; max: number };

export type LengthConfig = {
  min?: number;
  max?: number;
};

export type DtmfOptions = {
  maxDigits: number,
  minDigits: number,
  digitsTimeoutMs: number,
  maxTimeoutMs: number
};

export enum RecognizerSource {
    SPEECH = 'speech',
    DTMF = 'dtmf',
}

export interface NumericalQuestion extends Question {
  ranges: Range[];
  goTo: GoTo;
  joinNumbers: boolean;
  length?: LengthConfig;
  associatedAnswers?: AssociatedAnswer[];
  dtmfOptions?: DtmfOptions;
  inputType?: RecognizerSource[]
}

export type EndQuestion = {
  id: string;
  type: QuestionType.END;
  isDisabled?: boolean;
  name?: string;
  message: Message;
  changeStatusTo?: QuestionCustomStatus;
  associatedAnswers?: AssociatedAnswer[];
};

export interface HelpProposeQuestion {
  id: string;
  type: QuestionType.HELP_PROPOSE;
  isDisabled?: boolean;
  name?: string;
  saveTo?: string;
  message: Message;
  followupMessage: Message;
  helpNeededMessage: Message;
  fromIntentMessage: Message;
  dictionary: ConfirmationDictionary;
  anonymizeAnswer?: boolean;
  associatedAnswers?: AssociatedAnswer[];
}

export interface StatementStep {
  id: string;
  type: QuestionType.STATEMENT;
  isDisabled?: boolean;
  name?: string;
  message: Message;
  saveTo?: string;
  duplex?: boolean;
  associatedAnswers?: AssociatedAnswer[];
}

export type RecordingAction = 'stop';

export interface RedirectStep {
  id: string;
  type: QuestionType.REDIRECT;
  isDisabled?: boolean;
  name?: string;
  message: Message;
  redirectTo: string;
  redirectFrom?: string;
  redirectTrunk?: string;
  redirectType: 'transfer' | 'dial' | 'local';
  recordingAction?: RecordingAction;
  associatedAnswers?: AssociatedAnswer[];
  redirectHeaders?: RedirectHeader[];
  redirectDestination?: string;
}

export interface RedirectHeader {
  name: string;
  value: string;
}

export type GoToBlockQuestion = {
  id: string;
  isDisabled?: boolean;
  name?: string;
  type: QuestionType.GO_TO_BLOCK;
  nextBlock: string;
  associatedAnswers?: AssociatedAnswer[];
};
export type DatetimeQuestion = {
  id: string;
  type: QuestionType.DATETIME;
  isDisabled?: boolean;
  ignorable?: boolean;
  name?: string;
  maxRepeatQuestionCount?: number;
  date: {
    message: Message;
    repeatMessages: MultipleMessages;
    fromIntentMessage: Message;
  };
  hour: {
    message: Message;
    repeatMessages: MultipleMessages;
    fromIntentMessage: Message;
  };
  possibleQuestions?: PossibleQuestion[];
  goTo: {
    false?: string;
    repeated?: string;
  };
  saveTo?: string;
  ignorableIntents?: string[];
  duplex?: boolean;
  requiredAnswer?: boolean;
  isPostponeRequest?: boolean;
  anonymizeAnswer?: boolean;
  associatedAnswers?: AssociatedAnswer[];
};

export enum ConditionOperator {
  LessThan = 'lt',
  GreaterThan = 'gt',
  Equal = 'eq',
  NotEqual = 'neq',
  In = 'in',
  Truthy = 'truthy',
  Falsy = 'falsy',
  Exists = 'exists',
  Includes = 'includes',
  Invalid = 'invalid'
}

export interface Condition {
  variableId?: string;
  operator?: ConditionOperator;
  operand?: string | string[] | number | boolean;
}

export interface ConditionGroup {
  connective: 'AND' | 'OR';
  args: Condition[];
}

export interface CompoundConditions {
  connective: 'AND' | 'OR';
  args: ConditionGroup[];
}

export type ConditionalQuestion = {
  id: string;
  isDisabled?: boolean;
  name?: string;
  type: QuestionType.CONDITIONAL;
  question: BlockQuestion;
  conditions: CompoundConditions;
  associatedAnswers?: AssociatedAnswer[];
};

export type QuestionWithAnswer =
  (| NumericalQuestion
    | DatetimeQuestion
    | DateQuestion
    | WordQuestion
    | OpenQuestion
    | HelpProposeQuestion
    | SpellingQuestion)
  & {
    preSpeechSilenceTimeout?: number;
    postSpeechSilenceTimeout?: number;
    recognizerOptions?: {
      type: Recognizer['type'],
      model: Recognizer['model'],
    }
  };

export type BlockQuestion =
  | QuestionWithAnswer
  | ConditionalQuestion
  | EndQuestion
  | GoToBlockQuestion
  | HttpStep
  | StatementStep
  | SmsStep
  | SetVariableStep
  | DataCollectionStep
  | EmailStep
  | GenericOrderQuestion
  | RedirectStep
  | FunctionStep;

export type Block = {
  id: string;
  name: string;
  questions: BlockQuestion[];
  groupId?: string;
  description?: string;
  position?: { 
    x: number; 
    y: number 
  };
};

export type Fallback = {
  steps: BlockQuestion[];
  description?: string;
}

export type AssociatedEntity = Omit<AssociatedAnswer, 'reaction'>;

type SurveyIntentAttributes = {
  group?: string;
  entities: AssociatedEntity[];
};

export type SurveyIntent =
  (BlockSurveyIntent | WordSurveyIntent | EndSurveyIntent | StatementSurveyIntent);

export type BlockSurveyIntent = {
  name: string;
  block: string;
} & SurveyIntentAttributes;

export type WordSurveyIntent = {
  name: string;
  question: WordQuestion;
} & SurveyIntentAttributes;

export type StatementSurveyIntent = {
  name: string;
  question: StatementStep;
} & SurveyIntentAttributes;

export type EndSurveyIntent = {
  name: string;
  question: EndQuestion;
} & SurveyIntentAttributes;

export type SurveyDefinition = {
  blocks: Block[];
  intents: SurveyIntent[];
  callbacks: Callbacks[];
  variables: SurveyDefinitionVariable[];
  fallback?: Fallback; // TODO - make it not optional
  silenceFallback?: Fallback;
  groups: Group[];
};

export const callbackTypes = ['voiceMailDetected', 'callFinished', 'callNotAnswered'] as const;

export interface Callbacks {
  voiceMailDetected?: CallbackBlock;
  callFinished?: CallbackBlock;
  callNotAnswered?: CallbackBlock;
}

export type CallbackBlock = Block;

export type VariableOrigin = 'step' | 'contact' | 'system' | 'external';

export interface Variable {
  id: string;
  name: string;
  type?: VariableType;
  origin: VariableOrigin;
  sipHeaderName?: string;
}

export interface BooleanVariable extends Variable {
  type: VariableType.BOOLEAN;
}

export interface NumberVariable extends Variable {
  type: VariableType.NUMBER;
}

export interface StringVariable extends Variable {
  type: VariableType.STRING;
}

export interface DictionaryVariable extends Variable {
  type: VariableType.DICTIONARY;
  values: ReadonlyArray<string>;
}

export type SystemVariableScope =
  'creator'
  | 'planner'
  | 'callbackCallNotAnswered'
  | 'callbackVoicemailDetected'
  | 'callbackCallFinished';

export type VariableFromStep = {
  origin: 'step';
  id: string;
  name: string;
  type?: VariableType;
}
export type SystemVariable = {
  origin: 'system';
  isWritable?: boolean;
  id: string;
  name: string;
  type?: VariableType;
  scopes: SystemVariableScope[];
}

export type ExternalVariable = {
  origin: 'external';
  id: string;
  name: string;
  type?: VariableType;
  sipHeaderType: string;
}

export type SurveyDefinitionVariable =
  VariableFromStep
  | BooleanVariable
  | NumberVariable
  | DictionaryVariable
  | StringVariable
  | SystemVariable
  | ExternalVariable;

export const isDictionaryVariable = (variable: SurveyDefinitionVariable): variable is DictionaryVariable => variable.type === VariableType.DICTIONARY;

export const isStepVariable = (variable: SurveyDefinitionVariable): variable is VariableFromStep => variable.origin === 'step';
export const isSystemVariable = (variable: SurveyDefinitionVariable): variable is SystemVariable => variable.origin === 'system';

export type VariableName = {
  id: string;
  name: string;
  origin?: string;
}

export interface Bot {
  id: string;
  name: string;
  languageCode: LanguageCode;
  type?: string;
  organization: string;

  organizationId: string;
  customerVisible?: boolean;
  returnCalls?: {
    enabled: boolean;
    maxCallsToCustomer?: number;
    callsIntervalMs?: number;
  };
  phoneConfigs?: {
    [phoneNumber: string]: string | undefined;
  };
  conversationData?: {
    repeatMessage?: BasicMessage;
    failureMessage?: BasicMessage;
  };
  surveyDefinition?: SurveyDefinition;
  schedule?: Schedule;
  voiceConfigs: VoiceConfigType[],
  tags?: string[],
}

export type ReactionType = 'end' | 'word' | 'block' | 'statement';

export type AssociatedAnswerReactionType = 'no_reaction' | 'continue';

export type AssociatedAnswer = {
  id: string;
  entity: string;
  variableId?: string;
  reaction?: AssociatedAnswerReactionType;
}

export type EntityAnswer = {
  key: string;
  synonyms?: Synonym[];
  id?: string;
}

export interface RawEntityValue {
  key: string;
  synonyms: Synonym[];
}

export interface RawEntity {
  name: string;
  values: RawEntityValue[],
}

export type IntentSentence = string;

export type QuestionError = {
  stepId: string;
  error: string;
  field: string;
  moduleId?: string;
}

export type ConditionError = {
  variableId?: string;
  operator?: string;
  operand?: string;
}

export enum CommandType {
  RENAME = 'rename-module',
  CREATE = 'create-module',
  CHANGE_POSITION = 'change-position',
}

export type Command = RenameModuleCommand | CreateModuleCommand | ChangePositionModuleCommand;

interface RenameModuleCommand {
  command: CommandType.RENAME;
  payload: { moduleId: string; newName: string };
}

interface CreateModuleCommand {
  command: CommandType.CREATE;
  payload: { name: string, afterIndex: number };
}

interface ChangePositionModuleCommand {
  command: CommandType.CHANGE_POSITION;
  payload: { moduleId: string; position: { x: number; y: number } };
}


export const slugifyIntentName = (name: string) => slugify(name, { strict: true })