import classNames from 'classnames';
import _ from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { TextAreaWithVariables } from '../../../components/TextArea/TextAreaWithVariables';
import { useCurrentBot } from '../../../utils/CurrentBot';
import { BasicMessage, ConditionalMessage } from '../../model';
import { SurveyContext } from '../../SurveyCreatorContext';
import { DeleteOption } from '../optionsMenu/DeleteOption';

import { AddConditionalMessage } from './conditional/AddConditionalMessage';
import { MessageAudio, SurveyQuestionType } from './MessageAudio';
import styles from './SingleMessage.pcss';

type Props = {
  answerId?: string;
  dataTestPrefix: string;
  labelId?: string;
  label?: string;
  title?: string;
  message: BasicMessage;
  onChange: (message: BasicMessage, answerId?: string) => void;
  onDelete?: () => void;
  onConditionChange?: (message: BasicMessage) => void;
  surveyQuestionType?: SurveyQuestionType;
  disabled?: boolean;
  standalone?: boolean;
  errorMessage?: string
};

export const SingleMessageComponent = (props: Props) => {
  const context = useContext(SurveyContext);
  const variables = context.variables.getVariablesForCreator();

  return (
    <RawMessageComponent
      {...props}
      textArea={(
        <TextAreaWithVariables
          dataTest={`${props.dataTestPrefix}-message-text`}
          value={props.message.text}
          onChange={(value: string) => props.onChange && props.onChange({ ...props.message, text: value }, props.answerId)}
          disabled={props.disabled}
          availableVariables={variables}
          errorMessage={props.errorMessage}
        />
      )} />
  );
}

const RawMessageComponent = (props: Props & { textArea: JSX.Element }) => {
  const currentBot = useCurrentBot();

  return (
    <div data-test={`${props.dataTestPrefix}-message`} className={classNames(styles.container, { [styles.borderContainer]: props.standalone })}>
      {
        props.title && <div className={styles.title} data-test={`${props.dataTestPrefix}-title`}>
          {props.title}
          {
            props.onDelete &&
            <DeleteOption key={'delete-option'} onDelete={props.onDelete} alignToTop />
          }
        </div>
      }
      <div className={classNames(styles.header)}>
        <div className={styles.label} data-test="message-label">
          {props.labelId && <FormattedMessage id={props.labelId} />}
          {!!props.label && props.label}
        </div>
        <div className={styles.headerMenu}>
          {
            props.onConditionChange &&
            <div className={styles.conditionalButton} data-test={`${props.dataTestPrefix}-message-conditional-button`}>
              {
                // FIXME: find proper type instead 'message as unknown'
              }
              <AddConditionalMessage message={props.message as unknown as ConditionalMessage} onClick={_.noop} onChange={props.onConditionChange} dataTest={`${props.dataTestPrefix}-message-add-conditional`} labelId={'survey-creator.conditionalMessageButtonLabel'} buttonType={'link'} />
            </div>
          }
          {
            props.onDelete && !props.title &&
            <DeleteOption key={'delete-option'} onDelete={props.onDelete} />
          }
          <MessageAudio
            answerId={props.answerId}
            onChange={props.onChange}
            message={props.message}
            botId={currentBot.id}
            disabled={props.disabled}
          />
        </div>
      </div>
      {props.textArea}
    </div>
  );
}