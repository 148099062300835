import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {useParams} from 'react-router';

import {Header} from '../layout/Header';
import {MainPage} from '../layout/MainPage';
import {AnalyticsCommandPalette} from '../commandPalette/AnalyticsCommandPalette';
import {AnalyticsCommandPaletteProvider} from '../commandPalette/AnalyticsCommandPaletteProvider';
import {BlockDetails} from './components/BlockDetails';
import {BlocksAndIntents} from './components/BlocksAndIntents';
import {CallbackDetails} from './components/CallbackDetails';
import {EntityDetails} from './components/EntityDetails/EntityDetails';
import {IntentDetails} from './components/intentDetails/IntentDetails';
import {BlockDescriptionEmptyState} from './components/BlockDescriptionEmptyState/BlockDescriptionEmptyState';
import {StepComponent} from './components/StepComponent';
import {BotCreatorRouteMatch, useCreatorRouter} from './useCreatorRouter';
import {useSurveyCreatorView} from './useSurveyCreatorView';
import {VisualEditorContainer} from './VisualEditor/VisualEditorContainer';

import styles from './SurveyCreator.pcss';
import HistoryModal from './history/HistoryModal';
import {FallbackDetails} from './components/FallbackDetails';
import {SilenceFallbackDetails} from './components/SilenceFallbackDetails';
import {SurveyContext, SurveyContextType} from './SurveyCreatorContext';

export const SurveyCreator = React.memo(() => {
  const { blocks, intents } = React.useContext<SurveyContextType>(SurveyContext);
  const surveyCreatorView = useSurveyCreatorView();

  if (
    blocks.get() === undefined ||
    intents.get() === undefined
  ) {
    return (
      <MainPage>
        <Header />
        <div className={styles.errorText}>
          <FormattedMessage id="survey-creator.incorrectDefinition" />
        </div>
      </MainPage>
    );
  }

  return (
    <MainPage>
      <Header />
        <AnalyticsCommandPaletteProvider>
          <>
            {surveyCreatorView === 'visual' ? (
              <VisualEditorContainer />
            ) : (
              <CreatorContent />
            )}
          </>
          <AnalyticsCommandPalette />
        </AnalyticsCommandPaletteProvider>
        <HistoryModal />
    </MainPage>
  );
});

const CreatorContent = React.memo(() => {
    const {intents} = React.useContext<SurveyContextType>(SurveyContext);
    const {current} = useCreatorRouter();
    const params = useParams<BotCreatorRouteMatch>();
    const isInIntentContext = intents.getBySlugifiedName(params.intentId) || intents.new;




    const displayDetails = () => {
        if (isInIntentContext) {
            return <IntentDetails key={intents.new ? `new` : params.intentId}/>;
        } else if (current.callbackId) {
            return (
                <>
                    <CallbackDetails/>
                    <StepComponent descriptionElement={({onCreateDescription}) => <BlockDescriptionEmptyState messageId={`survey-creator.callback.${current.callbackId}.emptyState`} onCreateDescription={onCreateDescription} /> }/>
                </>
            );
        } else if (current.fallback) {
            return (
                <>
                    <FallbackDetails/>
                    <StepComponent descriptionElement={({onCreateDescription}) => <BlockDescriptionEmptyState messageId="survey-creator.fallback.emptyState" onCreateDescription={onCreateDescription} /> } />
                </>
            );
        } else if (current.silenceFallback) {
            return (
                <>
                    <SilenceFallbackDetails/>
                    <StepComponent descriptionElement={({onCreateDescription}) => <BlockDescriptionEmptyState messageId="survey-creator.silenceFallback.emptyState" onCreateDescription={onCreateDescription} /> }/>
                </>
            );
        }

        return (
            <>
                <BlockDetails/>
                <StepComponent descriptionElement={ ({onCreateDescription}) =>  <BlockDescriptionEmptyState messageId="survey-creator.addBlockDescription" onCreateDescription={onCreateDescription} /> }/>
            </>
        );
    };

    return <div
        className={isInIntentContext ? styles.intentContent : styles.blockContent}
    >
        <BlocksAndIntents/>
        {params.tabName === 'scenario' && displayDetails()}
        {params.tabName === 'entities' && <EntityDetails/>}
    </div>
})
