import _ from 'lodash';
import { inject } from 'regexparam';
import * as React from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { BotPicker } from '../components/BotPicker/BotPicker';
import { BotIcon, BugIcon, CallIcon, ConversationsIcon, GraduationCapIcon, StatisticsIcon } from '../icons';
import { SettingsIcon } from '../icons/SettingsIcon';
import dashboardIcon from '../images/dashboard-icon.svg';
import { currentUserRoles } from '../selectors';
import { Bot } from '../surveyCreator/model';
import { getBotUrlId, getSlugifiedBotParams, useCurrentBot } from '../utils/CurrentBot';
import { UserRole } from '../utils/CurrentUser';
import { useFetchBotConfig } from '../views/Settings/Settings.hooks';
import { BotClock } from './header/BotClock';
import { ReportsIcon } from '../icons/ReportsIcon';
import useBranding from '../hooks/useBranding/useBranding';
import { useReportsNotifications } from '../reports/useReportsNotifications';
import { HeaderContainer } from './header/HeaderContainer';
import { HeaderLogo } from './header/HeaderLogo';
import { KnowledgeBaseIcon } from './header/KnowledgeBaseIcon';
import { UserSettingsIcon } from './header/UserSettingsIcon';
import { HeaderActionContainer } from './header/HeaderActionContainer';
import { HeaderLink } from './header/HeaderLink';

import styles from './Header.pcss';

export const Header = React.memo(() => {
    const { favicon, faviconWithNotification } = useBranding();
    const roles = useSelector(currentUserRoles);
    const isAdmin = roles.includes(UserRole.ADMIN);
    const isCreator = roles.includes(UserRole.CREATOR);
    const hasPlannerRole = roles.includes(UserRole.PLANNER);
    const isInspectorUser = roles.includes(UserRole.INSPECTOR);
    const bot = useCurrentBot();
    const currentBotConfig = useFetchBotConfig()
    const surveyBot = _.get(bot, 'type') === 'survey';
    const history = useHistory();
    const match = useRouteMatch();
    const location = useLocation();
    const currentBot = useCurrentBot();

    const { showNotificationIcon } = useReportsNotifications(currentBot.id);
    const faviconUrl = showNotificationIcon ? faviconWithNotification : favicon;

    const changeBot = useCallback(
        (newBot: Bot) => {
            history.push({
                ...location,
                search: '',
                pathname: inject(match.path, {
                    ...match.params,
                    ...getSlugifiedBotParams(newBot),
                    callId: undefined
                })
            });
        },
        [location, history, match]
    );

    const HEADER_LINKS = [
        {
            path: '/reports',
            label: 'header.reports',
            IconComponent: ReportsIcon,
            available: surveyBot
        },
        {
            path: '/statistics',
            label: 'header.statistics',
            IconComponent: StatisticsIcon
        },
        {
            path: '/survey-creator',
            label: 'header.survey-creator',
            IconComponent: BotIcon,
            dataTest: 'app-header-survey-creator',
            available: (isAdmin || isCreator) && surveyBot
        },
        {
            path: '/contacts',
            label: 'header.contacts',
            IconComponent: CallIcon,
            dataTest: 'app-header-contacts',
            available: (isAdmin || hasPlannerRole) && surveyBot,
            additionalPath: '/planer'
        },
        {
            path: '/learning',
            label: 'header.learning',
            IconComponent: GraduationCapIcon,
            dataTest: 'app-header-learn',
            available: isAdmin
        },
        {
            path: '/inspector',
            label: 'header.inspector',
            IconComponent: BugIcon,
            dataTest: 'app-header-inspector',
            available: (isAdmin || isInspectorUser)
        },
        {
            path: '/settings',
            label: 'header.settings',
            IconComponent: SettingsIcon,
            dataTest: 'app-header-survey',
            available: surveyBot
        }
    ];

    return (
        <>
            <Helmet 
                defer={false}
            >
                <link rel="shortcut icon" id="favicon" type="image/x-icon" href={faviconUrl} />
            </Helmet>
            <HeaderContainer>
                <div>
                    <Link to={'/'} className={styles.logoContainer}>
                        <HeaderLogo className={styles.logo} />
                        <img src={dashboardIcon} className={styles.dashboardIcon} alt="dashboard" />
                    </Link>
                    <BotPicker changeCurrentBot={changeBot} />
                </div>
                <div className={styles.linksWrapper}>
                    <HeaderLink
                        to={`/${getBotUrlId(bot)}`}
                        label="header.conversations"
                        isActive={(linkMatch, linkLocation) =>
                            linkMatch &&
                            !HEADER_LINKS.find(
                                link => {

                                    return linkLocation.pathname.startsWith(`/${getBotUrlId(bot)}${link.path}`) ||
                                        (link.additionalPath &&
                                            linkLocation.pathname.startsWith(`/${getBotUrlId(bot)}${link.additionalPath}`))
                                }
                            )
                        }
                        IconComponent={ConversationsIcon}
                    />
                    {HEADER_LINKS.filter(link => link.available === undefined || link.available).map(link => (
                        <HeaderLink
                            data-test={link.dataTest}
                            key={link.path}
                            to={`/${getBotUrlId(bot)}${link.path}`}
                            label={link.label}
                            isActive={(linkMatch, linkLocation) => {
                                return (
                                    linkLocation.pathname.startsWith(`/${getBotUrlId(bot)}${link.path}`) ||
                                    (link.additionalPath &&
                                        linkLocation.pathname.startsWith(
                                            `/${getBotUrlId(bot)}${link.additionalPath}`
                                        ))
                                );
                            }}
                            withBadge={link.path === '/reports' && showNotificationIcon}
                            IconComponent={link.IconComponent}
                        />
                    ))}
                </div>
                <HeaderActionContainer>
                    {(bot && currentBotConfig.data) && <BotClock timeZone={currentBotConfig?.data?.timeZone} languageCode={bot.languageCode} />}
                    <KnowledgeBaseIcon />
                    <UserSettingsIcon />
                </HeaderActionContainer>
            </HeaderContainer>
        </>
    );
})