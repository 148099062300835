import * as React from 'react';
import {useContext} from 'react';

import {SelectComponent, Value} from '../../../components/Select/Select';
import {SurveyIntent} from '../../model';
import {Intent, IntentsContext, SurveyContext} from '../../SurveyCreatorContext';

import styles from './IntentDetailsHeader.pcss';
import { sortByNameComparator } from '../../../utils/SortUtils';

type Props = {
    isNew: boolean,
    currentIntent: SurveyIntent,
    onChange: (id: string) => void
};

export const IntentDetailsHeader = ({onChange, isNew, currentIntent}: Props) => {
    const {intents} = useContext(SurveyContext);
    const allIntents = useContext(IntentsContext);
    const intentName = getIntentName(allIntents, currentIntent);
    const selectedIntent = {
        id: currentIntent.name,
        name: intentName
    };
    const selectedIntents = intents.get();
    const intentOptions = getIntentSelectOptions(allIntents, selectedIntents)

    return isNew
        ? <IntentSelect selected={selectedIntent}
                        options={intentOptions}
                        onChange={onChange}/>
        : <IntentName name={intentName}/>
}

type SelectProps = {
    selected: Value;
    options: Intent[]
    onChange: (value: string) => void
};

const IntentSelect = ({selected, options, onChange}: SelectProps) =>
    <div data-test="intent-select" className={styles.intentSelect}>
        <SelectComponent value={selected}
                         isSearchable
                         options={options.map(toOption).sort(sortByNameComparator)}
                         onChange={value => onChange(value.id)}
        />
    </div>

const IntentName = (props: { name: string }) =>
    <div data-test="intent-name" className={styles.intentName}>
        {props.name}
    </div>

const getIntentName = (allIntents: Intent[], currentIntent: SurveyIntent) =>
    allIntents
        .find(item => item.name === currentIntent.name)
        ?.displayName
    ?? currentIntent.name;

const getIntentSelectOptions = (allIntents: Intent[], intents: SurveyIntent[]) => {
    const selectedIntentNames = new Set(intents.map(it => it.name));
    return allIntents
        .filter(it => !selectedIntentNames.has(it.name))
}
const toOption = ({name, displayName}: { name: string, displayName?: string }) => ({
    id: name,
    name: displayName ?? name
});
