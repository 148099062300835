import _ from 'lodash';
import React, { useContext } from 'react';

import { ElementWithLabel } from '../../../components/ElementWithLabel/ElementWithLabel';
import { SelectComponent } from '../../../components/Select/Select';
import { StringChipsComponent } from '../../../components/chips/ChipsComponent';
import { Variables } from '../variables/Variables';
import { VariableType, typeOptions } from '../variables/Variables.utils';
import { useVariableCreation } from '../variables/useVariableCreation';
import { Input } from '../../../components/Input';
import { SurveyContext, SurveyContextType } from '../../SurveyCreatorContext';
import { InputsArrayElement } from '../InputsArray/InputsArrayElement';
import { AddButton } from '../AddButton';

import styles from './Mapping.pcss';

export type Mapping = {
  variable: string;
  path: string;
  type: `${VariableType}`;
  possibleValues?: string[];
};

type MappingProps = {
  mapping: Mapping[];
  onChange: (mappings: Mapping[]) => void;
};

export const Mappings = ({ mapping, onChange }: MappingProps) => {
  const { variables } = useContext<SurveyContextType>(SurveyContext);

  const onMappingChange = (index: number) => (changedMapping: Mapping) => {
    onChange(
      mapping.map((oldMapping, id) =>
        id === index ? changedMapping : oldMapping
      )
    );
  };

  const addMapping = () =>
    onChange([
      ...mapping,
      { type: VariableType.STRING, path: '', variable: '' },
    ]);

  const removeMapping = (index: number) => {
    const mappingToRemove = mapping[index];
    variables.changePendingVariableUsage(mappingToRemove.variable);
    onChange(mapping.filter((value, id) => id !== index));
  };

  return (
    <>
      {mapping.map((mappingEntry, index) => (
        <InputsArrayElement
          key={index}
          onRemove={() => removeMapping(index)}
          dataTest="remove-mapping-button"
        >
          <MappingEntry
            mapping={mappingEntry}
            onChange={onMappingChange(index)}
          />
        </InputsArrayElement>
      ))}
      <AddButton
        onClick={addMapping}
        dataTest="add-mapping-button"
        labelId="survey-creator.mapping.addMappingButton"
      />
    </>
  );
};

type MappingEntryProps = {
  mapping: Mapping;
  onChange: (value: Mapping) => void;
};

const MappingEntry = ({ mapping, onChange }: MappingEntryProps) => {
  const handleChange = (key: string) => (value: string) => {
    onChange({
      ...mapping,
      [key]: value,
    });
  };

  const handleTypeChange = (value: any) => {
    const { possibleValues, ...rest } = {
      ...mapping,
      type: value.id,
    };
    onChange(rest);
  };

  const handlePossibleValueChange = (values: string[]) => {
    onChange({
      ...mapping,
      possibleValues: values,
    });
  };

  const { selectedOption, options, handleSelect, handleRename, handleCreate } =
    useVariableCreation(handleChange('variable'), mapping.variable);

  return (
    <>
      <ElementWithLabel
        className={styles.boxContainer}
        labelId="survey-creator.mapping.mappingVariable"
        noPadding
        dataTest="variable"
      >
        <Variables
          selectedOption={selectedOption}
          options={options}
          handleSelect={handleSelect}
          handleRename={handleRename}
          handleCreate={handleCreate}
        />
      </ElementWithLabel>
      <ElementWithLabel
        labelId="survey-creator.mapping.mappingPath"
        noPadding
        className={styles.boxContainer}
      >
        <Input
          dataTest="path"
          value={_.isNil(mapping.path) ? '' : mapping.path}
          onChange={handleChange('path')}
          width="100%"
        />
      </ElementWithLabel>
      <ElementWithLabel
        labelId="survey-creator.mapping.mappingType"
        noPadding
        className={styles.boxContainer}
      >
        <div data-test="mapping-type">
          <SelectComponent
            options={typeOptions}
            onChange={handleTypeChange}
            value={{
              id: mapping.type || VariableType.STRING,
              name: mapping.type || VariableType.STRING,
            }}
          />
          {mapping.type === VariableType.DICTIONARY && (
            <StringChipsComponent
              data-test="mapping-possible-values"
              i18nKey=""
              items={mapping.possibleValues || []}
              onChange={handlePossibleValueChange}
            />
          )}
        </div>
      </ElementWithLabel>
    </>
  );
};
