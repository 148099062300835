import * as React from 'react';

import {Label} from '../../components/Label';
import {Message, MultipleMessages} from '../model';

import styles from './DatetimeMessages.pcss';
import {MessageComponent} from './messages/Message';
import {MultipleMessagesComponent} from './messages/MultipleMessages';
import {StepElementProps} from './StepElementProps';
import {useContext} from 'react';
import {SurveyContext, SurveyContextType} from '../SurveyCreatorContext';
type Props = {
    id: string,
    date: {
        message: Message,
        repeatMessages: MultipleMessages,
        fromIntentMessage: Message,
    },
    hour: {
        message: Message,
        repeatMessages: MultipleMessages,
        fromIntentMessage: Message,
    },
    maxRepeatQuestionCount?: number;
}
export const DatetimeMessages = ({question, onChange}: StepElementProps<Props>) => {
    const { questions: { withError } } = useContext<SurveyContextType>(SurveyContext);

    const changeMessage = (type: 'date' | 'hour', messageType: 'message' | 'repeatMessages' | 'fromIntentMessage') => (message: Message | MultipleMessages) => {
        onChange({
            ...question,
            [type]: {
                ...question[type],
                [messageType]: message
            }
        });
    };

    const setMaxRepeatQuestionCount = (value: number) => {
        onChange({
            ...question,
            maxRepeatQuestionCount: value
        });
    };

    return (
        <>
            <div>
                <Label labelId={'survey-creator.datetime.dateLabel'} />
                <div className={styles.block}>
                    <MessageComponent message={question.date.message} dataTestPrefix={'question-date'}
                                      labelId={'survey-creator.messageLabel'}
                                      onChange={changeMessage('date', 'message')}
                                      errors={withError(question.id, 'date.message')}
                                      path={'date.message'}
                    />
                    <MultipleMessagesComponent messages={question.date.repeatMessages} dataTestPrefix={'question-date-repeat'}
                                               onChange={changeMessage('date', 'repeatMessages')}
                                               onMaxRepeatQuestionCountChange={setMaxRepeatQuestionCount}
                                               maxRepeatQuestionCount={question.maxRepeatQuestionCount}
                                               errors={withError(question.id, 'date.repeatMessages')}
                    />
                    <MessageComponent message={question.date.fromIntentMessage || {text: ''}}
                                      dataTestPrefix={'question-date-from-intent'}
                                      labelId={'survey-creator.fromIntentMessageLabel'}
                                      onChange={changeMessage('date', 'fromIntentMessage')}
                                      path={'date.message'}
                    />
                </div>
            </div>
            <div>
                <Label labelId={'survey-creator.datetime.timeLabel'}/>
                <div className={styles.block}>
                    <MessageComponent message={question.hour.message} dataTestPrefix={'question-hour'}
                                      labelId={'survey-creator.messageLabel'}
                                      onChange={changeMessage('hour', 'message')}
                                      errors={withError(question.id, 'hour.message')}
                                      path={'hour.message'}
                    />
                    <MultipleMessagesComponent messages={question.hour.repeatMessages} dataTestPrefix={'question-hour-repeat'}
                                               onChange={changeMessage('hour', 'repeatMessages')}
                                               onMaxRepeatQuestionCountChange={setMaxRepeatQuestionCount}
                                               maxRepeatQuestionCount={question.maxRepeatQuestionCount}
                                               errors={withError(question.id, 'hour.repeatMessages')}
                    />
                    <MessageComponent message={question.hour.fromIntentMessage || {text: ''}}
                                      dataTestPrefix={'question-hour-from-intent'}
                                      labelId={'survey-creator.fromIntentMessageLabel'}
                                      onChange={changeMessage('hour', 'fromIntentMessage')}
                                      path={'hour.message'}
                    />
                </div>
            </div>
        </>
    );
};
