import React, { useContext } from 'react';
import classname from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExecutableFunctionDefinition, FunctionStep } from '../../model';
import { SelectWithLabel } from '../../../components/SelectWithLabel/SelectWithLabel';
import { Value } from '../../../components/Select/Select';
import { Box } from '../../../components/Box/Box';
import { SurveyContext, SurveyContextType } from '../../SurveyCreatorContext';
import { renderOutputTypeToWidget } from './FunctionStepOutputWidgets';
import styles from './FunctionStepComponent.pcss';
import {FunctionStepInputsWrapperComponent} from './inputs/FunctionStepInputsWrapperComponent';

export function isLLMFunction(fn: ExecutableFunctionDefinition<any, any>) {
  return fn?.type === 'llm';
}

type FunctionStepProps = {
  functionStep: FunctionStep;
  onChange: (step: FunctionStep) => void;
};

export const FunctionStepComponent: React.FC<FunctionStepProps> = ({
  functionStep,
  onChange,
}) => {
  const intl = useIntl();
  const { executableFunctions } = useContext<SurveyContextType>(SurveyContext);

  const handleFunctionNameChange = (value: Value) =>
    onChange({
      ...functionStep,
      functionName: value.id,
      functionInputs: {},
      functionOutputs: {},
    });
  const handleFunctionOutputVariableChange = (key: string, value: string) =>
    onChange({
      ...functionStep,
      functionOutputs: {
        ...functionStep.functionOutputs,
        [key]: value,
      },
    });

  const executableFunctionDefinitions = executableFunctions.get();
  const functionNameOptions: Value[] =
    executableFunctionDefinitions.map((fn) => {
      return {
        id: fn.name,
        name: isLLMFunction(fn)
          ? fn.name
          : intl.formatMessage({
              id: `survey-creator.executableFunctions.functionNames.${fn.name}`,
            }),
        badge: isLLMFunction(fn) ? 'LLM' : undefined,
      };
    });

  const executableFunction = executableFunctionDefinitions.find(
    ({ name }) => name === functionStep.functionName
  );
  const functionInputs = executableFunction?.inputs || [];
  const functionOutputs = executableFunction?.outputs || [];
  const functionDescription = executableFunction?.description;

  return (
    <Box border noPadding>
      <SelectWithLabel
        dataTest="function-name-select"
        labelId="survey-creator.functionStepLabel"
        onChange={handleFunctionNameChange}
        options={functionNameOptions}
        value={functionNameOptions.find(
          ({ id }) => id === functionStep.functionName
        )}
      />
      {functionDescription && (
        <div
          className={classname(styles.description, styles.descriptionContainer)}
        >
          {intl.formatMessage({
            id: `survey-creator.functionStep.functions.${functionDescription}`,
          })}
        </div>
      )}

      <FunctionStepInputsWrapperComponent
        functionInputs={functionInputs}
        executableFunction={executableFunction}
        functionStep={functionStep}
        onChange={onChange}
      />

      {functionOutputs.map(({ name, description, type }) => {
        const Widget = renderOutputTypeToWidget(type);
        const displayedDescription = isLLMFunction(executableFunction)
          ? description
          : intl.formatMessage({
              id: `survey-creator.functionStep.functions.${description}`,
            });

        return (
          <Box dataTest={`function-output-${name}`} key={name}>
            <div className={styles.label}>
              {isLLMFunction(executableFunction) ? (
                name
              ) : (
                <FormattedMessage
                  id={`survey-creator.functionStep.functions.${name}`}
                />
              )}
            </div>
            {displayedDescription && (
              <div className={styles.description}>{displayedDescription}</div>
            )}
            <Widget
              value={functionStep.functionOutputs[name]}
              onChange={(changedValue) =>
                handleFunctionOutputVariableChange(name, changedValue)
              }
            />
          </Box>
        );
      })}
    </Box>
  );
};
