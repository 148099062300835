import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { TranscriptItemFunction } from '../models/Transcript';
import { Modal } from '../../components/Modal/Modal';
import { ModalContent } from '../../components/ModalContent/ModalContent';
import { TextLabel } from '../../components/Label';
import { Highlighter, prettyPrintJson } from '../../components/Highlighter';
import { useExecutableFunctions } from '../../surveyCreator/useExecutableFunctions';
import { Loader } from '../../components/Loader';
import styles from './StepLineDetails.pcss';
import { StepLinkExpired } from './StepLinkExpired';
import { isLLMFunction } from '../../surveyCreator/components/FunctionStep/FunctionStepComponent';

type Props = {
  onClose: () => void;
  line: TranscriptItemFunction;
  stepLink: string;
  isStepLinkExpired: boolean | undefined;
};

export function FunctionStepLineDetails({ line, onClose, stepLink, isStepLinkExpired }: Props) {
  const intl = useIntl();

  const { data: executableFunctions, isLoading } = useExecutableFunctions();
  const executableFunction = executableFunctions?.find(
    (fn) => fn.name === line.functionName
  );

  return (
    <Modal
      modalSize="medium"
      dataTest="function-step-details"
      noPadding
      noFixedHeight
    >
      <ModalContent
        headerContent={
          <span data-test="function-step-details-header">{line.stepName}</span>
        }
        onClose={onClose}
        closeButtonDataTest="function-step-details-close"
        displayBottomSeparator={false}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div className={styles.content}>
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.variables.modal.stepLink',
                })}
              />
              {
                isStepLinkExpired
                  ? <StepLinkExpired />
                  : <Link
                    to={stepLink}
                    target="_blank"
                    data-test="function-step-details-step-link"
                    className={styles.stepLink}
                  >
                    {line.stepName}
                  </Link>
              }
            </div>
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.function.name',
                })}
              />
              <span data-test="function-step-details-function-name">
                <TextLabel
                  label={
                    isLLMFunction(executableFunction)
                      ? executableFunction.name
                      : intl.formatMessage({
                          id: `survey-creator.executableFunctions.functionNames.${executableFunction.name}`,
                        })
                  }
                />
              </span>
            </div>
            <div className={styles.entry}>
              <TextLabel
                className={styles.stepEntry}
                label={intl.formatMessage({
                  id: 'conversations.function.inputs',
                })}
              />
              {executableFunction.inputs.map((input) => (
                <div key={input.name} data-test="function-step-details-input">
                  <TextLabel
                    label={
                      isLLMFunction(executableFunction) 
                        ? input.name
                        : intl.formatMessage({ id: `survey-creator.functionStep.functions.${input.name}`})
                      }
                    dataTest="function-step-details-input-name"
                  />
                  <Highlighter dataTest="function-step-details-input-value">
                    {line.input[input.name] === undefined ||
                      typeof line.input[input.name] === 'boolean'
                      ? intl.formatMessage({
                        id: line.input[input.name]
                          ? 'conversations.function.enabled'
                          : 'conversations.function.disabled',
                      })
                      : prettyPrintJson(line.input[input.name])}
                  </Highlighter>
                  {line.errors?.[input.name] && (
                    <p
                      className={styles.error}
                      data-test="function-step-details-input-error"
                    >
                      {line.errors[input.name]}
                    </p>
                  )}
                </div>
              ))}
            </div>
            {line.result && (
              <div className={styles.entry}>
                <TextLabel
                  className={styles.stepEntry}
                  label={intl.formatMessage({
                    id: 'conversations.function.outputs',
                  })}
                />
                {executableFunction.outputs.map((output) => (
                  <div
                    key={output.name}
                    data-test="function-step-details-output"
                  >
                    <TextLabel
                      label={
                        isLLMFunction(executableFunction) 
                          ? output.name
                          : intl.formatMessage({id: `survey-creator.functionStep.functions.${output.name}`})
                        }
                      dataTest="function-step-details-output-name"
                    />
                    <Highlighter dataTest="function-step-details-output-value">
                      {prettyPrintJson(line.result[output.name])}
                    </Highlighter>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </ModalContent>
    </Modal>
  );
}
